import { useCallback, useMemo } from 'react'
import { Box, Center, Spinner, Tabs, useBoolean } from 'ui-lib'
import { isDefined } from 'utils'

import { ExpensesCountByStatus, ExpenseStatus } from '@/gql'
import { useQueryParamState } from '@/src/hooks/misc'

import { ExpensesTable } from '../expenses-table/expenses-table'
import {
  IN_REVIEW_STATUSES,
  READY_TO_SYNC_STATUSES,
  SYNCED_STATUSES,
  WAITING_FOR_CARDHOLDER_STATUSES,
} from '../expenses-table/helpers'
import { ExpensesGridProvider, useDataGridExpenses } from '../expenses-table/use-data-grid-expenses'

type TabConfig = {
  label: string
  statuses: ExpenseStatus[]
  getCount: (counts: ExpensesCountByStatus) => number | number[] | null | undefined
}

export const TABS = {
  0: 'Needs review',
  1: 'Ready to Sync',
  2: 'Waiting for Cardholder',
  3: 'Synced',
} as const

const TABS_CONFIG: Record<number, TabConfig> = {
  0: {
    label: TABS[0],
    statuses: IN_REVIEW_STATUSES,
    getCount: (counts) => counts?.IN_REVIEW ?? 0,
  },
  1: {
    label: TABS[1],
    statuses: READY_TO_SYNC_STATUSES,
    getCount: (counts) => [counts?.REVIEWED ?? 0, counts?.FAILED ?? 0],
  },
  2: {
    label: TABS[2],
    statuses: WAITING_FOR_CARDHOLDER_STATUSES,
    getCount: (counts) => [counts?.CREATED ?? 0, counts?.REQUEST_FOR_INFORMATION ?? 0],
  },
  3: {
    label: TABS[3],
    statuses: SYNCED_STATUSES,
    getCount: (counts) => [counts?.SYNCED ?? 0],
  },
}

interface ExpensesTabsInnerProps {
  currentTab: number
  onTabChange: (tab: number) => void
}

const getFormattedCount = (count: number | null | undefined): string => {
  return isDefined(count) ? `(${count})` : ''
}

const getMixedFormattedCount = (counts: (number | null | undefined)[]): string => {
  const noCount = counts.every((count) => !isDefined(count))
  if (noCount) {
    return ''
  }
  const totalCount = counts.filter(isDefined).reduce((acc, count) => acc + (isDefined(count) ? count : 0), 0)
  return getFormattedCount(totalCount)
}

const ExpensesTabsInner = ({ currentTab, onTabChange }: ExpensesTabsInnerProps) => {
  const { expensesCountByStatus, isLoading, refetchExpensesData } = useDataGridExpenses()
  const [isRefetching, setIsRefetching] = useBoolean(false)

  const handleTabChange = useCallback(
    async (tabIndex: number): Promise<void> => {
      onTabChange(tabIndex)
      const tabConfig = TABS_CONFIG[tabIndex]

      setIsRefetching.on()
      await refetchExpensesData({
        currentStatuses: tabConfig.statuses,
        selectedFilters: {},
      })
      setIsRefetching.off()
    },
    [onTabChange, refetchExpensesData, setIsRefetching]
  )

  const tabs = useMemo(() => {
    return Object.entries(TABS_CONFIG).map(([index, config]) => {
      const count = config.getCount(expensesCountByStatus ?? {})
      const formattedCount = Array.isArray(count) ? getMixedFormattedCount(count) : getFormattedCount(count)

      return (
        <Tabs.Tab key={index} px="0" mr={Number(index) < 3 ? '6' : '0'} onClick={() => handleTabChange(Number(index))}>
          {config.label} {formattedCount}
        </Tabs.Tab>
      )
    })
  }, [expensesCountByStatus, handleTabChange])

  const isReadyToSyncTab = currentTab === 1

  return (
    <Tabs variant="legacy" h="full" defaultIndex={currentTab}>
      <Tabs.TabList borderBottom="1px solid" borderColor="border-soft" pb="1">
        {tabs}
      </Tabs.TabList>
      <Box pt="6" h="full">
        {isLoading || isRefetching ? (
          <Center height="10rem">
            <Spinner />
          </Center>
        ) : (
          <ExpensesTable canSyncExpenses={isReadyToSyncTab} />
        )}
      </Box>
    </Tabs>
  )
}

export const ExpensesTabs = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useQueryParamState<number>('currentTab')

  return (
    <ExpensesGridProvider currentTab={currentTab ?? 0}>
      <ExpensesTabsInner
        currentTab={currentTab ?? 0}
        onTabChange={(tab: number) => {
          setCurrentTab(tab)
        }}
      />
    </ExpensesGridProvider>
  )
}
