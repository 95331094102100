import { isDefined, useBoolean, useToast } from 'ui-lib'

import { ExpenseErrorType, ExpenseStatus, useUpdateExpensesMutation } from '@/gql'

type UpdateExpenseData = {
  status?: ExpenseStatus
  memo?: string
  entityCategoryId?: string | null
  entityTaxCodeId?: string | null
  entityMerchantId?: string | null
  entityClassId?: string | null
}

type UpdateExpenses = {
  expensesIds: string[]
  input: UpdateExpenseData
}

export const useUpdateExpenses = () => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useBoolean()
  const [updateExpensesMutation] = useUpdateExpensesMutation()

  const updateExpenses = async ({ expensesIds, input }: UpdateExpenses) => {
    setIsLoading.on()
    let updatedExpenseIds: string[] = []
    let errors: ExpenseErrorType[] = []
    try {
      const { data } = await updateExpensesMutation({
        variables: {
          body: {
            expensesIds,
            status: input.status,
            memo: input.memo,
            entityCategoryId: input.entityCategoryId,
            entityTaxCodeId: input.entityTaxCodeId,
            entityMerchantId: input.entityMerchantId,
            entityClassId: input.entityClassId,
          },
        },
      })
      errors = data?.updateExpenses?.errors ?? []
      updatedExpenseIds = data?.updateExpenses?.updatedExpenses.map((expense) => expense.id) ?? []
      if (isDefined(errors) && errors.length > 0) {
        toast({
          title: 'Something went wrong',
          description: 'Some expenses failed to update',
          status: 'error',
          position: 'top',
        })
      }
    } catch (error) {
      console.error(error)
      toast({
        title: 'Something went wrong while updating the expenses',
        status: 'error',
        position: 'top',
      })
    }
    setIsLoading.off()

    return { updatedExpenseIds, errors }
  }

  return { updateExpenses, isLoading }
}
