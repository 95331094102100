import { useRouter } from 'next/router'
import { Box } from 'ui-lib'

import { FundingStep, FundingWithOriginationFeeProvider, useFundingWithOriginationFee } from '@/src/contexts/capital'

import { TopStepperTemplate } from '../stepper-template'

type FundingWithOriginationFeeTemplateProps = React.ComponentProps<typeof FundingWithOriginationFeeProvider>

interface TemplateProps {
  children: React.ReactNode
}

const STEPS: Record<
  string,
  {
    key: FundingStep
    label: string
    position: number
  }
> = {
  purpose: {
    key: 'purpose',
    label: 'Purpose',
    position: 0,
  },
  'financing-plan': {
    key: 'financing-plan',
    label: 'Financing plan',
    position: 1,
  },
  review: {
    key: 'review',
    label: 'Review',
    position: 2,
  },
}

const Template = (props: TemplateProps) => {
  const router = useRouter()
  const { currentStep, goToStep } = useFundingWithOriginationFee()

  return (
    <TopStepperTemplate
      currentStep={currentStep}
      steps={currentStep === 'complete' ? {} : STEPS}
      onExit={() => router.push('/capital')}
      onStepChange={goToStep}
      showConfirmationOnExit={currentStep !== 'complete'}
    >
      <Box maxWidth="440px" mx="auto">
        {props.children}
      </Box>
    </TopStepperTemplate>
  )
}

export const FundingWithOriginationFeeTemplate = ({
  children,
  onComplete,
}: Pick<FundingWithOriginationFeeTemplateProps, 'children' | 'onComplete'>) => (
  <FundingWithOriginationFeeProvider onComplete={onComplete}>
    <Template>{children}</Template>
  </FundingWithOriginationFeeProvider>
)
