import '@/global.css'

import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { SessionProvider } from 'next-auth/react'
import React, { ReactNode, useCallback } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import AuthOnly from '@/src/components/atoms/AuthOnly'
import ThemeProvider from '@/src/components/atoms/ThemeProvider'
import { ErrorBoundary } from '@/src/components/error-boundary'
import { CapitalApplicationContextProvider } from '@/src/contexts/capital/application'
import { CustomApolloProvider, FeatureGatesProvider } from '@/src/contexts/misc'
import { logger } from '@/src/logging/logger'
import { wrapper } from '@/src/redux/store'
import { analytics } from '@/src/utils/misc'

interface MyAppProps extends AppProps {
  Component: AppProps['Component'] & {
    auth?: boolean
    skipAccountVerification?: boolean
  }
}

const GlobalModals = dynamic(() => import('@/src/components/atoms/GlobalModals'), {
  ssr: false,
})

const AppInitializers = dynamic(() => import('@/src/components/misc').then((mod) => mod.AppInitializers), {
  ssr: false,
})

analytics.on('track', (event, properties, options) => {
  logger.info('Tracking custom event', { event, properties, options })
})

analytics.on('identify', (event, properties, options) => {
  logger.info('Tracking identify', { event, properties, options })
})

export const ContextWrapper = ({ children, pathname }: { children: ReactNode; pathname: string }) => {
  /**
   * This function should be updated in case we want to add router based context
   */
  const wrapper = <>{children}</>
  if (pathname.includes('/capital')) {
    return <CapitalApplicationContextProvider>{children}</CapitalApplicationContextProvider>
  }
  return wrapper
}

const App = ({ Component, router, pageProps: { session, ...pageProps } }: MyAppProps) => {
  const { store } = wrapper.useWrappedStore(pageProps)

  const ActivePage = useCallback(() => {
    let FinalComponent = <Component {...pageProps} />
    if (Component.auth ?? false) {
      FinalComponent = (
        <AuthOnly skipAccountVerification={Component.skipAccountVerification}>
          <FeatureGatesProvider pageProps={pageProps}>
            <GlobalModals />
            {FinalComponent}
          </FeatureGatesProvider>
        </AuthOnly>
      )
    }

    return FinalComponent
  }, [Component, pageProps])

  return (
    <ReduxProvider store={store}>
      <SessionProvider session={session}>
        <CustomApolloProvider pageProps={pageProps}>
          <ThemeProvider cookies={pageProps.cookies}>
            <ErrorBoundary>
              <AppInitializers>
                <ContextWrapper pathname={router.pathname}>
                  <ActivePage />
                </ContextWrapper>
              </AppInitializers>
            </ErrorBoundary>
          </ThemeProvider>
        </CustomApolloProvider>
      </SessionProvider>
    </ReduxProvider>
  )
}

export default App
