import { KeepFeatureFlag } from 'services/src/featureFlag/featureFlags'

import { useAppSelector } from '@/src/redux/hooks'

/**
 * Validates that all the received feature flags are enabled for the current user
 * @param flags Feature flags to validate
 * @returns Boolean indicating if all feature flags are enabled
 */
export const useUserHasAllFeatureFlags = (...flags: readonly KeepFeatureFlag[]): boolean | null => {
  const { flags: userFeatureFlags } = useAppSelector((state) => state.featureFlags)

  return flags.every((flag) => {
    return userFeatureFlags.includes(flag)
  })
}
