import { Alert, AlertDescription, Box, Button, Flex, Pencil, Text, VStack } from 'ui-lib'
import { isDefined } from 'utils'

interface ReviewItemProps {
  label: string
  value: string | string[]
}

const ReviewItem = ({ label, value }: ReviewItemProps) => {
  return (
    <VStack key={label} spacing="0" alignItems="flex-start" maxW="full">
      <Text textStyle="subheading-md" textColor="text-soft" textTransform="uppercase" mb="2" letterSpacing="0.06em">
        {label}
      </Text>
      {Array.isArray(value) &&
        value.map((val) => (
          <Text key={val} textStyle="paragraph-lg" textColor="text-primary" maxW="full">
            {val}
          </Text>
        ))}
      {!Array.isArray(value) && (
        <Text textStyle="paragraph-lg" textColor="text-primary" maxW="full">
          {value}
        </Text>
      )}
    </VStack>
  )
}

interface Props {
  title: string
  onEdit: () => void
  infoMessage?: string
  leftItems: { label: string; value: string }[]
  rightItems: { label: string; value: string | string[] }[]
  isEditDisabled?: boolean
  bottomComponent?: React.ReactNode
}

export const ReviewBox = ({
  title,
  onEdit,
  infoMessage,
  leftItems,
  rightItems,
  isEditDisabled = false,
  bottomComponent,
}: Props) => {
  return (
    <Box w="full" p="6" border="1px" borderColor="border-soft" borderRadius="lg">
      <Flex width="full" justifyContent="space-between" alignItems="center" mb="8">
        <Text textStyle="title-md" textColor="text-primary">
          {title}
        </Text>
        {!isEditDisabled && (
          <Button variant="tertiary" color="text-primary" leftIcon={<Pencil w="4" h="4" />} onClick={onEdit}>
            Edit
          </Button>
        )}
      </Flex>
      <Flex
        gap="6"
        flexDir={{
          base: 'column',
          md: 'row',
        }}
      >
        <VStack
          spacing="6"
          width="full"
          maxW={{
            base: 'full',
            md: '50%',
          }}
          alignItems="flex-start"
        >
          {leftItems.map(({ label, value }) => (
            <ReviewItem key={label} label={label} value={value} />
          ))}
        </VStack>
        <VStack
          spacing="6"
          width="full"
          maxW={{
            base: 'full',
            md: '50%',
          }}
          alignItems="flex-start"
        >
          {rightItems.map(({ label, value }) => (
            <ReviewItem key={label} label={label} value={value} />
          ))}
        </VStack>
      </Flex>
      {isDefined(bottomComponent) && <Box mt="6">{bottomComponent}</Box>}
      {isDefined(infoMessage) && (
        <Alert variant="info" mt="6" style={{ alignItems: 'flex-start' }}>
          <AlertDescription>{infoMessage}</AlertDescription>
        </Alert>
      )}
    </Box>
  )
}
