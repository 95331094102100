import { ComponentProps } from 'react'
import { chakra } from 'ui-lib'

const KeepSquareIcon = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="8" fill="black" />
      <path
        d="M20.6584 14.9136L19.0326 13.2732L20.84 11.3522L22.4658 12.9895C22.9429 13.4702 23.324 14.0435 23.5872 14.6766C23.8503 15.3097 23.9904 15.9903 23.9995 16.6795C24.0086 17.3686 23.8866 18.0529 23.6403 18.6933C23.3941 19.3336 23.0284 19.9175 22.5643 20.4115C22.1001 20.9055 21.5465 21.3 20.9352 21.5725C20.3238 21.845 19.6666 21.9901 19.001 21.9995C17.6569 22.0186 16.3605 21.484 15.3971 20.5133L13.7713 18.8728L15.5787 16.9518L17.2045 18.5892C17.555 18.9441 17.9996 19.1828 18.4816 19.2751C18.9637 19.3674 19.4613 19.3089 19.9112 19.1072C20.2101 18.9749 20.4804 18.782 20.7062 18.5398C21.0489 18.1769 21.2795 17.7165 21.3686 17.2173C21.4577 16.7181 21.4013 16.2028 21.2065 15.7369C21.0787 15.4274 20.8923 15.1474 20.6584 14.9136Z"
        fill="white"
      />
      <path
        d="M16.6029 12.4867C15.6395 11.516 14.3431 10.9814 12.999 11.0005C11.6549 11.0196 10.3731 11.5908 9.43572 12.5885C8.49832 13.5862 7.98205 14.9287 8.00048 16.3205C8.0189 17.7124 8.57052 19.0397 9.53399 20.0104L11.1598 21.6478L12.9672 19.7268L11.3414 18.0771C10.9922 17.7246 10.7514 17.2732 10.6491 16.7795C10.5469 16.2857 10.5878 15.7717 10.7667 15.302C10.8852 14.988 11.0646 14.7026 11.2938 14.4633C11.5203 14.2212 11.7904 14.0274 12.0888 13.8928C12.3881 13.7595 12.71 13.6893 13.0357 13.6862C13.3609 13.6815 13.6837 13.7434 13.9857 13.8682C14.2877 13.9931 14.5629 14.1786 14.7955 14.4139L14.7969 14.4124L16.4212 16.0482L18.2286 14.1272L16.6029 12.4867Z"
        fill="white"
      />
    </chakra.svg>
  )
}

export default KeepSquareIcon
