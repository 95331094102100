import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigInt: { input: string; output: string }
  CalendarDate: { input: string; output: string }
  DateTime: { input: string; output: string }
  JSON: { input: string; output: string }
  Money: { input: string; output: string }
  MoneyV2: {
    input: { amount: string; amountAsNumber: number; currency: string }
    output: { amount: string; amountAsNumber: number; currency: string }
  }
  OnboardingStep: { input: string; output: string }
  PaymentCycle: { input: string; output: string }
  RewardPoint: { input: string; output: string }
  RewardPoints: { input: string; output: string }
  RewardScalar: { input: string; output: string }
}

export type AboutBusinessData = {
  __typename?: 'AboutBusinessData'
  businessCategory: Scalars['String']['output']
  businessDBA: Scalars['String']['output']
  businessDescription: Scalars['String']['output']
  businessNameLegal: Scalars['String']['output']
  businessPhone: Scalars['String']['output']
  businessType: Scalars['String']['output']
  businessWebsite?: Maybe<Scalars['String']['output']>
  dateOfIncorporation?: Maybe<Scalars['String']['output']>
  incorporationNumber: Scalars['String']['output']
  isMoneyBusiness: Scalars['Boolean']['output']
  numberOfEmployees: Scalars['Int']['output']
}

export type AboutBusinessInput = {
  businessCategory: Scalars['String']['input']
  businessDBA?: InputMaybe<Scalars['String']['input']>
  businessDescription: Scalars['String']['input']
  businessHasWebsite: Scalars['Boolean']['input']
  businessNameLegal: Scalars['String']['input']
  businessPhone: Scalars['String']['input']
  businessType: Scalars['String']['input']
  businessWebsite?: InputMaybe<Scalars['String']['input']>
  dateOfIncorporation?: InputMaybe<Scalars['String']['input']>
  incorporationNumber: Scalars['String']['input']
  isMoneyBusiness: Scalars['Boolean']['input']
  maxNumberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  minNumberOfEmployees: Scalars['Int']['input']
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  supportingDocuments?: InputMaybe<Array<InputMaybe<DocumentInput>>>
}

export type AcceptAgreementsInput = {
  agreementTypes?: InputMaybe<Array<AgreementTypeEnum>>
}

export type AcceptInvitationMutationInput = {
  acceptedAgreements: Scalars['Boolean']['input']
  invitationToken: Scalars['String']['input']
  userData: UserDataInput
}

export type AcceptedInvitationResult = {
  __typename?: 'AcceptedInvitationResult'
  invitation: EmployeeInvitation
  issuanceResult?: Maybe<PendingCardsIssuanceResult>
}

export type Account = {
  __typename?: 'Account'
  id: Scalars['ID']['output']
  provider?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export enum AccountNumberType {
  AccountNumber = 'ACCOUNT_NUMBER',
  Clabe = 'CLABE',
  Iban = 'IBAN',
}

export enum AccountType {
  Brokerage = 'brokerage',
  Credit = 'credit',
  Depository = 'depository',
  Investment = 'investment',
  Loan = 'loan',
  Other = 'other',
}

export type AccountingExpense = {
  __typename?: 'AccountingExpense'
  amount?: Maybe<Scalars['Money']['output']>
  businessId: Scalars['String']['output']
  card?: Maybe<Card>
  cardId?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  entityCategoryId?: Maybe<Scalars['String']['output']>
  entityClassId?: Maybe<Scalars['String']['output']>
  entityMerchantId?: Maybe<Scalars['String']['output']>
  entityTaxCodeId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  memo?: Maybe<Scalars['String']['output']>
  merchantName?: Maybe<Scalars['String']['output']>
  receipt?: Maybe<AccountingExpenseReceipt>
  requestedAmount?: Maybe<Scalars['Money']['output']>
  requestedCurrency?: Maybe<Scalars['String']['output']>
  source?: Maybe<AccountingExpenseEntity>
  status: ExpenseStatus
  statusSnapshots?: Maybe<Array<AccountingExpenseStatusSnapshot>>
  transactionDate?: Maybe<Scalars['DateTime']['output']>
}

export type AccountingExpenseCategory = {
  __typename?: 'AccountingExpenseCategory'
  category: AccountingExpenseCategoryType
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  nominalCode?: Maybe<Scalars['String']['output']>
  status: AccountingExpenseCategoryStatus
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum AccountingExpenseCategoryStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export enum AccountingExpenseCategoryType {
  Asset = 'ASSET',
  Equity = 'EQUITY',
  Expense = 'EXPENSE',
  Income = 'INCOME',
  Liability = 'LIABILITY',
}

export type AccountingExpenseClass = {
  __typename?: 'AccountingExpenseClass'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  hasChildren?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  lastSyncedAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  parentId?: Maybe<Scalars['String']['output']>
  platformId?: Maybe<Scalars['String']['output']>
  status?: Maybe<AccountingExpenseClassStatus>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum AccountingExpenseClassStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum AccountingExpenseEntity {
  Chargeback = 'CHARGEBACK',
  Clearing = 'CLEARING',
  Return = 'RETURN',
  ReturnReversal = 'RETURN_REVERSAL',
  StatementPayment = 'STATEMENT_PAYMENT',
}

export type AccountingExpenseReceipt = {
  __typename?: 'AccountingExpenseReceipt'
  document?: Maybe<DocumentType>
  documentId: Scalars['String']['output']
  id: Scalars['ID']['output']
}

/** Snapshot of the status of an expense at a given time */
export type AccountingExpenseStatusSnapshot = {
  __typename?: 'AccountingExpenseStatusSnapshot'
  createdAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  status: ExpenseStatus
}

export type AccountingExpenseTaxRate = {
  __typename?: 'AccountingExpenseTaxRate'
  code?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  effectiveRate?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: TaxRateStatus
  totalTaxRate?: Maybe<Scalars['Float']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type AccountingExpenseVendor = {
  __typename?: 'AccountingExpenseVendor'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  phone?: Maybe<Scalars['String']['output']>
  status: AccountingVendorStatus
  vendorName?: Maybe<Scalars['String']['output']>
}

export type AccountingIntegration = {
  __typename?: 'AccountingIntegration'
  business?: Maybe<Business>
  businessId: Scalars['String']['output']
  connectedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  externalData?: Maybe<Scalars['JSON']['output']>
  id: Scalars['ID']['output']
  integrationType: Scalars['String']['output']
  status: AccountingIntegrationStatus
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum AccountingIntegrationStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Paused = 'PAUSED',
  Pending = 'PENDING',
}

export enum AccountingIntegrationType {
  RutterQbo = 'RUTTER_QBO',
}

export enum AccountingVendorStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Unknown = 'UNKNOWN',
}

export type ActivationChecklistItem = {
  __typename?: 'ActivationChecklistItem'
  completed: Scalars['Boolean']['output']
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: ActivationChecklistName
  rewardsAfterCompletion: Scalars['Int']['output']
  stepNumber: Scalars['Int']['output']
}

export enum ActivationChecklistName {
  ActivatePhysicalCard = 'activatePhysicalCard',
  AddCardToWallet = 'addCardToWallet',
  CreateVirtualCard = 'createVirtualCard',
  FirstDayTransaction = 'firstDayTransaction',
  InviteTeammate = 'inviteTeammate',
  ReferBusiness = 'referBusiness',
}

export type AddExtraDocumentsInput = {
  documents?: InputMaybe<Array<DocumentInput>>
}

export type AddReceiptInput = {
  expenseId: Scalars['String']['input']
  receiptDocument: ReceiptInput
}

export type AdditionalInformationData = {
  __typename?: 'AdditionalInformationData'
  politicalPerson: Scalars['String']['output']
  sin?: Maybe<Scalars['String']['output']>
}

export type AdditionalInformationInput = {
  politicalPerson: Scalars['String']['input']
  sin?: InputMaybe<Scalars['String']['input']>
}

export type AddressData = {
  __typename?: 'AddressData'
  address1: Scalars['String']['output']
  address2?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  countryId?: Maybe<Scalars['String']['output']>
  countryRelation?: Maybe<Country>
  id: Scalars['ID']['output']
  postalCode: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type Agreement = {
  __typename?: 'Agreement'
  agreementType: AgreementTypeEnum
  id: Scalars['String']['output']
  link: Scalars['String']['output']
  name: Scalars['String']['output']
  version: Scalars['DateTime']['output']
}

/** The type of agreement. */
export enum AgreementTypeEnum {
  BusinessAccountsAgreement = 'BUSINESS_ACCOUNTS_AGREEMENT',
  CardholderAgreement = 'CARDHOLDER_AGREEMENT',
  ElectronicCommunicationConsentAgreement = 'ELECTRONIC_COMMUNICATION_CONSENT_AGREEMENT',
  FlinksEndUserAgreement = 'FLINKS_END_USER_AGREEMENT',
  LinkedAccountsAgreement = 'LINKED_ACCOUNTS_AGREEMENT',
  MarketingCommunications = 'MARKETING_COMMUNICATIONS',
  PlatformAgreement = 'PLATFORM_AGREEMENT',
  PreAuthorizedDebitAgreement = 'PRE_AUTHORIZED_DEBIT_AGREEMENT',
  PrivacyPolicy = 'PRIVACY_POLICY',
  ProhibitedAndRestrictedActivities = 'PROHIBITED_AND_RESTRICTED_ACTIVITIES',
  ReferralTerms = 'REFERRAL_TERMS',
  RewardsTerms = 'REWARDS_TERMS',
  StatementAdvanceAgreement = 'STATEMENT_ADVANCE_AGREEMENT',
  TermsAndConditionsOfUse = 'TERMS_AND_CONDITIONS_OF_USE',
}

export type ApplicantIdentityVerification = {
  __typename?: 'ApplicantIdentityVerification'
  transactionId?: Maybe<Scalars['String']['output']>
}

export type ApplicantIdentityVerificationInput = {
  transactionId?: InputMaybe<Scalars['String']['input']>
}

export type Application = {
  __typename?: 'Application'
  data?: Maybe<ApplicationData>
  id: Scalars['ID']['output']
  onboardingStep?: Maybe<Scalars['OnboardingStep']['output']>
  startedAt: Scalars['DateTime']['output']
  status: Scalars['String']['output']
  submittedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
  user?: Maybe<User>
  version?: Maybe<Scalars['String']['output']>
}

export type ApplicationAddress = {
  __typename?: 'ApplicationAddress'
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
}

export type ApplicationAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type ApplicationBankDetails = {
  __typename?: 'ApplicationBankDetails'
  provider?: Maybe<Scalars['String']['output']>
  skip?: Maybe<Scalars['Boolean']['output']>
  statements?: Maybe<Array<Maybe<SupportingDocuments>>>
}

export type ApplicationBankDetailsInput = {
  provider?: InputMaybe<Scalars['String']['input']>
  skip?: InputMaybe<Scalars['Boolean']['input']>
  statements?: InputMaybe<Array<InputMaybe<SupportingDocumentsInput>>>
}

export type ApplicationBusiness = {
  __typename?: 'ApplicationBusiness'
  address?: Maybe<ApplicationAddress>
  category?: Maybe<Scalars['String']['output']>
  dba?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  hasWebsite?: Maybe<Scalars['Boolean']['output']>
  incorporationDate?: Maybe<Scalars['String']['output']>
  incorporationNumber?: Maybe<Scalars['String']['output']>
  incorporationType?: Maybe<Scalars['String']['output']>
  isMSB?: Maybe<Scalars['Boolean']['output']>
  legalName?: Maybe<Scalars['String']['output']>
  maxNumberOfEmployees?: Maybe<Scalars['Int']['output']>
  minNumberOfEmployees?: Maybe<Scalars['Int']['output']>
  numberOfEmployees?: Maybe<Scalars['Int']['output']>
  phone?: Maybe<Scalars['String']['output']>
  supportingDocuments?: Maybe<Array<Maybe<SupportingDocuments>>>
  website?: Maybe<Scalars['String']['output']>
}

export type ApplicationBusinessInput = {
  address?: InputMaybe<ApplicationAddressInput>
  category?: InputMaybe<Scalars['String']['input']>
  dba?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  hasWebsite?: InputMaybe<Scalars['Boolean']['input']>
  incorporationDate?: InputMaybe<Scalars['String']['input']>
  incorporationNumber?: InputMaybe<Scalars['String']['input']>
  incorporationType?: InputMaybe<Scalars['String']['input']>
  isMSB?: InputMaybe<Scalars['Boolean']['input']>
  legalName?: InputMaybe<Scalars['String']['input']>
  maxNumberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  minNumberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  numberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  supportingDocuments?: InputMaybe<Array<InputMaybe<SupportingDocumentsInput>>>
  website?: InputMaybe<Scalars['String']['input']>
}

export type ApplicationCard = {
  __typename?: 'ApplicationCard'
  extraEmbossingLine?: Maybe<Scalars['String']['output']>
}

export type ApplicationCardInput = {
  extraEmbossingLine?: InputMaybe<Scalars['String']['input']>
}

export type ApplicationData = {
  __typename?: 'ApplicationData'
  ABOUT_BUSINESS_4?: Maybe<AboutBusinessData>
  ADDITIONAL_INFORMATION_2?: Maybe<AdditionalInformationData>
  BUSINESS_ADDRESS_5?: Maybe<BusinessAddressData>
  BUSINESS_DIRECTORS_7?: Maybe<BusinessDirectorsData>
  BUSINESS_OWNERS_6?: Maybe<BusinessOwnersData>
  IDENTITY_VERIFICATION_10?: Maybe<IdentityVerificationData>
  INCORPORATION_DOCUMENTS_8?: Maybe<IncorporationDocumentsData>
  PERSONAL_ADDRESS_3?: Maybe<GenericAddressData>
  PERSONAL_INFORMATION_1?: Maybe<PersonalInformationData>
  PLAID_9?: Maybe<Scalars['Boolean']['output']>
  REVIEW_11?: Maybe<ReviewData>
}

export type ApplicationDataV2 = {
  __typename?: 'ApplicationDataV2'
  bankDetails?: Maybe<ApplicationBankDetails>
  business?: Maybe<ApplicationBusiness>
  card?: Maybe<ApplicationCard>
  completedSteps?: Maybe<Array<Scalars['String']['output']>>
  device?: Maybe<ApplicationDevice>
  extraDocuments?: Maybe<ExtraDocuments>
  identityVerification?: Maybe<ApplicantIdentityVerification>
  productsSelection?: Maybe<ApplicationProductsSelection>
  relevantPeople?: Maybe<Array<ApplicationRelevantPerson>>
  shippingAddress?: Maybe<ApplicationAddress>
  validations?: Maybe<ApplicationValidations>
}

export type ApplicationDataV2Input = {
  bankDetails?: InputMaybe<ApplicationBankDetailsInput>
  business?: InputMaybe<ApplicationBusinessInput>
  card?: InputMaybe<ApplicationCardInput>
  device?: InputMaybe<ApplicationDeviceInput>
  extraDocuments?: InputMaybe<ExtraDocumentsInput>
  productsSelection?: InputMaybe<ApplicationProductsSelectionInput>
  relevantPeople?: InputMaybe<Array<InputMaybe<ApplicationRelevantPersonInput>>>
  shippingAddress?: InputMaybe<ApplicationAddressInput>
  validations?: InputMaybe<ApplicationValidationsInput>
}

export type ApplicationDevice = {
  __typename?: 'ApplicationDevice'
  blackboxId?: Maybe<Scalars['String']['output']>
  ip?: Maybe<Scalars['String']['output']>
}

export type ApplicationDeviceInput = {
  blackboxId?: InputMaybe<Scalars['String']['input']>
  ip?: InputMaybe<Scalars['String']['input']>
}

export type ApplicationMovement = {
  __typename?: 'ApplicationMovement'
  application?: Maybe<ApplicationV2>
  newStep?: Maybe<Scalars['OnboardingStep']['output']>
}

export type ApplicationMovementInput = {
  applicationId: Scalars['String']['input']
  stepData: ApplicationDataV2Input
  targetStep?: InputMaybe<Scalars['OnboardingStep']['input']>
}

export type ApplicationProductsSelection = {
  __typename?: 'ApplicationProductsSelection'
  banking?: Maybe<Scalars['Boolean']['output']>
  capital?: Maybe<Scalars['Boolean']['output']>
  creditCard?: Maybe<Scalars['Boolean']['output']>
  fxExchange?: Maybe<Scalars['Boolean']['output']>
}

export type ApplicationProductsSelectionInput = {
  banking?: InputMaybe<Scalars['Boolean']['input']>
  capital?: InputMaybe<Scalars['Boolean']['input']>
  creditCard?: InputMaybe<Scalars['Boolean']['input']>
  fxExchange?: InputMaybe<Scalars['Boolean']['input']>
}

export type ApplicationRelevantPerson = {
  __typename?: 'ApplicationRelevantPerson'
  address?: Maybe<ApplicationAddress>
  authorizedToSign?: Maybe<Scalars['Boolean']['output']>
  citizenship?: Maybe<Scalars['String']['output']>
  dateOfBirth?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  participation?: Maybe<Array<Maybe<PersonParticipation>>>
  phoneNumber?: Maybe<Scalars['String']['output']>
  politicalExposure?: Maybe<Scalars['Boolean']['output']>
  roleDescription?: Maybe<Scalars['String']['output']>
  socialInsuranceNumber?: Maybe<Scalars['String']['output']>
}

export type ApplicationRelevantPersonInput = {
  address?: InputMaybe<ApplicationAddressInput>
  authorizedToSign?: InputMaybe<Scalars['Boolean']['input']>
  citizenship?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  identityVerification?: InputMaybe<ApplicantIdentityVerificationInput>
  lastName?: InputMaybe<Scalars['String']['input']>
  participation?: InputMaybe<Array<InputMaybe<PersonParticipation>>>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  politicalExposure?: InputMaybe<Scalars['Boolean']['input']>
  roleDescription?: InputMaybe<Scalars['String']['input']>
  socialInsuranceNumber?: InputMaybe<Scalars['String']['input']>
}

export type ApplicationUpdateInput = {
  data: ApplicationDataV2Input
}

export type ApplicationV2 = {
  __typename?: 'ApplicationV2'
  businessId?: Maybe<Scalars['String']['output']>
  data?: Maybe<ApplicationDataV2>
  dataVersion: Scalars['String']['output']
  id: Scalars['String']['output']
  onboardingStep: OnboardingStepV2
  status: Scalars['String']['output']
  userId?: Maybe<Scalars['String']['output']>
  version: Scalars['String']['output']
}

export type ApplicationValidations = {
  __typename?: 'ApplicationValidations'
  authorizedToSign?: Maybe<Scalars['Boolean']['output']>
  noMoreDirectors?: Maybe<Scalars['Boolean']['output']>
  noMoreOwners?: Maybe<Scalars['Boolean']['output']>
}

export type ApplicationValidationsInput = {
  authorizedToSign?: InputMaybe<Scalars['Boolean']['input']>
  noMoreDirectors?: InputMaybe<Scalars['Boolean']['input']>
  noMoreOwners?: InputMaybe<Scalars['Boolean']['input']>
}

export type Approval = {
  __typename?: 'Approval'
  apr: Scalars['Float']['output']
  businessId: Scalars['String']['output']
  id: Scalars['ID']['output']
  maxRepaymentMonths: Scalars['Int']['output']
  pricingVersion: CapitalApprovalVersion
  repaymentFrequency: Scalars['String']['output']
  repayments?: Maybe<Array<Repayment>>
  riskLevel?: Maybe<Scalars['Int']['output']>
}

export type BalanceResult = {
  __typename?: 'BalanceResult'
  availableBalance?: Maybe<WalletBalance>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  currency?: Maybe<Scalars['String']['output']>
  currentBalance?: Maybe<WalletBalance>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  walletId: Scalars['ID']['output']
}

export type BankAccount = {
  __typename?: 'BankAccount'
  accountHolderName: Scalars['String']['output']
  accountHolderType: BankAccountLegalEntityType
  accountNumber: Scalars['String']['output']
  accountNumberType: AccountNumberType
  actorId?: Maybe<Scalars['String']['output']>
  address: Scalars['String']['output']
  agreedPad?: Maybe<Scalars['DateTime']['output']>
  businessId: Scalars['String']['output']
  country: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyCode
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  routingCode?: Maybe<Scalars['String']['output']>
  routingCodeType?: Maybe<BankAccountRoutingCodeType>
  updatedAt: Scalars['DateTime']['output']
}

export enum BankAccountLegalEntityType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
}

export enum BankAccountRoutingCodeType {
  BicSwift = 'BIC_SWIFT',
  RoutingCode = 'ROUTING_CODE',
  RoutingNumber = 'ROUTING_NUMBER',
  SortCode = 'SORT_CODE',
  WireRoutingNumber = 'WIRE_ROUTING_NUMBER',
}

export type BankConnectionAccount = {
  __typename?: 'BankConnectionAccount'
  accountNumber?: Maybe<Scalars['String']['output']>
  availableBalance?: Maybe<Scalars['BigInt']['output']>
  bank?: Maybe<BankInfo>
  bankNumber?: Maybe<Scalars['String']['output']>
  branchNumber?: Maybe<Scalars['String']['output']>
  currencyCode?: Maybe<Scalars['String']['output']>
  currentBalance?: Maybe<Scalars['BigInt']['output']>
  id: Scalars['String']['output']
  isPrimary?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  provider?: Maybe<Scalars['String']['output']>
  subtype?: Maybe<Scalars['String']['output']>
  verifiedAt?: Maybe<Scalars['DateTime']['output']>
}

export type BankInfo = {
  __typename?: 'BankInfo'
  logo?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type BankPayment = {
  __typename?: 'BankPayment'
  date: Scalars['String']['output']
  entityType: WalletTransactionEntityType
  id: Scalars['ID']['output']
  pointsAsNumber: Scalars['Float']['output']
  pointsAsString: Scalars['String']['output']
  transaction: BankPaymentTransaction
}

export type BankPaymentContact = {
  __typename?: 'BankPaymentContact'
  accountNumber?: Maybe<Scalars['String']['output']>
  accountNumberType?: Maybe<Scalars['String']['output']>
  address?: Maybe<Scalars['String']['output']>
  bankAccountHolderName?: Maybe<Scalars['String']['output']>
  routingCode?: Maybe<Scalars['String']['output']>
  routingCodeType?: Maybe<Scalars['String']['output']>
}

export type BankPaymentFee = {
  __typename?: 'BankPaymentFee'
  date: Scalars['String']['output']
  entityType: WalletTransactionEntityType
  id: Scalars['ID']['output']
  transaction: BankPaymentFeeTransaction
}

export type BankPaymentFeeTransaction = {
  __typename?: 'BankPaymentFeeTransaction'
  amount: Scalars['String']['output']
  amountAsNumber: Scalars['Float']['output']
  bankPaymentId: Scalars['ID']['output']
  currency: CurrencyCode
  paymentShortReference: Scalars['String']['output']
  priority: PaymentPriority
  status: BankPaymentStatusType
}

export enum BankPaymentStatusType {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
}

export type BankPaymentTransaction = {
  __typename?: 'BankPaymentTransaction'
  amount: Scalars['String']['output']
  amountAsNumber: Scalars['Float']['output']
  conversionFeeAmount?: Maybe<Scalars['String']['output']>
  conversionFeeAmountAsNumber?: Maybe<Scalars['Float']['output']>
  conversionFeeCurrency?: Maybe<Scalars['String']['output']>
  convertedAmount?: Maybe<Scalars['String']['output']>
  convertedCurrency?: Maybe<CurrencyCode>
  creditor: BankPaymentContact
  currency: CurrencyCode
  debitor: BankPaymentContact
  id: Scalars['ID']['output']
  paymentMethod?: Maybe<Scalars['String']['output']>
  polarity: Polarity
  priority: PaymentPriority
  reason: Scalars['String']['output']
  receiptUrl?: Maybe<Scalars['String']['output']>
  recipientId: Scalars['String']['output']
  reference: Scalars['String']['output']
  rejectedAt?: Maybe<Scalars['DateTime']['output']>
  relatedConversionId?: Maybe<Scalars['String']['output']>
  shortReference: Scalars['String']['output']
  speedFeeAmount?: Maybe<Scalars['String']['output']>
  speedFeeAmountAsNumber?: Maybe<Scalars['Float']['output']>
  speedFeeCurrency?: Maybe<Scalars['String']['output']>
  status: BankPaymentStatusType
  type: BankPaymentType
}

export enum BankPaymentType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
}

export type BankTransfer = {
  __typename?: 'BankTransfer'
  date: Scalars['String']['output']
  entityType: WalletTransactionEntityType
  id: Scalars['ID']['output']
  pointsAsNumber: Scalars['Float']['output']
  pointsAsString: Scalars['String']['output']
  transaction: BankTransferTransaction
}

export enum BankTransferDirection {
  HouseToSub = 'HOUSE_TO_SUB',
  SubToHouse = 'SUB_TO_HOUSE',
}

export type BankTransferTransaction = {
  __typename?: 'BankTransferTransaction'
  amount: Scalars['String']['output']
  amountAsNumber: Scalars['Float']['output']
  creditorBankAccountHolderName?: Maybe<Scalars['String']['output']>
  currency: CurrencyCode
  debitorBankAccountHolderName?: Maybe<Scalars['String']['output']>
  direction: BankTransferDirection
  friendlyTransferType: Scalars['String']['output']
  id: Scalars['ID']['output']
  polarity: Polarity
  reason?: Maybe<Scalars['String']['output']>
  reference?: Maybe<Scalars['String']['output']>
  shortReference: Scalars['String']['output']
  status: BankPaymentStatusType
  transferType: BankTransferType
}

export enum BankTransferType {
  BalanceCorrection = 'BALANCE_CORRECTION',
  CurrencyConversionFluctuation = 'CURRENCY_CONVERSION_FLUCTUATION',
  FailedPaymentBalanceAdjustment = 'FAILED_PAYMENT_BALANCE_ADJUSTMENT',
  Fee = 'FEE',
  Funding = 'FUNDING',
  Fx = 'FX',
  InstallmentPayment = 'INSTALLMENT_PAYMENT',
  InvoiceFactoringDeposit = 'INVOICE_FACTORING_DEPOSIT',
  InvoiceFactoringPayment = 'INVOICE_FACTORING_PAYMENT',
  Other = 'OTHER',
  Refund = 'REFUND',
  RewardRedemption = 'REWARD_REDEMPTION',
  StatementPayment = 'STATEMENT_PAYMENT',
}

export enum BankingPaymentMethodsType {
  Ach = 'ACH',
  Eft = 'EFT',
  FedWire = 'FED_WIRE',
  Fps = 'FPS',
  Pesonet = 'PESONET',
  Sepa = 'SEPA',
  Spei = 'SPEI',
  Swift = 'SWIFT',
  Target2 = 'TARGET2',
}

export type BankingStatement = {
  __typename?: 'BankingStatement'
  businessId: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  currency: Scalars['String']['output']
  id: Scalars['String']['output']
  month: Scalars['Int']['output']
  year: Scalars['Int']['output']
}

export type BankingStatementUrl = {
  __typename?: 'BankingStatementUrl'
  signedUrl: Scalars['String']['output']
}

export type Beneficiary = {
  __typename?: 'Beneficiary'
  accountNumber: Scalars['String']['output']
  address1: Scalars['String']['output']
  address2?: Maybe<Scalars['String']['output']>
  bankAccount?: Maybe<BankAccount>
  bankAccountHolderName: Scalars['String']['output']
  bankAccountId?: Maybe<Scalars['String']['output']>
  bankCountry: Scalars['String']['output']
  bankCountryId?: Maybe<Scalars['String']['output']>
  bankCountryRelation?: Maybe<Country>
  beneficiaryAddress: Scalars['String']['output']
  beneficiaryCity: Scalars['String']['output']
  beneficiaryCompanyName?: Maybe<Scalars['String']['output']>
  beneficiaryCountry: Scalars['String']['output']
  beneficiaryEntityType: LegalEntityType
  beneficiaryFirstName?: Maybe<Scalars['String']['output']>
  beneficiaryIdentificationType?: Maybe<IdentificationType>
  beneficiaryIdentificationValue?: Maybe<Scalars['String']['output']>
  beneficiaryLastName?: Maybe<Scalars['String']['output']>
  beneficiaryName: Scalars['String']['output']
  beneficiaryPostcode: Scalars['String']['output']
  beneficiaryStateOrProvince: Scalars['String']['output']
  currency: CurrencyCode
  email: Scalars['String']['output']
  id: Scalars['String']['output']
  paymentMethod: BankingPaymentMethodsType
  /** Payment type */
  paymentType: PaymentType
  routingCodeType1?: Maybe<Scalars['String']['output']>
  routingCodeValue1?: Maybe<Scalars['String']['output']>
}

export type BeneficiaryInput = {
  aba?: InputMaybe<Scalars['String']['input']>
  accountNumber?: InputMaybe<Scalars['String']['input']>
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  bankAccountHolderName: Scalars['String']['input']
  bankCountry: Scalars['String']['input']
  beneficiaryCity: Scalars['String']['input']
  beneficiaryCompanyName?: InputMaybe<Scalars['String']['input']>
  beneficiaryCountry: Scalars['String']['input']
  beneficiaryDateOfBirth?: InputMaybe<Scalars['String']['input']>
  beneficiaryEntityType: LegalEntityType
  beneficiaryFirstName?: InputMaybe<Scalars['String']['input']>
  beneficiaryIdentificationType?: InputMaybe<IdentificationType>
  beneficiaryIdentificationValue?: InputMaybe<Scalars['String']['input']>
  beneficiaryLastName?: InputMaybe<Scalars['String']['input']>
  beneficiaryPostcode: Scalars['String']['input']
  beneficiaryStateOrProvince: Scalars['String']['input']
  bicSwift?: InputMaybe<Scalars['String']['input']>
  branchNumber?: InputMaybe<Scalars['String']['input']>
  clabe?: InputMaybe<Scalars['String']['input']>
  currency: CurrencyCode
  email: Scalars['String']['input']
  iban?: InputMaybe<Scalars['String']['input']>
  institutionNumber?: InputMaybe<Scalars['String']['input']>
  paymentMethod: Scalars['String']['input']
  paymentType: PaymentType
  sortCode?: InputMaybe<Scalars['String']['input']>
}

export type BeneficiaryPaginationInput = {
  /** Beneficiaries Page Number */
  page?: Scalars['Int']['input']
  pageSize?: Scalars['Int']['input']
}

export type BeneficiaryValidation = {
  __typename?: 'BeneficiaryValidation'
  errors?: Maybe<Array<Maybe<FieldErrors>>>
  isValid: Scalars['Boolean']['output']
}

/** Output of bulk upload receipts */
export type BulkUploadReceiptOutput = {
  __typename?: 'BulkUploadReceiptOutput'
  matchedReceipts: Array<MatchedReceiptPair>
  unmatchedReceipts: Array<UnmatchedReceipt>
}

export type BulkUploadReceiptsInput = {
  receipts: Array<ReceiptInput>
}

export type Business = {
  __typename?: 'Business'
  address?: Maybe<AddressData>
  allStatements?: Maybe<Array<Maybe<Statement>>>
  businessNameDba?: Maybe<Scalars['String']['output']>
  businessNameLegal?: Maybe<Scalars['String']['output']>
  ccAccessStatus?: Maybe<CcAccessStatus>
  id: Scalars['ID']['output']
  isDelinquent?: Maybe<Scalars['Boolean']['output']>
  latestStatement?: Maybe<Statement>
  name?: Maybe<Scalars['String']['output']>
  paymentCycle?: Maybe<Scalars['PaymentCycle']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type BusinessAddressData = {
  __typename?: 'BusinessAddressData'
  businessAddressLine1: Scalars['String']['output']
  businessAddressLine2?: Maybe<Scalars['String']['output']>
  businessCity: Scalars['String']['output']
  businessCountry: Scalars['String']['output']
  businessPostalCode: Scalars['String']['output']
  businessProvince: Scalars['String']['output']
}

export type BusinessAddressInput = {
  businessAddressLine1: Scalars['String']['input']
  businessAddressLine2?: InputMaybe<Scalars['String']['input']>
  businessCity: Scalars['String']['input']
  businessCountry: Scalars['String']['input']
  businessPostalCode: Scalars['String']['input']
  businessProvince: Scalars['String']['input']
  usePersonalAddressAsBusinessAddress: Scalars['Boolean']['input']
}

export type BusinessCardLimit = {
  __typename?: 'BusinessCardLimit'
  activeCardsLimit: Scalars['Int']['output']
  businessCardsCount: Scalars['Int']['output']
  businessId?: Maybe<Scalars['String']['output']>
}

export type BusinessDelinquencyDetails = {
  __typename?: 'BusinessDelinquencyDetails'
  isDelinquent?: Maybe<Scalars['Boolean']['output']>
  lastDelinquentAmount: Scalars['Float']['output']
}

export enum BusinessDelinquencyProduct {
  Capital = 'CAPITAL',
  Card = 'CARD',
  StatementExtension = 'STATEMENT_EXTENSION',
}

export type BusinessDirectorsData = {
  __typename?: 'BusinessDirectorsData'
  addedSelfAsDirector: Scalars['Boolean']['output']
  directors?: Maybe<Array<DirectorData>>
  noMoreDirectors: Scalars['Boolean']['output']
}

export type BusinessDirectorsInput = {
  addedSelfAsDirector: Scalars['Boolean']['input']
  directors?: InputMaybe<Array<DirectorInput>>
  noMoreDirectors: Scalars['Boolean']['input']
}

export type BusinessOwnersData = {
  __typename?: 'BusinessOwnersData'
  addedSelfAsOwner: Scalars['Boolean']['output']
  noMoreOwners: Scalars['Boolean']['output']
  owners?: Maybe<Array<OwnerData>>
  selfOwnerNature?: Maybe<Scalars['String']['output']>
  selfOwnerNatureDescription?: Maybe<Scalars['String']['output']>
}

export type BusinessOwnersInput = {
  addedSelfAsOwner: Scalars['Boolean']['input']
  noMoreOwners: Scalars['Boolean']['input']
  owners?: InputMaybe<Array<OwnerInput>>
  selfOwnerNature?: InputMaybe<Scalars['String']['input']>
  selfOwnerNatureDescription?: InputMaybe<Scalars['String']['input']>
}

export type BusinessPaymentCycleInput = {
  /** Business ID */
  id: Scalars['String']['input']
  /** How often we pull funds from a business's bank account to pay off their balance. */
  paymentCycle: Scalars['PaymentCycle']['input']
}

export enum CcAccessStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  InReview = 'IN_REVIEW',
  NotRequested = 'NOT_REQUESTED',
  Requested = 'REQUESTED',
}

export type CcConversion = {
  __typename?: 'CCConversion'
  businessId: Scalars['String']['output']
  buyCurrency: CurrencyCode
  cancelledReason?: Maybe<Scalars['String']['output']>
  clientBuyAmount: Scalars['String']['output']
  clientRate: Scalars['String']['output']
  clientSellAmount: Scalars['String']['output']
  conversionDatePreference?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  currencyPair: Scalars['String']['output']
  depositAmount?: Maybe<Scalars['String']['output']>
  depositCurrency?: Maybe<CurrencyCode>
  depositRequired: Scalars['Boolean']['output']
  depositRequiredAt?: Maybe<Scalars['DateTime']['output']>
  depositStatus?: Maybe<Scalars['String']['output']>
  fixedSide: Scalars['String']['output']
  id: Scalars['ID']['output']
  reason?: Maybe<Scalars['String']['output']>
  sellCurrency: CurrencyCode
  status: Scalars['String']['output']
  termAgreement: Scalars['Boolean']['output']
  uniqueRequestId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type CalculateRepaymentDates = {
  __typename?: 'CalculateRepaymentDates'
  dates: Array<Scalars['DateTime']['output']>
  first: Scalars['DateTime']['output']
  last: Scalars['DateTime']['output']
}

export type CalculateRepaymentPlan = {
  __typename?: 'CalculateRepaymentPlan'
  amountLastPayment: Scalars['Money']['output']
  amountPerRemainingPayments: Scalars['Money']['output']
  durationFormatted: Scalars['String']['output']
  feeLastPayment: Scalars['Money']['output']
  feePerRemainingPayments: Scalars['Money']['output']
  monthsDuration: Scalars['Int']['output']
  monthsDurationFormatted: Scalars['String']['output']
  preDiscountPaymentFee: Scalars['Money']['output']
  preDiscountTotalFee: Scalars['Money']['output']
  principalAmount: Scalars['Money']['output']
  principalAmountLastPayment: Scalars['Money']['output']
  principalAmountPerRemainingPayments: Scalars['Money']['output']
  repaymentInstallments: Scalars['Int']['output']
  totalFee: Scalars['Money']['output']
  totalRepaymentAmount: Scalars['Money']['output']
}

export type CalculateRepaymentPlanWithOriginationFee = {
  __typename?: 'CalculateRepaymentPlanWithOriginationFee'
  amountLastPayment: Scalars['MoneyV2']['output']
  amountPerRemainingPayments: Scalars['MoneyV2']['output']
  complianceFee: Scalars['MoneyV2']['output']
  durationFormatted: Scalars['String']['output']
  feeLastPayment: Scalars['MoneyV2']['output']
  feePerRemainingPayments: Scalars['MoneyV2']['output']
  firstPaymentDate: Scalars['DateTime']['output']
  lastPaymentDate: Scalars['DateTime']['output']
  monthlyFeeRate: Scalars['Float']['output']
  monthsDuration: Scalars['Float']['output']
  monthsDurationFormatted: Scalars['String']['output']
  originationFee: Scalars['MoneyV2']['output']
  originationFeeRate: Scalars['Float']['output']
  paymentsDueOn: Scalars['String']['output']
  planPeriod: Scalars['String']['output']
  preDiscountPaymentFee: Scalars['MoneyV2']['output']
  preDiscountTotalFee: Scalars['MoneyV2']['output']
  principalAmount: Scalars['MoneyV2']['output']
  principalAmountLastPayment: Scalars['MoneyV2']['output']
  principalAmountPerRemainingPayments: Scalars['MoneyV2']['output']
  receivedAmount: Scalars['MoneyV2']['output']
  repaymentInstallments: Scalars['Int']['output']
  serviceFee: Scalars['MoneyV2']['output']
  totalFee: Scalars['MoneyV2']['output']
  totalRepaymentAmount: Scalars['MoneyV2']['output']
}

export type CalculateWholeAmountToPayForEarlyPaymentOutput = {
  __typename?: 'CalculateWholeAmountToPayForEarlyPaymentOutput'
  principalOutstandingAmount: Scalars['MoneyV2']['output']
  proRatedFeeAmount: Scalars['MoneyV2']['output']
  totalAmount: Scalars['MoneyV2']['output']
}

export type CanApplyForNewUserPromotionOutput = {
  __typename?: 'CanApplyForNewUserPromotionOutput'
  canApply: Scalars['Boolean']['output']
  cohort?: Maybe<Cohort>
}

export type CancelConversionInput = {
  conversionId: Scalars['String']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type CancelPaymentMutationInput = {
  /** Payment ID */
  paymentId: Scalars['String']['input']
}

export type CancelStatementPaymentInput = {
  /** ID of the EFT transaction to cancel */
  eftTransactionId: Scalars['String']['input']
}

export enum CapitalApprovalVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type CapitalFunding = {
  __typename?: 'CapitalFunding'
  agreementSignedDate?: Maybe<Scalars['DateTime']['output']>
  apr: Scalars['Float']['output']
  businessId: Scalars['String']['output']
  complianceFee: Scalars['MoneyV2']['output']
  complianceFeeRate: Scalars['Float']['output']
  depositedOn?: Maybe<Scalars['DateTime']['output']>
  discounts: Array<FundingDiscount>
  feeDiscountRate: Scalars['Float']['output']
  financingChannel: Scalars['String']['output']
  firstRepaymentDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  installments: Array<FundingInstallment>
  loanNumber: Scalars['String']['output']
  originationFee: Scalars['MoneyV2']['output']
  outstandingBalance: Scalars['MoneyV2']['output']
  preDiscountTotalFee: Scalars['MoneyV2']['output']
  principalAmount: Scalars['MoneyV2']['output']
  reason?: Maybe<Scalars['String']['output']>
  repaymentFrequency: Scalars['String']['output']
  serviceFee: Scalars['MoneyV2']['output']
  serviceFeeRate: Scalars['Float']['output']
  status: Scalars['String']['output']
  termDurationInMonths: Scalars['Float']['output']
  totalFee: Scalars['MoneyV2']['output']
  totalRefinanced: Scalars['MoneyV2']['output']
  totalRepayment: Scalars['MoneyV2']['output']
}

export type CapitalInfo = {
  __typename?: 'CapitalInfo'
  approval: Approval
  availableCreditLimit: Scalars['Money']['output']
  capitalCreditLimit: Scalars['Money']['output']
  capitalRequest?: Maybe<CapitalRequest>
  underwritingStatus: Scalars['String']['output']
}

export type CapitalRequest = {
  __typename?: 'CapitalRequest'
  businessId: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
}

export type Card = {
  __typename?: 'Card'
  availableBalance?: Maybe<Scalars['Money']['output']>
  business?: Maybe<Business>
  businessId: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  lastFour?: Maybe<Scalars['String']['output']>
  ledgerBalance?: Maybe<Scalars['Money']['output']>
  nameOnCard?: Maybe<Scalars['String']['output']>
  nickname?: Maybe<Scalars['String']['output']>
  outstandingBalance?: Maybe<Scalars['Money']['output']>
  shippingAddress?: Maybe<AddressData>
  spendLimit?: Maybe<SpendLimit>
  spendLimitInfo?: Maybe<SpendLimitInfo>
  status: CardStatus
  type: CardType
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export type CardOustandingBalanceData = {
  __typename?: 'CardOustandingBalanceData'
  balance: Scalars['Money']['output']
  formatted: Scalars['String']['output']
}

export enum CardShippingMethod {
  Express = 'EXPRESS',
  Standard = 'STANDARD',
}

export enum CardStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  FraudBlocked = 'FRAUD_BLOCKED',
  Inactive = 'INACTIVE',
  Lost = 'LOST',
  PreActive = 'PRE_ACTIVE',
}

/** Card types */
export enum CardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

export type CardsFilters = {
  statuses?: InputMaybe<Array<CardStatus>>
}

export type CardsTransactionsTotalNet = {
  __typename?: 'CardsTransactionsTotalNet'
  total: Scalars['Money']['output']
}

export type ChangePasswordMutationInput = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export enum Cohort {
  NewOnboardings = 'NEW_ONBOARDINGS',
  RecentOnboardingsLowSpend = 'RECENT_ONBOARDINGS_LOW_SPEND',
}

export type CompleteConnectionParams = {
  /** The ID of the account integration */
  accountingIntegrationId: Scalars['String']['input']
}

export type CompleteConnectionResponse = {
  __typename?: 'CompleteConnectionResponse'
  accountingIntegration?: Maybe<AccountingIntegration>
  connectionUrl: Scalars['String']['output']
}

export type CompleteInformationInput = {
  businessName: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  maxNumberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  minNumberOfEmployees: Scalars['Int']['input']
  phoneNumber: Scalars['String']['input']
}

export type Conversion = {
  __typename?: 'Conversion'
  date: Scalars['String']['output']
  entityType: WalletTransactionEntityType
  id: Scalars['ID']['output']
  pointsAsNumber: Scalars['Float']['output']
  pointsAsString: Scalars['String']['output']
  rewardRedemption: ConversionRewardRedemption
  transaction: ConversionTransaction
}

export enum ConversionFixedSide {
  Buy = 'BUY',
  Sell = 'SELL',
}

export type ConversionQuote = {
  __typename?: 'ConversionQuote'
  adjustedBuyAmount: Scalars['Money']['output']
  adjustedClientRate: Scalars['Float']['output']
  adjustedSellAmount: Scalars['Money']['output']
  clientBuyCurrency: CurrencyCode
  clientSellCurrency: CurrencyCode
  competitorsClientRate: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  eligibleRedeemableAmount?: Maybe<Scalars['Money']['output']>
  eligibleRedeemableCurrency?: Maybe<CurrencyCode>
  eligibleRedeemablePoints?: Maybe<Scalars['Money']['output']>
  id: Scalars['String']['output']
  noEligibleRewardsReason?: Maybe<NoEligibleRewardsReason>
}

export enum ConversionQuoteFixedSide {
  Buy = 'buy',
  Sell = 'sell',
}

export type ConversionRewardRedemption = {
  __typename?: 'ConversionRewardRedemption'
  isRedeemingPoints: Scalars['Boolean']['output']
  redeemedAmount?: Maybe<Scalars['String']['output']>
  redeemedAmountAsNumber?: Maybe<Scalars['Float']['output']>
  redeemedCurrency?: Maybe<CurrencyCode>
  redeemedPoints?: Maybe<Scalars['String']['output']>
  redeemedPointsAsNumber?: Maybe<Scalars['Float']['output']>
}

export type ConversionTransaction = {
  __typename?: 'ConversionTransaction'
  buyAmount: Scalars['String']['output']
  buyAmountAsNumber: Scalars['Float']['output']
  buyCurrency: CurrencyCode
  cancelledAt?: Maybe<Scalars['DateTime']['output']>
  clientRate: Scalars['String']['output']
  conversionDate?: Maybe<Scalars['DateTime']['output']>
  feeAmount?: Maybe<Scalars['String']['output']>
  feeAmountAsNumber?: Maybe<Scalars['Float']['output']>
  feeCurrency?: Maybe<Scalars['String']['output']>
  finalBuyAmount: Scalars['String']['output']
  finalBuyAmountAsNumber: Scalars['Float']['output']
  finalSellAmount: Scalars['String']['output']
  finalSellAmountAsNumber: Scalars['Float']['output']
  fixedSide: Scalars['String']['output']
  id: Scalars['ID']['output']
  relatedPaymentId?: Maybe<Scalars['String']['output']>
  sellAmount: Scalars['String']['output']
  sellAmountAsNumber: Scalars['Float']['output']
  sellCurrency: CurrencyCode
  status: BankPaymentStatusType
}

export type Country = {
  __typename?: 'Country'
  alpha2: Scalars['String']['output']
  alpha3: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** The result of the createBeneficiaryV2 operation, which can be either a Beneficiary or BeneficiaryValidation. */
export type CreateBeneficiaryV2Result = Beneficiary | BeneficiaryValidation

export type CreateCardInput = {
  /** Details for an existing cardholder to be linked to the new card. */
  existingCardholder?: InputMaybe<ExistingCardholderInput>
  extraEmbossingLine?: InputMaybe<Scalars['String']['input']>
  /** Details for a new cardholder to be created and linked to the new card. */
  newCardholder?: InputMaybe<NewCardholderInput>
  nickname: Scalars['String']['input']
  shippingAddress?: InputMaybe<GenericAddressInput>
  /** Shipping method for the new card. */
  shippingMethod?: InputMaybe<CardShippingMethod>
  /** Spend limit for the new card. */
  spendLimit?: InputMaybe<CreateSpendLimitInput>
  type: CardType
}

export type CreateCardResponse = {
  __typename?: 'CreateCardResponse'
  card?: Maybe<Card>
  id: Scalars['ID']['output']
  pendingCard: PendingCard
  userId: Scalars['String']['output']
}

export type CreateConversionInput = {
  currencyCloudTermAgreement: Scalars['Boolean']['input']
  quoteId: Scalars['String']['input']
  reason: Scalars['String']['input']
}

export type CreateConversionQuoteInput = {
  amount: Scalars['Float']['input']
  currencyFrom: CurrencyCode
  currencyTo: CurrencyCode
  fixedSide: ConversionQuoteFixedSide
}

export type CreateConversionV2Input = {
  redeemRewards?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateDisputeInput = {
  amount: Scalars['Float']['input']
  cardId: Scalars['String']['input']
  formData: Scalars['JSON']['input']
  transactionId: Scalars['String']['input']
}

export type CreateExtraDocumentsUploadUrlInput = {
  contentType: Scalars['String']['input']
  fileName: Scalars['String']['input']
}

export type CreateLinkedItemResponse = {
  __typename?: 'CreateLinkedItemResponse'
  accessToken?: Maybe<Scalars['String']['output']>
  applicationId: Scalars['String']['output']
  businessId?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deletedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  institutionRoutingId?: Maybe<Scalars['String']['output']>
  isValidToken?: Maybe<Scalars['Boolean']['output']>
  itemId: Scalars['String']['output']
  pdfParserServiceId?: Maybe<Scalars['String']['output']>
  plaidInstitutionId?: Maybe<Scalars['String']['output']>
  provider?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  userId: Scalars['String']['output']
}

export type CreateLoanInput = {
  amount: Scalars['Float']['input']
  reason: Scalars['String']['input']
  repaymentInstallments: Scalars['Int']['input']
}

export type CreateMultiCurrencyPaymentMutationInput = {
  /** Beneficiary ID */
  beneficiaryId: Scalars['String']['input']
  /** Must agree to terms */
  currencyCloudTermAgreement: Scalars['Boolean']['input']
  /** Whether the payment is earning or reeedeming rewards */
  isEarningRewards: Scalars['Boolean']['input']
  /** Priority of the payment. */
  priority: PaymentPriority
  /** Quote ID for the payment */
  quoteId: Scalars['String']['input']
  /** Reason for the payment */
  reason: Scalars['String']['input']
  /** Reference for the payment */
  reference: Scalars['String']['input']
  /** Idempotency key */
  uniqueRequestId: Scalars['String']['input']
}

export type CreatePaymentResult = {
  __typename?: 'CreatePaymentResult'
  id?: Maybe<Scalars['String']['output']>
}

export type CreateQuoteInput = {
  amount: Scalars['Float']['input']
  buyCurrency: CurrencyCode
  conversionDate?: InputMaybe<Scalars['DateTime']['input']>
  conversionDatePreference?: InputMaybe<Scalars['String']['input']>
  fixedSide: ConversionFixedSide
  isEarningRewards: Scalars['Boolean']['input']
  sellCurrency: CurrencyCode
}

export type CreateSameCurrencyPaymentMutationInput = {
  /** Amount to pay. */
  amount: Scalars['Float']['input']
  /** Beneficiary ID */
  beneficiaryId: Scalars['String']['input']
  /** Currency of the payment. */
  currency: CurrencyCode
  isEarningRewards: Scalars['Boolean']['input']
  /** Priority of the payment. */
  priority: PaymentPriority
  /** Quote ID */
  quoteId: Scalars['String']['input']
  /** Reason for the payment */
  reason: Scalars['String']['input']
  /** Reference for the payment */
  reference: Scalars['String']['input']
  /** Idempotency key */
  uniqueRequestId: Scalars['String']['input']
}

/** Spend limit for the new card. */
export type CreateSpendLimitInput = {
  amount: Scalars['Float']['input']
  period: SpendLimitPeriod
}

export type CreateStatementPaymentInput = {
  /** Account to debit */
  accountId: Scalars['String']['input']
  /** The account type, for example plaid or currency cloud */
  accountType: StatementPaymentAccountType
  /** Amount to pay */
  amount: Scalars['Money']['input']
  /** The request id to identify the request */
  requestId: Scalars['String']['input']
}

export type CreateSupportingDocumentsUploadUrlInput = {
  fileName: Scalars['String']['input']
}

export type CreateWalletInput = {
  currency: WalletCurrencyCode
}

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cnh = 'CNH',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sle = 'SLE',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Ved = 'VED',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xsu = 'XSU',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

export type CurrencyConversion = {
  __typename?: 'CurrencyConversion'
  businessId: Scalars['String']['output']
  buyAmount: Scalars['Money']['output']
  buyCurrency: CurrencyCode
  clientRate: Scalars['Float']['output']
  completedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  externalId: Scalars['String']['output']
  id: Scalars['String']['output']
  netMarginAmount: Scalars['Money']['output']
  netMarginCurrency: CurrencyCode
  quoteLockId: Scalars['String']['output']
  sellAmount: Scalars['Money']['output']
  sellCurrency: CurrencyCode
  settledAt?: Maybe<Scalars['DateTime']['output']>
  status: Scalars['String']['output']
}

export enum DbPaymentTypeMapping {
  Priority = 'PRIORITY',
  Regular = 'REGULAR',
}

export type DateRangeInput = {
  endDate: Scalars['DateTime']['input']
  startDate: Scalars['DateTime']['input']
}

export type DeactivatedUser = {
  __typename?: 'DeactivatedUser'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
}

export type DeleteBeneficiaryInput = {
  id: Scalars['String']['input']
}

export type DelinquencyAmountResult = {
  __typename?: 'DelinquencyAmountResult'
  amount?: Maybe<Scalars['Money']['output']>
}

export type DirectorData = {
  __typename?: 'DirectorData'
  details: PersonalInformationData
  directorId: Scalars['String']['output']
  isOwnerToo?: Maybe<Scalars['Boolean']['output']>
}

export type DirectorInput = {
  details: PersonalInformationInput
  directorId: Scalars['String']['input']
  isOwnerToo?: InputMaybe<Scalars['Boolean']['input']>
}

export type Dispute = {
  __typename?: 'Dispute'
  amount: Scalars['Money']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: DisputeStatus
  transactionId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export enum DisputeStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING',
  Reversed = 'REVERSED',
}

export type DocumentData = {
  __typename?: 'DocumentData'
  base64: Scalars['String']['output']
  name: Scalars['String']['output']
  path: Scalars['String']['output']
  size: Scalars['Int']['output']
  type: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type DocumentInput = {
  name: Scalars['String']['input']
  path: Scalars['String']['input']
  size: Scalars['Int']['input']
  type: Scalars['String']['input']
  url: Scalars['String']['input']
}

export type DocumentType = {
  __typename?: 'DocumentType'
  displayName: Scalars['String']['output']
  id: Scalars['ID']['output']
  mimeType: Scalars['String']['output']
  path: Scalars['String']['output']
}

export type EarnedRewards = {
  __typename?: 'EarnedRewards'
  afterTransactionRewardsTotalPoints: WebMoney
  earnedRewardsAmount: WebMoney
  earnedRewardsAsPoints: WebMoney
  previousRewardsBalance: WebMoney
}

export enum EftInitiatorRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export type EmployeeInvitation = {
  __typename?: 'EmployeeInvitation'
  businessId: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  expiresAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: InvitationStatus
  token: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  usedAt?: Maybe<Scalars['DateTime']['output']>
  userId: Scalars['String']['output']
}

export type EstimateShippingTimeResponse = {
  __typename?: 'EstimateShippingTimeResponse'
  estimatedShippingDate: Scalars['DateTime']['output']
  isVip: Scalars['Boolean']['output']
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate'
  competitorsExchangeRate?: Maybe<Scalars['String']['output']>
  exchangeRate: Scalars['String']['output']
}

/** Link an existing cardholder to the new card. */
export type ExistingCardholderInput = {
  userId: Scalars['String']['input']
}

export type ExpenseErrorType = {
  __typename?: 'ExpenseErrorType'
  expenseId: Scalars['String']['output']
  message: Scalars['String']['output']
}

export enum ExpenseStatus {
  Created = 'CREATED',
  Draft = 'DRAFT',
  ExternallySynced = 'EXTERNALLY_SYNCED',
  Failed = 'FAILED',
  InReview = 'IN_REVIEW',
  RequestForInformation = 'REQUEST_FOR_INFORMATION',
  Reviewed = 'REVIEWED',
  Synced = 'SYNCED',
  Syncing = 'SYNCING',
}

export type ExpensesCountByStatus = {
  __typename?: 'ExpensesCountByStatus'
  CREATED?: Maybe<Scalars['Int']['output']>
  FAILED?: Maybe<Scalars['Int']['output']>
  IN_REVIEW?: Maybe<Scalars['Int']['output']>
  REQUEST_FOR_INFORMATION?: Maybe<Scalars['Int']['output']>
  REVIEWED?: Maybe<Scalars['Int']['output']>
  SYNCED?: Maybe<Scalars['Int']['output']>
  SYNCING?: Maybe<Scalars['Int']['output']>
}

export type ExpensesFilters = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>
  dateRange?: InputMaybe<DateRangeInput>
  receipt?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<ExpenseStatus>>
  taxCodes?: InputMaybe<Array<Scalars['String']['input']>>
  vendors?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ExtraDocuments = {
  __typename?: 'ExtraDocuments'
  documents?: Maybe<Array<Maybe<SupportingDocuments>>>
}

export type ExtraDocumentsInput = {
  documents?: InputMaybe<Array<InputMaybe<SupportingDocumentsInput>>>
}

export type FeeAndRewardsForQuote = {
  __typename?: 'FeeAndRewardsForQuote'
  balanceAfterwards: WebMoney
  convertingAmount?: Maybe<WebMoney>
  exchangeRate: ExchangeRate
  finalAmount: WebMoney
  internationalBankingFee: WebMoney
  rewards: EarnedRewards
  savingsAmount: WebMoney
  sellAmount?: Maybe<WebMoney>
  type?: Maybe<FeeAndRewardsForQuoteType>
}

export enum FeeAndRewardsForQuoteType {
  Conversion = 'CONVERSION',
  MultiCurrencyPayment = 'MULTI_CURRENCY_PAYMENT',
  SingleCurrencyPayment = 'SINGLE_CURRENCY_PAYMENT',
}

export type FetchSyncingExpensesResponse = {
  __typename?: 'FetchSyncingExpensesResponse'
  count: Scalars['Int']['output']
  expenses: Array<AccountingExpense>
}

export type FieldErrorParams = {
  __typename?: 'FieldErrorParams'
  /** Unknown */
  list?: Maybe<Scalars['String']['output']>
  /** Maximum field length */
  maxLength?: Maybe<Scalars['Int']['output']>
  /** Minimum field length */
  minLength?: Maybe<Scalars['Int']['output']>
  /** List of missing fields */
  missing_details?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Comma separated list of fields that should have unique values */
  parameters?: Maybe<Scalars['String']['output']>
  /** Comma separated list of possible values */
  range?: Maybe<Scalars['String']['output']>
  /** Expected data type */
  type?: Maybe<Scalars['String']['output']>
}

export type FieldErrors = {
  __typename?: 'FieldErrors'
  /** Error code */
  code: Scalars['String']['output']
  field: FieldName
  /** Readable error message */
  message: Scalars['String']['output']
  params: FieldErrorParams
}

export enum FieldName {
  Aba = 'aba',
  AccountNumber = 'accountNumber',
  BankAccountType = 'bankAccountType',
  BankCountry = 'bankCountry',
  BankName = 'bankName',
  BeneficiaryAddress = 'beneficiaryAddress',
  BeneficiaryCity = 'beneficiaryCity',
  BeneficiaryCompanyName = 'beneficiaryCompanyName',
  BeneficiaryCountry = 'beneficiaryCountry',
  BeneficiaryDateOfBirth = 'beneficiaryDateOfBirth',
  BeneficiaryEntityType = 'beneficiaryEntityType',
  BeneficiaryFirstName = 'beneficiaryFirstName',
  BeneficiaryIdentificationType = 'beneficiaryIdentificationType',
  BeneficiaryIdentificationValue = 'beneficiaryIdentificationValue',
  BeneficiaryLastName = 'beneficiaryLastName',
  BeneficiaryPostcode = 'beneficiaryPostcode',
  BeneficiaryStateOrProvince = 'beneficiaryStateOrProvince',
  BicSwift = 'bicSwift',
  BranchNumber = 'branchNumber',
  Clabe = 'clabe',
  Currency = 'currency',
  DefaultBeneficiary = 'defaultBeneficiary',
  Email = 'email',
  Iban = 'iban',
  InstitutionNumber = 'institutionNumber',
  PaymentTypes = 'paymentTypes',
  RoutingCodeType1 = 'routingCodeType1',
  RoutingCodeType2 = 'routingCodeType2',
  RoutingCodeValue1 = 'routingCodeValue1',
  RoutingCodeValue2 = 'routingCodeValue2',
  SortCode = 'sortCode',
}

export type FundingAccountWithDetails = {
  __typename?: 'FundingAccountWithDetails'
  bankAccount: BankAccount
  id: Scalars['ID']['output']
  paymentType: DbPaymentTypeMapping
}

export type FundingDiscount = {
  __typename?: 'FundingDiscount'
  amount: Scalars['Money']['output']
  id: Scalars['ID']['output']
  percent: Scalars['Float']['output']
  rewardRedemptionId?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type FundingInstallment = {
  __typename?: 'FundingInstallment'
  amountDue: Scalars['Money']['output']
  businessId: Scalars['String']['output']
  dateDue: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  installmentNumber: Scalars['Int']['output']
  interestAmountDue: Scalars['Money']['output']
  lateFee?: Maybe<Scalars['Money']['output']>
  loanId: Scalars['String']['output']
  principalAmountDue: Scalars['Money']['output']
  status: Scalars['String']['output']
}

export type FundingPaginated = {
  __typename?: 'FundingPaginated'
  agreementSignedDate?: Maybe<Scalars['DateTime']['output']>
  apr: Scalars['Float']['output']
  businessId: Scalars['String']['output']
  depositedOn?: Maybe<Scalars['DateTime']['output']>
  discounts: Array<FundingDiscount>
  feeDiscountRate: Scalars['Float']['output']
  financingChannel: Scalars['String']['output']
  firstRepaymentDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lastFundingPayment?: Maybe<LastFundingPayment>
  loanNumber: Scalars['String']['output']
  nextPaymentDate?: Maybe<Scalars['DateTime']['output']>
  outstandingBalance: Scalars['MoneyV2']['output']
  preDiscountTotalFee: Scalars['MoneyV2']['output']
  principalAmount: Scalars['MoneyV2']['output']
  reason?: Maybe<Scalars['String']['output']>
  repaymentFrequency: Scalars['String']['output']
  status: Scalars['String']['output']
  termDurationInMonths: Scalars['Float']['output']
  totalFee: Scalars['MoneyV2']['output']
  totalRefinanced: Scalars['MoneyV2']['output']
  totalRepayment: Scalars['MoneyV2']['output']
}

export type FundingPayment = {
  __typename?: 'FundingPayment'
  actorUserId: Scalars['String']['output']
  amount: Scalars['MoneyV2']['output']
  ccTransferId?: Maybe<Scalars['String']['output']>
  completedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  eftTransactionId?: Maybe<Scalars['String']['output']>
  fundingId: Scalars['String']['output']
  id: Scalars['ID']['output']
  paymentAdjustmentId?: Maybe<Scalars['String']['output']>
  rejectedAt?: Maybe<Scalars['DateTime']['output']>
  rewardRedemptionId?: Maybe<Scalars['String']['output']>
  status: FundingPaymentStatus
  type: FundingPaymentType
  updatedAt: Scalars['DateTime']['output']
}

export enum FundingPaymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Reversed = 'REVERSED',
}

export enum FundingPaymentType {
  Cashback = 'CASHBACK',
  EarlyPayment = 'EARLY_PAYMENT',
  Payment = 'PAYMENT',
  Refund = 'REFUND',
  RewardRedemption = 'REWARD_REDEMPTION',
}

export type FundingWithInstallments = {
  __typename?: 'FundingWithInstallments'
  agreementSignedDate?: Maybe<Scalars['DateTime']['output']>
  apr: Scalars['Float']['output']
  businessId: Scalars['String']['output']
  depositedOn?: Maybe<Scalars['DateTime']['output']>
  discounts: Array<FundingDiscount>
  feeDiscountRate: Scalars['Float']['output']
  financingChannel: Scalars['String']['output']
  firstRepaymentDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  installments: Array<FundingInstallment>
  lastFundingPayment?: Maybe<LastFundingPayment>
  loanNumber: Scalars['String']['output']
  nextPaymentDate?: Maybe<Scalars['DateTime']['output']>
  outstandingBalance: Scalars['Money']['output']
  preDiscountTotalFee: Scalars['Money']['output']
  principalAmount: Scalars['Money']['output']
  reason?: Maybe<Scalars['String']['output']>
  repaymentFrequency: Scalars['String']['output']
  status: Scalars['String']['output']
  termDurationInMonths: Scalars['Float']['output']
  totalFee: Scalars['Money']['output']
  totalRefinanced: Scalars['Money']['output']
  totalRepayment: Scalars['Money']['output']
}

export type GenericAddressData = {
  __typename?: 'GenericAddressData'
  addressLine1: Scalars['String']['output']
  addressLine2?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type GenericAddressInput = {
  addressLine1: Scalars['String']['input']
  addressLine2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type GetBankingStatementUrlInput = {
  bankingStatementId: Scalars['String']['input']
}

export type GetBankingStatementsInput = {
  currency?: InputMaybe<CurrencyCode>
  year?: InputMaybe<Scalars['Int']['input']>
}

export type GetBeneficiariesV2Output = {
  __typename?: 'GetBeneficiariesV2Output'
  /** List of partial fields of beneficiaries */
  beneficiaries?: Maybe<Array<Maybe<PartialBeneficiary>>>
  /** Total beneficiaries for the business */
  total?: Maybe<Scalars['Int']['output']>
}

export type GetExpenseReceiptFileOutput = {
  __typename?: 'GetExpenseReceiptFileOutput'
  file: Scalars['String']['output']
  fileName: Scalars['String']['output']
}

export enum IdentificationType {
  CitizenshipPapers = 'CITIZENSHIP_PAPERS',
  ClaveUnicaDeRegistroDePoblacion = 'CLAVE_UNICA_DE_REGISTRO_DE_POBLACION',
  CredentialDeElector = 'CREDENTIAL_DE_ELECTOR',
  DriversLicense = 'DRIVERS_LICENSE',
  DriversLicenseCanadian = 'DRIVERS_LICENSE_CANADIAN',
  EmployerIdentificationNumber = 'EMPLOYER_IDENTIFICATION_NUMBER',
  ExistingCreditCardDetails = 'EXISTING_CREDIT_CARD_DETAILS',
  GreenCard = 'GREEN_CARD',
  IncorporationNumber = 'INCORPORATION_NUMBER',
  MatriculaConsular = 'MATRICULA_CONSULAR',
  NationalId = 'NATIONAL_ID',
  None = 'NONE',
  Others = 'OTHERS',
  Passport = 'PASSPORT',
  RegistroFederalDeContribuyentes = 'REGISTRO_FEDERAL_DE_CONTRIBUYENTES',
  SocialInsuranceNumber = 'SOCIAL_INSURANCE_NUMBER',
  SocialSecurityNumber = 'SOCIAL_SECURITY_NUMBER',
  Visa = 'VISA',
}

export type IdentityVerificationData = {
  __typename?: 'IdentityVerificationData'
  idBack?: Maybe<IdentityVerificationDocumentData>
  idFront?: Maybe<IdentityVerificationDocumentData>
  identitySelfie?: Maybe<IdentityVerificationDocumentData>
  oneTimeToken?: Maybe<Scalars['String']['output']>
}

export type IdentityVerificationDocumentData = {
  __typename?: 'IdentityVerificationDocumentData'
  base64?: Maybe<Scalars['String']['output']>
  documentType?: Maybe<Scalars['String']['output']>
  path?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type IdentityVerificationDocumentInput = {
  base64: Scalars['String']['input']
  documentType?: InputMaybe<Scalars['String']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type IdentityVerificationInput = {
  idBack?: InputMaybe<IdentityVerificationDocumentInput>
  idFront?: InputMaybe<IdentityVerificationDocumentInput>
  idType?: InputMaybe<Scalars['String']['input']>
  identitySelfie?: InputMaybe<IdentityVerificationDocumentInput>
  oneTimeToken?: InputMaybe<Scalars['String']['input']>
}

export type IncidentNotification = {
  __typename?: 'IncidentNotification'
  dayOfMonth?: Maybe<Scalars['Int']['output']>
  enabled: Scalars['Boolean']['output']
  endDate?: Maybe<Scalars['DateTime']['output']>
  endTime?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  pages: Scalars['String']['output']
  severity: Scalars['String']['output']
  startDate?: Maybe<Scalars['DateTime']['output']>
  startTime?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  updatedBy: Scalars['String']['output']
  user?: Maybe<User>
}

export type IncorporationDocumentInput = {
  base64: Scalars['String']['input']
  name: Scalars['String']['input']
  path?: InputMaybe<Scalars['String']['input']>
  size: Scalars['Int']['input']
  type: Scalars['String']['input']
  url?: InputMaybe<Scalars['String']['input']>
}

export type IncorporationDocumentsData = {
  __typename?: 'IncorporationDocumentsData'
  documents?: Maybe<Array<DocumentData>>
}

export type IncorporationDocumentsInput = {
  documents?: InputMaybe<Array<IncorporationDocumentInput>>
}

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Revoked = 'REVOKED',
}

export type InviteUserMutationInput = {
  /** Information of the new user to be invited. */
  newUserData: NewUserInput
  /** Type of the user to be invited. */
  userType: UserType
}

export type IsTxDisputable = {
  __typename?: 'IsTxDisputable'
  isDisputable: Scalars['Boolean']['output']
}

export type IsTxDisputableInput = {
  transactionId: Scalars['String']['input']
}

export enum KycStatus {
  Done = 'DONE',
  None = 'NONE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type KycStatusSnapshot = {
  __typename?: 'KycStatusSnapshot'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: KycStatus
}

export type KycUserDataInput = {
  licenceBack: DocumentInput
  licenceFront: DocumentInput
  selfiePicture: DocumentInput
  workingPosition: Scalars['String']['input']
}

export type KycValidationFilesInput = {
  licenseBack: DocumentInput
  licenseFront: DocumentInput
  selfiePicture: DocumentInput
}

export type LastFundingPayment = {
  __typename?: 'LastFundingPayment'
  amount: Scalars['MoneyV2']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type LegacyTransaction = {
  __typename?: 'LegacyTransaction'
  amount: Scalars['String']['output']
  amountAsNumber: Scalars['Float']['output']
  cardId?: Maybe<Scalars['String']['output']>
  cardLastFour?: Maybe<Scalars['String']['output']>
  currency: Scalars['String']['output']
  date?: Maybe<Scalars['String']['output']>
  entityType: TransactionEntityType
  expense?: Maybe<AccountingExpense>
  id: Scalars['ID']['output']
  points?: Maybe<Scalars['RewardPoints']['output']>
  requestedAmount?: Maybe<Scalars['String']['output']>
  requestedAmountAsNumber?: Maybe<Scalars['Float']['output']>
  requestedAmountCurrency?: Maybe<Scalars['String']['output']>
  transaction: TransactionData
  transactionEventId?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
}

export enum LegalEntityType {
  Company = 'company',
  Individual = 'individual',
}

export type ListExpensesByBusinessResponse = {
  __typename?: 'ListExpensesByBusinessResponse'
  countsByStatus?: Maybe<ExpensesCountByStatus>
  expenses: Array<AccountingExpense>
  totalExpenses: Scalars['Int']['output']
}

export type ListExpensesByUserResponse = {
  __typename?: 'ListExpensesByUserResponse'
  countsByStatus?: Maybe<ExpensesCountByStatus>
  expenses: Array<AccountingExpense>
}

export type MfaSetting = {
  __typename?: 'MFASetting'
  channel: MfaChannelEnum
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isEnabled: Scalars['Boolean']['output']
  isPrimary: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
}

/** Matched receipt and expense pair */
export type MatchedReceiptPair = {
  __typename?: 'MatchedReceiptPair'
  expense: AccountingExpense
  receipt: AccountingExpenseReceipt
}

export type Merchant = {
  __typename?: 'Merchant'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  friendlyName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastSeenAt?: Maybe<Scalars['DateTime']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  mcc?: Maybe<Scalars['String']['output']>
  merchantCity?: Maybe<Scalars['String']['output']>
  merchantCode?: Maybe<Scalars['String']['output']>
  merchantName?: Maybe<Scalars['String']['output']>
  merchantState?: Maybe<Scalars['String']['output']>
  merchantZipCode?: Maybe<Scalars['String']['output']>
}

/** The channel of the MFA setting */
export enum MfaChannelEnum {
  EmailOtp = 'EMAIL_OTP',
  SmsOtp = 'SMS_OTP',
}

export type Movement = {
  __typename?: 'Movement'
  business?: Maybe<Business>
  businessId: Scalars['String']['output']
  id: Scalars['ID']['output']
  processedAt?: Maybe<Scalars['DateTime']['output']>
  producedAt?: Maybe<Scalars['DateTime']['output']>
  topic?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
  userId?: Maybe<Scalars['String']['output']>
}

export type MultiCurrencyPaymentQuoteInput = {
  /** Priority of the payment */
  priority: PaymentPriority
  /** Quote data for the payment */
  quote: CreateQuoteInput
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Makes an user agree to the latest version of all Keep's agreements. */
  acceptAgreements?: Maybe<Scalars['Boolean']['output']>
  /** Starts the acceptance process for an employee invitation. */
  acceptInvitation?: Maybe<AcceptedInvitationResult>
  /** Activates a new (pre-active) card */
  activateCard?: Maybe<Card>
  addExtraDocuments?: Maybe<Application>
  /** Adds a receipt to an expense */
  addReceiptToExpense?: Maybe<AccountingExpenseReceipt>
  /** Bulk upload receipts to expenses */
  bulkUploadReceipts?: Maybe<BulkUploadReceiptOutput>
  /** Places the card data in the VGS Vault. Card data is NOT exposed through this endpoint. */
  cacheSensitiveCardData?: Maybe<VgsCardToken>
  cancelConversion?: Maybe<Scalars['Boolean']['output']>
  cancelFundingPayment?: Maybe<FundingPayment>
  cancelPayment?: Maybe<Scalars['Boolean']['output']>
  /** Cancels a Pending Card */
  cancelPendingCard?: Maybe<PendingCard>
  /** Cancel an in progress, user-initiated credit card StatementPayment */
  cancelStatementPayment?: Maybe<StatementPayment>
  changePassword?: Maybe<Scalars['Boolean']['output']>
  /** Close the card */
  closeCard?: Maybe<Card>
  /** Check the status of account integration */
  completeAccountingIntegration?: Maybe<CompleteConnectionResponse>
  completeInformation?: Maybe<Scalars['Boolean']['output']>
  completeOnboarding?: Maybe<Scalars['Boolean']['output']>
  /** Create an enrollment config */
  createAutoEnrollmentConfig?: Maybe<StatementExtensionApproval>
  /** Creates a beneficiary */
  createBeneficiary?: Maybe<Beneficiary>
  /** Validates and creates a beneficiary */
  createBeneficiaryV2?: Maybe<CreateBeneficiaryV2Result>
  createCapitalFunding?: Maybe<CapitalFunding>
  createCapitalRequest?: Maybe<CapitalRequest>
  createCard?: Maybe<CreateCardResponse>
  createConversion?: Maybe<CcConversion>
  /** Create a currency conversion quote */
  createConversionQuote?: Maybe<ConversionQuote>
  /** Create a currency conversion */
  createCurrencyConversion?: Maybe<CurrencyConversion>
  createDispute?: Maybe<Dispute>
  createExtraDocumentsUploadUrl?: Maybe<PresignedPost>
  createLinkedItem?: Maybe<CreateLinkedItemResponse>
  createLoan?: Maybe<FundingWithInstallments>
  /** Send funds to a beneficiary with a conversion */
  createMultiCurrencyPayment?: Maybe<CreatePaymentResult>
  createQuote?: Maybe<QuoteWrapper>
  /** Get fees and rewards for same currency payment */
  createQuoteForMultiCurrencyPayment?: Maybe<QuoteWrapper>
  /** Get fees and rewards for same currency payment */
  createQuoteForSingleCurrencyPayment?: Maybe<QuoteWrapper>
  /** Send funds to a beneficiary */
  createSameCurrencyPayment?: Maybe<CreatePaymentResult>
  /** Mutation for user initiated credit card payments */
  createStatementPayment?: Maybe<StatementPayment>
  createSupportingDocumentsUploadUrl?: Maybe<PresignedPost>
  createWallet?: Maybe<Wallet>
  /** Deactivates an employee */
  deactivateEmployee?: Maybe<DeactivatedUser>
  deleteApplication?: Maybe<Application>
  /** Deletes a beneficiary */
  deleteBeneficiary?: Maybe<Scalars['String']['output']>
  /** Disconnects an accounting integration */
  disconnectAccountingIntegration?: Maybe<AccountingIntegration>
  flinksConnectionTakingTooLong?: Maybe<Scalars['Boolean']['output']>
  /** Freezes an ACTIVE card by setting its status to INACTIVE */
  freezeCard?: Maybe<Card>
  /** generates a token that identifies the user to be used by the support chat */
  generateSupportChatToken?: Maybe<Scalars['String']['output']>
  generateTruliooShortCode?: Maybe<TruliooShortCode>
  /** Invites a user to join a business. */
  inviteUser?: Maybe<SendInvitationResult>
  /** Mark a card as lost */
  lostCard?: Maybe<Card>
  makeApplication?: Maybe<Application>
  moveBackward?: Maybe<ApplicationMovement>
  moveForward?: Maybe<ApplicationMovement>
  moveToStep?: Maybe<ApplicationMovement>
  payOffFunding?: Maybe<FundingPayment>
  /** Redeem cashback rewards */
  redeemRewardCashback?: Maybe<RewardRedemption>
  registerUser?: Maybe<Scalars['String']['output']>
  /** Removes a receipt from an expense */
  removeReceiptFromExpense?: Maybe<AccountingExpense>
  /** Renames a card */
  renameCard?: Maybe<Card>
  /** Changes the nickname of a Pending Card */
  renamePendingCard?: Maybe<PendingCard>
  requestCurrencyCloudAccess?: Maybe<Business>
  /** Resend invitation to user */
  resendInvitation?: Maybe<Scalars['Boolean']['output']>
  resetPassword?: Maybe<Scalars['Boolean']['output']>
  saveFlinksLogin?: Maybe<PlaidItem>
  sendPhoneVerificationCode?: Maybe<Scalars['Boolean']['output']>
  sendRecoveryEmail?: Maybe<Scalars['Boolean']['output']>
  sendTruliooIdvLink?: Maybe<Scalars['Boolean']['output']>
  /** Send OTP for updating the email */
  sendUpdateEmailOtp?: Maybe<Scalars['Boolean']['output']>
  /** Send OTP for updating the phone number */
  sendUpdatePhoneNumberOtp?: Maybe<Scalars['Boolean']['output']>
  sendVerificationEmail?: Maybe<Scalars['Boolean']['output']>
  sendVerificationEmailFromToken?: Maybe<Scalars['Boolean']['output']>
  sendVerificationLink?: Maybe<SendSmsResponse>
  /** Sets the shipping address of a Pending Physical Card */
  setCardShippingAddress?: Maybe<PendingCard>
  /** Set a bank connection account as primary */
  setPrimaryBankConnectionAccount?: Maybe<Scalars['Boolean']['output']>
  /** Starts an accounting integration connection process */
  startAccountingIntegrationConnection?: Maybe<Scalars['String']['output']>
  /** Start an MFA challenge */
  startMFAChallenge?: Maybe<Scalars['Boolean']['output']>
  submitApplication?: Maybe<Application>
  /** Sync expenses to an Accounting Feed */
  syncExpenses?: Maybe<SyncExpensesResponse>
  /** Create a tracking event */
  trackEvent?: Maybe<TrackEvent>
  /** Unfreezes an INACTIVE card */
  unfreezeCard?: Maybe<Card>
  /** Attempt to update the applicant email */
  updateApplicantEmail?: Maybe<Scalars['Boolean']['output']>
  /** Attempt to update the phone number of an applicant */
  updateApplicantPhoneNumber?: Maybe<Scalars['Boolean']['output']>
  updateApplication?: Maybe<Application>
  updateApplicationV2?: Maybe<ApplicationV2>
  /** Edit an enrollment config */
  updateAutoEnrollmentConfig?: Maybe<StatementExtensionApproval>
  updateBusinessAddress?: Maybe<AddressData>
  updateBusinessPaymentCycle?: Maybe<Business>
  /** Set a card spend limit on i2c */
  updateCardSpendLimit?: Maybe<SpendLimit>
  /** Attempt to update an active user email */
  updateEmail?: Maybe<Scalars['Boolean']['output']>
  /** Updates the data for many expenses */
  updateExpenses?: Maybe<UpdatedExpensesResponse>
  updateNotificationSettings?: Maybe<Scalars['Boolean']['output']>
  /** Updates a Pending Card's Spend Limit */
  updatePendingCardSpendLimit?: Maybe<PendingCard>
  /** Attempt to update the phone number */
  updatePhoneNumber?: Maybe<Scalars['Boolean']['output']>
  /** Update the auto enrollment config for repayment installments (Term length). */
  updateStatementExtensionPlanTerm?: Maybe<StatementExtensionApproval>
  updateUserAddress?: Maybe<AddressData>
  uploadBase64Pdf?: Maybe<UploadBase64PdfResponse>
  uploadIdValidationFiles?: Maybe<Scalars['Boolean']['output']>
  /** Upsert an MFA setting */
  upsertMFASetting?: Maybe<MfaSetting>
  /** Validate a beneficiary's details. Useful for validating the user's input before sending a creation request. */
  validateBeneficiary?: Maybe<BeneficiaryValidation>
  /** Validates the last four digits of a card to ensure it matches the card in the system */
  validateLastFour?: Maybe<Scalars['Boolean']['output']>
  /** Validate a payment creation. Useful for validating that the payment payload is correct. */
  validatePayment?: Maybe<PaymentValidationResult>
  verifyAccount?: Maybe<Scalars['Boolean']['output']>
  /** Verify an MFA challenge */
  verifyMFAChallenge?: Maybe<Scalars['Boolean']['output']>
  verifyPhone?: Maybe<Scalars['Boolean']['output']>
}

export type MutationAcceptAgreementsArgs = {
  body: AcceptAgreementsInput
}

export type MutationAcceptInvitationArgs = {
  body: AcceptInvitationMutationInput
}

export type MutationActivateCardArgs = {
  id: Scalars['String']['input']
}

export type MutationAddExtraDocumentsArgs = {
  body: AddExtraDocumentsInput
}

export type MutationAddReceiptToExpenseArgs = {
  body: AddReceiptInput
}

export type MutationBulkUploadReceiptsArgs = {
  body: BulkUploadReceiptsInput
}

export type MutationCacheSensitiveCardDataArgs = {
  id: Scalars['String']['input']
}

export type MutationCancelConversionArgs = {
  body: CancelConversionInput
}

export type MutationCancelFundingPaymentArgs = {
  body: CancelFundingPaymentInput
}

export type MutationCancelPaymentArgs = {
  body: CancelPaymentMutationInput
}

export type MutationCancelPendingCardArgs = {
  id: Scalars['String']['input']
}

export type MutationCancelStatementPaymentArgs = {
  body: CancelStatementPaymentInput
}

export type MutationChangePasswordArgs = {
  body: ChangePasswordMutationInput
}

export type MutationCloseCardArgs = {
  id: Scalars['String']['input']
}

export type MutationCompleteAccountingIntegrationArgs = {
  body: CompleteConnectionParams
}

export type MutationCompleteInformationArgs = {
  body: CompleteInformationInput
}

export type MutationCreateAutoEnrollmentConfigArgs = {
  enabled: Scalars['Boolean']['input']
  repaymentInstallments: Scalars['Int']['input']
}

export type MutationCreateBeneficiaryArgs = {
  body: BeneficiaryInput
}

export type MutationCreateBeneficiaryV2Args = {
  body: BeneficiaryInput
}

export type MutationCreateCapitalFundingArgs = {
  body: CreateCapitalFundingInput
}

export type MutationCreateCardArgs = {
  body: CreateCardInput
}

export type MutationCreateConversionArgs = {
  body: CreateConversionInput
}

export type MutationCreateConversionQuoteArgs = {
  body: CreateConversionQuoteInput
}

export type MutationCreateCurrencyConversionArgs = {
  body?: InputMaybe<CreateConversionV2Input>
}

export type MutationCreateDisputeArgs = {
  body: CreateDisputeInput
}

export type MutationCreateExtraDocumentsUploadUrlArgs = {
  body: CreateExtraDocumentsUploadUrlInput
}

export type MutationCreateLoanArgs = {
  body: CreateLoanInput
}

export type MutationCreateMultiCurrencyPaymentArgs = {
  body: CreateMultiCurrencyPaymentMutationInput
}

export type MutationCreateQuoteArgs = {
  body: CreateQuoteInput
}

export type MutationCreateQuoteForMultiCurrencyPaymentArgs = {
  body: MultiCurrencyPaymentQuoteInput
}

export type MutationCreateQuoteForSingleCurrencyPaymentArgs = {
  body: SameCurrencyPaymentQuoteInput
}

export type MutationCreateSameCurrencyPaymentArgs = {
  body: CreateSameCurrencyPaymentMutationInput
}

export type MutationCreateStatementPaymentArgs = {
  body: CreateStatementPaymentInput
}

export type MutationCreateSupportingDocumentsUploadUrlArgs = {
  body: CreateSupportingDocumentsUploadUrlInput
}

export type MutationCreateWalletArgs = {
  body?: InputMaybe<CreateWalletInput>
}

export type MutationDeactivateEmployeeArgs = {
  employeeId: Scalars['String']['input']
}

export type MutationDeleteBeneficiaryArgs = {
  body: DeleteBeneficiaryInput
}

export type MutationDisconnectAccountingIntegrationArgs = {
  accountingIntegrationId: Scalars['String']['input']
}

export type MutationFlinksConnectionTakingTooLongArgs = {
  body: FlinksConnectionTakingTooLongInput
}

export type MutationFreezeCardArgs = {
  id: Scalars['String']['input']
}

export type MutationInviteUserArgs = {
  body: InviteUserMutationInput
}

export type MutationLostCardArgs = {
  id: Scalars['String']['input']
}

export type MutationMoveBackwardArgs = {
  body: ApplicationMovementInput
}

export type MutationMoveForwardArgs = {
  body: ApplicationMovementInput
}

export type MutationMoveToStepArgs = {
  body: ApplicationMovementInput
}

export type MutationPayOffFundingArgs = {
  body: PayOffFundingInput
}

export type MutationRedeemRewardCashbackArgs = {
  body: RedeemRewardCashbackMutationInput
}

export type MutationRegisterUserArgs = {
  body: RegisterUserMutationInput
}

export type MutationRemoveReceiptFromExpenseArgs = {
  body: RemoveReceiptInput
}

export type MutationRenameCardArgs = {
  id: Scalars['String']['input']
  newNickname: Scalars['String']['input']
}

export type MutationRenamePendingCardArgs = {
  body: RenamePendingCardInput
}

export type MutationRequestCurrencyCloudAccessArgs = {
  body: RequestCurrencyCloudAccessInput
}

export type MutationResendInvitationArgs = {
  invitedUserId: Scalars['String']['input']
}

export type MutationResetPasswordArgs = {
  body: ResetPasswordMutationInput
}

export type MutationSaveFlinksLoginArgs = {
  body: SaveFlinksLoginInput
}

export type MutationSendRecoveryEmailArgs = {
  body: SendRecoveryEmailInput
}

export type MutationSendUpdateEmailOtpArgs = {
  body: SendUpdateEmailOtpInput
}

export type MutationSendUpdatePhoneNumberOtpArgs = {
  body: SendUpdatePhoneNumberOtpInput
}

export type MutationSendVerificationEmailFromTokenArgs = {
  body: SendVerificationEmailFromTokenMutationInput
}

export type MutationSendVerificationLinkArgs = {
  phoneNumber: Scalars['String']['input']
}

export type MutationSetCardShippingAddressArgs = {
  body: SetCardShippingAddressInput
}

export type MutationSetPrimaryBankConnectionAccountArgs = {
  body: SetPrimaryBankConnectionAccountInput
}

export type MutationStartAccountingIntegrationConnectionArgs = {
  body: StartAccountingIntegrationConnectionParams
}

export type MutationStartMfaChallengeArgs = {
  body: StartMfaChallengeInput
}

export type MutationSubmitApplicationArgs = {
  body: SubmitApplicationInput
}

export type MutationSyncExpensesArgs = {
  expensesIds: Array<Scalars['String']['input']>
}

export type MutationTrackEventArgs = {
  event: Scalars['String']['input']
  payload: Scalars['JSON']['input']
  team: Scalars['String']['input']
}

export type MutationUnfreezeCardArgs = {
  id: Scalars['String']['input']
}

export type MutationUpdateApplicantEmailArgs = {
  body: UpdateEmailInput
}

export type MutationUpdateApplicantPhoneNumberArgs = {
  body: UpdatePhoneNumberInput
}

export type MutationUpdateApplicationArgs = {
  body: UpdateApplicationDataInput
}

export type MutationUpdateApplicationV2Args = {
  body: ApplicationUpdateInput
}

export type MutationUpdateAutoEnrollmentConfigArgs = {
  enabled: Scalars['Boolean']['input']
}

export type MutationUpdateBusinessAddressArgs = {
  body: UpdateAddressDataInput
}

export type MutationUpdateBusinessPaymentCycleArgs = {
  body: BusinessPaymentCycleInput
}

export type MutationUpdateCardSpendLimitArgs = {
  body: UpdateCardSpendLimitInput
}

export type MutationUpdateEmailArgs = {
  body: UpdateEmailInput
}

export type MutationUpdateExpensesArgs = {
  body: UpdateExpensesInput
}

export type MutationUpdateNotificationSettingsArgs = {
  body: UpdateNotificationSettingsMutationInput
}

export type MutationUpdatePendingCardSpendLimitArgs = {
  body: UpdateSpendLimitPendingCardInput
}

export type MutationUpdatePhoneNumberArgs = {
  body: UpdatePhoneNumberInput
}

export type MutationUpdateStatementExtensionPlanTermArgs = {
  repaymentInstallments: Scalars['Int']['input']
}

export type MutationUpdateUserAddressArgs = {
  body: UpdateAddressDataInput
}

export type MutationUploadBase64PdfArgs = {
  body: UploadBase64PdfInput
}

export type MutationUploadIdValidationFilesArgs = {
  body: KycValidationFilesInput
}

export type MutationUpsertMfaSettingArgs = {
  body: UpsertMfaSettingInput
}

export type MutationValidateBeneficiaryArgs = {
  body: BeneficiaryInput
}

export type MutationValidateLastFourArgs = {
  cardId: Scalars['String']['input']
  lastFour: Scalars['String']['input']
}

export type MutationValidatePaymentArgs = {
  body: ValidatePaymentMutationInput
}

export type MutationVerifyAccountArgs = {
  body: VerifyAccountMutationInput
}

export type MutationVerifyMfaChallengeArgs = {
  body: VerifyMfaChallengeInput
}

export type MutationVerifyPhoneArgs = {
  body: VerifyPhoneInput
}

/** Create a new cardholder for the new card. */
export type NewCardholderInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  userType: UserType
}

/** Information needed to create a new User record. */
export type NewUserInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}

/** No Eligible Rewards Reason */
export enum NoEligibleRewardsReason {
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  InsufficientFee = 'INSUFFICIENT_FEE',
}

export enum OnboardingStepV2 {
  ApplicantIdentity = 'APPLICANT_IDENTITY',
  BusinessAddress = 'BUSINESS_ADDRESS',
  BusinessDetails = 'BUSINESS_DETAILS',
  CardSetup = 'CARD_SETUP',
  CardSetupIntro = 'CARD_SETUP_INTRO',
  FinancialDetails = 'FINANCIAL_DETAILS',
  IdentityVerification = 'IDENTITY_VERIFICATION',
  IncorporationDetails = 'INCORPORATION_DETAILS',
  ListOfOwners = 'LIST_OF_OWNERS',
  ProductsSelection = 'PRODUCTS_SELECTION',
  Review = 'REVIEW',
}

/** Ascending (asc) or (descending) ordering */
export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc',
}

export type OutstandingBalances = {
  __typename?: 'OutstandingBalances'
  cardSettledBalance: Scalars['Money']['output']
  statementExtensionOutstandingBalance: Scalars['Money']['output']
  statementOutstandingBalance: Scalars['Money']['output']
}

export type OwnerData = {
  __typename?: 'OwnerData'
  addAsDirector: Scalars['Boolean']['output']
  additionalInformation: AdditionalInformationData
  details: PersonalInformationData
  homeAddress: GenericAddressData
  ownerId: Scalars['String']['output']
  ownershipNature: Scalars['String']['output']
  ownershipNatureDescription?: Maybe<Scalars['String']['output']>
}

export type OwnerInput = {
  addAsDirector: Scalars['Boolean']['input']
  additionalInformation: AdditionalInformationInput
  details: PersonalInformationInput
  homeAddress: GenericAddressInput
  ownerId: Scalars['String']['input']
  ownershipNature: Scalars['String']['input']
  ownershipNatureDescription?: InputMaybe<Scalars['String']['input']>
}

export type PaginateFundings = {
  __typename?: 'PaginateFundings'
  data: Array<FundingPaginated>
  pages: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type PaginatedCapitalFunding = {
  __typename?: 'PaginatedCapitalFunding'
  agreementSignedDate?: Maybe<Scalars['DateTime']['output']>
  apr: Scalars['Float']['output']
  businessId: Scalars['String']['output']
  complianceFee: Scalars['MoneyV2']['output']
  complianceFeeRate: Scalars['Float']['output']
  depositedOn?: Maybe<Scalars['DateTime']['output']>
  discounts: Array<FundingDiscount>
  feeDiscountRate: Scalars['Float']['output']
  financingChannel: Scalars['String']['output']
  firstRepaymentDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lastFundingPayment?: Maybe<LastFundingPayment>
  loanNumber: Scalars['String']['output']
  nextPaymentDate?: Maybe<Scalars['DateTime']['output']>
  originationFee: Scalars['MoneyV2']['output']
  outstandingBalance: Scalars['MoneyV2']['output']
  preDiscountTotalFee: Scalars['MoneyV2']['output']
  principalAmount: Scalars['MoneyV2']['output']
  reason?: Maybe<Scalars['String']['output']>
  receivedAmount: Scalars['MoneyV2']['output']
  repaymentFrequency: Scalars['String']['output']
  serviceFee: Scalars['MoneyV2']['output']
  serviceFeeRate: Scalars['Float']['output']
  status: Scalars['String']['output']
  termDurationInMonths: Scalars['Float']['output']
  totalFee: Scalars['MoneyV2']['output']
  totalRefinanced: Scalars['MoneyV2']['output']
  totalRepayment: Scalars['MoneyV2']['output']
}

export type PaginatedCapitalFundings = {
  __typename?: 'PaginatedCapitalFundings'
  data: Array<PaginatedCapitalFunding>
  pages: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type PaginatedPaymentResult = {
  __typename?: 'PaginatedPaymentResult'
  count?: Maybe<Scalars['Int']['output']>
  pages?: Maybe<Scalars['Int']['output']>
  payments?: Maybe<Array<StatementPayment>>
  skip?: Maybe<Scalars['Int']['output']>
  take?: Maybe<Scalars['Int']['output']>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedReferrals = {
  __typename?: 'PaginatedReferrals'
  referrals: Array<Referral>
  totalCount: Scalars['Int']['output']
}

export type PaginatedRewardsHistory = {
  __typename?: 'PaginatedRewardsHistory'
  count?: Maybe<Scalars['Int']['output']>
  pages?: Maybe<Scalars['Int']['output']>
  rewards?: Maybe<Array<RewardsHistory>>
  skip?: Maybe<Scalars['Int']['output']>
  take?: Maybe<Scalars['Int']['output']>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedTransactionEventsResult = {
  __typename?: 'PaginatedTransactionEventsResult'
  count?: Maybe<Scalars['Int']['output']>
  events?: Maybe<Array<TransactionEvent>>
  pages?: Maybe<Scalars['Int']['output']>
  skip?: Maybe<Scalars['Int']['output']>
  take?: Maybe<Scalars['Int']['output']>
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type PaginatedTransactionResult = {
  __typename?: 'PaginatedTransactionResult'
  count?: Maybe<Scalars['Int']['output']>
  pages?: Maybe<Scalars['Int']['output']>
  pendingTransactions?: Maybe<Array<LegacyTransaction>>
  skip?: Maybe<Scalars['Int']['output']>
  take?: Maybe<Scalars['Int']['output']>
  totalCount?: Maybe<Scalars['Int']['output']>
  transactions?: Maybe<Array<LegacyTransaction>>
}

export type PaginatedWalletTransactionResult = {
  __typename?: 'PaginatedWalletTransactionResult'
  count: Scalars['Int']['output']
  pages: Scalars['Int']['output']
  skip: Scalars['Int']['output']
  take: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
  transactions?: Maybe<Array<WalletTransaction>>
}

export type PaginationInput = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type PartialBeneficiary = {
  __typename?: 'PartialBeneficiary'
  beneficiaryId: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  currencyCode: CurrencyCode
  email: Scalars['String']['output']
  lastFourDigits: Scalars['String']['output']
  recipientName: Scalars['String']['output']
}

export type PaymentBalanceResult = {
  __typename?: 'PaymentBalanceResult'
  cardOutstandingBalance?: Maybe<CardOustandingBalanceData>
}

/** How often we pull funds from a business's bank account to pay off their balance. */
export enum PaymentCycleEnum {
  Biweekly = 'BIWEEKLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  currency: Scalars['String']['output']
  externalId: Scalars['String']['output']
  id: Scalars['String']['output']
  isPrimary: Scalars['Boolean']['output']
  lastFour?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export enum PaymentPriority {
  Priority = 'PRIORITY',
  Standard = 'STANDARD',
}

export enum PaymentType {
  Priority = 'priority',
  Regular = 'regular',
}

export type PaymentValidationResult = {
  __typename?: 'PaymentValidationResult'
  isValid: Scalars['Boolean']['output']
}

export type PdfInput = {
  filename: Scalars['String']['input']
  pdfBase64: Scalars['String']['input']
  url: Scalars['String']['input']
}

export type PendingBankPayment = {
  __typename?: 'PendingBankPayment'
  date: Scalars['String']['output']
  entityType: WalletTransactionEntityType
  id: Scalars['ID']['output']
  pointsAsNumber: Scalars['Float']['output']
  pointsAsString: Scalars['String']['output']
  transaction: PendingBankPaymentTransaction
}

export type PendingBankPaymentTransaction = {
  __typename?: 'PendingBankPaymentTransaction'
  amount: Scalars['String']['output']
  amountAsNumber: Scalars['Float']['output']
  conversionFeeAmount?: Maybe<Scalars['String']['output']>
  conversionFeeAmountAsNumber?: Maybe<Scalars['Float']['output']>
  conversionFeeCurrency?: Maybe<Scalars['String']['output']>
  convertedAmount?: Maybe<Scalars['String']['output']>
  convertedCurrency?: Maybe<CurrencyCode>
  createdAt?: Maybe<Scalars['String']['output']>
  creditorAccountHolderName: Scalars['String']['output']
  currency: CurrencyCode
  debitorAccountHolderName: Scalars['String']['output']
  id: Scalars['ID']['output']
  paymentMethod?: Maybe<Scalars['String']['output']>
  priority: PaymentPriority
  reason: Scalars['String']['output']
  reference: Scalars['String']['output']
  speedFeeAmount?: Maybe<Scalars['String']['output']>
  speedFeeAmountAsNumber?: Maybe<Scalars['Float']['output']>
  speedFeeCurrency?: Maybe<Scalars['String']['output']>
}

export type PendingCard = {
  __typename?: 'PendingCard'
  addressLine1?: Maybe<Scalars['String']['output']>
  addressLine2?: Maybe<Scalars['String']['output']>
  belongsToValidUser?: Maybe<Scalars['Boolean']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  nameOnCard?: Maybe<Scalars['String']['output']>
  nickname?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  spendLimitAmount?: Maybe<Scalars['Money']['output']>
  spendLimitPeriod?: Maybe<SpendLimitPeriod>
  state?: Maybe<Scalars['String']['output']>
  status?: Maybe<PendingCardStatus>
  type?: Maybe<CardType>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export enum PendingCardStatus {
  CancelledIssuance = 'CANCELLED_ISSUANCE',
  EnrollmentPending = 'ENROLLMENT_PENDING',
  IssuanceFailed = 'ISSUANCE_FAILED',
  Issued = 'ISSUED',
  Issuing = 'ISSUING',
}

export type PendingCardsIssuanceResult = {
  __typename?: 'PendingCardsIssuanceResult'
  errors?: Maybe<Array<Scalars['String']['output']>>
  issuedCardsIds: Array<Scalars['String']['output']>
}

export enum PersonParticipation {
  Applicant = 'APPLICANT',
  Director = 'DIRECTOR',
  Owner = 'OWNER',
}

export type PersonalAddressInput = {
  addressLine1: Scalars['String']['input']
  addressLine2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
  useAsBusinessAddress: Scalars['Boolean']['input']
}

export type PersonalInformationData = {
  __typename?: 'PersonalInformationData'
  dateOfBirth?: Maybe<Scalars['String']['output']>
  emailAddress?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  socialNetworks?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type PersonalInformationInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  socialNetworks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type PlaidAccount = {
  __typename?: 'PlaidAccount'
  accountId?: Maybe<Scalars['ID']['output']>
  accountSubtype?: Maybe<Scalars['String']['output']>
  accountType?: Maybe<AccountType>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type PlaidInstitution = {
  __typename?: 'PlaidInstitution'
  id: Scalars['ID']['output']
  logo?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type PlaidItem = {
  __typename?: 'PlaidItem'
  id: Scalars['ID']['output']
  institution?: Maybe<PlaidInstitution>
  plaidAccounts?: Maybe<Array<Maybe<PlaidAccount>>>
  plaidInstitutionId?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export type PlainAccount = {
  __typename?: 'PlainAccount'
  accountName?: Maybe<Scalars['String']['output']>
  accountNumber?: Maybe<Scalars['String']['output']>
}

export enum Polarity {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
}

export type PresignedPost = {
  __typename?: 'PresignedPost'
  fields: Scalars['JSON']['output']
  url: Scalars['String']['output']
}

export type Purchase = {
  __typename?: 'Purchase'
  amount?: Maybe<Scalars['Money']['output']>
  business?: Maybe<Business>
  card?: Maybe<Card>
  cardId?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastFour?: Maybe<Scalars['String']['output']>
  merchant?: Maybe<Merchant>
  merchantId?: Maybe<Scalars['String']['output']>
  points?: Maybe<Scalars['String']['output']>
  processedAt?: Maybe<Scalars['DateTime']['output']>
  producedAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<Scalars['String']['output']>
  transactionType?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export type Query = {
  __typename?: 'Query'
  activationChecklist?: Maybe<Array<ActivationChecklistItem>>
  activeIncidentNotifications?: Maybe<Array<Maybe<IncidentNotification>>>
  address?: Maybe<AddressData>
  /** Returns latest active agreements */
  agreements?: Maybe<Array<Agreement>>
  application?: Maybe<Application>
  applicationV2?: Maybe<ApplicationV2>
  applicationV2ByBusinessId?: Maybe<ApplicationV2>
  /** Get all active bank connection accounts for the current user */
  bankConnectionAccounts: Array<BankConnectionAccount>
  bankingStatements: Array<BankingStatement>
  /** Gets all beneficiaries for a business */
  beneficiaries?: Maybe<Array<Maybe<PartialBeneficiary>>>
  /** Get all beneficiaries for a business V2 */
  beneficiariesV2?: Maybe<GetBeneficiariesV2Output>
  business: Business
  businessAccountingIntegration?: Maybe<AccountingIntegration>
  /** Fetch delinquency details of a business */
  businessDelinquencyDetails?: Maybe<BusinessDelinquencyDetails>
  /** Get business users with kyc status */
  businessUsersWithKycStatus?: Maybe<Array<UserWithKycStatusSnapshot>>
  calculateRepaymentDates?: Maybe<CalculateRepaymentDates>
  calculateRepaymentPlan?: Maybe<CalculateRepaymentPlan>
  calculateRepaymentPlans?: Maybe<Array<Maybe<CalculateRepaymentPlan>>>
  calculateRepaymentPlansWithOriginationFee?: Maybe<Array<Maybe<CalculateRepaymentPlanWithOriginationFee>>>
  calculateWholeAmountToPayForEarlyPayment?: Maybe<CalculateWholeAmountToPayForEarlyPaymentOutput>
  canApplyForNewUserPromotion?: Maybe<CanApplyForNewUserPromotionOutput>
  capitalApproval?: Maybe<Approval>
  capitalFundingsByBusinessIdPaginated?: Maybe<PaginatedCapitalFundings>
  capitalInfo?: Maybe<CapitalInfo>
  card?: Maybe<Card>
  /** Fetch card limit of a business */
  cardLimit?: Maybe<BusinessCardLimit>
  cards?: Maybe<Array<Card>>
  cardsTransactionsTotalNet?: Maybe<CardsTransactionsTotalNet>
  countries?: Maybe<Array<Maybe<Country>>>
  currentStatement?: Maybe<Statement>
  /** Get the amount to show in the delinquency banner for a business */
  delinquencyAmount?: Maybe<DelinquencyAmountResult>
  /** Computes an estimation of the shipping time for a physical card depending on the business credit limit */
  estimateShippingTime?: Maybe<EstimateShippingTimeResponse>
  /** Fetches all syncing expenses. It's useful to see if a business has any expenses that are still syncing */
  fetchSyncingExpenses?: Maybe<FetchSyncingExpensesResponse>
  fundingsByBusinessIdPaginated?: Maybe<PaginateFundings>
  getBankingStatementUrl: BankingStatementUrl
  getExpenseReceiptFile?: Maybe<GetExpenseReceiptFileOutput>
  getUserAddress?: Maybe<AddressData>
  getUserVerificationData?: Maybe<UserVerificationData>
  healthcheck?: Maybe<Scalars['Boolean']['output']>
  installments?: Maybe<Array<Maybe<FundingInstallment>>>
  isTxDisputable?: Maybe<IsTxDisputable>
  legacyTransactions?: Maybe<PaginatedTransactionResult>
  listAccountingCategories?: Maybe<Array<AccountingExpenseCategory>>
  listAccountingClasses?: Maybe<Array<AccountingExpenseClass>>
  listAccountingTaxRates?: Maybe<Array<AccountingExpenseTaxRate>>
  listAccountingVendors?: Maybe<Array<AccountingExpenseVendor>>
  listExpensesByBusiness?: Maybe<ListExpensesByBusinessResponse>
  listExpensesByUser?: Maybe<ListExpensesByUserResponse>
  loans?: Maybe<Array<Maybe<FundingWithInstallments>>>
  /** Get mfa settings for a given user */
  mfaSettings?: Maybe<Array<MfaSetting>>
  outstandingBalances?: Maybe<OutstandingBalances>
  /** Get all active payment methods for the current business */
  paymentMethods: Array<PaymentMethod>
  /** Gets payment balance for a business */
  paymentsBalance?: Maybe<PaymentBalanceResult>
  /** Returns all pending agreements for the user. */
  pendingAgreements?: Maybe<Array<Agreement>>
  pendingCard?: Maybe<PendingCard>
  pendingCards?: Maybe<Array<PendingCard>>
  physicalPendingCard?: Maybe<PendingCard>
  physicalPreActiveCard?: Maybe<Card>
  /** Get the primary account */
  primaryAccount?: Maybe<PlainAccount>
  referralCode?: Maybe<ReferralCode>
  referralCodeByCode?: Maybe<ReferralCode>
  referralRewardsEarned?: Maybe<ReferralRewardAmount>
  referrals?: Maybe<PaginatedReferrals>
  rewardsHistory?: Maybe<PaginatedRewardsHistory>
  statementExtensionApproval?: Maybe<StatementExtensionApproval>
  statementExtensions?: Maybe<Array<FundingWithInstallments>>
  /** Get credit card StatementPayments for the business */
  statementPayments?: Maybe<PaginatedPaymentResult>
  /** Fetch a list of statements for a business */
  statements?: Maybe<Array<Maybe<Statement>>>
  /** Get the total balance for the given currency */
  totalBalanceInCurrency?: Maybe<TotalBalanceInCurrency>
  /** Fetch total cards credit limit of a business */
  totalCardsCreditLimit?: Maybe<Scalars['Money']['output']>
  user?: Maybe<User>
  userHasAgreedReferrals?: Maybe<Scalars['Boolean']['output']>
  userHasPhysicalCard?: Maybe<Scalars['Boolean']['output']>
  walletTransactions?: Maybe<PaginatedWalletTransactionResult>
  wallets?: Maybe<Array<Maybe<Wallet>>>
}

export type QueryBankingStatementsArgs = {
  input?: InputMaybe<GetBankingStatementsInput>
}

export type QueryBeneficiariesV2Args = {
  pagination?: InputMaybe<BeneficiaryPaginationInput>
}

export type QueryBusinessAccountingIntegrationArgs = {
  integrationType: AccountingIntegrationType
}

export type QueryBusinessDelinquencyDetailsArgs = {
  product: BusinessDelinquencyProduct
}

export type QueryCalculateRepaymentDatesArgs = {
  repaymentFrequency: Scalars['String']['input']
  repaymentInstallments: Scalars['Int']['input']
  startDate: Scalars['DateTime']['input']
}

export type QueryCalculateRepaymentPlanArgs = {
  apr: Scalars['Float']['input']
  feeDiscountRate?: InputMaybe<Scalars['Float']['input']>
  fundingAmount: Scalars['Float']['input']
  repaymentFrequency: Scalars['String']['input']
  repaymentInstallments: Scalars['Int']['input']
}

export type QueryCalculateRepaymentPlansArgs = {
  apr: Scalars['Float']['input']
  feeDiscountRate: Scalars['Float']['input']
  fundingAmount: Scalars['Float']['input']
  repaymentFrequency: Scalars['String']['input']
  repaymentInstallments: Array<Scalars['Int']['input']>
}

export type QueryCalculateRepaymentPlansWithOriginationFeeArgs = {
  fundingAmount: Scalars['Float']['input']
  maxRepaymentMonths: Scalars['Int']['input']
  repaymentFrequency: Scalars['String']['input']
}

export type QueryCalculateWholeAmountToPayForEarlyPaymentArgs = {
  fundingId: Scalars['String']['input']
}

export type QueryCapitalFundingsByBusinessIdPaginatedArgs = {
  limit: Scalars['Int']['input']
  page: Scalars['Int']['input']
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QueryCardArgs = {
  id: Scalars['String']['input']
}

export type QueryCardsArgs = {
  filters?: InputMaybe<CardsFilters>
}

export type QueryCardsTransactionsTotalNetArgs = {
  businessId: Scalars['String']['input']
  dateFrom?: InputMaybe<Scalars['CalendarDate']['input']>
  dateTo?: InputMaybe<Scalars['CalendarDate']['input']>
}

export type QueryEstimateShippingTimeArgs = {
  fromDate?: InputMaybe<Scalars['CalendarDate']['input']>
}

export type QueryFundingsByBusinessIdPaginatedArgs = {
  financingChannel?: InputMaybe<Scalars['String']['input']>
  limit: Scalars['Int']['input']
  page: Scalars['Int']['input']
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QueryGetBankingStatementUrlArgs = {
  input: GetBankingStatementUrlInput
}

export type QueryGetExpenseReceiptFileArgs = {
  expenseId: Scalars['String']['input']
}

export type QueryHealthcheckArgs = {
  comment?: InputMaybe<Scalars['String']['input']>
}

export type QueryInstallmentsArgs = {
  fundingId: Scalars['String']['input']
}

export type QueryIsTxDisputableArgs = {
  body: IsTxDisputableInput
}

export type QueryLegacyTransactionsArgs = {
  cardId?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['CalendarDate']['input']>
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['CalendarDate']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type QueryListExpensesByBusinessArgs = {
  filters?: InputMaybe<ExpensesFilters>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryListExpensesByUserArgs = {
  filters?: InputMaybe<ExpensesFilters>
  pagination?: InputMaybe<PaginationInput>
}

export type QueryPendingCardArgs = {
  id: Scalars['String']['input']
}

export type QueryReferralCodeByCodeArgs = {
  code: Scalars['String']['input']
}

export type QueryReferralsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type QueryRewardsHistoryArgs = {
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type QueryStatementPaymentsArgs = {
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Array<StatementPaymentStatus>>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTotalBalanceInCurrencyArgs = {
  currency: CurrencyCode
}

export type QueryWalletTransactionsArgs = {
  currencyCode?: InputMaybe<CurrencyCode>
  endDate?: InputMaybe<Scalars['CalendarDate']['input']>
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['CalendarDate']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type Quote = {
  __typename?: 'Quote'
  clientRate: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
}

export type QuoteWrapper = {
  __typename?: 'QuoteWrapper'
  feesAndRewards: FeeAndRewardsForQuote
  quote: Quote
}

export type ReceiptInput = {
  displayName: Scalars['String']['input']
  id?: InputMaybe<Scalars['String']['input']>
  mimeType: Scalars['String']['input']
  path: Scalars['String']['input']
}

export type RedeemRewardCashbackMutationInput = {
  /** Amount to redeem */
  amount: Scalars['RewardScalar']['input']
}

export type Referral = {
  __typename?: 'Referral'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  invitee?: Maybe<User>
  inviteeId: Scalars['String']['output']
  referrerId?: Maybe<Scalars['String']['output']>
  status: ReferralStatus
}

export type ReferralCode = {
  __typename?: 'ReferralCode'
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export type ReferralRewardAmount = {
  __typename?: 'ReferralRewardAmount'
  amount: Scalars['BigInt']['output']
}

export enum ReferralStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
}

export type Refund = {
  __typename?: 'Refund'
  amount?: Maybe<Scalars['Money']['output']>
  business?: Maybe<Business>
  card?: Maybe<Card>
  cardId?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastFour?: Maybe<Scalars['String']['output']>
  merchant?: Maybe<Merchant>
  merchantId?: Maybe<Scalars['String']['output']>
  processedAt?: Maybe<Scalars['DateTime']['output']>
  producedAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<Scalars['String']['output']>
  transactionType?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export type RegisterUserMutationInput = {
  agreeMarketingCommunications: Scalars['Boolean']['input']
  agreePlatformAgreement: Scalars['Boolean']['input']
  agreePrivacyPolicy: Scalars['Boolean']['input']
  agreeTOS: Scalars['Boolean']['input']
  businessName?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  fastTrack?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  maxNumberOfEmployees?: InputMaybe<Scalars['Int']['input']>
  minNumberOfEmployees: Scalars['Int']['input']
  password: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  referralCode?: InputMaybe<Scalars['String']['input']>
}

export type RejectedUploads = {
  __typename?: 'RejectedUploads'
  filename: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type RelationshipToTheBusinessInput = {
  authorizedSigner?: InputMaybe<SignerInformationInput>
  authorizedToSign: Scalars['String']['input']
  customPosition?: InputMaybe<Scalars['String']['input']>
  position: Scalars['String']['input']
}

export type RemoveReceiptInput = {
  expenseId: Scalars['String']['input']
}

/** Allows renaming a pending card */
export type RenamePendingCardInput = {
  id: Scalars['String']['input']
  nickname: Scalars['String']['input']
}

export type Repayment = {
  __typename?: 'Repayment'
  formatted: Scalars['String']['output']
  repayment: Scalars['Int']['output']
}

export type Repayments = {
  __typename?: 'Repayments'
  formatted: Scalars['String']['output']
  repayment: Scalars['Int']['output']
}

export type RequestCurrencyCloudAccessInput = {
  /** Business ID */
  id: Scalars['String']['input']
}

export type ResetPasswordMutationInput = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type ResolvedUploads = {
  __typename?: 'ResolvedUploads'
  pdfHeronId: Scalars['String']['output']
  requestId: Scalars['String']['output']
}

export type ReviewData = {
  __typename?: 'ReviewData'
  isReview?: Maybe<Scalars['Boolean']['output']>
}

export type ReviewInput = {
  isReview?: InputMaybe<Scalars['Boolean']['input']>
}

export type Reward = {
  __typename?: 'Reward'
  amount?: Maybe<Scalars['RewardPoint']['output']>
  availableAt?: Maybe<Scalars['DateTime']['output']>
  cardId?: Maybe<Scalars['String']['output']>
  currency?: Maybe<Scalars['String']['output']>
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  merchant?: Maybe<Merchant>
  merchantId?: Maybe<Scalars['String']['output']>
  processedAt?: Maybe<Scalars['DateTime']['output']>
  producedAt?: Maybe<Scalars['DateTime']['output']>
  purchase?: Maybe<Purchase>
  purchaseId?: Maybe<Scalars['String']['output']>
  transactionType?: Maybe<Scalars['String']['output']>
}

export enum RewardHistoryAction {
  Cashback = 'CASHBACK',
  CreditCard = 'CREDIT_CARD',
  CurrencyConversion = 'CURRENCY_CONVERSION',
  Fx = 'FX',
  InvoiceFactoringFee = 'INVOICE_FACTORING_FEE',
  Points = 'POINTS',
  Referral = 'REFERRAL',
  StatementExtensionFee = 'STATEMENT_EXTENSION_FEE',
}

export type RewardRedemption = {
  __typename?: 'RewardRedemption'
  availableRewards: Scalars['RewardScalar']['output']
}

export type RewardsHistory = {
  __typename?: 'RewardsHistory'
  action: RewardHistoryAction
  amount: Scalars['String']['output']
  amountAsNumber: Scalars['Float']['output']
  entityId: Scalars['String']['output']
  entityType: Scalars['String']['output']
  polarity: Polarity
  rewardDate: Scalars['DateTime']['output']
  userFirstName: Scalars['String']['output']
  userLastName: Scalars['String']['output']
}

export type RutterAdditionalData = {
  /** Access token granted by Rutter */
  publicToken: Scalars['String']['input']
}

export type SameCurrencyPaymentQuoteInput = {
  /** Amount to pay */
  amount: Scalars['Float']['input']
  /** Currency of the payment */
  currency: CurrencyCode
  isEarningRewards: Scalars['Boolean']['input']
  /** Priority of the payment */
  priority: PaymentPriority
}

export type SaveFlinksLoginInput = {
  loginId: Scalars['String']['input']
  reconnect: Scalars['Boolean']['input']
}

export type SendInvitationResult = {
  __typename?: 'SendInvitationResult'
  invitation: EmployeeInvitation
}

export type SendRecoveryEmailInput = {
  email: Scalars['String']['input']
}

export type SendSmsResponse = {
  __typename?: 'SendSmsResponse'
  remainingTime?: Maybe<Scalars['Int']['output']>
  success: Scalars['Boolean']['output']
}

export type SendUpdateEmailOtpInput = {
  email: Scalars['String']['input']
}

export type SendUpdatePhoneNumberOtpInput = {
  phoneNumber: Scalars['String']['input']
}

export type SendVerificationEmailFromTokenMutationInput = {
  token: Scalars['String']['input']
}

/** Input for setting the shipping address of a pending physical card */
export type SetCardShippingAddressInput = {
  address: ShippingAddressInput
  /** Pending Card ID */
  pendingCardId: Scalars['String']['input']
}

export type SetPrimaryBankConnectionAccountInput = {
  id: Scalars['String']['input']
}

/** Input for setting the shipping address of a pending physical card */
export type ShippingAddressInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type SignerInformationInput = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type SpendLimit = {
  __typename?: 'SpendLimit'
  amount: Scalars['Money']['output']
  businessId?: Maybe<Scalars['String']['output']>
  cardId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  period: Scalars['String']['output']
  startDateTime?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type SpendLimitInfo = {
  __typename?: 'SpendLimitInfo'
  consumed: Scalars['Money']['output']
  frequency: Scalars['String']['output']
  periodEndDate: Scalars['DateTime']['output']
  periodLimit: Scalars['Money']['output']
  periodStartDate: Scalars['DateTime']['output']
}

/** Spend Limit Period Options */
export enum SpendLimitPeriod {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type StartAccountingIntegrationConnectionParams = {
  /** Additional data required by the connection */
  additionalData?: InputMaybe<RutterAdditionalData>
  /** The ID of the business to start the connection process for */
  businessId: Scalars['String']['input']
  /** The type of integration to start the connection process */
  integrationType: AccountingIntegrationType
}

export type StartMfaChallengeInput = {
  channel: MfaChannelEnum
  scope: VerificationScopeEnum
}

export type Statement = {
  __typename?: 'Statement'
  availableCreditLimit: Scalars['Money']['output']
  beginningBalance: Scalars['Money']['output']
  business?: Maybe<Business>
  businessId: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  endBalance: Scalars['Money']['output']
  id: Scalars['ID']['output']
  pdfPath?: Maybe<Scalars['String']['output']>
  pdfUrl?: Maybe<Scalars['String']['output']>
  rewardsRedemption?: Maybe<Scalars['Money']['output']>
  statementDateFrom: Scalars['DateTime']['output']
  statementDateTo: Scalars['DateTime']['output']
  statementDueDate?: Maybe<Scalars['DateTime']['output']>
  statementNumber: Scalars['String']['output']
  statementPayment: Array<Maybe<StatementPaymentTransaction>>
  totalCreditLimit: Scalars['Money']['output']
}

export type StatementExtensionApproval = {
  __typename?: 'StatementExtensionApproval'
  apr: Scalars['Float']['output']
  autoEnrollmentConfig?: Maybe<StatementExtensionAutoEnrollmentConfig>
  businessId: Scalars['String']['output']
  businessNameLegal: Scalars['String']['output']
  cardDueDate?: Maybe<Scalars['DateTime']['output']>
  enable: Scalars['Boolean']['output']
  enabledAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  maxRepaymentAmount: Scalars['Int']['output']
  repaymentFrequency: Scalars['String']['output']
  repayments?: Maybe<Array<Repayments>>
}

export type StatementExtensionAutoEnrollmentConfig = {
  __typename?: 'StatementExtensionAutoEnrollmentConfig'
  createdAt: Scalars['DateTime']['output']
  enabled: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  lastToggledAt: Scalars['DateTime']['output']
  repaymentInstallments: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type StatementPayment = {
  __typename?: 'StatementPayment'
  /** Payment amount */
  amount: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  /** Payment's EFT Transaction ID */
  id: Scalars['ID']['output']
  initatorRole: EftInitiatorRole
  status: StatementPaymentStatus
}

export enum StatementPaymentAccountType {
  CcTransfer = 'CC_TRANSFER',
  EftTransaction = 'EFT_TRANSACTION',
  Extension = 'EXTENSION',
  PaymentAdjustment = 'PAYMENT_ADJUSTMENT',
  PaymentExtension = 'PAYMENT_EXTENSION',
}

export enum StatementPaymentStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
}

export type StatementPaymentTransaction = {
  __typename?: 'StatementPaymentTransaction'
  amount?: Maybe<Scalars['Money']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  plaidAccountId?: Maybe<Scalars['String']['output']>
  postedAt?: Maybe<Scalars['DateTime']['output']>
  processedAt?: Maybe<Scalars['DateTime']['output']>
  rejectedAt?: Maybe<Scalars['DateTime']['output']>
  statementId?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

/** The status of the statement processing task. */
export enum StatementRunStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Scheduled = 'SCHEDULED',
  Started = 'STARTED',
}

export type StatementsCron = {
  __typename?: 'StatementsCron'
  business?: Maybe<Business>
  businessId?: Maybe<Scalars['String']['output']>
  errorMessage?: Maybe<Scalars['String']['output']>
  errorName?: Maybe<Scalars['String']['output']>
  failedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  runCount?: Maybe<Scalars['Int']['output']>
  startedAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<Scalars['String']['output']>
  succeedAt?: Maybe<Scalars['DateTime']['output']>
}

export type SubmitApplicationDeviceInput = {
  blackboxId: Scalars['String']['input']
}

export type SubmitApplicationInput = {
  device: SubmitApplicationDeviceInput
}

export type SupportingDocuments = {
  __typename?: 'SupportingDocuments'
  name?: Maybe<Scalars['String']['output']>
  path?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Float']['output']>
  type?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type SupportingDocumentsInput = {
  name?: InputMaybe<Scalars['String']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type SyncExpensesResponse = {
  __typename?: 'SyncExpensesResponse'
  syncingExpenses: Array<Scalars['String']['output']>
}

export enum TaxRateStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unknown = 'UNKNOWN',
}

export type TotalBalanceInCurrency = {
  __typename?: 'TotalBalanceInCurrency'
  availableBalanceInRequestedCurrency: Scalars['Money']['output']
  currentBalanceInRequestedCurrency: Scalars['Money']['output']
}

export type TrackEvent = {
  __typename?: 'TrackEvent'
  event: Scalars['String']['output']
}

export type TransactionData = {
  __typename?: 'TransactionData'
  authorizationCode?: Maybe<Scalars['String']['output']>
  friendlyName: Scalars['String']['output']
  id?: Maybe<Scalars['ID']['output']>
  isReceivedPayment: Scalars['Boolean']['output']
  logoUrl?: Maybe<Scalars['String']['output']>
  merchantId?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  status: TransactionPurchaseStatus
}

export enum TransactionEntityType {
  Chargeback = 'Chargeback',
  Purchase = 'Purchase',
  Refund = 'Refund',
  StatementPayment = 'StatementPayment',
}

export type TransactionEvent = {
  __typename?: 'TransactionEvent'
  amount: Scalars['Money']['output']
  authorizationCode: Scalars['String']['output']
  businessId: Scalars['String']['output']
  cardId: Scalars['String']['output']
  cardNumber: Scalars['String']['output']
  date: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  eventType: TransactionEventType
  id: Scalars['ID']['output']
  isInternational: Scalars['Boolean']['output']
  merchant?: Maybe<TransactionMerchant>
  requestedAmount?: Maybe<Scalars['Money']['output']>
  retrievalReferenceNumber: Scalars['String']['output']
  user?: Maybe<User>
  userId: Scalars['String']['output']
}

export enum TransactionEventType {
  Clearing = 'CLEARING',
  Return = 'RETURN',
  ReturnReversal = 'RETURN_REVERSAL',
}

export type TransactionMerchant = {
  __typename?: 'TransactionMerchant'
  categoryCode: Scalars['String']['output']
  code: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  name: Scalars['String']['output']
}

export enum TransactionPurchaseStatus {
  Pending = 'PENDING',
  Reversed = 'REVERSED',
  Settled = 'SETTLED',
}

export type TruliooShortCode = {
  __typename?: 'TruliooShortCode'
  shortCode?: Maybe<Scalars['String']['output']>
}

/** Unmatched receipt */
export type UnmatchedReceipt = {
  __typename?: 'UnmatchedReceipt'
  reason?: Maybe<Scalars['String']['output']>
  receipt: AccountingExpenseReceipt
}

export type UpdateAddressDataInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type UpdateApplicationDataInput = {
  ABOUT_BUSINESS_4?: InputMaybe<AboutBusinessInput>
  ADDITIONAL_INFORMATION_2?: InputMaybe<AdditionalInformationInput>
  BUSINESS_ADDRESS_5?: InputMaybe<BusinessAddressInput>
  BUSINESS_DIRECTORS_7?: InputMaybe<BusinessDirectorsInput>
  BUSINESS_OWNERS_6?: InputMaybe<BusinessOwnersInput>
  IDENTITY_VERIFICATION_10?: InputMaybe<IdentityVerificationInput>
  INCORPORATION_DOCUMENTS_8?: InputMaybe<IncorporationDocumentsInput>
  PERSONAL_ADDRESS_3?: InputMaybe<PersonalAddressInput>
  PERSONAL_INFORMATION_1?: InputMaybe<PersonalInformationInput>
  PLAID_9?: InputMaybe<Scalars['Boolean']['input']>
  RELATIONSHIP_TO_THE_BUSINESS_40?: InputMaybe<RelationshipToTheBusinessInput>
  REVIEW_11?: InputMaybe<ReviewInput>
  nextStep: Scalars['OnboardingStep']['input']
}

export type UpdateCardSpendLimitInput = {
  amount: Scalars['Float']['input']
  cardId: Scalars['String']['input']
  period: SpendLimitPeriod
}

export type UpdateEmailInput = {
  code: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type UpdateExpensesInput = {
  entityCategoryId?: InputMaybe<Scalars['String']['input']>
  entityClassId?: InputMaybe<Scalars['String']['input']>
  entityMerchantId?: InputMaybe<Scalars['String']['input']>
  entityTaxCodeId?: InputMaybe<Scalars['String']['input']>
  expensesIds: Array<Scalars['String']['input']>
  memo?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ExpenseStatus>
}

export type UpdateNotificationSettingsMutationInput = {
  notifyCardClosed: Scalars['Boolean']['input']
  notifyCardIssued: Scalars['Boolean']['input']
  notifyCardSpendLimitReached: Scalars['Boolean']['input']
  notifyCreditLimitBelowThreshold: Scalars['Boolean']['input']
  notifyOnPurchaseReversal: Scalars['Boolean']['input']
  notifyOnlinePurchase: Scalars['Boolean']['input']
  notifyOnlinePurchaseDeclined: Scalars['Boolean']['input']
  notifyPosPurchase: Scalars['Boolean']['input']
  notifyPosPurchaseDeclined: Scalars['Boolean']['input']
  notifyStatementAvailable: Scalars['Boolean']['input']
}

export type UpdatePhoneNumberInput = {
  code: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

/** Allows updating the spend limit of a pending card */
export type UpdateSpendLimitPendingCardInput = {
  id: Scalars['String']['input']
  spendLimitAmount?: InputMaybe<Scalars['String']['input']>
  spendLimitPeriod?: InputMaybe<SpendLimitPeriod>
}

export type UpdatedExpensesResponse = {
  __typename?: 'UpdatedExpensesResponse'
  errors: Array<ExpenseErrorType>
  updatedExpenses: Array<AccountingExpense>
}

export type UploadBase64PdfInput = {
  pdfs?: InputMaybe<Array<PdfInput>>
  plaidItemId: Scalars['String']['input']
}

export type UploadBase64PdfResponse = {
  __typename?: 'UploadBase64PdfResponse'
  id: Scalars['ID']['output']
  rejected: Array<RejectedUploads>
  resolved: Array<ResolvedUploads>
}

export type UpsertMfaSettingInput = {
  channel: MfaChannelEnum
  code: Scalars['String']['input']
  isEnabled: Scalars['Boolean']['input']
  isPrimary: Scalars['Boolean']['input']
  password: Scalars['String']['input']
}

export type User = {
  __typename?: 'User'
  accounts?: Maybe<Array<Maybe<Account>>>
  approvedAt?: Maybe<Scalars['DateTime']['output']>
  business?: Maybe<Business>
  businessId?: Maybe<Scalars['String']['output']>
  cards?: Maybe<Array<Maybe<Card>>>
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  onboardedAt?: Maybe<Scalars['DateTime']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  userType: UserType
}

export type UserAddressDataInput = {
  address1: Scalars['String']['input']
  address2?: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type UserDataInput = {
  address: UserAddressDataInput
  dateOfBirth: Scalars['String']['input']
  firstName: Scalars['String']['input']
  kycData?: InputMaybe<KycUserDataInput>
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export enum UserType {
  Accountant = 'ACCOUNTANT',
  AccountAdmin = 'ACCOUNT_ADMIN',
  Admin = 'ADMIN',
  Cardholder = 'CARDHOLDER',
  User = 'USER',
}

export type UserVerificationData = {
  __typename?: 'UserVerificationData'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  phoneVerifiedAt?: Maybe<Scalars['DateTime']['output']>
  verifiedAt?: Maybe<Scalars['DateTime']['output']>
}

export type UserWithKycStatusSnapshot = {
  __typename?: 'UserWithKycStatusSnapshot'
  businessId?: Maybe<Scalars['String']['output']>
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  hasReachedPhysicalCardLimit: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  kycStatusSnapshots?: Maybe<Array<Maybe<KycStatusSnapshot>>>
  lastName?: Maybe<Scalars['String']['output']>
  userType: UserType
  verifiedAt?: Maybe<Scalars['DateTime']['output']>
}

export type VgsCardToken = {
  __typename?: 'VGSCardToken'
  card?: Maybe<Card>
  cardId?: Maybe<Scalars['String']['output']>
  cardNumberToken?: Maybe<Scalars['String']['output']>
  cvvToken?: Maybe<Scalars['String']['output']>
  expDateToken?: Maybe<Scalars['String']['output']>
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  id?: Maybe<Scalars['String']['output']>
}

export type ValidatePaymentMutationInput = {
  /** Beneficiary ID */
  beneficiaryId: Scalars['String']['input']
  /** Priority of the payment. */
  priority: PaymentPriority
  /** Quote ID for the payment */
  quoteId: Scalars['String']['input']
  /** Reason for the payment */
  reason: Scalars['String']['input']
  /** Reference for the payment */
  reference: Scalars['String']['input']
}

/** The scope of the MFA verification */
export enum VerificationScopeEnum {
  ManageMfa = 'MANAGE_MFA',
  Mfa = 'MFA',
  SignUp = 'SIGN_UP',
  UpdateEmail = 'UPDATE_EMAIL',
  UpdatePhoneNumber = 'UPDATE_PHONE_NUMBER',
}

export type VerifyAccountMutationInput = {
  token: Scalars['String']['input']
}

export type VerifyMfaChallengeInput = {
  channel: MfaChannelEnum
  code: Scalars['String']['input']
  scope: VerificationScopeEnum
}

export type VerifyPhoneInput = {
  code: Scalars['String']['input']
}

export type Wallet = {
  __typename?: 'Wallet'
  balance?: Maybe<BalanceResult>
  business?: Maybe<Business>
  businessId: Scalars['String']['output']
  ccAccountId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  currency: CurrencyCode
  fundingAccounts: Array<Maybe<FundingAccountWithDetails>>
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type WalletBalance = {
  __typename?: 'WalletBalance'
  amount?: Maybe<Scalars['Money']['output']>
  amountAsNumber?: Maybe<Scalars['Float']['output']>
}

export enum WalletCurrencyCode {
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Mxn = 'MXN',
  Php = 'PHP',
  Usd = 'USD',
}

export type WalletTransaction = BankPayment | BankPaymentFee | BankTransfer | Conversion | PendingBankPayment

export enum WalletTransactionEntityType {
  BankPayment = 'BankPayment',
  BankPaymentFee = 'BankPaymentFee',
  BankTransfer = 'BankTransfer',
  Conversion = 'Conversion',
  PendingBankPayment = 'PendingBankPayment',
}

export type WebMoney = {
  __typename?: 'WebMoney'
  amountAsNumber: Scalars['Float']['output']
  amountAsString: Scalars['String']['output']
  currency: Scalars['String']['output']
}

export type CancelFundingPaymentInput = {
  fundingPaymentId: Scalars['String']['input']
}

export type CreateCapitalFundingInput = {
  amount: Scalars['Float']['input']
  reason: Scalars['String']['input']
  repaymentFrequency: Scalars['String']['input']
  repaymentInstallments: Scalars['Int']['input']
}

export type FlinksConnectionTakingTooLongInput = {
  businessId: Scalars['String']['input']
  connectionTime: Scalars['Int']['input']
  loginId?: InputMaybe<Scalars['String']['input']>
  requestId?: InputMaybe<Scalars['String']['input']>
  step: Scalars['String']['input']
}

export type PayOffFundingInput = {
  accountType: Scalars['String']['input']
  fundingId: Scalars['String']['input']
  linkedAccountId?: InputMaybe<Scalars['String']['input']>
}

export type TransactionFragmentFragment = {
  __typename?: 'LegacyTransaction'
  id: string
  amount: string
  currency: string
  amountAsNumber: number
  requestedAmount?: string | null
  requestedAmountAsNumber?: number | null
  requestedAmountCurrency?: string | null
  entityType: TransactionEntityType
  userName?: string | null
  date?: string | null
  cardId?: string | null
  cardLastFour?: string | null
  points?: string | null
  transactionEventId?: string | null
  transaction: {
    __typename?: 'TransactionData'
    id?: string | null
    merchantId?: string | null
    friendlyName: string
    status: TransactionPurchaseStatus
    name: string
    logoUrl?: string | null
    isReceivedPayment: boolean
    authorizationCode?: string | null
  }
  expense?: {
    __typename?: 'AccountingExpense'
    id: string
    businessId: string
    status: ExpenseStatus
    memo?: string | null
    receipt?: {
      __typename?: 'AccountingExpenseReceipt'
      id: string
      documentId: string
      document?: { __typename?: 'DocumentType'; id: string; path: string; displayName: string; mimeType: string } | null
    } | null
  } | null
}

export type WalletBankPaymentFragment = {
  __typename?: 'BankPayment'
  id: string
  pointsAsNumber: number
  pointsAsString: string
  entityType: WalletTransactionEntityType
  date: string
  bankTransaction: {
    __typename?: 'BankPaymentTransaction'
    id: string
    amount: string
    amountAsNumber: number
    currency: CurrencyCode
    recipientId: string
    polarity: Polarity
    status: BankPaymentStatusType
    type: BankPaymentType
    reason: string
    reference: string
    receiptUrl?: string | null
    shortReference: string
    relatedConversionId?: string | null
    priority: PaymentPriority
    convertedAmount?: string | null
    convertedCurrency?: CurrencyCode | null
    speedFeeAmount?: string | null
    speedFeeAmountAsNumber?: number | null
    speedFeeCurrency?: string | null
    conversionFeeAmount?: string | null
    conversionFeeAmountAsNumber?: number | null
    conversionFeeCurrency?: string | null
    paymentMethod?: string | null
    creditor: {
      __typename?: 'BankPaymentContact'
      accountNumber?: string | null
      accountNumberType?: string | null
      address?: string | null
      routingCode?: string | null
      routingCodeType?: string | null
      bankAccountHolderName?: string | null
    }
    debitor: {
      __typename?: 'BankPaymentContact'
      accountNumber?: string | null
      accountNumberType?: string | null
      address?: string | null
      routingCode?: string | null
      routingCodeType?: string | null
      bankAccountHolderName?: string | null
    }
  }
}

export type WalletBankPaymentFeeFragment = {
  __typename?: 'BankPaymentFee'
  id: string
  date: string
  entityType: WalletTransactionEntityType
  paymentFee: {
    __typename?: 'BankPaymentFeeTransaction'
    bankPaymentId: string
    amount: string
    amountAsNumber: number
    currency: CurrencyCode
    status: BankPaymentStatusType
    paymentShortReference: string
    priority: PaymentPriority
  }
}

export type WalletPendingBankPaymentFragment = {
  __typename?: 'PendingBankPayment'
  id: string
  entityType: WalletTransactionEntityType
  pointsAsString: string
  pointsAsNumber: number
  date: string
  bankTransaction: {
    __typename?: 'PendingBankPaymentTransaction'
    id: string
    amount: string
    amountAsNumber: number
    currency: CurrencyCode
    convertedAmount?: string | null
    convertedCurrency?: CurrencyCode | null
    creditorAccountHolderName: string
    debitorAccountHolderName: string
    speedFeeAmount?: string | null
    speedFeeAmountAsNumber?: number | null
    speedFeeCurrency?: string | null
    conversionFeeAmount?: string | null
    conversionFeeAmountAsNumber?: number | null
    conversionFeeCurrency?: string | null
    priority: PaymentPriority
    reason: string
    reference: string
    createdAt?: string | null
    paymentMethod?: string | null
  }
}

export type WalletBankTransferFragment = {
  __typename?: 'BankTransfer'
  id: string
  pointsAsNumber: number
  pointsAsString: string
  entityType: WalletTransactionEntityType
  date: string
  bankTransfer: {
    __typename?: 'BankTransferTransaction'
    amount: string
    amountAsNumber: number
    creditorBankAccountHolderName?: string | null
    currency: CurrencyCode
    debitorBankAccountHolderName?: string | null
    direction: BankTransferDirection
    id: string
    friendlyTransferType: string
    polarity: Polarity
    reason?: string | null
    reference?: string | null
    shortReference: string
    status: BankPaymentStatusType
    transferType: BankTransferType
  }
}

export type WalletConversionFragment = {
  __typename?: 'Conversion'
  id: string
  pointsAsNumber: number
  pointsAsString: string
  date: string
  entityType: WalletTransactionEntityType
  conversionTransaction: {
    __typename?: 'ConversionTransaction'
    id: string
    buyAmount: string
    buyAmountAsNumber: number
    buyCurrency: CurrencyCode
    sellAmount: string
    sellAmountAsNumber: number
    sellCurrency: CurrencyCode
    clientRate: string
    conversionDate?: string | null
    cancelledAt?: string | null
    fixedSide: string
    status: BankPaymentStatusType
    relatedPaymentId?: string | null
    feeAmount?: string | null
    feeAmountAsNumber?: number | null
    feeCurrency?: string | null
    finalBuyAmount: string
    finalBuyAmountAsNumber: number
    finalSellAmount: string
    finalSellAmountAsNumber: number
  }
  rewardRedemption: {
    __typename?: 'ConversionRewardRedemption'
    isRedeemingPoints: boolean
    redeemedAmount?: string | null
    redeemedAmountAsNumber?: number | null
    redeemedCurrency?: CurrencyCode | null
    redeemedPoints?: string | null
    redeemedPointsAsNumber?: number | null
  }
}

export type AddressFragment = {
  __typename?: 'ApplicationAddress'
  address1?: string | null
  address2?: string | null
  postalCode?: string | null
  city?: string | null
  country?: string | null
  state?: string | null
}

export type ApplicationV2FragmentFragment = {
  __typename?: 'ApplicationV2'
  id: string
  userId?: string | null
  status: string
  onboardingStep: OnboardingStepV2
  businessId?: string | null
  version: string
  dataVersion: string
  data?: {
    __typename?: 'ApplicationDataV2'
    completedSteps?: Array<string> | null
    device?: { __typename?: 'ApplicationDevice'; blackboxId?: string | null; ip?: string | null } | null
    extraDocuments?: {
      __typename?: 'ExtraDocuments'
      documents?: Array<{
        __typename?: 'SupportingDocuments'
        url?: string | null
        name?: string | null
        path?: string | null
        size?: number | null
        type?: string | null
      } | null> | null
    } | null
    validations?: {
      __typename?: 'ApplicationValidations'
      noMoreOwners?: boolean | null
      noMoreDirectors?: boolean | null
      authorizedToSign?: boolean | null
    } | null
    business?: {
      __typename?: 'ApplicationBusiness'
      legalName?: string | null
      dba?: string | null
      phone?: string | null
      website?: string | null
      incorporationNumber?: string | null
      incorporationDate?: string | null
      description?: string | null
      incorporationType?: string | null
      category?: string | null
      hasWebsite?: boolean | null
      numberOfEmployees?: number | null
      minNumberOfEmployees?: number | null
      maxNumberOfEmployees?: number | null
      isMSB?: boolean | null
      supportingDocuments?: Array<{
        __typename?: 'SupportingDocuments'
        url?: string | null
        name?: string | null
        path?: string | null
        size?: number | null
        type?: string | null
      } | null> | null
      address?: {
        __typename?: 'ApplicationAddress'
        address1?: string | null
        address2?: string | null
        postalCode?: string | null
        city?: string | null
        country?: string | null
        state?: string | null
      } | null
    } | null
    relevantPeople?: Array<{
      __typename?: 'ApplicationRelevantPerson'
      firstName?: string | null
      lastName?: string | null
      phoneNumber?: string | null
      participation?: Array<PersonParticipation | null> | null
      socialInsuranceNumber?: string | null
      email?: string | null
      citizenship?: string | null
      dateOfBirth?: string | null
      politicalExposure?: boolean | null
      roleDescription?: string | null
      authorizedToSign?: boolean | null
      address?: {
        __typename?: 'ApplicationAddress'
        address1?: string | null
        address2?: string | null
        postalCode?: string | null
        city?: string | null
        country?: string | null
        state?: string | null
      } | null
    }> | null
    bankDetails?: { __typename?: 'ApplicationBankDetails'; provider?: string | null; skip?: boolean | null } | null
    identityVerification?: { __typename?: 'ApplicantIdentityVerification'; transactionId?: string | null } | null
    productsSelection?: {
      __typename?: 'ApplicationProductsSelection'
      banking?: boolean | null
      capital?: boolean | null
      fxExchange?: boolean | null
      creditCard?: boolean | null
    } | null
    shippingAddress?: {
      __typename?: 'ApplicationAddress'
      address1?: string | null
      address2?: string | null
      postalCode?: string | null
      city?: string | null
      country?: string | null
      state?: string | null
    } | null
    card?: { __typename?: 'ApplicationCard'; extraEmbossingLine?: string | null } | null
  } | null
}

export type RepaymentPlanFragmentFragment = {
  __typename?: 'CalculateRepaymentPlan'
  totalFee: string
  totalRepaymentAmount: string
  amountLastPayment: string
  amountPerRemainingPayments: string
  principalAmountLastPayment: string
  principalAmountPerRemainingPayments: string
  feeLastPayment: string
  feePerRemainingPayments: string
  monthsDuration: number
  monthsDurationFormatted: string
  principalAmount: string
  preDiscountTotalFee: string
  preDiscountPaymentFee: string
  repaymentInstallments: number
  durationFormatted: string
}

export type InstallmentFragmentFragment = {
  __typename?: 'FundingInstallment'
  loanId: string
  businessId: string
  status: string
  dateDue: string
  amountDue: string
  installmentNumber: number
  principalAmountDue: string
  interestAmountDue: string
  lateFee?: string | null
}

export type DiscountFragmentFragment = {
  __typename?: 'FundingDiscount'
  id: string
  percent: number
  amount: string
  type: string
  rewardRedemptionId?: string | null
}

export type CapitalApprovalFragmentFragment = {
  __typename?: 'Approval'
  id: string
  businessId: string
  riskLevel?: number | null
  maxRepaymentMonths: number
  apr: number
  repaymentFrequency: string
  pricingVersion: CapitalApprovalVersion
  repayments?: Array<{ __typename?: 'Repayment'; repayment: number; formatted: string }> | null
}

export type LastFundingPaymentFragmentFragment = {
  __typename?: 'LastFundingPayment'
  id: string
  amount: { amount: string; amountAsNumber: number; currency: string }
  createdAt: string
  status: string
  type: string
  updatedAt: string
}

export type FreezeCardMutationVariables = Exact<{
  cardId: Scalars['String']['input']
}>

export type FreezeCardMutation = {
  __typename?: 'Mutation'
  freezeCard?: { __typename?: 'Card'; id: string; status: CardStatus } | null
}

export type UnfreezeCardMutationVariables = Exact<{
  cardId: Scalars['String']['input']
}>

export type UnfreezeCardMutation = {
  __typename?: 'Mutation'
  unfreezeCard?: { __typename?: 'Card'; id: string; status: CardStatus } | null
}

export type CompleteAccountingIntegrationMutationVariables = Exact<{
  body: CompleteConnectionParams
}>

export type CompleteAccountingIntegrationMutation = {
  __typename?: 'Mutation'
  completeAccountingIntegration?: {
    __typename?: 'CompleteConnectionResponse'
    connectionUrl: string
    accountingIntegration?: {
      __typename?: 'AccountingIntegration'
      id: string
      status: AccountingIntegrationStatus
      integrationType: string
      connectedAt?: string | null
    } | null
  } | null
}

export type StartAccountingIntegrationMutationVariables = Exact<{
  body: StartAccountingIntegrationConnectionParams
}>

export type StartAccountingIntegrationMutation = {
  __typename?: 'Mutation'
  startAccountingIntegrationConnection?: string | null
}

export type AddExtraDocumentsMutationVariables = Exact<{
  body: AddExtraDocumentsInput
}>

export type AddExtraDocumentsMutation = {
  __typename?: 'Mutation'
  addExtraDocuments?: { __typename?: 'Application'; id: string } | null
}

export type CreateStatementPaymentMutationVariables = Exact<{
  body: CreateStatementPaymentInput
}>

export type CreateStatementPaymentMutation = {
  __typename?: 'Mutation'
  createStatementPayment?: { __typename?: 'StatementPayment'; id: string } | null
}

export type RenameCardMutationVariables = Exact<{
  cardId: Scalars['String']['input']
  newNickname: Scalars['String']['input']
}>

export type RenameCardMutation = {
  __typename?: 'Mutation'
  renameCard?: { __typename?: 'Card'; id: string; nickname?: string | null } | null
}

export type RenamePendingCardMutationVariables = Exact<{
  body: RenamePendingCardInput
}>

export type RenamePendingCardMutation = {
  __typename?: 'Mutation'
  renameCard?: { __typename?: 'PendingCard'; id: string; nickname?: string | null } | null
}

export type CloseCardMutationVariables = Exact<{
  cardId: Scalars['String']['input']
}>

export type CloseCardMutation = { __typename?: 'Mutation'; closeCard?: { __typename?: 'Card'; id: string } | null }

export type ClosePendingCardMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ClosePendingCardMutation = {
  __typename?: 'Mutation'
  closeCard?: { __typename?: 'PendingCard'; id: string } | null
}

export type ResendInvitationMutationVariables = Exact<{
  invitedUserId: Scalars['String']['input']
}>

export type ResendInvitationMutation = { __typename?: 'Mutation'; resendInvitation?: boolean | null }

export type CreateCardMutationVariables = Exact<{
  body: CreateCardInput
}>

export type CreateCardMutation = {
  __typename?: 'Mutation'
  createCard?: {
    __typename?: 'CreateCardResponse'
    id: string
    userId: string
    card?: {
      __typename?: 'Card'
      id: string
      status: CardStatus
      type: CardType
      userId: string
      businessId: string
    } | null
    pendingCard: { __typename?: 'PendingCard'; id: string; userId: string }
  } | null
}

export type UpdateCardSpendLimitMutationVariables = Exact<{
  body: UpdateCardSpendLimitInput
}>

export type UpdateCardSpendLimitMutation = {
  __typename?: 'Mutation'
  updateCardSpendLimit?: { __typename?: 'SpendLimit'; id: string; period: string; amount: string } | null
}

export type UpdateSpendLimitPendingCardMutationVariables = Exact<{
  body: UpdateSpendLimitPendingCardInput
}>

export type UpdateSpendLimitPendingCardMutation = {
  __typename?: 'Mutation'
  updateCardSpendLimit?: {
    __typename?: 'PendingCard'
    id: string
    period?: SpendLimitPeriod | null
    amount?: string | null
  } | null
}

export type CreateConversionQuoteMutationVariables = Exact<{
  body: CreateConversionQuoteInput
}>

export type CreateConversionQuoteMutation = {
  __typename?: 'Mutation'
  createConversionQuote?: {
    __typename?: 'ConversionQuote'
    id: string
    clientBuyCurrency: CurrencyCode
    clientSellCurrency: CurrencyCode
    adjustedClientRate: number
    adjustedBuyAmount: string
    adjustedSellAmount: string
    createdAt: string
    eligibleRedeemablePoints?: string | null
    eligibleRedeemableAmount?: string | null
    eligibleRedeemableCurrency?: CurrencyCode | null
    noEligibleRewardsReason?: NoEligibleRewardsReason | null
    competitorsClientRate: number
  } | null
}

export type CreateCurrencyConversionMutationVariables = Exact<{
  body?: InputMaybe<CreateConversionV2Input>
}>

export type CreateCurrencyConversionMutation = {
  __typename?: 'Mutation'
  createCurrencyConversion?: {
    __typename?: 'CurrencyConversion'
    id: string
    businessId: string
    quoteLockId: string
    createdAt: string
    externalId: string
    buyAmount: string
    buyCurrency: CurrencyCode
    sellAmount: string
    sellCurrency: CurrencyCode
    clientRate: number
    netMarginAmount: string
    netMarginCurrency: CurrencyCode
    status: string
    settledAt?: string | null
    completedAt?: string | null
  } | null
}

export type AcceptAgreementsMutationVariables = Exact<{
  body: AcceptAgreementsInput
}>

export type AcceptAgreementsMutation = { __typename?: 'Mutation'; acceptAgreements?: boolean | null }

export type RegisterUserMutationVariables = Exact<{
  body: RegisterUserMutationInput
}>

export type RegisterUserMutation = { __typename?: 'Mutation'; registerUser?: string | null }

export type AcceptEmployeeInvitationMutationVariables = Exact<{
  body: AcceptInvitationMutationInput
}>

export type AcceptEmployeeInvitationMutation = {
  __typename?: 'Mutation'
  acceptInvitation?: {
    __typename?: 'AcceptedInvitationResult'
    invitation: {
      __typename?: 'EmployeeInvitation'
      id: string
      status: InvitationStatus
      usedAt?: string | null
      userId: string
      businessId: string
    }
  } | null
}

export type InviteUserMutationVariables = Exact<{
  body: InviteUserMutationInput
}>

export type InviteUserMutation = {
  __typename?: 'Mutation'
  inviteUser?: {
    __typename?: 'SendInvitationResult'
    invitation: {
      __typename?: 'EmployeeInvitation'
      id: string
      status: InvitationStatus
      usedAt?: string | null
      userId: string
      businessId: string
    }
  } | null
}

export type CreateAutoEnrollmentConfigMutationVariables = Exact<{
  repaymentInstallments: Scalars['Int']['input']
  enabled: Scalars['Boolean']['input']
}>

export type CreateAutoEnrollmentConfigMutation = {
  __typename?: 'Mutation'
  createAutoEnrollmentConfig?: {
    __typename?: 'StatementExtensionApproval'
    id: string
    businessNameLegal: string
    businessId: string
    maxRepaymentAmount: number
    repaymentFrequency: string
    enable: boolean
    apr: number
    enabledAt: string
    autoEnrollmentConfig?: {
      __typename?: 'StatementExtensionAutoEnrollmentConfig'
      id: string
      enabled: boolean
      lastToggledAt: string
      repaymentInstallments: number
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type UploadBase64PdfMutationVariables = Exact<{
  body: UploadBase64PdfInput
}>

export type UploadBase64PdfMutation = {
  __typename?: 'Mutation'
  uploadBase64Pdf?: {
    __typename?: 'UploadBase64PdfResponse'
    id: string
    rejected: Array<{ __typename?: 'RejectedUploads'; message: string; filename: string }>
    resolved: Array<{ __typename?: 'ResolvedUploads'; pdfHeronId: string; requestId: string }>
  } | null
}

export type CreateLinkedItemMutationVariables = Exact<{ [key: string]: never }>

export type CreateLinkedItemMutation = {
  __typename?: 'Mutation'
  createLinkedItem?: { __typename?: 'CreateLinkedItemResponse'; id: string; userId: string } | null
}

export type UpdateAutoEnrollmentConfigMutationVariables = Exact<{
  enabled: Scalars['Boolean']['input']
}>

export type UpdateAutoEnrollmentConfigMutation = {
  __typename?: 'Mutation'
  updateAutoEnrollmentConfig?: {
    __typename?: 'StatementExtensionApproval'
    id: string
    businessNameLegal: string
    businessId: string
    maxRepaymentAmount: number
    repaymentFrequency: string
    enable: boolean
    apr: number
    enabledAt: string
    autoEnrollmentConfig?: {
      __typename?: 'StatementExtensionAutoEnrollmentConfig'
      id: string
      enabled: boolean
      lastToggledAt: string
      repaymentInstallments: number
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type SetCardShippingAddressMutationVariables = Exact<{
  body: SetCardShippingAddressInput
}>

export type SetCardShippingAddressMutation = {
  __typename?: 'Mutation'
  setCardShippingAddress?: {
    __typename?: 'PendingCard'
    id: string
    userId: string
    type?: CardType | null
    status?: PendingCardStatus | null
  } | null
}

export type ActivateCardMutationVariables = Exact<{
  cardId: Scalars['String']['input']
}>

export type ActivateCardMutation = {
  __typename?: 'Mutation'
  activateCard?: { __typename?: 'Card'; id: string } | null
}

export type SaveFlinksLoginMutationVariables = Exact<{
  body: SaveFlinksLoginInput
}>

export type SaveFlinksLoginMutation = {
  __typename?: 'Mutation'
  saveFlinksLogin?: { __typename?: 'PlaidItem'; id: string } | null
}

export type FlinksConnectionTakingTooLongMutationVariables = Exact<{
  body: FlinksConnectionTakingTooLongInput
}>

export type FlinksConnectionTakingTooLongMutation = {
  __typename?: 'Mutation'
  flinksConnectionTakingTooLong?: boolean | null
}

export type SendRecoveryEmailMutationVariables = Exact<{
  body: SendRecoveryEmailInput
}>

export type SendRecoveryEmailMutation = { __typename?: 'Mutation'; sendRecoveryEmail?: boolean | null }

export type ResetPasswordMutationVariables = Exact<{
  body: ResetPasswordMutationInput
}>

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword?: boolean | null }

export type SendPhoneVerificationCodeMutationVariables = Exact<{ [key: string]: never }>

export type SendPhoneVerificationCodeMutation = { __typename?: 'Mutation'; sendPhoneVerificationCode?: boolean | null }

export type VerifyPhoneMutationVariables = Exact<{
  body: VerifyPhoneInput
}>

export type VerifyPhoneMutation = { __typename?: 'Mutation'; verifyPhone?: boolean | null }

export type MoveForwardMutationVariables = Exact<{
  body: ApplicationMovementInput
}>

export type MoveForwardMutation = {
  __typename?: 'Mutation'
  moveForward?: {
    __typename?: 'ApplicationMovement'
    newStep?: string | null
    application?: {
      __typename?: 'ApplicationV2'
      id: string
      userId?: string | null
      status: string
      onboardingStep: OnboardingStepV2
      businessId?: string | null
      version: string
      dataVersion: string
      data?: {
        __typename?: 'ApplicationDataV2'
        completedSteps?: Array<string> | null
        device?: { __typename?: 'ApplicationDevice'; blackboxId?: string | null; ip?: string | null } | null
        extraDocuments?: {
          __typename?: 'ExtraDocuments'
          documents?: Array<{
            __typename?: 'SupportingDocuments'
            url?: string | null
            name?: string | null
            path?: string | null
            size?: number | null
            type?: string | null
          } | null> | null
        } | null
        validations?: {
          __typename?: 'ApplicationValidations'
          noMoreOwners?: boolean | null
          noMoreDirectors?: boolean | null
          authorizedToSign?: boolean | null
        } | null
        business?: {
          __typename?: 'ApplicationBusiness'
          legalName?: string | null
          dba?: string | null
          phone?: string | null
          website?: string | null
          incorporationNumber?: string | null
          incorporationDate?: string | null
          description?: string | null
          incorporationType?: string | null
          category?: string | null
          hasWebsite?: boolean | null
          numberOfEmployees?: number | null
          minNumberOfEmployees?: number | null
          maxNumberOfEmployees?: number | null
          isMSB?: boolean | null
          supportingDocuments?: Array<{
            __typename?: 'SupportingDocuments'
            url?: string | null
            name?: string | null
            path?: string | null
            size?: number | null
            type?: string | null
          } | null> | null
          address?: {
            __typename?: 'ApplicationAddress'
            address1?: string | null
            address2?: string | null
            postalCode?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        } | null
        relevantPeople?: Array<{
          __typename?: 'ApplicationRelevantPerson'
          firstName?: string | null
          lastName?: string | null
          phoneNumber?: string | null
          participation?: Array<PersonParticipation | null> | null
          socialInsuranceNumber?: string | null
          email?: string | null
          citizenship?: string | null
          dateOfBirth?: string | null
          politicalExposure?: boolean | null
          roleDescription?: string | null
          authorizedToSign?: boolean | null
          address?: {
            __typename?: 'ApplicationAddress'
            address1?: string | null
            address2?: string | null
            postalCode?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        }> | null
        bankDetails?: { __typename?: 'ApplicationBankDetails'; provider?: string | null; skip?: boolean | null } | null
        identityVerification?: { __typename?: 'ApplicantIdentityVerification'; transactionId?: string | null } | null
        productsSelection?: {
          __typename?: 'ApplicationProductsSelection'
          banking?: boolean | null
          capital?: boolean | null
          fxExchange?: boolean | null
          creditCard?: boolean | null
        } | null
        shippingAddress?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        card?: { __typename?: 'ApplicationCard'; extraEmbossingLine?: string | null } | null
      } | null
    } | null
  } | null
}

export type MoveBackwardMutationVariables = Exact<{
  body: ApplicationMovementInput
}>

export type MoveBackwardMutation = {
  __typename?: 'Mutation'
  moveBackward?: {
    __typename?: 'ApplicationMovement'
    newStep?: string | null
    application?: {
      __typename?: 'ApplicationV2'
      id: string
      userId?: string | null
      status: string
      onboardingStep: OnboardingStepV2
      businessId?: string | null
      version: string
      dataVersion: string
      data?: {
        __typename?: 'ApplicationDataV2'
        completedSteps?: Array<string> | null
        device?: { __typename?: 'ApplicationDevice'; blackboxId?: string | null; ip?: string | null } | null
        extraDocuments?: {
          __typename?: 'ExtraDocuments'
          documents?: Array<{
            __typename?: 'SupportingDocuments'
            url?: string | null
            name?: string | null
            path?: string | null
            size?: number | null
            type?: string | null
          } | null> | null
        } | null
        validations?: {
          __typename?: 'ApplicationValidations'
          noMoreOwners?: boolean | null
          noMoreDirectors?: boolean | null
          authorizedToSign?: boolean | null
        } | null
        business?: {
          __typename?: 'ApplicationBusiness'
          legalName?: string | null
          dba?: string | null
          phone?: string | null
          website?: string | null
          incorporationNumber?: string | null
          incorporationDate?: string | null
          description?: string | null
          incorporationType?: string | null
          category?: string | null
          hasWebsite?: boolean | null
          numberOfEmployees?: number | null
          minNumberOfEmployees?: number | null
          maxNumberOfEmployees?: number | null
          isMSB?: boolean | null
          supportingDocuments?: Array<{
            __typename?: 'SupportingDocuments'
            url?: string | null
            name?: string | null
            path?: string | null
            size?: number | null
            type?: string | null
          } | null> | null
          address?: {
            __typename?: 'ApplicationAddress'
            address1?: string | null
            address2?: string | null
            postalCode?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        } | null
        relevantPeople?: Array<{
          __typename?: 'ApplicationRelevantPerson'
          firstName?: string | null
          lastName?: string | null
          phoneNumber?: string | null
          participation?: Array<PersonParticipation | null> | null
          socialInsuranceNumber?: string | null
          email?: string | null
          citizenship?: string | null
          dateOfBirth?: string | null
          politicalExposure?: boolean | null
          roleDescription?: string | null
          authorizedToSign?: boolean | null
          address?: {
            __typename?: 'ApplicationAddress'
            address1?: string | null
            address2?: string | null
            postalCode?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        }> | null
        bankDetails?: { __typename?: 'ApplicationBankDetails'; provider?: string | null; skip?: boolean | null } | null
        identityVerification?: { __typename?: 'ApplicantIdentityVerification'; transactionId?: string | null } | null
        productsSelection?: {
          __typename?: 'ApplicationProductsSelection'
          banking?: boolean | null
          capital?: boolean | null
          fxExchange?: boolean | null
          creditCard?: boolean | null
        } | null
        shippingAddress?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        card?: { __typename?: 'ApplicationCard'; extraEmbossingLine?: string | null } | null
      } | null
    } | null
  } | null
}

export type MoveToStepMutationVariables = Exact<{
  body: ApplicationMovementInput
}>

export type MoveToStepMutation = {
  __typename?: 'Mutation'
  moveToStep?: {
    __typename?: 'ApplicationMovement'
    newStep?: string | null
    application?: {
      __typename?: 'ApplicationV2'
      id: string
      userId?: string | null
      status: string
      onboardingStep: OnboardingStepV2
      businessId?: string | null
      version: string
      dataVersion: string
      data?: {
        __typename?: 'ApplicationDataV2'
        completedSteps?: Array<string> | null
        device?: { __typename?: 'ApplicationDevice'; blackboxId?: string | null; ip?: string | null } | null
        extraDocuments?: {
          __typename?: 'ExtraDocuments'
          documents?: Array<{
            __typename?: 'SupportingDocuments'
            url?: string | null
            name?: string | null
            path?: string | null
            size?: number | null
            type?: string | null
          } | null> | null
        } | null
        validations?: {
          __typename?: 'ApplicationValidations'
          noMoreOwners?: boolean | null
          noMoreDirectors?: boolean | null
          authorizedToSign?: boolean | null
        } | null
        business?: {
          __typename?: 'ApplicationBusiness'
          legalName?: string | null
          dba?: string | null
          phone?: string | null
          website?: string | null
          incorporationNumber?: string | null
          incorporationDate?: string | null
          description?: string | null
          incorporationType?: string | null
          category?: string | null
          hasWebsite?: boolean | null
          numberOfEmployees?: number | null
          minNumberOfEmployees?: number | null
          maxNumberOfEmployees?: number | null
          isMSB?: boolean | null
          supportingDocuments?: Array<{
            __typename?: 'SupportingDocuments'
            url?: string | null
            name?: string | null
            path?: string | null
            size?: number | null
            type?: string | null
          } | null> | null
          address?: {
            __typename?: 'ApplicationAddress'
            address1?: string | null
            address2?: string | null
            postalCode?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        } | null
        relevantPeople?: Array<{
          __typename?: 'ApplicationRelevantPerson'
          firstName?: string | null
          lastName?: string | null
          phoneNumber?: string | null
          participation?: Array<PersonParticipation | null> | null
          socialInsuranceNumber?: string | null
          email?: string | null
          citizenship?: string | null
          dateOfBirth?: string | null
          politicalExposure?: boolean | null
          roleDescription?: string | null
          authorizedToSign?: boolean | null
          address?: {
            __typename?: 'ApplicationAddress'
            address1?: string | null
            address2?: string | null
            postalCode?: string | null
            city?: string | null
            country?: string | null
            state?: string | null
          } | null
        }> | null
        bankDetails?: { __typename?: 'ApplicationBankDetails'; provider?: string | null; skip?: boolean | null } | null
        identityVerification?: { __typename?: 'ApplicantIdentityVerification'; transactionId?: string | null } | null
        productsSelection?: {
          __typename?: 'ApplicationProductsSelection'
          banking?: boolean | null
          capital?: boolean | null
          fxExchange?: boolean | null
          creditCard?: boolean | null
        } | null
        shippingAddress?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
        card?: { __typename?: 'ApplicationCard'; extraEmbossingLine?: string | null } | null
      } | null
    } | null
  } | null
}

export type DeleteBeneficiaryMutationVariables = Exact<{
  body: DeleteBeneficiaryInput
}>

export type DeleteBeneficiaryMutation = { __typename?: 'Mutation'; deleteBeneficiary?: string | null }

export type TrackEventMutationVariables = Exact<{
  event: Scalars['String']['input']
  team: Scalars['String']['input']
  payload: Scalars['JSON']['input']
}>

export type TrackEventMutation = {
  __typename?: 'Mutation'
  trackEvent?: { __typename?: 'TrackEvent'; event: string } | null
}

export type CompleteInformationMutationVariables = Exact<{
  body: CompleteInformationInput
}>

export type CompleteInformationMutation = { __typename?: 'Mutation'; completeInformation?: boolean | null }

export type UpdateApplicationV2MutationVariables = Exact<{
  body: ApplicationUpdateInput
}>

export type UpdateApplicationV2Mutation = {
  __typename?: 'Mutation'
  updateApplicationV2?: {
    __typename?: 'ApplicationV2'
    id: string
    userId?: string | null
    status: string
    onboardingStep: OnboardingStepV2
    businessId?: string | null
    version: string
    dataVersion: string
    data?: {
      __typename?: 'ApplicationDataV2'
      completedSteps?: Array<string> | null
      device?: { __typename?: 'ApplicationDevice'; blackboxId?: string | null; ip?: string | null } | null
      extraDocuments?: {
        __typename?: 'ExtraDocuments'
        documents?: Array<{
          __typename?: 'SupportingDocuments'
          url?: string | null
          name?: string | null
          path?: string | null
          size?: number | null
          type?: string | null
        } | null> | null
      } | null
      validations?: {
        __typename?: 'ApplicationValidations'
        noMoreOwners?: boolean | null
        noMoreDirectors?: boolean | null
        authorizedToSign?: boolean | null
      } | null
      business?: {
        __typename?: 'ApplicationBusiness'
        legalName?: string | null
        dba?: string | null
        phone?: string | null
        website?: string | null
        incorporationNumber?: string | null
        incorporationDate?: string | null
        description?: string | null
        incorporationType?: string | null
        category?: string | null
        hasWebsite?: boolean | null
        numberOfEmployees?: number | null
        minNumberOfEmployees?: number | null
        maxNumberOfEmployees?: number | null
        isMSB?: boolean | null
        supportingDocuments?: Array<{
          __typename?: 'SupportingDocuments'
          url?: string | null
          name?: string | null
          path?: string | null
          size?: number | null
          type?: string | null
        } | null> | null
        address?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
      relevantPeople?: Array<{
        __typename?: 'ApplicationRelevantPerson'
        firstName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        participation?: Array<PersonParticipation | null> | null
        socialInsuranceNumber?: string | null
        email?: string | null
        citizenship?: string | null
        dateOfBirth?: string | null
        politicalExposure?: boolean | null
        roleDescription?: string | null
        authorizedToSign?: boolean | null
        address?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      }> | null
      bankDetails?: { __typename?: 'ApplicationBankDetails'; provider?: string | null; skip?: boolean | null } | null
      identityVerification?: { __typename?: 'ApplicantIdentityVerification'; transactionId?: string | null } | null
      productsSelection?: {
        __typename?: 'ApplicationProductsSelection'
        banking?: boolean | null
        capital?: boolean | null
        fxExchange?: boolean | null
        creditCard?: boolean | null
      } | null
      shippingAddress?: {
        __typename?: 'ApplicationAddress'
        address1?: string | null
        address2?: string | null
        postalCode?: string | null
        city?: string | null
        country?: string | null
        state?: string | null
      } | null
      card?: { __typename?: 'ApplicationCard'; extraEmbossingLine?: string | null } | null
    } | null
  } | null
}

export type GenerateTruliooShortCodeMutationVariables = Exact<{ [key: string]: never }>

export type GenerateTruliooShortCodeMutation = {
  __typename?: 'Mutation'
  generateTruliooShortCode?: { __typename?: 'TruliooShortCode'; shortCode?: string | null } | null
}

export type ValidateLastFourMutationVariables = Exact<{
  cardId: Scalars['String']['input']
  lastFour: Scalars['String']['input']
}>

export type ValidateLastFourMutation = { __typename?: 'Mutation'; validateLastFour?: boolean | null }

export type VerifyAccountMutationVariables = Exact<{
  body: VerifyAccountMutationInput
}>

export type VerifyAccountMutation = { __typename?: 'Mutation'; verifyAccount?: boolean | null }

export type SendVerificationEmailMutationVariables = Exact<{ [key: string]: never }>

export type SendVerificationEmailMutation = { __typename?: 'Mutation'; sendVerificationEmail?: boolean | null }

export type GenerateSupportChatTokenMutationVariables = Exact<{ [key: string]: never }>

export type GenerateSupportChatTokenMutation = { __typename?: 'Mutation'; generateSupportChatToken?: string | null }

export type CreateLoanMutationVariables = Exact<{
  body: CreateLoanInput
}>

export type CreateLoanMutation = {
  __typename?: 'Mutation'
  createLoan?: {
    __typename?: 'FundingWithInstallments'
    id: string
    loanNumber: string
    businessId: string
    status: string
    repaymentFrequency: string
    principalAmount: string
    totalFee: string
    termDurationInMonths: number
    apr: number
    totalRepayment: string
    totalRefinanced: string
    outstandingBalance: string
    agreementSignedDate?: string | null
    firstRepaymentDate: string
    depositedOn?: string | null
    reason?: string | null
    financingChannel: string
    preDiscountTotalFee: string
    feeDiscountRate: number
  } | null
}

export type CreateCapitalFundingMutationVariables = Exact<{
  body: CreateCapitalFundingInput
}>

export type CreateCapitalFundingMutation = {
  __typename?: 'Mutation'
  createCapitalFunding?: {
    __typename?: 'CapitalFunding'
    businessId: string
    id: string
    originationFee: { amount: string; amountAsNumber: number; currency: string }
    principalAmount: { amount: string; amountAsNumber: number; currency: string }
    repaymentFrequency: string
    termDurationInMonths: number
  } | null
}

export type SubmitApplicationMutationVariables = Exact<{
  body: SubmitApplicationInput
}>

export type SubmitApplicationMutation = {
  __typename?: 'Mutation'
  submitApplication?: { __typename?: 'Application'; id: string } | null
}

export type ChangePasswordMutationVariables = Exact<{
  body: ChangePasswordMutationInput
}>

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword?: boolean | null }

export type CreateDisputeMutationVariables = Exact<{
  body: CreateDisputeInput
}>

export type CreateDisputeMutation = {
  __typename?: 'Mutation'
  createDispute?: { __typename?: 'Dispute'; id: string } | null
}

export type SendTruliooIdvLinkMutationVariables = Exact<{ [key: string]: never }>

export type SendTruliooIdvLinkMutation = { __typename?: 'Mutation'; sendTruliooIdvLink?: boolean | null }

export type UpdateStatementExtensionPlanTermMutationVariables = Exact<{
  repaymentInstallments: Scalars['Int']['input']
}>

export type UpdateStatementExtensionPlanTermMutation = {
  __typename?: 'Mutation'
  updateStatementExtensionPlanTerm?: {
    __typename?: 'StatementExtensionApproval'
    id: string
    repayments?: Array<{ __typename?: 'Repayments'; repayment: number; formatted: string }> | null
    autoEnrollmentConfig?: {
      __typename?: 'StatementExtensionAutoEnrollmentConfig'
      id: string
      enabled: boolean
      lastToggledAt: string
      repaymentInstallments: number
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type DeactivateEmployeeMutationVariables = Exact<{
  employeeId: Scalars['String']['input']
}>

export type DeactivateEmployeeMutation = {
  __typename?: 'Mutation'
  deactivateEmployee?: { __typename?: 'DeactivatedUser'; id: string; status: string } | null
}

export type RequestCurrencyCloudAccessMutationVariables = Exact<{
  body: RequestCurrencyCloudAccessInput
}>

export type RequestCurrencyCloudAccessMutation = {
  __typename?: 'Mutation'
  requestCurrencyCloudAccess?: { __typename?: 'Business'; ccAccessStatus?: CcAccessStatus | null } | null
}

export type CreateQuoteForMultiCurrencyPaymentMutationVariables = Exact<{
  body: MultiCurrencyPaymentQuoteInput
}>

export type CreateQuoteForMultiCurrencyPaymentMutation = {
  __typename?: 'Mutation'
  createQuoteForMultiCurrencyPayment?: {
    __typename?: 'QuoteWrapper'
    quote: { __typename?: 'Quote'; id: string; clientRate: string; createdAt: string }
    feesAndRewards: {
      __typename?: 'FeeAndRewardsForQuote'
      sellAmount?: { __typename?: 'WebMoney'; amountAsString: string; amountAsNumber: number; currency: string } | null
      internationalBankingFee: {
        __typename?: 'WebMoney'
        amountAsString: string
        amountAsNumber: number
        currency: string
      }
      convertingAmount?: {
        __typename?: 'WebMoney'
        amountAsString: string
        amountAsNumber: number
        currency: string
      } | null
      exchangeRate: { __typename?: 'ExchangeRate'; exchangeRate: string; competitorsExchangeRate?: string | null }
      savingsAmount: { __typename?: 'WebMoney'; amountAsString: string; amountAsNumber: number; currency: string }
      finalAmount: { __typename?: 'WebMoney'; amountAsString: string; amountAsNumber: number; currency: string }
      balanceAfterwards: { __typename?: 'WebMoney'; amountAsString: string; amountAsNumber: number; currency: string }
      rewards: {
        __typename?: 'EarnedRewards'
        earnedRewardsAmount: {
          __typename?: 'WebMoney'
          amountAsString: string
          amountAsNumber: number
          currency: string
        }
        previousRewardsBalance: {
          __typename?: 'WebMoney'
          amountAsString: string
          amountAsNumber: number
          currency: string
        }
        afterTransactionRewardsTotalPoints: {
          __typename?: 'WebMoney'
          amountAsString: string
          amountAsNumber: number
          currency: string
        }
        earnedRewardsAsPoints: {
          __typename?: 'WebMoney'
          amountAsString: string
          amountAsNumber: number
          currency: string
        }
      }
    }
  } | null
}

export type DisconnectAccountingIntegrationMutationVariables = Exact<{
  accountingIntegrationId: Scalars['String']['input']
}>

export type DisconnectAccountingIntegrationMutation = {
  __typename?: 'Mutation'
  disconnectAccountingIntegration?: {
    __typename?: 'AccountingIntegration'
    id: string
    status: AccountingIntegrationStatus
  } | null
}

export type UpdateExpensesMutationVariables = Exact<{
  body: UpdateExpensesInput
}>

export type UpdateExpensesMutation = {
  __typename?: 'Mutation'
  updateExpenses?: {
    __typename?: 'UpdatedExpensesResponse'
    updatedExpenses: Array<{ __typename?: 'AccountingExpense'; id: string; status: ExpenseStatus }>
    errors: Array<{ __typename?: 'ExpenseErrorType'; expenseId: string; message: string }>
  } | null
}

export type AddReceiptToExpenseMutationVariables = Exact<{
  body: AddReceiptInput
}>

export type AddReceiptToExpenseMutation = {
  __typename?: 'Mutation'
  addReceiptToExpense?: { __typename?: 'AccountingExpenseReceipt'; id: string; documentId: string } | null
}

export type RemoveReceiptFromExpenseMutationVariables = Exact<{
  body: RemoveReceiptInput
}>

export type RemoveReceiptFromExpenseMutation = {
  __typename?: 'Mutation'
  removeReceiptFromExpense?: {
    __typename?: 'AccountingExpense'
    id: string
    receipt?: { __typename?: 'AccountingExpenseReceipt'; id: string } | null
  } | null
}

export type UpsertMfaSettingMutationVariables = Exact<{
  body: UpsertMfaSettingInput
}>

export type UpsertMfaSettingMutation = {
  __typename?: 'Mutation'
  upsertMFASetting?: {
    __typename?: 'MFASetting'
    id: string
    channel: MfaChannelEnum
    isEnabled: boolean
    isPrimary: boolean
    createdAt: string
    updatedAt: string
  } | null
}

export type StartMfaChallengeMutationVariables = Exact<{
  body: StartMfaChallengeInput
}>

export type StartMfaChallengeMutation = { __typename?: 'Mutation'; startMFAChallenge?: boolean | null }

export type VerifyMfaChallengeMutationVariables = Exact<{
  body: VerifyMfaChallengeInput
}>

export type VerifyMfaChallengeMutation = { __typename?: 'Mutation'; verifyMFAChallenge?: boolean | null }

export type SyncExpensesMutationVariables = Exact<{
  expensesIds: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SyncExpensesMutation = {
  __typename?: 'Mutation'
  syncExpenses?: { __typename?: 'SyncExpensesResponse'; syncingExpenses: Array<string> } | null
}

export type SendUpdatePhoneNumberOtpMutationVariables = Exact<{
  body: SendUpdatePhoneNumberOtpInput
}>

export type SendUpdatePhoneNumberOtpMutation = { __typename?: 'Mutation'; sendUpdatePhoneNumberOtp?: boolean | null }

export type UpdatePhoneNumberMutationVariables = Exact<{
  body: UpdatePhoneNumberInput
}>

export type UpdatePhoneNumberMutation = { __typename?: 'Mutation'; updatePhoneNumber?: boolean | null }

export type SendUpdateEmailOtpMutationVariables = Exact<{
  body: SendUpdateEmailOtpInput
}>

export type SendUpdateEmailOtpMutation = { __typename?: 'Mutation'; sendUpdateEmailOtp?: boolean | null }

export type UpdateEmailMutationVariables = Exact<{
  body: UpdateEmailInput
}>

export type UpdateEmailMutation = { __typename?: 'Mutation'; updateEmail?: boolean | null }

export type CreateCapitalRequestMutationVariables = Exact<{ [key: string]: never }>

export type CreateCapitalRequestMutation = {
  __typename?: 'Mutation'
  createCapitalRequest?: { __typename?: 'CapitalRequest'; id: string; businessId: string } | null
}

export type CreateSupportingDocumentsUploadUrlMutationVariables = Exact<{
  body: CreateSupportingDocumentsUploadUrlInput
}>

export type CreateSupportingDocumentsUploadUrlMutation = {
  __typename?: 'Mutation'
  createSupportingDocumentsUploadUrl?: { __typename?: 'PresignedPost'; url: string; fields: string } | null
}

export type CreateExtraDocumentsUploadUrlMutationVariables = Exact<{
  body: CreateExtraDocumentsUploadUrlInput
}>

export type CreateExtraDocumentsUploadUrlMutation = {
  __typename?: 'Mutation'
  createExtraDocumentsUploadUrl?: { __typename?: 'PresignedPost'; url: string; fields: string } | null
}

export type UpdateApplicantPhoneNumberMutationVariables = Exact<{
  body: UpdatePhoneNumberInput
}>

export type UpdateApplicantPhoneNumberMutation = {
  __typename?: 'Mutation'
  updateApplicantPhoneNumber?: boolean | null
}

export type UpdateApplicantEmailMutationVariables = Exact<{
  body: UpdateEmailInput
}>

export type UpdateApplicantEmailMutation = { __typename?: 'Mutation'; updateApplicantEmail?: boolean | null }

export type BulkUploadReceiptsMutationVariables = Exact<{
  body: BulkUploadReceiptsInput
}>

export type BulkUploadReceiptsMutation = {
  __typename?: 'Mutation'
  bulkUploadReceipts?: {
    __typename?: 'BulkUploadReceiptOutput'
    matchedReceipts: Array<{
      __typename?: 'MatchedReceiptPair'
      receipt: { __typename?: 'AccountingExpenseReceipt'; id: string }
      expense: { __typename?: 'AccountingExpense'; id: string }
    }>
    unmatchedReceipts: Array<{
      __typename?: 'UnmatchedReceipt'
      reason?: string | null
      receipt: {
        __typename?: 'AccountingExpenseReceipt'
        id: string
        document?: { __typename?: 'DocumentType'; id: string; displayName: string } | null
      }
    }>
  } | null
}

export type CancelFundingPaymentMutationVariables = Exact<{
  body: CancelFundingPaymentInput
}>

export type CancelFundingPaymentMutation = {
  __typename?: 'Mutation'
  cancelFundingPayment?: { __typename?: 'FundingPayment'; id: string } | null
}

export type PayOffFundingMutationVariables = Exact<{
  body: PayOffFundingInput
}>

export type PayOffFundingMutation = {
  __typename?: 'Mutation'
  payOffFunding?: { __typename?: 'FundingPayment'; id: string } | null
}

export type SetPrimaryBankConnectionAccountMutationVariables = Exact<{
  body: SetPrimaryBankConnectionAccountInput
}>

export type SetPrimaryBankConnectionAccountMutation = {
  __typename?: 'Mutation'
  setPrimaryBankConnectionAccount?: boolean | null
}

export type CreateBeneficiaryV2MutationVariables = Exact<{
  body: BeneficiaryInput
}>

export type CreateBeneficiaryV2Mutation = {
  __typename?: 'Mutation'
  createBeneficiaryV2?:
    | {
        __typename?: 'Beneficiary'
        id: string
        accountNumber: string
        beneficiaryName: string
        beneficiaryFirstName?: string | null
        beneficiaryLastName?: string | null
        beneficiaryCompanyName?: string | null
        email: string
        bankAccountHolderName: string
        bankCountry: string
        beneficiaryEntityType: LegalEntityType
        paymentType: PaymentType
        paymentMethod: BankingPaymentMethodsType
        currency: CurrencyCode
        routingCodeValue1?: string | null
        routingCodeType1?: string | null
        address1: string
        address2?: string | null
        beneficiaryAddress: string
        beneficiaryCity: string
        beneficiaryCountry: string
        beneficiaryPostcode: string
        beneficiaryStateOrProvince: string
        beneficiaryIdentificationType?: IdentificationType | null
        beneficiaryIdentificationValue?: string | null
      }
    | {
        __typename?: 'BeneficiaryValidation'
        isValid: boolean
        errors?: Array<{
          __typename?: 'FieldErrors'
          field: FieldName
          message: string
          code: string
          params: {
            __typename?: 'FieldErrorParams'
            type?: string | null
            range?: string | null
            minLength?: number | null
            maxLength?: number | null
            parameters?: string | null
            missing_details?: Array<string | null> | null
            list?: string | null
          }
        } | null> | null
      }
    | null
}

export type LegacyTransactionsQueryVariables = Exact<{
  cardId?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['CalendarDate']['input']>
  endDate?: InputMaybe<Scalars['CalendarDate']['input']>
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}>

export type LegacyTransactionsQuery = {
  __typename?: 'Query'
  legacyTransactions?: {
    __typename?: 'PaginatedTransactionResult'
    skip?: number | null
    take?: number | null
    count?: number | null
    totalCount?: number | null
    pages?: number | null
    transactions?: Array<{
      __typename?: 'LegacyTransaction'
      id: string
      amount: string
      currency: string
      amountAsNumber: number
      requestedAmount?: string | null
      requestedAmountAsNumber?: number | null
      requestedAmountCurrency?: string | null
      entityType: TransactionEntityType
      userName?: string | null
      date?: string | null
      cardId?: string | null
      cardLastFour?: string | null
      points?: string | null
      transactionEventId?: string | null
      transaction: {
        __typename?: 'TransactionData'
        id?: string | null
        merchantId?: string | null
        friendlyName: string
        status: TransactionPurchaseStatus
        name: string
        logoUrl?: string | null
        isReceivedPayment: boolean
        authorizationCode?: string | null
      }
      expense?: {
        __typename?: 'AccountingExpense'
        id: string
        businessId: string
        status: ExpenseStatus
        memo?: string | null
        receipt?: {
          __typename?: 'AccountingExpenseReceipt'
          id: string
          documentId: string
          document?: {
            __typename?: 'DocumentType'
            id: string
            path: string
            displayName: string
            mimeType: string
          } | null
        } | null
      } | null
    }> | null
    pendingTransactions?: Array<{
      __typename?: 'LegacyTransaction'
      id: string
      amount: string
      currency: string
      amountAsNumber: number
      requestedAmount?: string | null
      requestedAmountAsNumber?: number | null
      requestedAmountCurrency?: string | null
      entityType: TransactionEntityType
      userName?: string | null
      date?: string | null
      cardId?: string | null
      cardLastFour?: string | null
      points?: string | null
      transactionEventId?: string | null
      transaction: {
        __typename?: 'TransactionData'
        id?: string | null
        merchantId?: string | null
        friendlyName: string
        status: TransactionPurchaseStatus
        name: string
        logoUrl?: string | null
        isReceivedPayment: boolean
        authorizationCode?: string | null
      }
      expense?: {
        __typename?: 'AccountingExpense'
        id: string
        businessId: string
        status: ExpenseStatus
        memo?: string | null
        receipt?: {
          __typename?: 'AccountingExpenseReceipt'
          id: string
          documentId: string
          document?: {
            __typename?: 'DocumentType'
            id: string
            path: string
            displayName: string
            mimeType: string
          } | null
        } | null
      } | null
    }> | null
  } | null
}

export type CardsQueryVariables = Exact<{
  filters?: InputMaybe<CardsFilters>
}>

export type CardsQuery = {
  __typename?: 'Query'
  cards?: Array<{
    __typename?: 'Card'
    id: string
    nickname?: string | null
    nameOnCard?: string | null
    lastFour?: string | null
    type: CardType
    status: CardStatus
    availableBalance?: string | null
    outstandingBalance?: string | null
    ledgerBalance?: string | null
    businessId: string
    createdAt?: string | null
    updatedAt?: string | null
    userId: string
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      userType: UserType
    } | null
    spendLimit?: { __typename?: 'SpendLimit'; id: string; amount: string; period: string } | null
  }> | null
}

export type BusinessUsersWithKycStatusQueryVariables = Exact<{ [key: string]: never }>

export type BusinessUsersWithKycStatusQuery = {
  __typename?: 'Query'
  businessUsersWithKycStatus?: Array<{
    __typename?: 'UserWithKycStatusSnapshot'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    userType: UserType
    dateOfBirth?: string | null
    verifiedAt?: string | null
    hasReachedPhysicalCardLimit: boolean
    kycStatusSnapshots?: Array<{
      __typename?: 'KycStatusSnapshot'
      id: string
      status: KycStatus
      createdAt: string
    } | null> | null
  }> | null
}

export type BusinessAddressQueryVariables = Exact<{ [key: string]: never }>

export type BusinessAddressQuery = {
  __typename?: 'Query'
  business: {
    __typename?: 'Business'
    address?: {
      __typename?: 'AddressData'
      id: string
      address1: string
      address2?: string | null
      city: string
      country: string
      postalCode: string
      state: string
    } | null
  }
}

export type BusinessQueryVariables = Exact<{ [key: string]: never }>

export type BusinessQuery = {
  __typename?: 'Query'
  business: { __typename?: 'Business'; id: string; isDelinquent?: boolean | null }
}

export type PendingCardsQueryVariables = Exact<{ [key: string]: never }>

export type PendingCardsQuery = {
  __typename?: 'Query'
  pendingCards?: Array<{
    __typename?: 'PendingCard'
    id: string
    nickname?: string | null
    spendLimitAmount?: string | null
    spendLimitPeriod?: SpendLimitPeriod | null
    type?: CardType | null
    status?: PendingCardStatus | null
    userId: string
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      userType: UserType
    } | null
  }> | null
}

export type PhysicalPendingCardQueryVariables = Exact<{ [key: string]: never }>

export type PhysicalPendingCardQuery = {
  __typename?: 'Query'
  physicalPendingCard?: {
    __typename?: 'PendingCard'
    id: string
    userId: string
    nickname?: string | null
    status?: PendingCardStatus | null
    type?: CardType | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    postalCode?: string | null
    spendLimitAmount?: string | null
    spendLimitPeriod?: SpendLimitPeriod | null
    nameOnCard?: string | null
  } | null
}

export type BusinessAccountingIntegrationQueryVariables = Exact<{
  integrationType: AccountingIntegrationType
}>

export type BusinessAccountingIntegrationQuery = {
  __typename?: 'Query'
  businessAccountingIntegration?: {
    __typename?: 'AccountingIntegration'
    id: string
    businessId: string
    integrationType: string
    status: AccountingIntegrationStatus
    connectedAt?: string | null
  } | null
}

export type CardQueryVariables = Exact<{
  cardId: Scalars['String']['input']
}>

export type CardQuery = {
  __typename?: 'Query'
  card?: {
    __typename?: 'Card'
    id: string
    nameOnCard?: string | null
    nickname?: string | null
    availableBalance?: string | null
    outstandingBalance?: string | null
    ledgerBalance?: string | null
    status: CardStatus
    type: CardType
    userId: string
    businessId: string
    lastFour?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    spendLimit?: { __typename?: 'SpendLimit'; id: string; amount: string; period: string } | null
    business?: {
      __typename?: 'Business'
      id: string
      address?: {
        __typename?: 'AddressData'
        address1: string
        address2?: string | null
        city: string
        country: string
        id: string
        postalCode: string
        state: string
      } | null
    } | null
    spendLimitInfo?: {
      __typename?: 'SpendLimitInfo'
      periodStartDate: string
      periodEndDate: string
      consumed: string
      frequency: string
      periodLimit: string
    } | null
    shippingAddress?: {
      __typename?: 'AddressData'
      id: string
      address1: string
      address2?: string | null
      city: string
      country: string
      postalCode: string
      state: string
    } | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      userType: UserType
    } | null
  } | null
}

export type CardBasicInfoQueryVariables = Exact<{
  cardId: Scalars['String']['input']
}>

export type CardBasicInfoQuery = {
  __typename?: 'Query'
  card?: {
    __typename?: 'Card'
    id: string
    nameOnCard?: string | null
    nickname?: string | null
    status: CardStatus
    type: CardType
    userId: string
    businessId: string
    lastFour?: string | null
    createdAt?: string | null
    updatedAt?: string | null
    spendLimit?: { __typename?: 'SpendLimit'; id: string; amount: string; period: string } | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      userType: UserType
    } | null
  } | null
}

export type PendingCardQueryVariables = Exact<{
  pendingCardId: Scalars['String']['input']
}>

export type PendingCardQuery = {
  __typename?: 'Query'
  pendingCard?: {
    __typename?: 'PendingCard'
    id: string
    userId: string
    nickname?: string | null
    type?: CardType | null
    status?: PendingCardStatus | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    postalCode?: string | null
    spendLimitAmount?: string | null
    spendLimitPeriod?: SpendLimitPeriod | null
    createdAt?: string | null
    updatedAt?: string | null
    nameOnCard?: string | null
    belongsToValidUser?: boolean | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      userType: UserType
    } | null
  } | null
}

export type PhysicalPreActiveCardQueryVariables = Exact<{ [key: string]: never }>

export type PhysicalPreActiveCardQuery = {
  __typename?: 'Query'
  physicalPreActiveCard?: {
    __typename?: 'Card'
    id: string
    userId: string
    businessId: string
    type: CardType
    status: CardStatus
    createdAt?: string | null
    updatedAt?: string | null
  } | null
}

export type BusinessCardLimitQueryVariables = Exact<{ [key: string]: never }>

export type BusinessCardLimitQuery = {
  __typename?: 'Query'
  cardLimit?: {
    __typename?: 'BusinessCardLimit'
    businessId?: string | null
    businessCardsCount: number
    activeCardsLimit: number
  } | null
}

export type PendingAgreementsQueryVariables = Exact<{ [key: string]: never }>

export type PendingAgreementsQuery = {
  __typename?: 'Query'
  pendingAgreements?: Array<{
    __typename?: 'Agreement'
    id: string
    agreementType: AgreementTypeEnum
    version: string
    name: string
    link: string
  }> | null
}

export type AgreementsQueryVariables = Exact<{ [key: string]: never }>

export type AgreementsQuery = {
  __typename?: 'Query'
  agreements?: Array<{
    __typename?: 'Agreement'
    id: string
    agreementType: AgreementTypeEnum
    version: string
    name: string
    link: string
  }> | null
}

export type WalletTransactionsQueryVariables = Exact<{
  currencyCode?: InputMaybe<CurrencyCode>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['CalendarDate']['input']>
  endDate?: InputMaybe<Scalars['CalendarDate']['input']>
}>

export type WalletTransactionsQuery = {
  __typename?: 'Query'
  walletTransactions?: {
    __typename?: 'PaginatedWalletTransactionResult'
    count: number
    pages: number
    skip: number
    take: number
    totalCount: number
    transactions?: Array<
      | {
          __typename?: 'BankPayment'
          id: string
          pointsAsNumber: number
          pointsAsString: string
          entityType: WalletTransactionEntityType
          date: string
          bankTransaction: {
            __typename?: 'BankPaymentTransaction'
            id: string
            amount: string
            amountAsNumber: number
            currency: CurrencyCode
            recipientId: string
            polarity: Polarity
            status: BankPaymentStatusType
            type: BankPaymentType
            reason: string
            reference: string
            receiptUrl?: string | null
            shortReference: string
            relatedConversionId?: string | null
            priority: PaymentPriority
            convertedAmount?: string | null
            convertedCurrency?: CurrencyCode | null
            speedFeeAmount?: string | null
            speedFeeAmountAsNumber?: number | null
            speedFeeCurrency?: string | null
            conversionFeeAmount?: string | null
            conversionFeeAmountAsNumber?: number | null
            conversionFeeCurrency?: string | null
            paymentMethod?: string | null
            creditor: {
              __typename?: 'BankPaymentContact'
              accountNumber?: string | null
              accountNumberType?: string | null
              address?: string | null
              routingCode?: string | null
              routingCodeType?: string | null
              bankAccountHolderName?: string | null
            }
            debitor: {
              __typename?: 'BankPaymentContact'
              accountNumber?: string | null
              accountNumberType?: string | null
              address?: string | null
              routingCode?: string | null
              routingCodeType?: string | null
              bankAccountHolderName?: string | null
            }
          }
        }
      | {
          __typename?: 'BankPaymentFee'
          id: string
          date: string
          entityType: WalletTransactionEntityType
          paymentFee: {
            __typename?: 'BankPaymentFeeTransaction'
            bankPaymentId: string
            amount: string
            amountAsNumber: number
            currency: CurrencyCode
            status: BankPaymentStatusType
            paymentShortReference: string
            priority: PaymentPriority
          }
        }
      | {
          __typename?: 'BankTransfer'
          id: string
          pointsAsNumber: number
          pointsAsString: string
          entityType: WalletTransactionEntityType
          date: string
          bankTransfer: {
            __typename?: 'BankTransferTransaction'
            amount: string
            amountAsNumber: number
            creditorBankAccountHolderName?: string | null
            currency: CurrencyCode
            debitorBankAccountHolderName?: string | null
            direction: BankTransferDirection
            id: string
            friendlyTransferType: string
            polarity: Polarity
            reason?: string | null
            reference?: string | null
            shortReference: string
            status: BankPaymentStatusType
            transferType: BankTransferType
          }
        }
      | {
          __typename?: 'Conversion'
          id: string
          pointsAsNumber: number
          pointsAsString: string
          date: string
          entityType: WalletTransactionEntityType
          conversionTransaction: {
            __typename?: 'ConversionTransaction'
            id: string
            buyAmount: string
            buyAmountAsNumber: number
            buyCurrency: CurrencyCode
            sellAmount: string
            sellAmountAsNumber: number
            sellCurrency: CurrencyCode
            clientRate: string
            conversionDate?: string | null
            cancelledAt?: string | null
            fixedSide: string
            status: BankPaymentStatusType
            relatedPaymentId?: string | null
            feeAmount?: string | null
            feeAmountAsNumber?: number | null
            feeCurrency?: string | null
            finalBuyAmount: string
            finalBuyAmountAsNumber: number
            finalSellAmount: string
            finalSellAmountAsNumber: number
          }
          rewardRedemption: {
            __typename?: 'ConversionRewardRedemption'
            isRedeemingPoints: boolean
            redeemedAmount?: string | null
            redeemedAmountAsNumber?: number | null
            redeemedCurrency?: CurrencyCode | null
            redeemedPoints?: string | null
            redeemedPointsAsNumber?: number | null
          }
        }
      | {
          __typename?: 'PendingBankPayment'
          id: string
          entityType: WalletTransactionEntityType
          pointsAsString: string
          pointsAsNumber: number
          date: string
          bankTransaction: {
            __typename?: 'PendingBankPaymentTransaction'
            id: string
            amount: string
            amountAsNumber: number
            currency: CurrencyCode
            convertedAmount?: string | null
            convertedCurrency?: CurrencyCode | null
            creditorAccountHolderName: string
            debitorAccountHolderName: string
            speedFeeAmount?: string | null
            speedFeeAmountAsNumber?: number | null
            speedFeeCurrency?: string | null
            conversionFeeAmount?: string | null
            conversionFeeAmountAsNumber?: number | null
            conversionFeeCurrency?: string | null
            priority: PaymentPriority
            reason: string
            reference: string
            createdAt?: string | null
            paymentMethod?: string | null
          }
        }
    > | null
  } | null
}

export type PaymentsBalanceQueryVariables = Exact<{ [key: string]: never }>

export type PaymentsBalanceQuery = {
  __typename?: 'Query'
  paymentsBalance?: {
    __typename?: 'PaymentBalanceResult'
    cardOutstandingBalance?: { __typename?: 'CardOustandingBalanceData'; formatted: string; balance: string } | null
  } | null
}

export type StatementsQueryVariables = Exact<{ [key: string]: never }>

export type StatementsQuery = {
  __typename?: 'Query'
  statements?: Array<{
    __typename?: 'Statement'
    id: string
    businessId: string
    statementNumber: string
    pdfUrl?: string | null
    pdfPath?: string | null
    statementDateFrom: string
    statementDateTo: string
    beginningBalance: string
    endBalance: string
    availableCreditLimit: string
    totalCreditLimit: string
    createdAt?: string | null
    rewardsRedemption?: string | null
  } | null> | null
}

export type StatementExtensionsQueryVariables = Exact<{ [key: string]: never }>

export type StatementExtensionsQuery = {
  __typename?: 'Query'
  statementExtensions?: Array<{
    __typename?: 'FundingWithInstallments'
    id: string
    loanNumber: string
    businessId: string
    status: string
    repaymentFrequency: string
    principalAmount: string
    totalFee: string
    termDurationInMonths: number
    apr: number
    totalRepayment: string
    outstandingBalance: string
    preDiscountTotalFee: string
    feeDiscountRate: number
    firstRepaymentDate: string
    reason?: string | null
    financingChannel: string
    nextPaymentDate?: string | null
    installments: Array<{
      __typename?: 'FundingInstallment'
      loanId: string
      businessId: string
      status: string
      dateDue: string
      amountDue: string
      installmentNumber: number
      principalAmountDue: string
      interestAmountDue: string
      lateFee?: string | null
    }>
    lastFundingPayment?: {
      __typename?: 'LastFundingPayment'
      id: string
      amount: { amount: string; amountAsNumber: number; currency: string }
      createdAt: string
      status: string
      type: string
      updatedAt: string
    } | null
    discounts: Array<{
      __typename?: 'FundingDiscount'
      id: string
      percent: number
      amount: string
      type: string
      rewardRedemptionId?: string | null
    }>
  }> | null
}

export type OutstandingBalancesQueryVariables = Exact<{ [key: string]: never }>

export type OutstandingBalancesQuery = {
  __typename?: 'Query'
  outstandingBalances?: {
    __typename?: 'OutstandingBalances'
    statementExtensionOutstandingBalance: string
    cardSettledBalance: string
    statementOutstandingBalance: string
  } | null
}

export type StatementExtensionApprovalQueryVariables = Exact<{ [key: string]: never }>

export type StatementExtensionApprovalQuery = {
  __typename?: 'Query'
  statementExtensionApproval?: {
    __typename?: 'StatementExtensionApproval'
    id: string
    businessNameLegal: string
    businessId: string
    maxRepaymentAmount: number
    repaymentFrequency: string
    enable: boolean
    apr: number
    enabledAt: string
    cardDueDate?: string | null
    repayments?: Array<{ __typename?: 'Repayments'; repayment: number; formatted: string }> | null
    autoEnrollmentConfig?: {
      __typename?: 'StatementExtensionAutoEnrollmentConfig'
      id: string
      enabled: boolean
      lastToggledAt: string
      repaymentInstallments: number
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type CalculateRepaymentPlanQueryVariables = Exact<{
  apr: Scalars['Float']['input']
  repaymentFrequency: Scalars['String']['input']
  fundingAmount: Scalars['Float']['input']
  repaymentInstallments: Scalars['Int']['input']
  feeDiscountRate?: InputMaybe<Scalars['Float']['input']>
}>

export type CalculateRepaymentPlanQuery = {
  __typename?: 'Query'
  calculateRepaymentPlan?: {
    __typename?: 'CalculateRepaymentPlan'
    totalFee: string
    totalRepaymentAmount: string
    amountLastPayment: string
    amountPerRemainingPayments: string
    principalAmountLastPayment: string
    principalAmountPerRemainingPayments: string
    feeLastPayment: string
    feePerRemainingPayments: string
    monthsDuration: number
    monthsDurationFormatted: string
    principalAmount: string
    preDiscountTotalFee: string
    preDiscountPaymentFee: string
    repaymentInstallments: number
    durationFormatted: string
  } | null
}

export type CalculateRepaymentPlansWithOriginationFeeQueryVariables = Exact<{
  fundingAmount: Scalars['Float']['input']
  maxRepaymentMonths: Scalars['Int']['input']
  repaymentFrequency: Scalars['String']['input']
}>

export type CalculateRepaymentPlansWithOriginationFeeQuery = {
  __typename?: 'Query'
  calculateRepaymentPlansWithOriginationFee?: Array<{
    __typename?: 'CalculateRepaymentPlanWithOriginationFee'
    amountPerRemainingPayments: { amount: string; amountAsNumber: number; currency: string }
    complianceFee: { amount: string; amountAsNumber: number; currency: string }
    durationFormatted: string
    monthlyFeeRate: number
    originationFeeRate: number
    monthsDuration: number
    monthsDurationFormatted: string
    originationFee: { amount: string; amountAsNumber: number; currency: string }
    principalAmount: { amount: string; amountAsNumber: number; currency: string }
    receivedAmount: { amount: string; amountAsNumber: number; currency: string }
    repaymentInstallments: number
    serviceFee: { amount: string; amountAsNumber: number; currency: string }
    totalFee: { amount: string; amountAsNumber: number; currency: string }
    totalRepaymentAmount: { amount: string; amountAsNumber: number; currency: string }
    lastPaymentDate: string
  } | null> | null
}

export type PrimaryAccountQueryVariables = Exact<{ [key: string]: never }>

export type PrimaryAccountQuery = {
  __typename?: 'Query'
  primaryAccount?: { __typename?: 'PlainAccount'; accountName?: string | null; accountNumber?: string | null } | null
}

export type TotalBalanceInCurrencyQueryVariables = Exact<{
  currency: CurrencyCode
}>

export type TotalBalanceInCurrencyQuery = {
  __typename?: 'Query'
  totalBalanceInCurrency?: {
    __typename?: 'TotalBalanceInCurrency'
    currentBalanceInRequestedCurrency: string
    availableBalanceInRequestedCurrency: string
  } | null
}

export type ReferralCodeQueryVariables = Exact<{ [key: string]: never }>

export type ReferralCodeQuery = {
  __typename?: 'Query'
  referralCode?: { __typename?: 'ReferralCode'; code: string } | null
}

export type ApplicationV2QueryVariables = Exact<{ [key: string]: never }>

export type ApplicationV2Query = {
  __typename?: 'Query'
  applicationV2?: {
    __typename?: 'ApplicationV2'
    id: string
    userId?: string | null
    status: string
    onboardingStep: OnboardingStepV2
    businessId?: string | null
    version: string
    dataVersion: string
    data?: {
      __typename?: 'ApplicationDataV2'
      completedSteps?: Array<string> | null
      device?: { __typename?: 'ApplicationDevice'; blackboxId?: string | null; ip?: string | null } | null
      extraDocuments?: {
        __typename?: 'ExtraDocuments'
        documents?: Array<{
          __typename?: 'SupportingDocuments'
          url?: string | null
          name?: string | null
          path?: string | null
          size?: number | null
          type?: string | null
        } | null> | null
      } | null
      validations?: {
        __typename?: 'ApplicationValidations'
        noMoreOwners?: boolean | null
        noMoreDirectors?: boolean | null
        authorizedToSign?: boolean | null
      } | null
      business?: {
        __typename?: 'ApplicationBusiness'
        legalName?: string | null
        dba?: string | null
        phone?: string | null
        website?: string | null
        incorporationNumber?: string | null
        incorporationDate?: string | null
        description?: string | null
        incorporationType?: string | null
        category?: string | null
        hasWebsite?: boolean | null
        numberOfEmployees?: number | null
        minNumberOfEmployees?: number | null
        maxNumberOfEmployees?: number | null
        isMSB?: boolean | null
        supportingDocuments?: Array<{
          __typename?: 'SupportingDocuments'
          url?: string | null
          name?: string | null
          path?: string | null
          size?: number | null
          type?: string | null
        } | null> | null
        address?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      } | null
      relevantPeople?: Array<{
        __typename?: 'ApplicationRelevantPerson'
        firstName?: string | null
        lastName?: string | null
        phoneNumber?: string | null
        participation?: Array<PersonParticipation | null> | null
        socialInsuranceNumber?: string | null
        email?: string | null
        citizenship?: string | null
        dateOfBirth?: string | null
        politicalExposure?: boolean | null
        roleDescription?: string | null
        authorizedToSign?: boolean | null
        address?: {
          __typename?: 'ApplicationAddress'
          address1?: string | null
          address2?: string | null
          postalCode?: string | null
          city?: string | null
          country?: string | null
          state?: string | null
        } | null
      }> | null
      bankDetails?: { __typename?: 'ApplicationBankDetails'; provider?: string | null; skip?: boolean | null } | null
      identityVerification?: { __typename?: 'ApplicantIdentityVerification'; transactionId?: string | null } | null
      productsSelection?: {
        __typename?: 'ApplicationProductsSelection'
        banking?: boolean | null
        capital?: boolean | null
        fxExchange?: boolean | null
        creditCard?: boolean | null
      } | null
      shippingAddress?: {
        __typename?: 'ApplicationAddress'
        address1?: string | null
        address2?: string | null
        postalCode?: string | null
        city?: string | null
        country?: string | null
        state?: string | null
      } | null
      card?: { __typename?: 'ApplicationCard'; extraEmbossingLine?: string | null } | null
    } | null
  } | null
}

export type ApplicationV2ByBusinessIdQueryVariables = Exact<{ [key: string]: never }>

export type ApplicationV2ByBusinessIdQuery = {
  __typename?: 'Query'
  applicationV2ByBusinessId?: { __typename?: 'ApplicationV2'; id: string } | null
}

export type CalculateRepaymentPlansQueryVariables = Exact<{
  apr: Scalars['Float']['input']
  repaymentFrequency: Scalars['String']['input']
  repaymentInstallments: Array<Scalars['Int']['input']> | Scalars['Int']['input']
  fundingAmount: Scalars['Float']['input']
  feeDiscountRate: Scalars['Float']['input']
}>

export type CalculateRepaymentPlansQuery = {
  __typename?: 'Query'
  calculateRepaymentPlans?: Array<{
    __typename?: 'CalculateRepaymentPlan'
    totalFee: string
    totalRepaymentAmount: string
    amountLastPayment: string
    amountPerRemainingPayments: string
    principalAmountLastPayment: string
    principalAmountPerRemainingPayments: string
    feeLastPayment: string
    feePerRemainingPayments: string
    monthsDuration: number
    monthsDurationFormatted: string
    principalAmount: string
    preDiscountTotalFee: string
    preDiscountPaymentFee: string
    repaymentInstallments: number
    durationFormatted: string
  } | null> | null
}

export type ReferralCodeByCodeQueryVariables = Exact<{
  code: Scalars['String']['input']
}>

export type ReferralCodeByCodeQuery = {
  __typename?: 'Query'
  referralCodeByCode?: {
    __typename?: 'ReferralCode'
    code: string
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName?: string | null
      lastName?: string | null
      userType: UserType
    } | null
  } | null
}

export type ReferralsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}>

export type ReferralsQuery = {
  __typename?: 'Query'
  referrals?: {
    __typename?: 'PaginatedReferrals'
    totalCount: number
    referrals: Array<{
      __typename?: 'Referral'
      id: string
      inviteeId: string
      status: ReferralStatus
      createdAt: string
      invitee?: {
        __typename?: 'User'
        id: string
        email: string
        firstName?: string | null
        lastName?: string | null
        userType: UserType
      } | null
    }>
  } | null
}

export type ReferralRewardsEarnedQueryVariables = Exact<{ [key: string]: never }>

export type ReferralRewardsEarnedQuery = {
  __typename?: 'Query'
  referralRewardsEarned?: { __typename?: 'ReferralRewardAmount'; amount: string } | null
}

export type CalculateRepaymentDatesQueryVariables = Exact<{
  repaymentFrequency: Scalars['String']['input']
  repaymentInstallments: Scalars['Int']['input']
  startDate: Scalars['DateTime']['input']
}>

export type CalculateRepaymentDatesQuery = {
  __typename?: 'Query'
  calculateRepaymentDates?: {
    __typename?: 'CalculateRepaymentDates'
    dates: Array<string>
    first: string
    last: string
  } | null
}

export type GetCapitalInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetCapitalInfoQuery = {
  __typename?: 'Query'
  capitalInfo?: {
    __typename?: 'CapitalInfo'
    availableCreditLimit: string
    capitalCreditLimit: string
    underwritingStatus: string
    capitalRequest?: { __typename?: 'CapitalRequest'; id: string; businessId: string; createdAt: string } | null
    approval: {
      __typename?: 'Approval'
      id: string
      businessId: string
      riskLevel?: number | null
      maxRepaymentMonths: number
      apr: number
      repaymentFrequency: string
      pricingVersion: CapitalApprovalVersion
      repayments?: Array<{ __typename?: 'Repayment'; repayment: number; formatted: string }> | null
    }
  } | null
}

export type ActiveIncidentNotificationsQueryVariables = Exact<{ [key: string]: never }>

export type ActiveIncidentNotificationsQuery = {
  __typename?: 'Query'
  activeIncidentNotifications?: Array<{
    __typename?: 'IncidentNotification'
    message: string
    severity: string
    pages: string
  } | null> | null
}

export type UserHasAgreedReferralsQueryVariables = Exact<{ [key: string]: never }>

export type UserHasAgreedReferralsQuery = { __typename?: 'Query'; userHasAgreedReferrals?: boolean | null }

export type GetUserVerificationDataQueryVariables = Exact<{ [key: string]: never }>

export type GetUserVerificationDataQuery = {
  __typename?: 'Query'
  getUserVerificationData?: {
    __typename?: 'UserVerificationData'
    id: string
    email?: string | null
    verifiedAt?: string | null
    phoneNumber?: string | null
    phoneVerifiedAt?: string | null
  } | null
}

export type IsTxDisputableQueryVariables = Exact<{
  body: IsTxDisputableInput
}>

export type IsTxDisputableQuery = {
  __typename?: 'Query'
  isTxDisputable?: { __typename?: 'IsTxDisputable'; isDisputable: boolean } | null
}

export type GetInstallmentsQueryVariables = Exact<{
  fundingId: Scalars['String']['input']
}>

export type GetInstallmentsQuery = {
  __typename?: 'Query'
  installments?: Array<{
    __typename?: 'FundingInstallment'
    loanId: string
    businessId: string
    status: string
    dateDue: string
    amountDue: string
    installmentNumber: number
    principalAmountDue: string
    interestAmountDue: string
    lateFee?: string | null
  } | null> | null
}

export type GetRewardsHistoryQueryVariables = Exact<{
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}>

export type GetRewardsHistoryQuery = {
  __typename?: 'Query'
  rewardsHistory?: {
    __typename?: 'PaginatedRewardsHistory'
    pages?: number | null
    rewards?: Array<{
      __typename?: 'RewardsHistory'
      rewardDate: string
      userFirstName: string
      userLastName: string
      amount: string
      amountAsNumber: number
      action: RewardHistoryAction
      entityType: string
      entityId: string
      polarity: Polarity
    }> | null
  } | null
}

export type FundingsByBusinessIdPaginatedQueryVariables = Exact<{
  limit: Scalars['Int']['input']
  page: Scalars['Int']['input']
  financingChannel?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
}>

export type FundingsByBusinessIdPaginatedQuery = {
  __typename?: 'Query'
  fundingsByBusinessIdPaginated?: {
    __typename?: 'PaginateFundings'
    total: number
    pages: number
    data: Array<{
      __typename?: 'FundingPaginated'
      id: string
      loanNumber: string
      businessId: string
      status: string
      repaymentFrequency: string
      principalAmount: { amount: string; amountAsNumber: number; currency: string }
      totalFee: { amount: string; amountAsNumber: number; currency: string }
      termDurationInMonths: number
      apr: number
      totalRepayment: { amount: string; amountAsNumber: number; currency: string }
      totalRefinanced: { amount: string; amountAsNumber: number; currency: string }
      outstandingBalance: { amount: string; amountAsNumber: number; currency: string }
      agreementSignedDate?: string | null
      firstRepaymentDate: string
      financingChannel: string
      preDiscountTotalFee: { amount: string; amountAsNumber: number; currency: string }
      feeDiscountRate: number
      depositedOn?: string | null
      reason?: string | null
      nextPaymentDate?: string | null
      discounts: Array<{
        __typename?: 'FundingDiscount'
        id: string
        percent: number
        amount: string
        type: string
        rewardRedemptionId?: string | null
      }>
      lastFundingPayment?: {
        __typename?: 'LastFundingPayment'
        id: string
        amount: { amount: string; amountAsNumber: number; currency: string }
        createdAt: string
        status: string
        type: string
        updatedAt: string
      } | null
    }>
  } | null
}

export type CardsTransactionsTotalNetQueryVariables = Exact<{
  businessId: Scalars['String']['input']
  dateFrom: Scalars['CalendarDate']['input']
  dateTo: Scalars['CalendarDate']['input']
}>

export type CardsTransactionsTotalNetQuery = {
  __typename?: 'Query'
  cardsTransactionsTotalNet?: { __typename?: 'CardsTransactionsTotalNet'; total: string } | null
}

export type CapitalFundingsByBusinessIdPaginatedQueryVariables = Exact<{
  limit: Scalars['Int']['input']
  page: Scalars['Int']['input']
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
}>

export type CapitalFundingsByBusinessIdPaginatedQuery = {
  __typename?: 'Query'
  capitalFundingsByBusinessIdPaginated?: {
    __typename?: 'PaginatedCapitalFundings'
    pages: number
    data: Array<{
      __typename?: 'PaginatedCapitalFunding'
      id: string
      loanNumber: string
      businessId: string
      status: string
      principalAmount: { amount: string; amountAsNumber: number; currency: string }
      totalFee: { amount: string; amountAsNumber: number; currency: string }
      originationFee: { amount: string; amountAsNumber: number; currency: string }
      totalRepayment: { amount: string; amountAsNumber: number; currency: string }
      feeDiscountRate: number
      preDiscountTotalFee: { amount: string; amountAsNumber: number; currency: string }
      nextPaymentDate?: string | null
      firstRepaymentDate: string
      repaymentFrequency: string
      apr: number
      discounts: Array<{
        __typename?: 'FundingDiscount'
        id: string
        percent: number
        amount: string
        type: string
        rewardRedemptionId?: string | null
      }>
      lastFundingPayment?: {
        __typename?: 'LastFundingPayment'
        id: string
        amount: { amount: string; amountAsNumber: number; currency: string }
        createdAt: string
        status: string
        type: string
        updatedAt: string
      } | null
    }>
  } | null
}

export type ExpensesByBusinessQueryVariables = Exact<{
  filters?: InputMaybe<ExpensesFilters>
  pagination?: InputMaybe<PaginationInput>
}>

export type ExpensesByBusinessQuery = {
  __typename?: 'Query'
  listExpensesByBusiness?: {
    __typename?: 'ListExpensesByBusinessResponse'
    totalExpenses: number
    expenses: Array<{
      __typename?: 'AccountingExpense'
      id: string
      businessId: string
      status: ExpenseStatus
      transactionDate?: string | null
      merchantName?: string | null
      amount?: string | null
      currency?: string | null
      requestedAmount?: string | null
      requestedCurrency?: string | null
      cardId?: string | null
      memo?: string | null
      entityMerchantId?: string | null
      entityCategoryId?: string | null
      entityTaxCodeId?: string | null
      entityClassId?: string | null
      statusSnapshots?: Array<{
        __typename?: 'AccountingExpenseStatusSnapshot'
        id: string
        status: ExpenseStatus
        description?: string | null
        createdAt: string
      }> | null
      card?: {
        __typename?: 'Card'
        id: string
        userId: string
        businessId: string
        status: CardStatus
        lastFour?: string | null
        nameOnCard?: string | null
        type: CardType
      } | null
      receipt?: {
        __typename?: 'AccountingExpenseReceipt'
        id: string
        documentId: string
        document?: {
          __typename?: 'DocumentType'
          id: string
          path: string
          displayName: string
          mimeType: string
        } | null
      } | null
    }>
    countsByStatus?: {
      __typename?: 'ExpensesCountByStatus'
      CREATED?: number | null
      REQUEST_FOR_INFORMATION?: number | null
      IN_REVIEW?: number | null
      REVIEWED?: number | null
      SYNCING?: number | null
      SYNCED?: number | null
      FAILED?: number | null
    } | null
  } | null
}

export type ExpensesByUserQueryVariables = Exact<{
  filters?: InputMaybe<ExpensesFilters>
  pagination?: InputMaybe<PaginationInput>
}>

export type ExpensesByUserQuery = {
  __typename?: 'Query'
  listExpensesByUser?: {
    __typename?: 'ListExpensesByUserResponse'
    expenses: Array<{
      __typename?: 'AccountingExpense'
      id: string
      businessId: string
      status: ExpenseStatus
      transactionDate?: string | null
      merchantName?: string | null
      amount?: string | null
      currency?: string | null
      cardId?: string | null
      memo?: string | null
      entityMerchantId?: string | null
      entityCategoryId?: string | null
      entityTaxCodeId?: string | null
      entityClassId?: string | null
      statusSnapshots?: Array<{
        __typename?: 'AccountingExpenseStatusSnapshot'
        id: string
        status: ExpenseStatus
        description?: string | null
        createdAt: string
      }> | null
      card?: {
        __typename?: 'Card'
        id: string
        userId: string
        businessId: string
        status: CardStatus
        lastFour?: string | null
        nameOnCard?: string | null
        type: CardType
      } | null
      receipt?: {
        __typename?: 'AccountingExpenseReceipt'
        id: string
        documentId: string
        document?: {
          __typename?: 'DocumentType'
          id: string
          path: string
          displayName: string
          mimeType: string
        } | null
      } | null
    }>
  } | null
}

export type MfaSettingsQueryVariables = Exact<{ [key: string]: never }>

export type MfaSettingsQuery = {
  __typename?: 'Query'
  mfaSettings?: Array<{
    __typename?: 'MFASetting'
    id: string
    channel: MfaChannelEnum
    isEnabled: boolean
    isPrimary: boolean
  }> | null
}

export type BankConnectionAccountsQueryVariables = Exact<{ [key: string]: never }>

export type BankConnectionAccountsQuery = {
  __typename?: 'Query'
  bankConnectionAccounts: Array<{
    __typename?: 'BankConnectionAccount'
    id: string
    name: string
    accountNumber?: string | null
    branchNumber?: string | null
    bankNumber?: string | null
    verifiedAt?: string | null
    isPrimary?: boolean | null
    subtype?: string | null
    currencyCode?: string | null
    provider?: string | null
    currentBalance?: string | null
    availableBalance?: string | null
    bank?: { __typename?: 'BankInfo'; name?: string | null; logo?: string | null } | null
  }>
}

export type ListAccountingCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type ListAccountingCategoriesQuery = {
  __typename?: 'Query'
  listAccountingCategories?: Array<{
    __typename?: 'AccountingExpenseCategory'
    id: string
    name?: string | null
    status: AccountingExpenseCategoryStatus
    category: AccountingExpenseCategoryType
    nominalCode?: string | null
    createdAt?: string | null
    updatedAt?: string | null
  }> | null
}

export type ListAccountingTaxRatesQueryVariables = Exact<{ [key: string]: never }>

export type ListAccountingTaxRatesQuery = {
  __typename?: 'Query'
  listAccountingTaxRates?: Array<{
    __typename?: 'AccountingExpenseTaxRate'
    id: string
    name: string
    code?: string | null
    effectiveRate?: number | null
    totalTaxRate?: number | null
    createdAt?: string | null
    updatedAt?: string | null
    status: TaxRateStatus
  }> | null
}

export type ListAccountingVendorsQueryVariables = Exact<{ [key: string]: never }>

export type ListAccountingVendorsQuery = {
  __typename?: 'Query'
  listAccountingVendors?: Array<{
    __typename?: 'AccountingExpenseVendor'
    id: string
    status: AccountingVendorStatus
    vendorName?: string | null
    email?: string | null
    phone?: string | null
  }> | null
}

export type GetExpenseReceiptFileQueryVariables = Exact<{
  expenseId: Scalars['String']['input']
}>

export type GetExpenseReceiptFileQuery = {
  __typename?: 'Query'
  getExpenseReceiptFile?: { __typename?: 'GetExpenseReceiptFileOutput'; file: string; fileName: string } | null
}

export type FetchSyncingExpensesQueryVariables = Exact<{ [key: string]: never }>

export type FetchSyncingExpensesQuery = {
  __typename?: 'Query'
  fetchSyncingExpenses?: {
    __typename?: 'FetchSyncingExpensesResponse'
    count: number
    expenses: Array<{ __typename?: 'AccountingExpense'; id: string }>
  } | null
}

export type DelinquencyAmountQueryVariables = Exact<{ [key: string]: never }>

export type DelinquencyAmountQuery = {
  __typename?: 'Query'
  delinquencyAmount?: { __typename?: 'DelinquencyAmountResult'; amount?: string | null } | null
}

export type CapitalApprovalQueryVariables = Exact<{ [key: string]: never }>

export type CapitalApprovalQuery = {
  __typename?: 'Query'
  capitalApproval?: {
    __typename?: 'Approval'
    id: string
    businessId: string
    riskLevel?: number | null
    maxRepaymentMonths: number
    apr: number
    repaymentFrequency: string
    pricingVersion: CapitalApprovalVersion
    repayments?: Array<{ __typename?: 'Repayment'; repayment: number; formatted: string }> | null
  } | null
}

export type CanApplyForNewUserPromotionQueryVariables = Exact<{ [key: string]: never }>

export type CanApplyForNewUserPromotionQuery = {
  __typename?: 'Query'
  canApplyForNewUserPromotion?: {
    __typename?: 'CanApplyForNewUserPromotionOutput'
    canApply: boolean
    cohort?: Cohort | null
  } | null
}

export type ActivationChecklistQueryVariables = Exact<{ [key: string]: never }>

export type ActivationChecklistQuery = {
  __typename?: 'Query'
  activationChecklist?: Array<{
    __typename?: 'ActivationChecklistItem'
    id: string
    name: ActivationChecklistName
    description: string
    completed: boolean
    rewardsAfterCompletion: number
    stepNumber: number
    ctaText?: string | null
    ctaUrl?: string | null
  }> | null
}

export type GetUserAddressQueryVariables = Exact<{ [key: string]: never }>

export type GetUserAddressQuery = {
  __typename?: 'Query'
  getUserAddress?: {
    __typename?: 'AddressData'
    address1: string
    address2?: string | null
    postalCode: string
    city: string
    country: string
    state: string
  } | null
}

export type CurrentStatementQueryVariables = Exact<{ [key: string]: never }>

export type CurrentStatementQuery = {
  __typename?: 'Query'
  currentStatement?: {
    __typename?: 'Statement'
    id: string
    businessId: string
    statementDateTo: string
    statementDueDate?: string | null
  } | null
}

export type EstimateShippingTimeQueryVariables = Exact<{ [key: string]: never }>

export type EstimateShippingTimeQuery = {
  __typename?: 'Query'
  estimateShippingTime?: {
    __typename?: 'EstimateShippingTimeResponse'
    estimatedShippingDate: string
    isVip: boolean
  } | null
}

export type ListAccountingClassesQueryVariables = Exact<{ [key: string]: never }>

export type ListAccountingClassesQuery = {
  __typename?: 'Query'
  listAccountingClasses?: Array<{
    __typename?: 'AccountingExpenseClass'
    id: string
    name: string
    status?: AccountingExpenseClassStatus | null
  }> | null
}

export type UserHasPhysicalCardQueryVariables = Exact<{ [key: string]: never }>

export type UserHasPhysicalCardQuery = { __typename?: 'Query'; userHasPhysicalCard?: boolean | null }

export type CalculateWholeAmountToPayForEarlyPaymentQueryVariables = Exact<{
  fundingId: Scalars['String']['input']
}>

export type CalculateWholeAmountToPayForEarlyPaymentQuery = {
  __typename?: 'Query'
  calculateWholeAmountToPayForEarlyPayment?: {
    __typename?: 'CalculateWholeAmountToPayForEarlyPaymentOutput'
    principalOutstandingAmount: { amount: string; amountAsNumber: number; currency: string }
    proRatedFeeAmount: { amount: string; amountAsNumber: number; currency: string }
    totalAmount: { amount: string; amountAsNumber: number; currency: string }
  } | null
}

export type TotalCardsCreditLimitQueryVariables = Exact<{ [key: string]: never }>

export type TotalCardsCreditLimitQuery = { __typename?: 'Query'; totalCardsCreditLimit?: string | null }

export type ListWalletsQueryVariables = Exact<{ [key: string]: never }>

export type ListWalletsQuery = {
  __typename?: 'Query'
  wallets?: Array<{
    __typename?: 'Wallet'
    id: string
    currency: CurrencyCode
    balance?: {
      __typename?: 'BalanceResult'
      availableBalance?: { __typename?: 'WalletBalance'; amount?: string | null; amountAsNumber?: number | null } | null
    } | null
  } | null> | null
}

export type WalletsQueryVariables = Exact<{ [key: string]: never }>

export type WalletsQuery = {
  __typename?: 'Query'
  wallets?: Array<{
    __typename?: 'Wallet'
    id: string
    currency: CurrencyCode
    businessId: string
    balance?: {
      __typename?: 'BalanceResult'
      availableBalance?: { __typename?: 'WalletBalance'; amount?: string | null; amountAsNumber?: number | null } | null
      currentBalance?: { __typename?: 'WalletBalance'; amount?: string | null; amountAsNumber?: number | null } | null
    } | null
    fundingAccounts: Array<{
      __typename?: 'FundingAccountWithDetails'
      id: string
      paymentType: DbPaymentTypeMapping
      bankAccount: {
        __typename?: 'BankAccount'
        id: string
        agreedPad?: string | null
        name: string
        address: string
        country: string
        accountNumber: string
        routingCode?: string | null
        routingCodeType?: BankAccountRoutingCodeType | null
      }
    } | null>
  } | null> | null
}

export type BankingStatementsQueryVariables = Exact<{
  input?: InputMaybe<GetBankingStatementsInput>
}>

export type BankingStatementsQuery = {
  __typename?: 'Query'
  bankingStatements: Array<{
    __typename?: 'BankingStatement'
    id: string
    businessId: string
    currency: string
    year: number
    month: number
    createdAt: string
  }>
}

export type GetBankingStatementUrlQueryVariables = Exact<{
  input: GetBankingStatementUrlInput
}>

export type GetBankingStatementUrlQuery = {
  __typename?: 'Query'
  getBankingStatementUrl: { __typename?: 'BankingStatementUrl'; signedUrl: string }
}

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>

export type PaymentMethodsQuery = {
  __typename?: 'Query'
  paymentMethods: Array<{
    __typename?: 'PaymentMethod'
    id: string
    currency: string
    externalId: string
    isPrimary: boolean
    lastFour?: string | null
    name: string
    type: string
  }>
}

export type StatementPaymentsQueryVariables = Exact<{
  status?: InputMaybe<Array<StatementPaymentStatus> | StatementPaymentStatus>
  order?: InputMaybe<OrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}>

export type StatementPaymentsQuery = {
  __typename?: 'Query'
  statementPayments?: {
    __typename?: 'PaginatedPaymentResult'
    skip?: number | null
    take?: number | null
    count?: number | null
    totalCount?: number | null
    pages?: number | null
    payments?: Array<{
      __typename?: 'StatementPayment'
      id: string
      amount: string
      status: StatementPaymentStatus
      createdAt: string
      initatorRole: EftInitiatorRole
    }> | null
  } | null
}

export type BusinessDelinquencyDetailsQueryVariables = Exact<{
  product: BusinessDelinquencyProduct
}>

export type BusinessDelinquencyDetailsQuery = {
  __typename?: 'Query'
  businessDelinquencyDetails?: {
    __typename?: 'BusinessDelinquencyDetails'
    isDelinquent?: boolean | null
    lastDelinquentAmount: number
  } | null
}

export const TransactionFragmentFragmentDoc = gql`
  fragment TransactionFragment on LegacyTransaction {
    id
    amount
    currency
    amountAsNumber
    requestedAmount
    requestedAmountAsNumber
    requestedAmountCurrency
    entityType
    userName
    date
    cardId
    cardLastFour
    points
    transactionEventId
    transaction {
      id
      merchantId
      friendlyName
      status
      name
      logoUrl
      isReceivedPayment
      authorizationCode
    }
    expense {
      id
      businessId
      status
      memo
      receipt {
        id
        documentId
        document {
          id
          path
          displayName
          mimeType
        }
      }
    }
  }
`
export const WalletBankPaymentFragmentDoc = gql`
  fragment WalletBankPayment on BankPayment {
    id
    pointsAsNumber
    pointsAsString
    entityType
    date
    bankTransaction: transaction {
      id
      amount
      amountAsNumber
      currency
      recipientId
      polarity
      status
      type
      reason
      reference
      receiptUrl
      shortReference
      relatedConversionId
      priority
      convertedAmount
      convertedCurrency
      speedFeeAmount
      speedFeeAmountAsNumber
      speedFeeCurrency
      conversionFeeAmount
      conversionFeeAmountAsNumber
      conversionFeeCurrency
      paymentMethod
      creditor {
        accountNumber
        accountNumberType
        address
        routingCode
        routingCodeType
        bankAccountHolderName
      }
      debitor {
        accountNumber
        accountNumberType
        address
        routingCode
        routingCodeType
        bankAccountHolderName
      }
    }
  }
`
export const WalletBankPaymentFeeFragmentDoc = gql`
  fragment WalletBankPaymentFee on BankPaymentFee {
    id
    date
    entityType
    paymentFee: transaction {
      bankPaymentId
      amount
      amountAsNumber
      currency
      status
      paymentShortReference
      priority
    }
  }
`
export const WalletPendingBankPaymentFragmentDoc = gql`
  fragment WalletPendingBankPayment on PendingBankPayment {
    id
    entityType
    pointsAsString
    pointsAsNumber
    date
    bankTransaction: transaction {
      id
      amount
      amountAsNumber
      currency
      convertedAmount
      convertedCurrency
      creditorAccountHolderName
      debitorAccountHolderName
      speedFeeAmount
      speedFeeAmountAsNumber
      speedFeeCurrency
      conversionFeeAmount
      conversionFeeAmountAsNumber
      conversionFeeCurrency
      priority
      reason
      reference
      createdAt
      paymentMethod
    }
  }
`
export const WalletBankTransferFragmentDoc = gql`
  fragment WalletBankTransfer on BankTransfer {
    id
    pointsAsNumber
    pointsAsString
    entityType
    date
    bankTransfer: transaction {
      amount
      amountAsNumber
      creditorBankAccountHolderName
      currency
      debitorBankAccountHolderName
      direction
      id
      friendlyTransferType
      polarity
      reason
      reference
      shortReference
      status
      transferType
    }
  }
`
export const WalletConversionFragmentDoc = gql`
  fragment WalletConversion on Conversion {
    id
    pointsAsNumber
    pointsAsString
    date
    entityType
    conversionTransaction: transaction {
      id
      buyAmount
      buyAmountAsNumber
      buyCurrency
      sellAmount
      sellAmountAsNumber
      sellCurrency
      clientRate
      conversionDate
      cancelledAt
      fixedSide
      status
      relatedPaymentId
      feeAmount
      feeAmountAsNumber
      feeCurrency
      finalBuyAmount
      finalBuyAmountAsNumber
      finalSellAmount
      finalSellAmountAsNumber
    }
    rewardRedemption {
      isRedeemingPoints
      redeemedAmount
      redeemedAmountAsNumber
      redeemedCurrency
      redeemedPoints
      redeemedPointsAsNumber
    }
  }
`
export const AddressFragmentDoc = gql`
  fragment Address on ApplicationAddress {
    address1
    address2
    postalCode
    city
    country
    state
  }
`
export const ApplicationV2FragmentFragmentDoc = gql`
  fragment ApplicationV2Fragment on ApplicationV2 {
    id
    userId
    status
    onboardingStep
    businessId
    version
    dataVersion
    data {
      completedSteps
      device {
        blackboxId
        ip
      }
      extraDocuments {
        documents {
          url
          name
          path
          size
          type
        }
      }
      validations {
        noMoreOwners
        noMoreDirectors
        authorizedToSign
      }
      business {
        legalName
        dba
        phone
        website
        incorporationNumber
        incorporationDate
        description
        incorporationType
        category
        hasWebsite
        supportingDocuments {
          url
          name
          path
          size
          type
        }
        address {
          ...Address
        }
        numberOfEmployees
        minNumberOfEmployees
        maxNumberOfEmployees
        isMSB
      }
      relevantPeople {
        firstName
        lastName
        phoneNumber
        participation
        socialInsuranceNumber
        phoneNumber
        email
        citizenship
        dateOfBirth
        politicalExposure
        roleDescription
        address {
          ...Address
        }
        authorizedToSign
      }
      bankDetails {
        provider
        skip
      }
      identityVerification {
        transactionId
      }
      productsSelection {
        banking
        capital
        fxExchange
        creditCard
      }
      shippingAddress {
        ...Address
      }
      card {
        extraEmbossingLine
      }
    }
  }
  ${AddressFragmentDoc}
`
export const RepaymentPlanFragmentFragmentDoc = gql`
  fragment RepaymentPlanFragment on CalculateRepaymentPlan {
    totalFee
    totalRepaymentAmount
    amountLastPayment
    amountPerRemainingPayments
    principalAmountLastPayment
    principalAmountPerRemainingPayments
    feeLastPayment
    feePerRemainingPayments
    monthsDuration
    monthsDurationFormatted
    principalAmount
    preDiscountTotalFee
    preDiscountPaymentFee
    repaymentInstallments
    durationFormatted
  }
`
export const InstallmentFragmentFragmentDoc = gql`
  fragment InstallmentFragment on FundingInstallment {
    loanId
    businessId
    status
    dateDue
    amountDue
    installmentNumber
    principalAmountDue
    interestAmountDue
    lateFee
  }
`
export const DiscountFragmentFragmentDoc = gql`
  fragment DiscountFragment on FundingDiscount {
    id
    percent
    amount
    type
    rewardRedemptionId
  }
`
export const CapitalApprovalFragmentFragmentDoc = gql`
  fragment CapitalApprovalFragment on Approval {
    id
    businessId
    riskLevel
    maxRepaymentMonths
    apr
    repaymentFrequency
    repayments {
      repayment
      formatted
    }
    pricingVersion
  }
`
export const LastFundingPaymentFragmentFragmentDoc = gql`
  fragment LastFundingPaymentFragment on LastFundingPayment {
    id
    amount
    createdAt
    status
    type
    updatedAt
  }
`
export const FreezeCardDocument = gql`
  mutation FreezeCard($cardId: String!) {
    freezeCard(id: $cardId) {
      id
      status
    }
  }
`
export type FreezeCardMutationFn = Apollo.MutationFunction<FreezeCardMutation, FreezeCardMutationVariables>

/**
 * __useFreezeCardMutation__
 *
 * To run a mutation, you first call `useFreezeCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreezeCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freezeCardMutation, { data, loading, error }] = useFreezeCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useFreezeCardMutation(
  baseOptions?: Apollo.MutationHookOptions<FreezeCardMutation, FreezeCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FreezeCardMutation, FreezeCardMutationVariables>(FreezeCardDocument, options)
}
export type FreezeCardMutationHookResult = ReturnType<typeof useFreezeCardMutation>
export type FreezeCardMutationResult = Apollo.MutationResult<FreezeCardMutation>
export type FreezeCardMutationOptions = Apollo.BaseMutationOptions<FreezeCardMutation, FreezeCardMutationVariables>
export const UnfreezeCardDocument = gql`
  mutation UnfreezeCard($cardId: String!) {
    unfreezeCard(id: $cardId) {
      id
      status
    }
  }
`
export type UnfreezeCardMutationFn = Apollo.MutationFunction<UnfreezeCardMutation, UnfreezeCardMutationVariables>

/**
 * __useUnfreezeCardMutation__
 *
 * To run a mutation, you first call `useUnfreezeCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfreezeCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfreezeCardMutation, { data, loading, error }] = useUnfreezeCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useUnfreezeCardMutation(
  baseOptions?: Apollo.MutationHookOptions<UnfreezeCardMutation, UnfreezeCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnfreezeCardMutation, UnfreezeCardMutationVariables>(UnfreezeCardDocument, options)
}
export type UnfreezeCardMutationHookResult = ReturnType<typeof useUnfreezeCardMutation>
export type UnfreezeCardMutationResult = Apollo.MutationResult<UnfreezeCardMutation>
export type UnfreezeCardMutationOptions = Apollo.BaseMutationOptions<
  UnfreezeCardMutation,
  UnfreezeCardMutationVariables
>
export const CompleteAccountingIntegrationDocument = gql`
  mutation CompleteAccountingIntegration($body: CompleteConnectionParams!) {
    completeAccountingIntegration(body: $body) {
      accountingIntegration {
        id
        status
        integrationType
        connectedAt
      }
      connectionUrl
    }
  }
`
export type CompleteAccountingIntegrationMutationFn = Apollo.MutationFunction<
  CompleteAccountingIntegrationMutation,
  CompleteAccountingIntegrationMutationVariables
>

/**
 * __useCompleteAccountingIntegrationMutation__
 *
 * To run a mutation, you first call `useCompleteAccountingIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAccountingIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAccountingIntegrationMutation, { data, loading, error }] = useCompleteAccountingIntegrationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCompleteAccountingIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAccountingIntegrationMutation,
    CompleteAccountingIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteAccountingIntegrationMutation, CompleteAccountingIntegrationMutationVariables>(
    CompleteAccountingIntegrationDocument,
    options
  )
}
export type CompleteAccountingIntegrationMutationHookResult = ReturnType<
  typeof useCompleteAccountingIntegrationMutation
>
export type CompleteAccountingIntegrationMutationResult = Apollo.MutationResult<CompleteAccountingIntegrationMutation>
export type CompleteAccountingIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CompleteAccountingIntegrationMutation,
  CompleteAccountingIntegrationMutationVariables
>
export const StartAccountingIntegrationDocument = gql`
  mutation StartAccountingIntegration($body: StartAccountingIntegrationConnectionParams!) {
    startAccountingIntegrationConnection(body: $body)
  }
`
export type StartAccountingIntegrationMutationFn = Apollo.MutationFunction<
  StartAccountingIntegrationMutation,
  StartAccountingIntegrationMutationVariables
>

/**
 * __useStartAccountingIntegrationMutation__
 *
 * To run a mutation, you first call `useStartAccountingIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAccountingIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAccountingIntegrationMutation, { data, loading, error }] = useStartAccountingIntegrationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useStartAccountingIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartAccountingIntegrationMutation,
    StartAccountingIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartAccountingIntegrationMutation, StartAccountingIntegrationMutationVariables>(
    StartAccountingIntegrationDocument,
    options
  )
}
export type StartAccountingIntegrationMutationHookResult = ReturnType<typeof useStartAccountingIntegrationMutation>
export type StartAccountingIntegrationMutationResult = Apollo.MutationResult<StartAccountingIntegrationMutation>
export type StartAccountingIntegrationMutationOptions = Apollo.BaseMutationOptions<
  StartAccountingIntegrationMutation,
  StartAccountingIntegrationMutationVariables
>
export const AddExtraDocumentsDocument = gql`
  mutation AddExtraDocuments($body: AddExtraDocumentsInput!) {
    addExtraDocuments(body: $body) {
      id
    }
  }
`
export type AddExtraDocumentsMutationFn = Apollo.MutationFunction<
  AddExtraDocumentsMutation,
  AddExtraDocumentsMutationVariables
>

/**
 * __useAddExtraDocumentsMutation__
 *
 * To run a mutation, you first call `useAddExtraDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExtraDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExtraDocumentsMutation, { data, loading, error }] = useAddExtraDocumentsMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddExtraDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddExtraDocumentsMutation, AddExtraDocumentsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddExtraDocumentsMutation, AddExtraDocumentsMutationVariables>(
    AddExtraDocumentsDocument,
    options
  )
}
export type AddExtraDocumentsMutationHookResult = ReturnType<typeof useAddExtraDocumentsMutation>
export type AddExtraDocumentsMutationResult = Apollo.MutationResult<AddExtraDocumentsMutation>
export type AddExtraDocumentsMutationOptions = Apollo.BaseMutationOptions<
  AddExtraDocumentsMutation,
  AddExtraDocumentsMutationVariables
>
export const CreateStatementPaymentDocument = gql`
  mutation CreateStatementPayment($body: CreateStatementPaymentInput!) {
    createStatementPayment(body: $body) {
      id
    }
  }
`
export type CreateStatementPaymentMutationFn = Apollo.MutationFunction<
  CreateStatementPaymentMutation,
  CreateStatementPaymentMutationVariables
>

/**
 * __useCreateStatementPaymentMutation__
 *
 * To run a mutation, you first call `useCreateStatementPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStatementPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStatementPaymentMutation, { data, loading, error }] = useCreateStatementPaymentMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateStatementPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStatementPaymentMutation, CreateStatementPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateStatementPaymentMutation, CreateStatementPaymentMutationVariables>(
    CreateStatementPaymentDocument,
    options
  )
}
export type CreateStatementPaymentMutationHookResult = ReturnType<typeof useCreateStatementPaymentMutation>
export type CreateStatementPaymentMutationResult = Apollo.MutationResult<CreateStatementPaymentMutation>
export type CreateStatementPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateStatementPaymentMutation,
  CreateStatementPaymentMutationVariables
>
export const RenameCardDocument = gql`
  mutation RenameCard($cardId: String!, $newNickname: String!) {
    renameCard(id: $cardId, newNickname: $newNickname) {
      id
      nickname
    }
  }
`
export type RenameCardMutationFn = Apollo.MutationFunction<RenameCardMutation, RenameCardMutationVariables>

/**
 * __useRenameCardMutation__
 *
 * To run a mutation, you first call `useRenameCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameCardMutation, { data, loading, error }] = useRenameCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      newNickname: // value for 'newNickname'
 *   },
 * });
 */
export function useRenameCardMutation(
  baseOptions?: Apollo.MutationHookOptions<RenameCardMutation, RenameCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenameCardMutation, RenameCardMutationVariables>(RenameCardDocument, options)
}
export type RenameCardMutationHookResult = ReturnType<typeof useRenameCardMutation>
export type RenameCardMutationResult = Apollo.MutationResult<RenameCardMutation>
export type RenameCardMutationOptions = Apollo.BaseMutationOptions<RenameCardMutation, RenameCardMutationVariables>
export const RenamePendingCardDocument = gql`
  mutation RenamePendingCard($body: RenamePendingCardInput!) {
    renameCard: renamePendingCard(body: $body) {
      id
      nickname
    }
  }
`
export type RenamePendingCardMutationFn = Apollo.MutationFunction<
  RenamePendingCardMutation,
  RenamePendingCardMutationVariables
>

/**
 * __useRenamePendingCardMutation__
 *
 * To run a mutation, you first call `useRenamePendingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenamePendingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renamePendingCardMutation, { data, loading, error }] = useRenamePendingCardMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRenamePendingCardMutation(
  baseOptions?: Apollo.MutationHookOptions<RenamePendingCardMutation, RenamePendingCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenamePendingCardMutation, RenamePendingCardMutationVariables>(
    RenamePendingCardDocument,
    options
  )
}
export type RenamePendingCardMutationHookResult = ReturnType<typeof useRenamePendingCardMutation>
export type RenamePendingCardMutationResult = Apollo.MutationResult<RenamePendingCardMutation>
export type RenamePendingCardMutationOptions = Apollo.BaseMutationOptions<
  RenamePendingCardMutation,
  RenamePendingCardMutationVariables
>
export const CloseCardDocument = gql`
  mutation CloseCard($cardId: String!) {
    closeCard(id: $cardId) {
      id
    }
  }
`
export type CloseCardMutationFn = Apollo.MutationFunction<CloseCardMutation, CloseCardMutationVariables>

/**
 * __useCloseCardMutation__
 *
 * To run a mutation, you first call `useCloseCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeCardMutation, { data, loading, error }] = useCloseCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCloseCardMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseCardMutation, CloseCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CloseCardMutation, CloseCardMutationVariables>(CloseCardDocument, options)
}
export type CloseCardMutationHookResult = ReturnType<typeof useCloseCardMutation>
export type CloseCardMutationResult = Apollo.MutationResult<CloseCardMutation>
export type CloseCardMutationOptions = Apollo.BaseMutationOptions<CloseCardMutation, CloseCardMutationVariables>
export const ClosePendingCardDocument = gql`
  mutation ClosePendingCard($id: String!) {
    closeCard: cancelPendingCard(id: $id) {
      id
    }
  }
`
export type ClosePendingCardMutationFn = Apollo.MutationFunction<
  ClosePendingCardMutation,
  ClosePendingCardMutationVariables
>

/**
 * __useClosePendingCardMutation__
 *
 * To run a mutation, you first call `useClosePendingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClosePendingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closePendingCardMutation, { data, loading, error }] = useClosePendingCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClosePendingCardMutation(
  baseOptions?: Apollo.MutationHookOptions<ClosePendingCardMutation, ClosePendingCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClosePendingCardMutation, ClosePendingCardMutationVariables>(
    ClosePendingCardDocument,
    options
  )
}
export type ClosePendingCardMutationHookResult = ReturnType<typeof useClosePendingCardMutation>
export type ClosePendingCardMutationResult = Apollo.MutationResult<ClosePendingCardMutation>
export type ClosePendingCardMutationOptions = Apollo.BaseMutationOptions<
  ClosePendingCardMutation,
  ClosePendingCardMutationVariables
>
export const ResendInvitationDocument = gql`
  mutation ResendInvitation($invitedUserId: String!) {
    resendInvitation(invitedUserId: $invitedUserId)
  }
`
export type ResendInvitationMutationFn = Apollo.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      invitedUserId: // value for 'invitedUserId'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
    ResendInvitationDocument,
    options
  )
}
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>
export const CreateCardDocument = gql`
  mutation CreateCard($body: CreateCardInput!) {
    createCard(body: $body) {
      id
      userId
      card {
        id
        status
        type
        userId
        businessId
      }
      pendingCard {
        id
        userId
      }
    }
  }
`
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options)
}
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>
export const UpdateCardSpendLimitDocument = gql`
  mutation UpdateCardSpendLimit($body: UpdateCardSpendLimitInput!) {
    updateCardSpendLimit(body: $body) {
      id
      period
      amount
    }
  }
`
export type UpdateCardSpendLimitMutationFn = Apollo.MutationFunction<
  UpdateCardSpendLimitMutation,
  UpdateCardSpendLimitMutationVariables
>

/**
 * __useUpdateCardSpendLimitMutation__
 *
 * To run a mutation, you first call `useUpdateCardSpendLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardSpendLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardSpendLimitMutation, { data, loading, error }] = useUpdateCardSpendLimitMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateCardSpendLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCardSpendLimitMutation, UpdateCardSpendLimitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCardSpendLimitMutation, UpdateCardSpendLimitMutationVariables>(
    UpdateCardSpendLimitDocument,
    options
  )
}
export type UpdateCardSpendLimitMutationHookResult = ReturnType<typeof useUpdateCardSpendLimitMutation>
export type UpdateCardSpendLimitMutationResult = Apollo.MutationResult<UpdateCardSpendLimitMutation>
export type UpdateCardSpendLimitMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardSpendLimitMutation,
  UpdateCardSpendLimitMutationVariables
>
export const UpdateSpendLimitPendingCardDocument = gql`
  mutation UpdateSpendLimitPendingCard($body: UpdateSpendLimitPendingCardInput!) {
    updateCardSpendLimit: updatePendingCardSpendLimit(body: $body) {
      id
      period: spendLimitPeriod
      amount: spendLimitAmount
    }
  }
`
export type UpdateSpendLimitPendingCardMutationFn = Apollo.MutationFunction<
  UpdateSpendLimitPendingCardMutation,
  UpdateSpendLimitPendingCardMutationVariables
>

/**
 * __useUpdateSpendLimitPendingCardMutation__
 *
 * To run a mutation, you first call `useUpdateSpendLimitPendingCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpendLimitPendingCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpendLimitPendingCardMutation, { data, loading, error }] = useUpdateSpendLimitPendingCardMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateSpendLimitPendingCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSpendLimitPendingCardMutation,
    UpdateSpendLimitPendingCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSpendLimitPendingCardMutation, UpdateSpendLimitPendingCardMutationVariables>(
    UpdateSpendLimitPendingCardDocument,
    options
  )
}
export type UpdateSpendLimitPendingCardMutationHookResult = ReturnType<typeof useUpdateSpendLimitPendingCardMutation>
export type UpdateSpendLimitPendingCardMutationResult = Apollo.MutationResult<UpdateSpendLimitPendingCardMutation>
export type UpdateSpendLimitPendingCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateSpendLimitPendingCardMutation,
  UpdateSpendLimitPendingCardMutationVariables
>
export const CreateConversionQuoteDocument = gql`
  mutation CreateConversionQuote($body: CreateConversionQuoteInput!) {
    createConversionQuote(body: $body) {
      id
      clientBuyCurrency
      clientSellCurrency
      adjustedClientRate
      adjustedBuyAmount
      adjustedSellAmount
      createdAt
      eligibleRedeemablePoints
      eligibleRedeemableAmount
      eligibleRedeemableCurrency
      noEligibleRewardsReason
      competitorsClientRate
    }
  }
`
export type CreateConversionQuoteMutationFn = Apollo.MutationFunction<
  CreateConversionQuoteMutation,
  CreateConversionQuoteMutationVariables
>

/**
 * __useCreateConversionQuoteMutation__
 *
 * To run a mutation, you first call `useCreateConversionQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversionQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversionQuoteMutation, { data, loading, error }] = useCreateConversionQuoteMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateConversionQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateConversionQuoteMutation, CreateConversionQuoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateConversionQuoteMutation, CreateConversionQuoteMutationVariables>(
    CreateConversionQuoteDocument,
    options
  )
}
export type CreateConversionQuoteMutationHookResult = ReturnType<typeof useCreateConversionQuoteMutation>
export type CreateConversionQuoteMutationResult = Apollo.MutationResult<CreateConversionQuoteMutation>
export type CreateConversionQuoteMutationOptions = Apollo.BaseMutationOptions<
  CreateConversionQuoteMutation,
  CreateConversionQuoteMutationVariables
>
export const CreateCurrencyConversionDocument = gql`
  mutation CreateCurrencyConversion($body: CreateConversionV2Input) {
    createCurrencyConversion(body: $body) {
      id
      businessId
      quoteLockId
      createdAt
      externalId
      buyAmount
      buyCurrency
      sellAmount
      sellCurrency
      clientRate
      netMarginAmount
      netMarginCurrency
      status
      settledAt
      completedAt
    }
  }
`
export type CreateCurrencyConversionMutationFn = Apollo.MutationFunction<
  CreateCurrencyConversionMutation,
  CreateCurrencyConversionMutationVariables
>

/**
 * __useCreateCurrencyConversionMutation__
 *
 * To run a mutation, you first call `useCreateCurrencyConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrencyConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrencyConversionMutation, { data, loading, error }] = useCreateCurrencyConversionMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateCurrencyConversionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCurrencyConversionMutation, CreateCurrencyConversionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCurrencyConversionMutation, CreateCurrencyConversionMutationVariables>(
    CreateCurrencyConversionDocument,
    options
  )
}
export type CreateCurrencyConversionMutationHookResult = ReturnType<typeof useCreateCurrencyConversionMutation>
export type CreateCurrencyConversionMutationResult = Apollo.MutationResult<CreateCurrencyConversionMutation>
export type CreateCurrencyConversionMutationOptions = Apollo.BaseMutationOptions<
  CreateCurrencyConversionMutation,
  CreateCurrencyConversionMutationVariables
>
export const AcceptAgreementsDocument = gql`
  mutation AcceptAgreements($body: AcceptAgreementsInput!) {
    acceptAgreements(body: $body)
  }
`
export type AcceptAgreementsMutationFn = Apollo.MutationFunction<
  AcceptAgreementsMutation,
  AcceptAgreementsMutationVariables
>

/**
 * __useAcceptAgreementsMutation__
 *
 * To run a mutation, you first call `useAcceptAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAgreementsMutation, { data, loading, error }] = useAcceptAgreementsMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAcceptAgreementsMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>(
    AcceptAgreementsDocument,
    options
  )
}
export type AcceptAgreementsMutationHookResult = ReturnType<typeof useAcceptAgreementsMutation>
export type AcceptAgreementsMutationResult = Apollo.MutationResult<AcceptAgreementsMutation>
export type AcceptAgreementsMutationOptions = Apollo.BaseMutationOptions<
  AcceptAgreementsMutation,
  AcceptAgreementsMutationVariables
>
export const RegisterUserDocument = gql`
  mutation RegisterUser($body: RegisterUserMutationInput!) {
    registerUser(body: $body)
  }
`
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options)
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>
export const AcceptEmployeeInvitationDocument = gql`
  mutation AcceptEmployeeInvitation($body: AcceptInvitationMutationInput!) {
    acceptInvitation(body: $body) {
      invitation {
        id
        status
        usedAt
        userId
        businessId
      }
    }
  }
`
export type AcceptEmployeeInvitationMutationFn = Apollo.MutationFunction<
  AcceptEmployeeInvitationMutation,
  AcceptEmployeeInvitationMutationVariables
>

/**
 * __useAcceptEmployeeInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptEmployeeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEmployeeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEmployeeInvitationMutation, { data, loading, error }] = useAcceptEmployeeInvitationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAcceptEmployeeInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptEmployeeInvitationMutation, AcceptEmployeeInvitationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptEmployeeInvitationMutation, AcceptEmployeeInvitationMutationVariables>(
    AcceptEmployeeInvitationDocument,
    options
  )
}
export type AcceptEmployeeInvitationMutationHookResult = ReturnType<typeof useAcceptEmployeeInvitationMutation>
export type AcceptEmployeeInvitationMutationResult = Apollo.MutationResult<AcceptEmployeeInvitationMutation>
export type AcceptEmployeeInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptEmployeeInvitationMutation,
  AcceptEmployeeInvitationMutationVariables
>
export const InviteUserDocument = gql`
  mutation InviteUser($body: InviteUserMutationInput!) {
    inviteUser(body: $body) {
      invitation {
        id
        status
        usedAt
        userId
        businessId
      }
    }
  }
`
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options)
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>
export const CreateAutoEnrollmentConfigDocument = gql`
  mutation CreateAutoEnrollmentConfig($repaymentInstallments: Int!, $enabled: Boolean!) {
    createAutoEnrollmentConfig(repaymentInstallments: $repaymentInstallments, enabled: $enabled) {
      id
      businessNameLegal
      businessId
      maxRepaymentAmount
      repaymentFrequency
      enable
      apr
      enabledAt
      autoEnrollmentConfig {
        id
        enabled
        lastToggledAt
        repaymentInstallments
        createdAt
        updatedAt
      }
    }
  }
`
export type CreateAutoEnrollmentConfigMutationFn = Apollo.MutationFunction<
  CreateAutoEnrollmentConfigMutation,
  CreateAutoEnrollmentConfigMutationVariables
>

/**
 * __useCreateAutoEnrollmentConfigMutation__
 *
 * To run a mutation, you first call `useCreateAutoEnrollmentConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoEnrollmentConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutoEnrollmentConfigMutation, { data, loading, error }] = useCreateAutoEnrollmentConfigMutation({
 *   variables: {
 *      repaymentInstallments: // value for 'repaymentInstallments'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useCreateAutoEnrollmentConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAutoEnrollmentConfigMutation,
    CreateAutoEnrollmentConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAutoEnrollmentConfigMutation, CreateAutoEnrollmentConfigMutationVariables>(
    CreateAutoEnrollmentConfigDocument,
    options
  )
}
export type CreateAutoEnrollmentConfigMutationHookResult = ReturnType<typeof useCreateAutoEnrollmentConfigMutation>
export type CreateAutoEnrollmentConfigMutationResult = Apollo.MutationResult<CreateAutoEnrollmentConfigMutation>
export type CreateAutoEnrollmentConfigMutationOptions = Apollo.BaseMutationOptions<
  CreateAutoEnrollmentConfigMutation,
  CreateAutoEnrollmentConfigMutationVariables
>
export const UploadBase64PdfDocument = gql`
  mutation UploadBase64Pdf($body: UploadBase64PdfInput!) {
    uploadBase64Pdf(body: $body) {
      id
      rejected {
        message
        filename
      }
      resolved {
        pdfHeronId
        requestId
      }
    }
  }
`
export type UploadBase64PdfMutationFn = Apollo.MutationFunction<
  UploadBase64PdfMutation,
  UploadBase64PdfMutationVariables
>

/**
 * __useUploadBase64PdfMutation__
 *
 * To run a mutation, you first call `useUploadBase64PdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBase64PdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBase64PdfMutation, { data, loading, error }] = useUploadBase64PdfMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUploadBase64PdfMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadBase64PdfMutation, UploadBase64PdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadBase64PdfMutation, UploadBase64PdfMutationVariables>(UploadBase64PdfDocument, options)
}
export type UploadBase64PdfMutationHookResult = ReturnType<typeof useUploadBase64PdfMutation>
export type UploadBase64PdfMutationResult = Apollo.MutationResult<UploadBase64PdfMutation>
export type UploadBase64PdfMutationOptions = Apollo.BaseMutationOptions<
  UploadBase64PdfMutation,
  UploadBase64PdfMutationVariables
>
export const CreateLinkedItemDocument = gql`
  mutation CreateLinkedItem {
    createLinkedItem {
      id
      userId
    }
  }
`
export type CreateLinkedItemMutationFn = Apollo.MutationFunction<
  CreateLinkedItemMutation,
  CreateLinkedItemMutationVariables
>

/**
 * __useCreateLinkedItemMutation__
 *
 * To run a mutation, you first call `useCreateLinkedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkedItemMutation, { data, loading, error }] = useCreateLinkedItemMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateLinkedItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLinkedItemMutation, CreateLinkedItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLinkedItemMutation, CreateLinkedItemMutationVariables>(
    CreateLinkedItemDocument,
    options
  )
}
export type CreateLinkedItemMutationHookResult = ReturnType<typeof useCreateLinkedItemMutation>
export type CreateLinkedItemMutationResult = Apollo.MutationResult<CreateLinkedItemMutation>
export type CreateLinkedItemMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkedItemMutation,
  CreateLinkedItemMutationVariables
>
export const UpdateAutoEnrollmentConfigDocument = gql`
  mutation UpdateAutoEnrollmentConfig($enabled: Boolean!) {
    updateAutoEnrollmentConfig(enabled: $enabled) {
      id
      businessNameLegal
      businessId
      maxRepaymentAmount
      repaymentFrequency
      enable
      apr
      enabledAt
      autoEnrollmentConfig {
        id
        enabled
        lastToggledAt
        repaymentInstallments
        createdAt
        updatedAt
      }
    }
  }
`
export type UpdateAutoEnrollmentConfigMutationFn = Apollo.MutationFunction<
  UpdateAutoEnrollmentConfigMutation,
  UpdateAutoEnrollmentConfigMutationVariables
>

/**
 * __useUpdateAutoEnrollmentConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAutoEnrollmentConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoEnrollmentConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoEnrollmentConfigMutation, { data, loading, error }] = useUpdateAutoEnrollmentConfigMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateAutoEnrollmentConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutoEnrollmentConfigMutation,
    UpdateAutoEnrollmentConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAutoEnrollmentConfigMutation, UpdateAutoEnrollmentConfigMutationVariables>(
    UpdateAutoEnrollmentConfigDocument,
    options
  )
}
export type UpdateAutoEnrollmentConfigMutationHookResult = ReturnType<typeof useUpdateAutoEnrollmentConfigMutation>
export type UpdateAutoEnrollmentConfigMutationResult = Apollo.MutationResult<UpdateAutoEnrollmentConfigMutation>
export type UpdateAutoEnrollmentConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateAutoEnrollmentConfigMutation,
  UpdateAutoEnrollmentConfigMutationVariables
>
export const SetCardShippingAddressDocument = gql`
  mutation SetCardShippingAddress($body: SetCardShippingAddressInput!) {
    setCardShippingAddress(body: $body) {
      id
      userId
      type
      status
    }
  }
`
export type SetCardShippingAddressMutationFn = Apollo.MutationFunction<
  SetCardShippingAddressMutation,
  SetCardShippingAddressMutationVariables
>

/**
 * __useSetCardShippingAddressMutation__
 *
 * To run a mutation, you first call `useSetCardShippingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCardShippingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCardShippingAddressMutation, { data, loading, error }] = useSetCardShippingAddressMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSetCardShippingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCardShippingAddressMutation, SetCardShippingAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetCardShippingAddressMutation, SetCardShippingAddressMutationVariables>(
    SetCardShippingAddressDocument,
    options
  )
}
export type SetCardShippingAddressMutationHookResult = ReturnType<typeof useSetCardShippingAddressMutation>
export type SetCardShippingAddressMutationResult = Apollo.MutationResult<SetCardShippingAddressMutation>
export type SetCardShippingAddressMutationOptions = Apollo.BaseMutationOptions<
  SetCardShippingAddressMutation,
  SetCardShippingAddressMutationVariables
>
export const ActivateCardDocument = gql`
  mutation ActivateCard($cardId: String!) {
    activateCard(id: $cardId) {
      id
    }
  }
`
export type ActivateCardMutationFn = Apollo.MutationFunction<ActivateCardMutation, ActivateCardMutationVariables>

/**
 * __useActivateCardMutation__
 *
 * To run a mutation, you first call `useActivateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardMutation, { data, loading, error }] = useActivateCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useActivateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateCardMutation, ActivateCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ActivateCardMutation, ActivateCardMutationVariables>(ActivateCardDocument, options)
}
export type ActivateCardMutationHookResult = ReturnType<typeof useActivateCardMutation>
export type ActivateCardMutationResult = Apollo.MutationResult<ActivateCardMutation>
export type ActivateCardMutationOptions = Apollo.BaseMutationOptions<
  ActivateCardMutation,
  ActivateCardMutationVariables
>
export const SaveFlinksLoginDocument = gql`
  mutation SaveFlinksLogin($body: SaveFlinksLoginInput!) {
    saveFlinksLogin(body: $body) {
      id
    }
  }
`
export type SaveFlinksLoginMutationFn = Apollo.MutationFunction<
  SaveFlinksLoginMutation,
  SaveFlinksLoginMutationVariables
>

/**
 * __useSaveFlinksLoginMutation__
 *
 * To run a mutation, you first call `useSaveFlinksLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFlinksLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFlinksLoginMutation, { data, loading, error }] = useSaveFlinksLoginMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSaveFlinksLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveFlinksLoginMutation, SaveFlinksLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveFlinksLoginMutation, SaveFlinksLoginMutationVariables>(SaveFlinksLoginDocument, options)
}
export type SaveFlinksLoginMutationHookResult = ReturnType<typeof useSaveFlinksLoginMutation>
export type SaveFlinksLoginMutationResult = Apollo.MutationResult<SaveFlinksLoginMutation>
export type SaveFlinksLoginMutationOptions = Apollo.BaseMutationOptions<
  SaveFlinksLoginMutation,
  SaveFlinksLoginMutationVariables
>
export const FlinksConnectionTakingTooLongDocument = gql`
  mutation FlinksConnectionTakingTooLong($body: flinksConnectionTakingTooLongInput!) {
    flinksConnectionTakingTooLong(body: $body)
  }
`
export type FlinksConnectionTakingTooLongMutationFn = Apollo.MutationFunction<
  FlinksConnectionTakingTooLongMutation,
  FlinksConnectionTakingTooLongMutationVariables
>

/**
 * __useFlinksConnectionTakingTooLongMutation__
 *
 * To run a mutation, you first call `useFlinksConnectionTakingTooLongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlinksConnectionTakingTooLongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flinksConnectionTakingTooLongMutation, { data, loading, error }] = useFlinksConnectionTakingTooLongMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useFlinksConnectionTakingTooLongMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlinksConnectionTakingTooLongMutation,
    FlinksConnectionTakingTooLongMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FlinksConnectionTakingTooLongMutation, FlinksConnectionTakingTooLongMutationVariables>(
    FlinksConnectionTakingTooLongDocument,
    options
  )
}
export type FlinksConnectionTakingTooLongMutationHookResult = ReturnType<
  typeof useFlinksConnectionTakingTooLongMutation
>
export type FlinksConnectionTakingTooLongMutationResult = Apollo.MutationResult<FlinksConnectionTakingTooLongMutation>
export type FlinksConnectionTakingTooLongMutationOptions = Apollo.BaseMutationOptions<
  FlinksConnectionTakingTooLongMutation,
  FlinksConnectionTakingTooLongMutationVariables
>
export const SendRecoveryEmailDocument = gql`
  mutation SendRecoveryEmail($body: SendRecoveryEmailInput!) {
    sendRecoveryEmail(body: $body)
  }
`
export type SendRecoveryEmailMutationFn = Apollo.MutationFunction<
  SendRecoveryEmailMutation,
  SendRecoveryEmailMutationVariables
>

/**
 * __useSendRecoveryEmailMutation__
 *
 * To run a mutation, you first call `useSendRecoveryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRecoveryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRecoveryEmailMutation, { data, loading, error }] = useSendRecoveryEmailMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendRecoveryEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendRecoveryEmailMutation, SendRecoveryEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendRecoveryEmailMutation, SendRecoveryEmailMutationVariables>(
    SendRecoveryEmailDocument,
    options
  )
}
export type SendRecoveryEmailMutationHookResult = ReturnType<typeof useSendRecoveryEmailMutation>
export type SendRecoveryEmailMutationResult = Apollo.MutationResult<SendRecoveryEmailMutation>
export type SendRecoveryEmailMutationOptions = Apollo.BaseMutationOptions<
  SendRecoveryEmailMutation,
  SendRecoveryEmailMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($body: ResetPasswordMutationInput!) {
    resetPassword(body: $body)
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const SendPhoneVerificationCodeDocument = gql`
  mutation SendPhoneVerificationCode {
    sendPhoneVerificationCode
  }
`
export type SendPhoneVerificationCodeMutationFn = Apollo.MutationFunction<
  SendPhoneVerificationCodeMutation,
  SendPhoneVerificationCodeMutationVariables
>

/**
 * __useSendPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneVerificationCodeMutation, { data, loading, error }] = useSendPhoneVerificationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendPhoneVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendPhoneVerificationCodeMutation, SendPhoneVerificationCodeMutationVariables>(
    SendPhoneVerificationCodeDocument,
    options
  )
}
export type SendPhoneVerificationCodeMutationHookResult = ReturnType<typeof useSendPhoneVerificationCodeMutation>
export type SendPhoneVerificationCodeMutationResult = Apollo.MutationResult<SendPhoneVerificationCodeMutation>
export type SendPhoneVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  SendPhoneVerificationCodeMutation,
  SendPhoneVerificationCodeMutationVariables
>
export const VerifyPhoneDocument = gql`
  mutation VerifyPhone($body: VerifyPhoneInput!) {
    verifyPhone(body: $body)
  }
`
export type VerifyPhoneMutationFn = Apollo.MutationFunction<VerifyPhoneMutation, VerifyPhoneMutationVariables>

/**
 * __useVerifyPhoneMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneMutation, { data, loading, error }] = useVerifyPhoneMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useVerifyPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyPhoneMutation, VerifyPhoneMutationVariables>(VerifyPhoneDocument, options)
}
export type VerifyPhoneMutationHookResult = ReturnType<typeof useVerifyPhoneMutation>
export type VerifyPhoneMutationResult = Apollo.MutationResult<VerifyPhoneMutation>
export type VerifyPhoneMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>
export const MoveForwardDocument = gql`
  mutation MoveForward($body: ApplicationMovementInput!) {
    moveForward(body: $body) {
      newStep
      application {
        ...ApplicationV2Fragment
      }
    }
  }
  ${ApplicationV2FragmentFragmentDoc}
`
export type MoveForwardMutationFn = Apollo.MutationFunction<MoveForwardMutation, MoveForwardMutationVariables>

/**
 * __useMoveForwardMutation__
 *
 * To run a mutation, you first call `useMoveForwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveForwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveForwardMutation, { data, loading, error }] = useMoveForwardMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useMoveForwardMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveForwardMutation, MoveForwardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveForwardMutation, MoveForwardMutationVariables>(MoveForwardDocument, options)
}
export type MoveForwardMutationHookResult = ReturnType<typeof useMoveForwardMutation>
export type MoveForwardMutationResult = Apollo.MutationResult<MoveForwardMutation>
export type MoveForwardMutationOptions = Apollo.BaseMutationOptions<MoveForwardMutation, MoveForwardMutationVariables>
export const MoveBackwardDocument = gql`
  mutation MoveBackward($body: ApplicationMovementInput!) {
    moveBackward(body: $body) {
      newStep
      application {
        ...ApplicationV2Fragment
      }
    }
  }
  ${ApplicationV2FragmentFragmentDoc}
`
export type MoveBackwardMutationFn = Apollo.MutationFunction<MoveBackwardMutation, MoveBackwardMutationVariables>

/**
 * __useMoveBackwardMutation__
 *
 * To run a mutation, you first call `useMoveBackwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveBackwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveBackwardMutation, { data, loading, error }] = useMoveBackwardMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useMoveBackwardMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveBackwardMutation, MoveBackwardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveBackwardMutation, MoveBackwardMutationVariables>(MoveBackwardDocument, options)
}
export type MoveBackwardMutationHookResult = ReturnType<typeof useMoveBackwardMutation>
export type MoveBackwardMutationResult = Apollo.MutationResult<MoveBackwardMutation>
export type MoveBackwardMutationOptions = Apollo.BaseMutationOptions<
  MoveBackwardMutation,
  MoveBackwardMutationVariables
>
export const MoveToStepDocument = gql`
  mutation MoveToStep($body: ApplicationMovementInput!) {
    moveToStep(body: $body) {
      newStep
      application {
        ...ApplicationV2Fragment
      }
    }
  }
  ${ApplicationV2FragmentFragmentDoc}
`
export type MoveToStepMutationFn = Apollo.MutationFunction<MoveToStepMutation, MoveToStepMutationVariables>

/**
 * __useMoveToStepMutation__
 *
 * To run a mutation, you first call `useMoveToStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToStepMutation, { data, loading, error }] = useMoveToStepMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useMoveToStepMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveToStepMutation, MoveToStepMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveToStepMutation, MoveToStepMutationVariables>(MoveToStepDocument, options)
}
export type MoveToStepMutationHookResult = ReturnType<typeof useMoveToStepMutation>
export type MoveToStepMutationResult = Apollo.MutationResult<MoveToStepMutation>
export type MoveToStepMutationOptions = Apollo.BaseMutationOptions<MoveToStepMutation, MoveToStepMutationVariables>
export const DeleteBeneficiaryDocument = gql`
  mutation DeleteBeneficiary($body: DeleteBeneficiaryInput!) {
    deleteBeneficiary(body: $body)
  }
`
export type DeleteBeneficiaryMutationFn = Apollo.MutationFunction<
  DeleteBeneficiaryMutation,
  DeleteBeneficiaryMutationVariables
>

/**
 * __useDeleteBeneficiaryMutation__
 *
 * To run a mutation, you first call `useDeleteBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBeneficiaryMutation, { data, loading, error }] = useDeleteBeneficiaryMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useDeleteBeneficiaryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBeneficiaryMutation, DeleteBeneficiaryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBeneficiaryMutation, DeleteBeneficiaryMutationVariables>(
    DeleteBeneficiaryDocument,
    options
  )
}
export type DeleteBeneficiaryMutationHookResult = ReturnType<typeof useDeleteBeneficiaryMutation>
export type DeleteBeneficiaryMutationResult = Apollo.MutationResult<DeleteBeneficiaryMutation>
export type DeleteBeneficiaryMutationOptions = Apollo.BaseMutationOptions<
  DeleteBeneficiaryMutation,
  DeleteBeneficiaryMutationVariables
>
export const TrackEventDocument = gql`
  mutation TrackEvent($event: String!, $team: String!, $payload: JSON!) {
    trackEvent(event: $event, team: $team, payload: $payload) {
      event
    }
  }
`
export type TrackEventMutationFn = Apollo.MutationFunction<TrackEventMutation, TrackEventMutationVariables>

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      team: // value for 'team'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTrackEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TrackEventMutation, TrackEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(TrackEventDocument, options)
}
export type TrackEventMutationHookResult = ReturnType<typeof useTrackEventMutation>
export type TrackEventMutationResult = Apollo.MutationResult<TrackEventMutation>
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<TrackEventMutation, TrackEventMutationVariables>
export const CompleteInformationDocument = gql`
  mutation CompleteInformation($body: CompleteInformationInput!) {
    completeInformation(body: $body)
  }
`
export type CompleteInformationMutationFn = Apollo.MutationFunction<
  CompleteInformationMutation,
  CompleteInformationMutationVariables
>

/**
 * __useCompleteInformationMutation__
 *
 * To run a mutation, you first call `useCompleteInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInformationMutation, { data, loading, error }] = useCompleteInformationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCompleteInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteInformationMutation, CompleteInformationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteInformationMutation, CompleteInformationMutationVariables>(
    CompleteInformationDocument,
    options
  )
}
export type CompleteInformationMutationHookResult = ReturnType<typeof useCompleteInformationMutation>
export type CompleteInformationMutationResult = Apollo.MutationResult<CompleteInformationMutation>
export type CompleteInformationMutationOptions = Apollo.BaseMutationOptions<
  CompleteInformationMutation,
  CompleteInformationMutationVariables
>
export const UpdateApplicationV2Document = gql`
  mutation UpdateApplicationV2($body: ApplicationUpdateInput!) {
    updateApplicationV2(body: $body) {
      ...ApplicationV2Fragment
    }
  }
  ${ApplicationV2FragmentFragmentDoc}
`
export type UpdateApplicationV2MutationFn = Apollo.MutationFunction<
  UpdateApplicationV2Mutation,
  UpdateApplicationV2MutationVariables
>

/**
 * __useUpdateApplicationV2Mutation__
 *
 * To run a mutation, you first call `useUpdateApplicationV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationV2Mutation, { data, loading, error }] = useUpdateApplicationV2Mutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateApplicationV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApplicationV2Mutation, UpdateApplicationV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApplicationV2Mutation, UpdateApplicationV2MutationVariables>(
    UpdateApplicationV2Document,
    options
  )
}
export type UpdateApplicationV2MutationHookResult = ReturnType<typeof useUpdateApplicationV2Mutation>
export type UpdateApplicationV2MutationResult = Apollo.MutationResult<UpdateApplicationV2Mutation>
export type UpdateApplicationV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationV2Mutation,
  UpdateApplicationV2MutationVariables
>
export const GenerateTruliooShortCodeDocument = gql`
  mutation GenerateTruliooShortCode {
    generateTruliooShortCode {
      shortCode
    }
  }
`
export type GenerateTruliooShortCodeMutationFn = Apollo.MutationFunction<
  GenerateTruliooShortCodeMutation,
  GenerateTruliooShortCodeMutationVariables
>

/**
 * __useGenerateTruliooShortCodeMutation__
 *
 * To run a mutation, you first call `useGenerateTruliooShortCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTruliooShortCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTruliooShortCodeMutation, { data, loading, error }] = useGenerateTruliooShortCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateTruliooShortCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateTruliooShortCodeMutation, GenerateTruliooShortCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateTruliooShortCodeMutation, GenerateTruliooShortCodeMutationVariables>(
    GenerateTruliooShortCodeDocument,
    options
  )
}
export type GenerateTruliooShortCodeMutationHookResult = ReturnType<typeof useGenerateTruliooShortCodeMutation>
export type GenerateTruliooShortCodeMutationResult = Apollo.MutationResult<GenerateTruliooShortCodeMutation>
export type GenerateTruliooShortCodeMutationOptions = Apollo.BaseMutationOptions<
  GenerateTruliooShortCodeMutation,
  GenerateTruliooShortCodeMutationVariables
>
export const ValidateLastFourDocument = gql`
  mutation ValidateLastFour($cardId: String!, $lastFour: String!) {
    validateLastFour(cardId: $cardId, lastFour: $lastFour)
  }
`
export type ValidateLastFourMutationFn = Apollo.MutationFunction<
  ValidateLastFourMutation,
  ValidateLastFourMutationVariables
>

/**
 * __useValidateLastFourMutation__
 *
 * To run a mutation, you first call `useValidateLastFourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateLastFourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateLastFourMutation, { data, loading, error }] = useValidateLastFourMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      lastFour: // value for 'lastFour'
 *   },
 * });
 */
export function useValidateLastFourMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateLastFourMutation, ValidateLastFourMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ValidateLastFourMutation, ValidateLastFourMutationVariables>(
    ValidateLastFourDocument,
    options
  )
}
export type ValidateLastFourMutationHookResult = ReturnType<typeof useValidateLastFourMutation>
export type ValidateLastFourMutationResult = Apollo.MutationResult<ValidateLastFourMutation>
export type ValidateLastFourMutationOptions = Apollo.BaseMutationOptions<
  ValidateLastFourMutation,
  ValidateLastFourMutationVariables
>
export const VerifyAccountDocument = gql`
  mutation VerifyAccount($body: VerifyAccountMutationInput!) {
    verifyAccount(body: $body)
  }
`
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useVerifyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options)
}
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>
export const SendVerificationEmailDocument = gql`
  mutation SendVerificationEmail {
    sendVerificationEmail
  }
`
export type SendVerificationEmailMutationFn = Apollo.MutationFunction<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>(
    SendVerificationEmailDocument,
    options
  )
}
export type SendVerificationEmailMutationHookResult = ReturnType<typeof useSendVerificationEmailMutation>
export type SendVerificationEmailMutationResult = Apollo.MutationResult<SendVerificationEmailMutation>
export type SendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>
export const GenerateSupportChatTokenDocument = gql`
  mutation GenerateSupportChatToken {
    generateSupportChatToken
  }
`
export type GenerateSupportChatTokenMutationFn = Apollo.MutationFunction<
  GenerateSupportChatTokenMutation,
  GenerateSupportChatTokenMutationVariables
>

/**
 * __useGenerateSupportChatTokenMutation__
 *
 * To run a mutation, you first call `useGenerateSupportChatTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSupportChatTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSupportChatTokenMutation, { data, loading, error }] = useGenerateSupportChatTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSupportChatTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateSupportChatTokenMutation, GenerateSupportChatTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateSupportChatTokenMutation, GenerateSupportChatTokenMutationVariables>(
    GenerateSupportChatTokenDocument,
    options
  )
}
export type GenerateSupportChatTokenMutationHookResult = ReturnType<typeof useGenerateSupportChatTokenMutation>
export type GenerateSupportChatTokenMutationResult = Apollo.MutationResult<GenerateSupportChatTokenMutation>
export type GenerateSupportChatTokenMutationOptions = Apollo.BaseMutationOptions<
  GenerateSupportChatTokenMutation,
  GenerateSupportChatTokenMutationVariables
>
export const CreateLoanDocument = gql`
  mutation CreateLoan($body: CreateLoanInput!) {
    createLoan(body: $body) {
      id
      loanNumber
      businessId
      status
      repaymentFrequency
      principalAmount
      totalFee
      termDurationInMonths
      apr
      totalRepayment
      totalRefinanced
      outstandingBalance
      agreementSignedDate
      firstRepaymentDate
      depositedOn
      reason
      financingChannel
      preDiscountTotalFee
      feeDiscountRate
    }
  }
`
export type CreateLoanMutationFn = Apollo.MutationFunction<CreateLoanMutation, CreateLoanMutationVariables>

/**
 * __useCreateLoanMutation__
 *
 * To run a mutation, you first call `useCreateLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoanMutation, { data, loading, error }] = useCreateLoanMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLoanMutation, CreateLoanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLoanMutation, CreateLoanMutationVariables>(CreateLoanDocument, options)
}
export type CreateLoanMutationHookResult = ReturnType<typeof useCreateLoanMutation>
export type CreateLoanMutationResult = Apollo.MutationResult<CreateLoanMutation>
export type CreateLoanMutationOptions = Apollo.BaseMutationOptions<CreateLoanMutation, CreateLoanMutationVariables>
export const CreateCapitalFundingDocument = gql`
  mutation CreateCapitalFunding($body: createCapitalFundingInput!) {
    createCapitalFunding(body: $body) {
      businessId
      id
      originationFee
      principalAmount
      repaymentFrequency
      termDurationInMonths
    }
  }
`
export type CreateCapitalFundingMutationFn = Apollo.MutationFunction<
  CreateCapitalFundingMutation,
  CreateCapitalFundingMutationVariables
>

/**
 * __useCreateCapitalFundingMutation__
 *
 * To run a mutation, you first call `useCreateCapitalFundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCapitalFundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCapitalFundingMutation, { data, loading, error }] = useCreateCapitalFundingMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateCapitalFundingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCapitalFundingMutation, CreateCapitalFundingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCapitalFundingMutation, CreateCapitalFundingMutationVariables>(
    CreateCapitalFundingDocument,
    options
  )
}
export type CreateCapitalFundingMutationHookResult = ReturnType<typeof useCreateCapitalFundingMutation>
export type CreateCapitalFundingMutationResult = Apollo.MutationResult<CreateCapitalFundingMutation>
export type CreateCapitalFundingMutationOptions = Apollo.BaseMutationOptions<
  CreateCapitalFundingMutation,
  CreateCapitalFundingMutationVariables
>
export const SubmitApplicationDocument = gql`
  mutation SubmitApplication($body: SubmitApplicationInput!) {
    submitApplication(body: $body) {
      id
    }
  }
`
export type SubmitApplicationMutationFn = Apollo.MutationFunction<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>

/**
 * __useSubmitApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApplicationMutation, { data, loading, error }] = useSubmitApplicationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSubmitApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(
    SubmitApplicationDocument,
    options
  )
}
export type SubmitApplicationMutationHookResult = ReturnType<typeof useSubmitApplicationMutation>
export type SubmitApplicationMutationResult = Apollo.MutationResult<SubmitApplicationMutation>
export type SubmitApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables
>
export const ChangePasswordDocument = gql`
  mutation ChangePassword($body: ChangePasswordMutationInput!) {
    changePassword(body: $body)
  }
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options)
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const CreateDisputeDocument = gql`
  mutation CreateDispute($body: CreateDisputeInput!) {
    createDispute(body: $body) {
      id
    }
  }
`
export type CreateDisputeMutationFn = Apollo.MutationFunction<CreateDisputeMutation, CreateDisputeMutationVariables>

/**
 * __useCreateDisputeMutation__
 *
 * To run a mutation, you first call `useCreateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeMutation, { data, loading, error }] = useCreateDisputeMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDisputeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDisputeMutation, CreateDisputeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDisputeMutation, CreateDisputeMutationVariables>(CreateDisputeDocument, options)
}
export type CreateDisputeMutationHookResult = ReturnType<typeof useCreateDisputeMutation>
export type CreateDisputeMutationResult = Apollo.MutationResult<CreateDisputeMutation>
export type CreateDisputeMutationOptions = Apollo.BaseMutationOptions<
  CreateDisputeMutation,
  CreateDisputeMutationVariables
>
export const SendTruliooIdvLinkDocument = gql`
  mutation SendTruliooIdvLink {
    sendTruliooIdvLink
  }
`
export type SendTruliooIdvLinkMutationFn = Apollo.MutationFunction<
  SendTruliooIdvLinkMutation,
  SendTruliooIdvLinkMutationVariables
>

/**
 * __useSendTruliooIdvLinkMutation__
 *
 * To run a mutation, you first call `useSendTruliooIdvLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTruliooIdvLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTruliooIdvLinkMutation, { data, loading, error }] = useSendTruliooIdvLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendTruliooIdvLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<SendTruliooIdvLinkMutation, SendTruliooIdvLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendTruliooIdvLinkMutation, SendTruliooIdvLinkMutationVariables>(
    SendTruliooIdvLinkDocument,
    options
  )
}
export type SendTruliooIdvLinkMutationHookResult = ReturnType<typeof useSendTruliooIdvLinkMutation>
export type SendTruliooIdvLinkMutationResult = Apollo.MutationResult<SendTruliooIdvLinkMutation>
export type SendTruliooIdvLinkMutationOptions = Apollo.BaseMutationOptions<
  SendTruliooIdvLinkMutation,
  SendTruliooIdvLinkMutationVariables
>
export const UpdateStatementExtensionPlanTermDocument = gql`
  mutation UpdateStatementExtensionPlanTerm($repaymentInstallments: Int!) {
    updateStatementExtensionPlanTerm(repaymentInstallments: $repaymentInstallments) {
      id
      repayments {
        repayment
        formatted
      }
      autoEnrollmentConfig {
        id
        enabled
        lastToggledAt
        repaymentInstallments
        createdAt
        updatedAt
      }
    }
  }
`
export type UpdateStatementExtensionPlanTermMutationFn = Apollo.MutationFunction<
  UpdateStatementExtensionPlanTermMutation,
  UpdateStatementExtensionPlanTermMutationVariables
>

/**
 * __useUpdateStatementExtensionPlanTermMutation__
 *
 * To run a mutation, you first call `useUpdateStatementExtensionPlanTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatementExtensionPlanTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatementExtensionPlanTermMutation, { data, loading, error }] = useUpdateStatementExtensionPlanTermMutation({
 *   variables: {
 *      repaymentInstallments: // value for 'repaymentInstallments'
 *   },
 * });
 */
export function useUpdateStatementExtensionPlanTermMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStatementExtensionPlanTermMutation,
    UpdateStatementExtensionPlanTermMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateStatementExtensionPlanTermMutation,
    UpdateStatementExtensionPlanTermMutationVariables
  >(UpdateStatementExtensionPlanTermDocument, options)
}
export type UpdateStatementExtensionPlanTermMutationHookResult = ReturnType<
  typeof useUpdateStatementExtensionPlanTermMutation
>
export type UpdateStatementExtensionPlanTermMutationResult =
  Apollo.MutationResult<UpdateStatementExtensionPlanTermMutation>
export type UpdateStatementExtensionPlanTermMutationOptions = Apollo.BaseMutationOptions<
  UpdateStatementExtensionPlanTermMutation,
  UpdateStatementExtensionPlanTermMutationVariables
>
export const DeactivateEmployeeDocument = gql`
  mutation DeactivateEmployee($employeeId: String!) {
    deactivateEmployee(employeeId: $employeeId) {
      id
      status
    }
  }
`
export type DeactivateEmployeeMutationFn = Apollo.MutationFunction<
  DeactivateEmployeeMutation,
  DeactivateEmployeeMutationVariables
>

/**
 * __useDeactivateEmployeeMutation__
 *
 * To run a mutation, you first call `useDeactivateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateEmployeeMutation, { data, loading, error }] = useDeactivateEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useDeactivateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateEmployeeMutation, DeactivateEmployeeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeactivateEmployeeMutation, DeactivateEmployeeMutationVariables>(
    DeactivateEmployeeDocument,
    options
  )
}
export type DeactivateEmployeeMutationHookResult = ReturnType<typeof useDeactivateEmployeeMutation>
export type DeactivateEmployeeMutationResult = Apollo.MutationResult<DeactivateEmployeeMutation>
export type DeactivateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  DeactivateEmployeeMutation,
  DeactivateEmployeeMutationVariables
>
export const RequestCurrencyCloudAccessDocument = gql`
  mutation RequestCurrencyCloudAccess($body: RequestCurrencyCloudAccessInput!) {
    requestCurrencyCloudAccess(body: $body) {
      ccAccessStatus
    }
  }
`
export type RequestCurrencyCloudAccessMutationFn = Apollo.MutationFunction<
  RequestCurrencyCloudAccessMutation,
  RequestCurrencyCloudAccessMutationVariables
>

/**
 * __useRequestCurrencyCloudAccessMutation__
 *
 * To run a mutation, you first call `useRequestCurrencyCloudAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCurrencyCloudAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCurrencyCloudAccessMutation, { data, loading, error }] = useRequestCurrencyCloudAccessMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRequestCurrencyCloudAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCurrencyCloudAccessMutation,
    RequestCurrencyCloudAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RequestCurrencyCloudAccessMutation, RequestCurrencyCloudAccessMutationVariables>(
    RequestCurrencyCloudAccessDocument,
    options
  )
}
export type RequestCurrencyCloudAccessMutationHookResult = ReturnType<typeof useRequestCurrencyCloudAccessMutation>
export type RequestCurrencyCloudAccessMutationResult = Apollo.MutationResult<RequestCurrencyCloudAccessMutation>
export type RequestCurrencyCloudAccessMutationOptions = Apollo.BaseMutationOptions<
  RequestCurrencyCloudAccessMutation,
  RequestCurrencyCloudAccessMutationVariables
>
export const CreateQuoteForMultiCurrencyPaymentDocument = gql`
  mutation CreateQuoteForMultiCurrencyPayment($body: MultiCurrencyPaymentQuoteInput!) {
    createQuoteForMultiCurrencyPayment(body: $body) {
      quote {
        id
        clientRate
        createdAt
      }
      feesAndRewards {
        sellAmount {
          amountAsString
          amountAsNumber
          currency
        }
        internationalBankingFee {
          amountAsString
          amountAsNumber
          currency
        }
        convertingAmount {
          amountAsString
          amountAsNumber
          currency
        }
        exchangeRate {
          exchangeRate
          competitorsExchangeRate
        }
        savingsAmount {
          amountAsString
          amountAsNumber
          currency
        }
        finalAmount {
          amountAsString
          amountAsNumber
          currency
        }
        balanceAfterwards {
          amountAsString
          amountAsNumber
          currency
        }
        rewards {
          earnedRewardsAmount {
            amountAsString
            amountAsNumber
            currency
          }
          previousRewardsBalance {
            amountAsString
            amountAsNumber
            currency
          }
          afterTransactionRewardsTotalPoints {
            amountAsString
            amountAsNumber
            currency
          }
          earnedRewardsAsPoints {
            amountAsString
            amountAsNumber
            currency
          }
        }
      }
    }
  }
`
export type CreateQuoteForMultiCurrencyPaymentMutationFn = Apollo.MutationFunction<
  CreateQuoteForMultiCurrencyPaymentMutation,
  CreateQuoteForMultiCurrencyPaymentMutationVariables
>

/**
 * __useCreateQuoteForMultiCurrencyPaymentMutation__
 *
 * To run a mutation, you first call `useCreateQuoteForMultiCurrencyPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteForMultiCurrencyPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteForMultiCurrencyPaymentMutation, { data, loading, error }] = useCreateQuoteForMultiCurrencyPaymentMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateQuoteForMultiCurrencyPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuoteForMultiCurrencyPaymentMutation,
    CreateQuoteForMultiCurrencyPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateQuoteForMultiCurrencyPaymentMutation,
    CreateQuoteForMultiCurrencyPaymentMutationVariables
  >(CreateQuoteForMultiCurrencyPaymentDocument, options)
}
export type CreateQuoteForMultiCurrencyPaymentMutationHookResult = ReturnType<
  typeof useCreateQuoteForMultiCurrencyPaymentMutation
>
export type CreateQuoteForMultiCurrencyPaymentMutationResult =
  Apollo.MutationResult<CreateQuoteForMultiCurrencyPaymentMutation>
export type CreateQuoteForMultiCurrencyPaymentMutationOptions = Apollo.BaseMutationOptions<
  CreateQuoteForMultiCurrencyPaymentMutation,
  CreateQuoteForMultiCurrencyPaymentMutationVariables
>
export const DisconnectAccountingIntegrationDocument = gql`
  mutation DisconnectAccountingIntegration($accountingIntegrationId: String!) {
    disconnectAccountingIntegration(accountingIntegrationId: $accountingIntegrationId) {
      id
      status
    }
  }
`
export type DisconnectAccountingIntegrationMutationFn = Apollo.MutationFunction<
  DisconnectAccountingIntegrationMutation,
  DisconnectAccountingIntegrationMutationVariables
>

/**
 * __useDisconnectAccountingIntegrationMutation__
 *
 * To run a mutation, you first call `useDisconnectAccountingIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectAccountingIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectAccountingIntegrationMutation, { data, loading, error }] = useDisconnectAccountingIntegrationMutation({
 *   variables: {
 *      accountingIntegrationId: // value for 'accountingIntegrationId'
 *   },
 * });
 */
export function useDisconnectAccountingIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectAccountingIntegrationMutation,
    DisconnectAccountingIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DisconnectAccountingIntegrationMutation, DisconnectAccountingIntegrationMutationVariables>(
    DisconnectAccountingIntegrationDocument,
    options
  )
}
export type DisconnectAccountingIntegrationMutationHookResult = ReturnType<
  typeof useDisconnectAccountingIntegrationMutation
>
export type DisconnectAccountingIntegrationMutationResult =
  Apollo.MutationResult<DisconnectAccountingIntegrationMutation>
export type DisconnectAccountingIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DisconnectAccountingIntegrationMutation,
  DisconnectAccountingIntegrationMutationVariables
>
export const UpdateExpensesDocument = gql`
  mutation UpdateExpenses($body: UpdateExpensesInput!) {
    updateExpenses(body: $body) {
      updatedExpenses {
        id
        status
      }
      errors {
        expenseId
        message
      }
    }
  }
`
export type UpdateExpensesMutationFn = Apollo.MutationFunction<UpdateExpensesMutation, UpdateExpensesMutationVariables>

/**
 * __useUpdateExpensesMutation__
 *
 * To run a mutation, you first call `useUpdateExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpensesMutation, { data, loading, error }] = useUpdateExpensesMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateExpensesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExpensesMutation, UpdateExpensesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateExpensesMutation, UpdateExpensesMutationVariables>(UpdateExpensesDocument, options)
}
export type UpdateExpensesMutationHookResult = ReturnType<typeof useUpdateExpensesMutation>
export type UpdateExpensesMutationResult = Apollo.MutationResult<UpdateExpensesMutation>
export type UpdateExpensesMutationOptions = Apollo.BaseMutationOptions<
  UpdateExpensesMutation,
  UpdateExpensesMutationVariables
>
export const AddReceiptToExpenseDocument = gql`
  mutation AddReceiptToExpense($body: AddReceiptInput!) {
    addReceiptToExpense(body: $body) {
      id
      documentId
    }
  }
`
export type AddReceiptToExpenseMutationFn = Apollo.MutationFunction<
  AddReceiptToExpenseMutation,
  AddReceiptToExpenseMutationVariables
>

/**
 * __useAddReceiptToExpenseMutation__
 *
 * To run a mutation, you first call `useAddReceiptToExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReceiptToExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReceiptToExpenseMutation, { data, loading, error }] = useAddReceiptToExpenseMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddReceiptToExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<AddReceiptToExpenseMutation, AddReceiptToExpenseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddReceiptToExpenseMutation, AddReceiptToExpenseMutationVariables>(
    AddReceiptToExpenseDocument,
    options
  )
}
export type AddReceiptToExpenseMutationHookResult = ReturnType<typeof useAddReceiptToExpenseMutation>
export type AddReceiptToExpenseMutationResult = Apollo.MutationResult<AddReceiptToExpenseMutation>
export type AddReceiptToExpenseMutationOptions = Apollo.BaseMutationOptions<
  AddReceiptToExpenseMutation,
  AddReceiptToExpenseMutationVariables
>
export const RemoveReceiptFromExpenseDocument = gql`
  mutation RemoveReceiptFromExpense($body: RemoveReceiptInput!) {
    removeReceiptFromExpense(body: $body) {
      id
      receipt {
        id
      }
    }
  }
`
export type RemoveReceiptFromExpenseMutationFn = Apollo.MutationFunction<
  RemoveReceiptFromExpenseMutation,
  RemoveReceiptFromExpenseMutationVariables
>

/**
 * __useRemoveReceiptFromExpenseMutation__
 *
 * To run a mutation, you first call `useRemoveReceiptFromExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReceiptFromExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReceiptFromExpenseMutation, { data, loading, error }] = useRemoveReceiptFromExpenseMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRemoveReceiptFromExpenseMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveReceiptFromExpenseMutation, RemoveReceiptFromExpenseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveReceiptFromExpenseMutation, RemoveReceiptFromExpenseMutationVariables>(
    RemoveReceiptFromExpenseDocument,
    options
  )
}
export type RemoveReceiptFromExpenseMutationHookResult = ReturnType<typeof useRemoveReceiptFromExpenseMutation>
export type RemoveReceiptFromExpenseMutationResult = Apollo.MutationResult<RemoveReceiptFromExpenseMutation>
export type RemoveReceiptFromExpenseMutationOptions = Apollo.BaseMutationOptions<
  RemoveReceiptFromExpenseMutation,
  RemoveReceiptFromExpenseMutationVariables
>
export const UpsertMfaSettingDocument = gql`
  mutation UpsertMfaSetting($body: UpsertMFASettingInput!) {
    upsertMFASetting(body: $body) {
      id
      channel
      isEnabled
      isPrimary
      createdAt
      updatedAt
    }
  }
`
export type UpsertMfaSettingMutationFn = Apollo.MutationFunction<
  UpsertMfaSettingMutation,
  UpsertMfaSettingMutationVariables
>

/**
 * __useUpsertMfaSettingMutation__
 *
 * To run a mutation, you first call `useUpsertMfaSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMfaSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMfaSettingMutation, { data, loading, error }] = useUpsertMfaSettingMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpsertMfaSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertMfaSettingMutation, UpsertMfaSettingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertMfaSettingMutation, UpsertMfaSettingMutationVariables>(
    UpsertMfaSettingDocument,
    options
  )
}
export type UpsertMfaSettingMutationHookResult = ReturnType<typeof useUpsertMfaSettingMutation>
export type UpsertMfaSettingMutationResult = Apollo.MutationResult<UpsertMfaSettingMutation>
export type UpsertMfaSettingMutationOptions = Apollo.BaseMutationOptions<
  UpsertMfaSettingMutation,
  UpsertMfaSettingMutationVariables
>
export const StartMfaChallengeDocument = gql`
  mutation StartMFAChallenge($body: StartMFAChallengeInput!) {
    startMFAChallenge(body: $body)
  }
`
export type StartMfaChallengeMutationFn = Apollo.MutationFunction<
  StartMfaChallengeMutation,
  StartMfaChallengeMutationVariables
>

/**
 * __useStartMfaChallengeMutation__
 *
 * To run a mutation, you first call `useStartMfaChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMfaChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMfaChallengeMutation, { data, loading, error }] = useStartMfaChallengeMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useStartMfaChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<StartMfaChallengeMutation, StartMfaChallengeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartMfaChallengeMutation, StartMfaChallengeMutationVariables>(
    StartMfaChallengeDocument,
    options
  )
}
export type StartMfaChallengeMutationHookResult = ReturnType<typeof useStartMfaChallengeMutation>
export type StartMfaChallengeMutationResult = Apollo.MutationResult<StartMfaChallengeMutation>
export type StartMfaChallengeMutationOptions = Apollo.BaseMutationOptions<
  StartMfaChallengeMutation,
  StartMfaChallengeMutationVariables
>
export const VerifyMfaChallengeDocument = gql`
  mutation VerifyMFAChallenge($body: VerifyMFAChallengeInput!) {
    verifyMFAChallenge(body: $body)
  }
`
export type VerifyMfaChallengeMutationFn = Apollo.MutationFunction<
  VerifyMfaChallengeMutation,
  VerifyMfaChallengeMutationVariables
>

/**
 * __useVerifyMfaChallengeMutation__
 *
 * To run a mutation, you first call `useVerifyMfaChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMfaChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMfaChallengeMutation, { data, loading, error }] = useVerifyMfaChallengeMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useVerifyMfaChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyMfaChallengeMutation, VerifyMfaChallengeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyMfaChallengeMutation, VerifyMfaChallengeMutationVariables>(
    VerifyMfaChallengeDocument,
    options
  )
}
export type VerifyMfaChallengeMutationHookResult = ReturnType<typeof useVerifyMfaChallengeMutation>
export type VerifyMfaChallengeMutationResult = Apollo.MutationResult<VerifyMfaChallengeMutation>
export type VerifyMfaChallengeMutationOptions = Apollo.BaseMutationOptions<
  VerifyMfaChallengeMutation,
  VerifyMfaChallengeMutationVariables
>
export const SyncExpensesDocument = gql`
  mutation SyncExpenses($expensesIds: [String!]!) {
    syncExpenses(expensesIds: $expensesIds) {
      syncingExpenses
    }
  }
`
export type SyncExpensesMutationFn = Apollo.MutationFunction<SyncExpensesMutation, SyncExpensesMutationVariables>

/**
 * __useSyncExpensesMutation__
 *
 * To run a mutation, you first call `useSyncExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncExpensesMutation, { data, loading, error }] = useSyncExpensesMutation({
 *   variables: {
 *      expensesIds: // value for 'expensesIds'
 *   },
 * });
 */
export function useSyncExpensesMutation(
  baseOptions?: Apollo.MutationHookOptions<SyncExpensesMutation, SyncExpensesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncExpensesMutation, SyncExpensesMutationVariables>(SyncExpensesDocument, options)
}
export type SyncExpensesMutationHookResult = ReturnType<typeof useSyncExpensesMutation>
export type SyncExpensesMutationResult = Apollo.MutationResult<SyncExpensesMutation>
export type SyncExpensesMutationOptions = Apollo.BaseMutationOptions<
  SyncExpensesMutation,
  SyncExpensesMutationVariables
>
export const SendUpdatePhoneNumberOtpDocument = gql`
  mutation SendUpdatePhoneNumberOtp($body: SendUpdatePhoneNumberOtpInput!) {
    sendUpdatePhoneNumberOtp(body: $body)
  }
`
export type SendUpdatePhoneNumberOtpMutationFn = Apollo.MutationFunction<
  SendUpdatePhoneNumberOtpMutation,
  SendUpdatePhoneNumberOtpMutationVariables
>

/**
 * __useSendUpdatePhoneNumberOtpMutation__
 *
 * To run a mutation, you first call `useSendUpdatePhoneNumberOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUpdatePhoneNumberOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUpdatePhoneNumberOtpMutation, { data, loading, error }] = useSendUpdatePhoneNumberOtpMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendUpdatePhoneNumberOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<SendUpdatePhoneNumberOtpMutation, SendUpdatePhoneNumberOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendUpdatePhoneNumberOtpMutation, SendUpdatePhoneNumberOtpMutationVariables>(
    SendUpdatePhoneNumberOtpDocument,
    options
  )
}
export type SendUpdatePhoneNumberOtpMutationHookResult = ReturnType<typeof useSendUpdatePhoneNumberOtpMutation>
export type SendUpdatePhoneNumberOtpMutationResult = Apollo.MutationResult<SendUpdatePhoneNumberOtpMutation>
export type SendUpdatePhoneNumberOtpMutationOptions = Apollo.BaseMutationOptions<
  SendUpdatePhoneNumberOtpMutation,
  SendUpdatePhoneNumberOtpMutationVariables
>
export const UpdatePhoneNumberDocument = gql`
  mutation UpdatePhoneNumber($body: UpdatePhoneNumberInput!) {
    updatePhoneNumber(body: $body)
  }
`
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>(
    UpdatePhoneNumberDocument,
    options
  )
}
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>
export type UpdatePhoneNumberMutationResult = Apollo.MutationResult<UpdatePhoneNumberMutation>
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>
export const SendUpdateEmailOtpDocument = gql`
  mutation SendUpdateEmailOtp($body: SendUpdateEmailOtpInput!) {
    sendUpdateEmailOtp(body: $body)
  }
`
export type SendUpdateEmailOtpMutationFn = Apollo.MutationFunction<
  SendUpdateEmailOtpMutation,
  SendUpdateEmailOtpMutationVariables
>

/**
 * __useSendUpdateEmailOtpMutation__
 *
 * To run a mutation, you first call `useSendUpdateEmailOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUpdateEmailOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUpdateEmailOtpMutation, { data, loading, error }] = useSendUpdateEmailOtpMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendUpdateEmailOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<SendUpdateEmailOtpMutation, SendUpdateEmailOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendUpdateEmailOtpMutation, SendUpdateEmailOtpMutationVariables>(
    SendUpdateEmailOtpDocument,
    options
  )
}
export type SendUpdateEmailOtpMutationHookResult = ReturnType<typeof useSendUpdateEmailOtpMutation>
export type SendUpdateEmailOtpMutationResult = Apollo.MutationResult<SendUpdateEmailOtpMutation>
export type SendUpdateEmailOtpMutationOptions = Apollo.BaseMutationOptions<
  SendUpdateEmailOtpMutation,
  SendUpdateEmailOtpMutationVariables
>
export const UpdateEmailDocument = gql`
  mutation UpdateEmail($body: UpdateEmailInput!) {
    updateEmail(body: $body)
  }
`
export type UpdateEmailMutationFn = Apollo.MutationFunction<UpdateEmailMutation, UpdateEmailMutationVariables>

/**
 * __useUpdateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailMutation, { data, loading, error }] = useUpdateEmailMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailMutation, UpdateEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument, options)
}
export type UpdateEmailMutationHookResult = ReturnType<typeof useUpdateEmailMutation>
export type UpdateEmailMutationResult = Apollo.MutationResult<UpdateEmailMutation>
export type UpdateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateEmailMutation, UpdateEmailMutationVariables>
export const CreateCapitalRequestDocument = gql`
  mutation CreateCapitalRequest {
    createCapitalRequest {
      id
      businessId
    }
  }
`
export type CreateCapitalRequestMutationFn = Apollo.MutationFunction<
  CreateCapitalRequestMutation,
  CreateCapitalRequestMutationVariables
>

/**
 * __useCreateCapitalRequestMutation__
 *
 * To run a mutation, you first call `useCreateCapitalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCapitalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCapitalRequestMutation, { data, loading, error }] = useCreateCapitalRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCapitalRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCapitalRequestMutation, CreateCapitalRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCapitalRequestMutation, CreateCapitalRequestMutationVariables>(
    CreateCapitalRequestDocument,
    options
  )
}
export type CreateCapitalRequestMutationHookResult = ReturnType<typeof useCreateCapitalRequestMutation>
export type CreateCapitalRequestMutationResult = Apollo.MutationResult<CreateCapitalRequestMutation>
export type CreateCapitalRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateCapitalRequestMutation,
  CreateCapitalRequestMutationVariables
>
export const CreateSupportingDocumentsUploadUrlDocument = gql`
  mutation CreateSupportingDocumentsUploadUrl($body: CreateSupportingDocumentsUploadUrlInput!) {
    createSupportingDocumentsUploadUrl(body: $body) {
      url
      fields
    }
  }
`
export type CreateSupportingDocumentsUploadUrlMutationFn = Apollo.MutationFunction<
  CreateSupportingDocumentsUploadUrlMutation,
  CreateSupportingDocumentsUploadUrlMutationVariables
>

/**
 * __useCreateSupportingDocumentsUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateSupportingDocumentsUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportingDocumentsUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportingDocumentsUploadUrlMutation, { data, loading, error }] = useCreateSupportingDocumentsUploadUrlMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateSupportingDocumentsUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupportingDocumentsUploadUrlMutation,
    CreateSupportingDocumentsUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSupportingDocumentsUploadUrlMutation,
    CreateSupportingDocumentsUploadUrlMutationVariables
  >(CreateSupportingDocumentsUploadUrlDocument, options)
}
export type CreateSupportingDocumentsUploadUrlMutationHookResult = ReturnType<
  typeof useCreateSupportingDocumentsUploadUrlMutation
>
export type CreateSupportingDocumentsUploadUrlMutationResult =
  Apollo.MutationResult<CreateSupportingDocumentsUploadUrlMutation>
export type CreateSupportingDocumentsUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateSupportingDocumentsUploadUrlMutation,
  CreateSupportingDocumentsUploadUrlMutationVariables
>
export const CreateExtraDocumentsUploadUrlDocument = gql`
  mutation CreateExtraDocumentsUploadUrl($body: CreateExtraDocumentsUploadUrlInput!) {
    createExtraDocumentsUploadUrl(body: $body) {
      url
      fields
    }
  }
`
export type CreateExtraDocumentsUploadUrlMutationFn = Apollo.MutationFunction<
  CreateExtraDocumentsUploadUrlMutation,
  CreateExtraDocumentsUploadUrlMutationVariables
>

/**
 * __useCreateExtraDocumentsUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateExtraDocumentsUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtraDocumentsUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtraDocumentsUploadUrlMutation, { data, loading, error }] = useCreateExtraDocumentsUploadUrlMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateExtraDocumentsUploadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExtraDocumentsUploadUrlMutation,
    CreateExtraDocumentsUploadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateExtraDocumentsUploadUrlMutation, CreateExtraDocumentsUploadUrlMutationVariables>(
    CreateExtraDocumentsUploadUrlDocument,
    options
  )
}
export type CreateExtraDocumentsUploadUrlMutationHookResult = ReturnType<
  typeof useCreateExtraDocumentsUploadUrlMutation
>
export type CreateExtraDocumentsUploadUrlMutationResult = Apollo.MutationResult<CreateExtraDocumentsUploadUrlMutation>
export type CreateExtraDocumentsUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateExtraDocumentsUploadUrlMutation,
  CreateExtraDocumentsUploadUrlMutationVariables
>
export const UpdateApplicantPhoneNumberDocument = gql`
  mutation UpdateApplicantPhoneNumber($body: UpdatePhoneNumberInput!) {
    updateApplicantPhoneNumber(body: $body)
  }
`
export type UpdateApplicantPhoneNumberMutationFn = Apollo.MutationFunction<
  UpdateApplicantPhoneNumberMutation,
  UpdateApplicantPhoneNumberMutationVariables
>

/**
 * __useUpdateApplicantPhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantPhoneNumberMutation, { data, loading, error }] = useUpdateApplicantPhoneNumberMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateApplicantPhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicantPhoneNumberMutation,
    UpdateApplicantPhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApplicantPhoneNumberMutation, UpdateApplicantPhoneNumberMutationVariables>(
    UpdateApplicantPhoneNumberDocument,
    options
  )
}
export type UpdateApplicantPhoneNumberMutationHookResult = ReturnType<typeof useUpdateApplicantPhoneNumberMutation>
export type UpdateApplicantPhoneNumberMutationResult = Apollo.MutationResult<UpdateApplicantPhoneNumberMutation>
export type UpdateApplicantPhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicantPhoneNumberMutation,
  UpdateApplicantPhoneNumberMutationVariables
>
export const UpdateApplicantEmailDocument = gql`
  mutation UpdateApplicantEmail($body: UpdateEmailInput!) {
    updateApplicantEmail(body: $body)
  }
`
export type UpdateApplicantEmailMutationFn = Apollo.MutationFunction<
  UpdateApplicantEmailMutation,
  UpdateApplicantEmailMutationVariables
>

/**
 * __useUpdateApplicantEmailMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantEmailMutation, { data, loading, error }] = useUpdateApplicantEmailMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateApplicantEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApplicantEmailMutation, UpdateApplicantEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApplicantEmailMutation, UpdateApplicantEmailMutationVariables>(
    UpdateApplicantEmailDocument,
    options
  )
}
export type UpdateApplicantEmailMutationHookResult = ReturnType<typeof useUpdateApplicantEmailMutation>
export type UpdateApplicantEmailMutationResult = Apollo.MutationResult<UpdateApplicantEmailMutation>
export type UpdateApplicantEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicantEmailMutation,
  UpdateApplicantEmailMutationVariables
>
export const BulkUploadReceiptsDocument = gql`
  mutation BulkUploadReceipts($body: BulkUploadReceiptsInput!) {
    bulkUploadReceipts(body: $body) {
      matchedReceipts {
        receipt {
          id
        }
        expense {
          id
        }
      }
      unmatchedReceipts {
        receipt {
          id
          document {
            id
            displayName
          }
        }
        reason
      }
    }
  }
`
export type BulkUploadReceiptsMutationFn = Apollo.MutationFunction<
  BulkUploadReceiptsMutation,
  BulkUploadReceiptsMutationVariables
>

/**
 * __useBulkUploadReceiptsMutation__
 *
 * To run a mutation, you first call `useBulkUploadReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUploadReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUploadReceiptsMutation, { data, loading, error }] = useBulkUploadReceiptsMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useBulkUploadReceiptsMutation(
  baseOptions?: Apollo.MutationHookOptions<BulkUploadReceiptsMutation, BulkUploadReceiptsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BulkUploadReceiptsMutation, BulkUploadReceiptsMutationVariables>(
    BulkUploadReceiptsDocument,
    options
  )
}
export type BulkUploadReceiptsMutationHookResult = ReturnType<typeof useBulkUploadReceiptsMutation>
export type BulkUploadReceiptsMutationResult = Apollo.MutationResult<BulkUploadReceiptsMutation>
export type BulkUploadReceiptsMutationOptions = Apollo.BaseMutationOptions<
  BulkUploadReceiptsMutation,
  BulkUploadReceiptsMutationVariables
>
export const CancelFundingPaymentDocument = gql`
  mutation CancelFundingPayment($body: cancelFundingPaymentInput!) {
    cancelFundingPayment(body: $body) {
      id
    }
  }
`
export type CancelFundingPaymentMutationFn = Apollo.MutationFunction<
  CancelFundingPaymentMutation,
  CancelFundingPaymentMutationVariables
>

/**
 * __useCancelFundingPaymentMutation__
 *
 * To run a mutation, you first call `useCancelFundingPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFundingPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFundingPaymentMutation, { data, loading, error }] = useCancelFundingPaymentMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCancelFundingPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelFundingPaymentMutation, CancelFundingPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelFundingPaymentMutation, CancelFundingPaymentMutationVariables>(
    CancelFundingPaymentDocument,
    options
  )
}
export type CancelFundingPaymentMutationHookResult = ReturnType<typeof useCancelFundingPaymentMutation>
export type CancelFundingPaymentMutationResult = Apollo.MutationResult<CancelFundingPaymentMutation>
export type CancelFundingPaymentMutationOptions = Apollo.BaseMutationOptions<
  CancelFundingPaymentMutation,
  CancelFundingPaymentMutationVariables
>
export const PayOffFundingDocument = gql`
  mutation PayOffFunding($body: payOffFundingInput!) {
    payOffFunding(body: $body) {
      id
    }
  }
`
export type PayOffFundingMutationFn = Apollo.MutationFunction<PayOffFundingMutation, PayOffFundingMutationVariables>

/**
 * __usePayOffFundingMutation__
 *
 * To run a mutation, you first call `usePayOffFundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayOffFundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payOffFundingMutation, { data, loading, error }] = usePayOffFundingMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function usePayOffFundingMutation(
  baseOptions?: Apollo.MutationHookOptions<PayOffFundingMutation, PayOffFundingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PayOffFundingMutation, PayOffFundingMutationVariables>(PayOffFundingDocument, options)
}
export type PayOffFundingMutationHookResult = ReturnType<typeof usePayOffFundingMutation>
export type PayOffFundingMutationResult = Apollo.MutationResult<PayOffFundingMutation>
export type PayOffFundingMutationOptions = Apollo.BaseMutationOptions<
  PayOffFundingMutation,
  PayOffFundingMutationVariables
>
export const SetPrimaryBankConnectionAccountDocument = gql`
  mutation SetPrimaryBankConnectionAccount($body: SetPrimaryBankConnectionAccountInput!) {
    setPrimaryBankConnectionAccount(body: $body)
  }
`
export type SetPrimaryBankConnectionAccountMutationFn = Apollo.MutationFunction<
  SetPrimaryBankConnectionAccountMutation,
  SetPrimaryBankConnectionAccountMutationVariables
>

/**
 * __useSetPrimaryBankConnectionAccountMutation__
 *
 * To run a mutation, you first call `useSetPrimaryBankConnectionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryBankConnectionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryBankConnectionAccountMutation, { data, loading, error }] = useSetPrimaryBankConnectionAccountMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSetPrimaryBankConnectionAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPrimaryBankConnectionAccountMutation,
    SetPrimaryBankConnectionAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetPrimaryBankConnectionAccountMutation, SetPrimaryBankConnectionAccountMutationVariables>(
    SetPrimaryBankConnectionAccountDocument,
    options
  )
}
export type SetPrimaryBankConnectionAccountMutationHookResult = ReturnType<
  typeof useSetPrimaryBankConnectionAccountMutation
>
export type SetPrimaryBankConnectionAccountMutationResult =
  Apollo.MutationResult<SetPrimaryBankConnectionAccountMutation>
export type SetPrimaryBankConnectionAccountMutationOptions = Apollo.BaseMutationOptions<
  SetPrimaryBankConnectionAccountMutation,
  SetPrimaryBankConnectionAccountMutationVariables
>
export const CreateBeneficiaryV2Document = gql`
  mutation CreateBeneficiaryV2($body: BeneficiaryInput!) {
    createBeneficiaryV2(body: $body) {
      ... on BeneficiaryValidation {
        isValid
        errors {
          field
          message
          code
          params {
            type
            range
            minLength
            maxLength
            parameters
            missing_details
            list
          }
        }
      }
      ... on Beneficiary {
        id
        accountNumber
        beneficiaryName
        beneficiaryFirstName
        beneficiaryLastName
        beneficiaryCompanyName
        email
        bankAccountHolderName
        bankCountry
        beneficiaryEntityType
        paymentType
        paymentMethod
        currency
        routingCodeValue1
        routingCodeType1
        address1
        address2
        beneficiaryAddress
        beneficiaryCity
        beneficiaryCountry
        beneficiaryPostcode
        beneficiaryStateOrProvince
        beneficiaryIdentificationType
        beneficiaryIdentificationValue
      }
    }
  }
`
export type CreateBeneficiaryV2MutationFn = Apollo.MutationFunction<
  CreateBeneficiaryV2Mutation,
  CreateBeneficiaryV2MutationVariables
>

/**
 * __useCreateBeneficiaryV2Mutation__
 *
 * To run a mutation, you first call `useCreateBeneficiaryV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBeneficiaryV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBeneficiaryV2Mutation, { data, loading, error }] = useCreateBeneficiaryV2Mutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBeneficiaryV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeneficiaryV2Mutation, CreateBeneficiaryV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBeneficiaryV2Mutation, CreateBeneficiaryV2MutationVariables>(
    CreateBeneficiaryV2Document,
    options
  )
}
export type CreateBeneficiaryV2MutationHookResult = ReturnType<typeof useCreateBeneficiaryV2Mutation>
export type CreateBeneficiaryV2MutationResult = Apollo.MutationResult<CreateBeneficiaryV2Mutation>
export type CreateBeneficiaryV2MutationOptions = Apollo.BaseMutationOptions<
  CreateBeneficiaryV2Mutation,
  CreateBeneficiaryV2MutationVariables
>
export const LegacyTransactionsDocument = gql`
  query LegacyTransactions(
    $cardId: String
    $startDate: CalendarDate
    $endDate: CalendarDate
    $order: OrderBy
    $skip: Int
    $take: Int
  ) {
    legacyTransactions(
      startDate: $startDate
      endDate: $endDate
      order: $order
      skip: $skip
      take: $take
      cardId: $cardId
    ) {
      skip
      take
      count
      totalCount
      pages
      transactions {
        ...TransactionFragment
      }
      pendingTransactions {
        ...TransactionFragment
      }
    }
  }
  ${TransactionFragmentFragmentDoc}
`

/**
 * __useLegacyTransactionsQuery__
 *
 * To run a query within a React component, call `useLegacyTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyTransactionsQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useLegacyTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<LegacyTransactionsQuery, LegacyTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LegacyTransactionsQuery, LegacyTransactionsQueryVariables>(LegacyTransactionsDocument, options)
}
export function useLegacyTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegacyTransactionsQuery, LegacyTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LegacyTransactionsQuery, LegacyTransactionsQueryVariables>(
    LegacyTransactionsDocument,
    options
  )
}
export function useLegacyTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LegacyTransactionsQuery, LegacyTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LegacyTransactionsQuery, LegacyTransactionsQueryVariables>(
    LegacyTransactionsDocument,
    options
  )
}
export type LegacyTransactionsQueryHookResult = ReturnType<typeof useLegacyTransactionsQuery>
export type LegacyTransactionsLazyQueryHookResult = ReturnType<typeof useLegacyTransactionsLazyQuery>
export type LegacyTransactionsSuspenseQueryHookResult = ReturnType<typeof useLegacyTransactionsSuspenseQuery>
export type LegacyTransactionsQueryResult = Apollo.QueryResult<
  LegacyTransactionsQuery,
  LegacyTransactionsQueryVariables
>
export const CardsDocument = gql`
  query Cards($filters: CardsFilters) {
    cards(filters: $filters) {
      id
      nickname
      nameOnCard
      lastFour
      type
      status
      user {
        id
        email
        firstName
        lastName
        userType
      }
      availableBalance
      outstandingBalance
      ledgerBalance
      businessId
      createdAt
      updatedAt
      userId
      spendLimit {
        id
        amount
        period
      }
    }
  }
`

/**
 * __useCardsQuery__
 *
 * To run a query within a React component, call `useCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCardsQuery(baseOptions?: Apollo.QueryHookOptions<CardsQuery, CardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options)
}
export function useCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsQuery, CardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options)
}
export function useCardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CardsQuery, CardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CardsQuery, CardsQueryVariables>(CardsDocument, options)
}
export type CardsQueryHookResult = ReturnType<typeof useCardsQuery>
export type CardsLazyQueryHookResult = ReturnType<typeof useCardsLazyQuery>
export type CardsSuspenseQueryHookResult = ReturnType<typeof useCardsSuspenseQuery>
export type CardsQueryResult = Apollo.QueryResult<CardsQuery, CardsQueryVariables>
export const BusinessUsersWithKycStatusDocument = gql`
  query BusinessUsersWithKycStatus {
    businessUsersWithKycStatus {
      id
      email
      firstName
      lastName
      userType
      dateOfBirth
      verifiedAt
      kycStatusSnapshots {
        id
        status
        createdAt
      }
      hasReachedPhysicalCardLimit
    }
  }
`

/**
 * __useBusinessUsersWithKycStatusQuery__
 *
 * To run a query within a React component, call `useBusinessUsersWithKycStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessUsersWithKycStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessUsersWithKycStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessUsersWithKycStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessUsersWithKycStatusQuery, BusinessUsersWithKycStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BusinessUsersWithKycStatusQuery, BusinessUsersWithKycStatusQueryVariables>(
    BusinessUsersWithKycStatusDocument,
    options
  )
}
export function useBusinessUsersWithKycStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessUsersWithKycStatusQuery, BusinessUsersWithKycStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BusinessUsersWithKycStatusQuery, BusinessUsersWithKycStatusQueryVariables>(
    BusinessUsersWithKycStatusDocument,
    options
  )
}
export function useBusinessUsersWithKycStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BusinessUsersWithKycStatusQuery,
    BusinessUsersWithKycStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BusinessUsersWithKycStatusQuery, BusinessUsersWithKycStatusQueryVariables>(
    BusinessUsersWithKycStatusDocument,
    options
  )
}
export type BusinessUsersWithKycStatusQueryHookResult = ReturnType<typeof useBusinessUsersWithKycStatusQuery>
export type BusinessUsersWithKycStatusLazyQueryHookResult = ReturnType<typeof useBusinessUsersWithKycStatusLazyQuery>
export type BusinessUsersWithKycStatusSuspenseQueryHookResult = ReturnType<
  typeof useBusinessUsersWithKycStatusSuspenseQuery
>
export type BusinessUsersWithKycStatusQueryResult = Apollo.QueryResult<
  BusinessUsersWithKycStatusQuery,
  BusinessUsersWithKycStatusQueryVariables
>
export const BusinessAddressDocument = gql`
  query BusinessAddress {
    business {
      address {
        id
        address1
        address2
        city
        country
        postalCode
        state
      }
    }
  }
`

/**
 * __useBusinessAddressQuery__
 *
 * To run a query within a React component, call `useBusinessAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessAddressQuery, BusinessAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BusinessAddressQuery, BusinessAddressQueryVariables>(BusinessAddressDocument, options)
}
export function useBusinessAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessAddressQuery, BusinessAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BusinessAddressQuery, BusinessAddressQueryVariables>(BusinessAddressDocument, options)
}
export function useBusinessAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessAddressQuery, BusinessAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BusinessAddressQuery, BusinessAddressQueryVariables>(BusinessAddressDocument, options)
}
export type BusinessAddressQueryHookResult = ReturnType<typeof useBusinessAddressQuery>
export type BusinessAddressLazyQueryHookResult = ReturnType<typeof useBusinessAddressLazyQuery>
export type BusinessAddressSuspenseQueryHookResult = ReturnType<typeof useBusinessAddressSuspenseQuery>
export type BusinessAddressQueryResult = Apollo.QueryResult<BusinessAddressQuery, BusinessAddressQueryVariables>
export const BusinessDocument = gql`
  query Business {
    business {
      id
      isDelinquent
    }
  }
`

/**
 * __useBusinessQuery__
 *
 * To run a query within a React component, call `useBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessQuery(baseOptions?: Apollo.QueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options)
}
export function useBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessQuery, BusinessQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options)
}
export function useBusinessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessQuery, BusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BusinessQuery, BusinessQueryVariables>(BusinessDocument, options)
}
export type BusinessQueryHookResult = ReturnType<typeof useBusinessQuery>
export type BusinessLazyQueryHookResult = ReturnType<typeof useBusinessLazyQuery>
export type BusinessSuspenseQueryHookResult = ReturnType<typeof useBusinessSuspenseQuery>
export type BusinessQueryResult = Apollo.QueryResult<BusinessQuery, BusinessQueryVariables>
export const PendingCardsDocument = gql`
  query PendingCards {
    pendingCards {
      id
      nickname
      spendLimitAmount
      spendLimitPeriod
      type
      status
      userId
      user {
        id
        email
        firstName
        lastName
        userType
      }
    }
  }
`

/**
 * __usePendingCardsQuery__
 *
 * To run a query within a React component, call `usePendingCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<PendingCardsQuery, PendingCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PendingCardsQuery, PendingCardsQueryVariables>(PendingCardsDocument, options)
}
export function usePendingCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PendingCardsQuery, PendingCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PendingCardsQuery, PendingCardsQueryVariables>(PendingCardsDocument, options)
}
export function usePendingCardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PendingCardsQuery, PendingCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PendingCardsQuery, PendingCardsQueryVariables>(PendingCardsDocument, options)
}
export type PendingCardsQueryHookResult = ReturnType<typeof usePendingCardsQuery>
export type PendingCardsLazyQueryHookResult = ReturnType<typeof usePendingCardsLazyQuery>
export type PendingCardsSuspenseQueryHookResult = ReturnType<typeof usePendingCardsSuspenseQuery>
export type PendingCardsQueryResult = Apollo.QueryResult<PendingCardsQuery, PendingCardsQueryVariables>
export const PhysicalPendingCardDocument = gql`
  query PhysicalPendingCard {
    physicalPendingCard {
      id
      userId
      nickname
      status
      type
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      spendLimitAmount
      spendLimitPeriod
      nameOnCard
    }
  }
`

/**
 * __usePhysicalPendingCardQuery__
 *
 * To run a query within a React component, call `usePhysicalPendingCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhysicalPendingCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhysicalPendingCardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhysicalPendingCardQuery(
  baseOptions?: Apollo.QueryHookOptions<PhysicalPendingCardQuery, PhysicalPendingCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PhysicalPendingCardQuery, PhysicalPendingCardQueryVariables>(
    PhysicalPendingCardDocument,
    options
  )
}
export function usePhysicalPendingCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhysicalPendingCardQuery, PhysicalPendingCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PhysicalPendingCardQuery, PhysicalPendingCardQueryVariables>(
    PhysicalPendingCardDocument,
    options
  )
}
export function usePhysicalPendingCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PhysicalPendingCardQuery, PhysicalPendingCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PhysicalPendingCardQuery, PhysicalPendingCardQueryVariables>(
    PhysicalPendingCardDocument,
    options
  )
}
export type PhysicalPendingCardQueryHookResult = ReturnType<typeof usePhysicalPendingCardQuery>
export type PhysicalPendingCardLazyQueryHookResult = ReturnType<typeof usePhysicalPendingCardLazyQuery>
export type PhysicalPendingCardSuspenseQueryHookResult = ReturnType<typeof usePhysicalPendingCardSuspenseQuery>
export type PhysicalPendingCardQueryResult = Apollo.QueryResult<
  PhysicalPendingCardQuery,
  PhysicalPendingCardQueryVariables
>
export const BusinessAccountingIntegrationDocument = gql`
  query BusinessAccountingIntegration($integrationType: AccountingIntegrationType!) {
    businessAccountingIntegration(integrationType: $integrationType) {
      id
      businessId
      integrationType
      status
      connectedAt
    }
  }
`

/**
 * __useBusinessAccountingIntegrationQuery__
 *
 * To run a query within a React component, call `useBusinessAccountingIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAccountingIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAccountingIntegrationQuery({
 *   variables: {
 *      integrationType: // value for 'integrationType'
 *   },
 * });
 */
export function useBusinessAccountingIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessAccountingIntegrationQuery, BusinessAccountingIntegrationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BusinessAccountingIntegrationQuery, BusinessAccountingIntegrationQueryVariables>(
    BusinessAccountingIntegrationDocument,
    options
  )
}
export function useBusinessAccountingIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessAccountingIntegrationQuery,
    BusinessAccountingIntegrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BusinessAccountingIntegrationQuery, BusinessAccountingIntegrationQueryVariables>(
    BusinessAccountingIntegrationDocument,
    options
  )
}
export function useBusinessAccountingIntegrationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BusinessAccountingIntegrationQuery,
    BusinessAccountingIntegrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BusinessAccountingIntegrationQuery, BusinessAccountingIntegrationQueryVariables>(
    BusinessAccountingIntegrationDocument,
    options
  )
}
export type BusinessAccountingIntegrationQueryHookResult = ReturnType<typeof useBusinessAccountingIntegrationQuery>
export type BusinessAccountingIntegrationLazyQueryHookResult = ReturnType<
  typeof useBusinessAccountingIntegrationLazyQuery
>
export type BusinessAccountingIntegrationSuspenseQueryHookResult = ReturnType<
  typeof useBusinessAccountingIntegrationSuspenseQuery
>
export type BusinessAccountingIntegrationQueryResult = Apollo.QueryResult<
  BusinessAccountingIntegrationQuery,
  BusinessAccountingIntegrationQueryVariables
>
export const CardDocument = gql`
  query Card($cardId: String!) {
    card(id: $cardId) {
      id
      nameOnCard
      nickname
      availableBalance
      outstandingBalance
      ledgerBalance
      status
      type
      userId
      businessId
      lastFour
      createdAt
      updatedAt
      spendLimit {
        id
        amount
        period
      }
      business {
        id
        address {
          address1
          address2
          city
          country
          id
          postalCode
          state
        }
      }
      spendLimitInfo {
        periodStartDate
        periodEndDate
        consumed
        frequency
        periodLimit
      }
      shippingAddress {
        id
        address1
        address2
        city
        country
        postalCode
        state
      }
      user {
        id
        email
        firstName
        lastName
        userType
      }
    }
  }
`

/**
 * __useCardQuery__
 *
 * To run a query within a React component, call `useCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardQuery(baseOptions: Apollo.QueryHookOptions<CardQuery, CardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CardQuery, CardQueryVariables>(CardDocument, options)
}
export function useCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardQuery, CardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CardQuery, CardQueryVariables>(CardDocument, options)
}
export function useCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CardQuery, CardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CardQuery, CardQueryVariables>(CardDocument, options)
}
export type CardQueryHookResult = ReturnType<typeof useCardQuery>
export type CardLazyQueryHookResult = ReturnType<typeof useCardLazyQuery>
export type CardSuspenseQueryHookResult = ReturnType<typeof useCardSuspenseQuery>
export type CardQueryResult = Apollo.QueryResult<CardQuery, CardQueryVariables>
export const CardBasicInfoDocument = gql`
  query CardBasicInfo($cardId: String!) {
    card(id: $cardId) {
      id
      nameOnCard
      nickname
      status
      type
      userId
      businessId
      lastFour
      createdAt
      updatedAt
      spendLimit {
        id
        amount
        period
      }
      user {
        id
        email
        firstName
        lastName
        userType
      }
    }
  }
`

/**
 * __useCardBasicInfoQuery__
 *
 * To run a query within a React component, call `useCardBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardBasicInfoQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardBasicInfoQuery(
  baseOptions: Apollo.QueryHookOptions<CardBasicInfoQuery, CardBasicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CardBasicInfoQuery, CardBasicInfoQueryVariables>(CardBasicInfoDocument, options)
}
export function useCardBasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CardBasicInfoQuery, CardBasicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CardBasicInfoQuery, CardBasicInfoQueryVariables>(CardBasicInfoDocument, options)
}
export function useCardBasicInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CardBasicInfoQuery, CardBasicInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CardBasicInfoQuery, CardBasicInfoQueryVariables>(CardBasicInfoDocument, options)
}
export type CardBasicInfoQueryHookResult = ReturnType<typeof useCardBasicInfoQuery>
export type CardBasicInfoLazyQueryHookResult = ReturnType<typeof useCardBasicInfoLazyQuery>
export type CardBasicInfoSuspenseQueryHookResult = ReturnType<typeof useCardBasicInfoSuspenseQuery>
export type CardBasicInfoQueryResult = Apollo.QueryResult<CardBasicInfoQuery, CardBasicInfoQueryVariables>
export const PendingCardDocument = gql`
  query PendingCard($pendingCardId: String!) {
    pendingCard(id: $pendingCardId) {
      id
      userId
      nickname
      type
      status
      addressLine1
      addressLine2
      city
      state
      country
      postalCode
      spendLimitAmount
      spendLimitPeriod
      createdAt
      updatedAt
      nameOnCard
      belongsToValidUser
      user {
        id
        email
        firstName
        lastName
        userType
      }
    }
  }
`

/**
 * __usePendingCardQuery__
 *
 * To run a query within a React component, call `usePendingCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingCardQuery({
 *   variables: {
 *      pendingCardId: // value for 'pendingCardId'
 *   },
 * });
 */
export function usePendingCardQuery(baseOptions: Apollo.QueryHookOptions<PendingCardQuery, PendingCardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PendingCardQuery, PendingCardQueryVariables>(PendingCardDocument, options)
}
export function usePendingCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PendingCardQuery, PendingCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PendingCardQuery, PendingCardQueryVariables>(PendingCardDocument, options)
}
export function usePendingCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PendingCardQuery, PendingCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PendingCardQuery, PendingCardQueryVariables>(PendingCardDocument, options)
}
export type PendingCardQueryHookResult = ReturnType<typeof usePendingCardQuery>
export type PendingCardLazyQueryHookResult = ReturnType<typeof usePendingCardLazyQuery>
export type PendingCardSuspenseQueryHookResult = ReturnType<typeof usePendingCardSuspenseQuery>
export type PendingCardQueryResult = Apollo.QueryResult<PendingCardQuery, PendingCardQueryVariables>
export const PhysicalPreActiveCardDocument = gql`
  query PhysicalPreActiveCard {
    physicalPreActiveCard {
      id
      userId
      businessId
      type
      status
      createdAt
      updatedAt
    }
  }
`

/**
 * __usePhysicalPreActiveCardQuery__
 *
 * To run a query within a React component, call `usePhysicalPreActiveCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhysicalPreActiveCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhysicalPreActiveCardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhysicalPreActiveCardQuery(
  baseOptions?: Apollo.QueryHookOptions<PhysicalPreActiveCardQuery, PhysicalPreActiveCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PhysicalPreActiveCardQuery, PhysicalPreActiveCardQueryVariables>(
    PhysicalPreActiveCardDocument,
    options
  )
}
export function usePhysicalPreActiveCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhysicalPreActiveCardQuery, PhysicalPreActiveCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PhysicalPreActiveCardQuery, PhysicalPreActiveCardQueryVariables>(
    PhysicalPreActiveCardDocument,
    options
  )
}
export function usePhysicalPreActiveCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PhysicalPreActiveCardQuery, PhysicalPreActiveCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PhysicalPreActiveCardQuery, PhysicalPreActiveCardQueryVariables>(
    PhysicalPreActiveCardDocument,
    options
  )
}
export type PhysicalPreActiveCardQueryHookResult = ReturnType<typeof usePhysicalPreActiveCardQuery>
export type PhysicalPreActiveCardLazyQueryHookResult = ReturnType<typeof usePhysicalPreActiveCardLazyQuery>
export type PhysicalPreActiveCardSuspenseQueryHookResult = ReturnType<typeof usePhysicalPreActiveCardSuspenseQuery>
export type PhysicalPreActiveCardQueryResult = Apollo.QueryResult<
  PhysicalPreActiveCardQuery,
  PhysicalPreActiveCardQueryVariables
>
export const BusinessCardLimitDocument = gql`
  query BusinessCardLimit {
    cardLimit {
      businessId
      businessCardsCount
      activeCardsLimit
    }
  }
`

/**
 * __useBusinessCardLimitQuery__
 *
 * To run a query within a React component, call `useBusinessCardLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCardLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessCardLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessCardLimitQuery(
  baseOptions?: Apollo.QueryHookOptions<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>(BusinessCardLimitDocument, options)
}
export function useBusinessCardLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>(
    BusinessCardLimitDocument,
    options
  )
}
export function useBusinessCardLimitSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>(
    BusinessCardLimitDocument,
    options
  )
}
export type BusinessCardLimitQueryHookResult = ReturnType<typeof useBusinessCardLimitQuery>
export type BusinessCardLimitLazyQueryHookResult = ReturnType<typeof useBusinessCardLimitLazyQuery>
export type BusinessCardLimitSuspenseQueryHookResult = ReturnType<typeof useBusinessCardLimitSuspenseQuery>
export type BusinessCardLimitQueryResult = Apollo.QueryResult<BusinessCardLimitQuery, BusinessCardLimitQueryVariables>
export const PendingAgreementsDocument = gql`
  query PendingAgreements {
    pendingAgreements {
      id
      agreementType
      version
      name
      link
    }
  }
`

/**
 * __usePendingAgreementsQuery__
 *
 * To run a query within a React component, call `usePendingAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingAgreementsQuery(
  baseOptions?: Apollo.QueryHookOptions<PendingAgreementsQuery, PendingAgreementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PendingAgreementsQuery, PendingAgreementsQueryVariables>(PendingAgreementsDocument, options)
}
export function usePendingAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PendingAgreementsQuery, PendingAgreementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PendingAgreementsQuery, PendingAgreementsQueryVariables>(
    PendingAgreementsDocument,
    options
  )
}
export function usePendingAgreementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PendingAgreementsQuery, PendingAgreementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PendingAgreementsQuery, PendingAgreementsQueryVariables>(
    PendingAgreementsDocument,
    options
  )
}
export type PendingAgreementsQueryHookResult = ReturnType<typeof usePendingAgreementsQuery>
export type PendingAgreementsLazyQueryHookResult = ReturnType<typeof usePendingAgreementsLazyQuery>
export type PendingAgreementsSuspenseQueryHookResult = ReturnType<typeof usePendingAgreementsSuspenseQuery>
export type PendingAgreementsQueryResult = Apollo.QueryResult<PendingAgreementsQuery, PendingAgreementsQueryVariables>
export const AgreementsDocument = gql`
  query Agreements {
    agreements {
      id
      agreementType
      version
      name
      link
    }
  }
`

/**
 * __useAgreementsQuery__
 *
 * To run a query within a React component, call `useAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgreementsQuery(baseOptions?: Apollo.QueryHookOptions<AgreementsQuery, AgreementsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options)
}
export function useAgreementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options)
}
export function useAgreementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options)
}
export type AgreementsQueryHookResult = ReturnType<typeof useAgreementsQuery>
export type AgreementsLazyQueryHookResult = ReturnType<typeof useAgreementsLazyQuery>
export type AgreementsSuspenseQueryHookResult = ReturnType<typeof useAgreementsSuspenseQuery>
export type AgreementsQueryResult = Apollo.QueryResult<AgreementsQuery, AgreementsQueryVariables>
export const WalletTransactionsDocument = gql`
  query WalletTransactions(
    $currencyCode: CurrencyCode
    $skip: Int
    $take: Int
    $startDate: CalendarDate
    $endDate: CalendarDate
  ) {
    walletTransactions(
      currencyCode: $currencyCode
      skip: $skip
      take: $take
      startDate: $startDate
      endDate: $endDate
    ) {
      count
      pages
      skip
      take
      totalCount
      transactions {
        ...WalletBankPayment
        ...WalletPendingBankPayment
        ...WalletBankTransfer
        ...WalletConversion
        ...WalletBankPaymentFee
      }
    }
  }
  ${WalletBankPaymentFragmentDoc}
  ${WalletPendingBankPaymentFragmentDoc}
  ${WalletBankTransferFragmentDoc}
  ${WalletConversionFragmentDoc}
  ${WalletBankPaymentFeeFragmentDoc}
`

/**
 * __useWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletTransactionsQuery({
 *   variables: {
 *      currencyCode: // value for 'currencyCode'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useWalletTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(WalletTransactionsDocument, options)
}
export function useWalletTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(
    WalletTransactionsDocument,
    options
  )
}
export function useWalletTransactionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<WalletTransactionsQuery, WalletTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WalletTransactionsQuery, WalletTransactionsQueryVariables>(
    WalletTransactionsDocument,
    options
  )
}
export type WalletTransactionsQueryHookResult = ReturnType<typeof useWalletTransactionsQuery>
export type WalletTransactionsLazyQueryHookResult = ReturnType<typeof useWalletTransactionsLazyQuery>
export type WalletTransactionsSuspenseQueryHookResult = ReturnType<typeof useWalletTransactionsSuspenseQuery>
export type WalletTransactionsQueryResult = Apollo.QueryResult<
  WalletTransactionsQuery,
  WalletTransactionsQueryVariables
>
export const PaymentsBalanceDocument = gql`
  query PaymentsBalance {
    paymentsBalance {
      cardOutstandingBalance {
        formatted
        balance
      }
    }
  }
`

/**
 * __usePaymentsBalanceQuery__
 *
 * To run a query within a React component, call `usePaymentsBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentsBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>(PaymentsBalanceDocument, options)
}
export function usePaymentsBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>(PaymentsBalanceDocument, options)
}
export function usePaymentsBalanceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>(PaymentsBalanceDocument, options)
}
export type PaymentsBalanceQueryHookResult = ReturnType<typeof usePaymentsBalanceQuery>
export type PaymentsBalanceLazyQueryHookResult = ReturnType<typeof usePaymentsBalanceLazyQuery>
export type PaymentsBalanceSuspenseQueryHookResult = ReturnType<typeof usePaymentsBalanceSuspenseQuery>
export type PaymentsBalanceQueryResult = Apollo.QueryResult<PaymentsBalanceQuery, PaymentsBalanceQueryVariables>
export const StatementsDocument = gql`
  query Statements {
    statements {
      id
      businessId
      statementNumber
      pdfUrl
      pdfPath
      statementDateFrom
      statementDateTo
      beginningBalance
      endBalance
      availableCreditLimit
      totalCreditLimit
      createdAt
      rewardsRedemption
    }
  }
`

/**
 * __useStatementsQuery__
 *
 * To run a query within a React component, call `useStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatementsQuery(baseOptions?: Apollo.QueryHookOptions<StatementsQuery, StatementsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StatementsQuery, StatementsQueryVariables>(StatementsDocument, options)
}
export function useStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatementsQuery, StatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StatementsQuery, StatementsQueryVariables>(StatementsDocument, options)
}
export function useStatementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StatementsQuery, StatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StatementsQuery, StatementsQueryVariables>(StatementsDocument, options)
}
export type StatementsQueryHookResult = ReturnType<typeof useStatementsQuery>
export type StatementsLazyQueryHookResult = ReturnType<typeof useStatementsLazyQuery>
export type StatementsSuspenseQueryHookResult = ReturnType<typeof useStatementsSuspenseQuery>
export type StatementsQueryResult = Apollo.QueryResult<StatementsQuery, StatementsQueryVariables>
export const StatementExtensionsDocument = gql`
  query StatementExtensions {
    statementExtensions {
      id
      loanNumber
      businessId
      status
      repaymentFrequency
      principalAmount
      totalFee
      termDurationInMonths
      apr
      totalRepayment
      outstandingBalance
      preDiscountTotalFee
      feeDiscountRate
      installments {
        ...InstallmentFragment
      }
      firstRepaymentDate
      reason
      financingChannel
      nextPaymentDate
      lastFundingPayment {
        ...LastFundingPaymentFragment
      }
      discounts {
        ...DiscountFragment
      }
    }
  }
  ${InstallmentFragmentFragmentDoc}
  ${LastFundingPaymentFragmentFragmentDoc}
  ${DiscountFragmentFragmentDoc}
`

/**
 * __useStatementExtensionsQuery__
 *
 * To run a query within a React component, call `useStatementExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementExtensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementExtensionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatementExtensionsQuery(
  baseOptions?: Apollo.QueryHookOptions<StatementExtensionsQuery, StatementExtensionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StatementExtensionsQuery, StatementExtensionsQueryVariables>(
    StatementExtensionsDocument,
    options
  )
}
export function useStatementExtensionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatementExtensionsQuery, StatementExtensionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StatementExtensionsQuery, StatementExtensionsQueryVariables>(
    StatementExtensionsDocument,
    options
  )
}
export function useStatementExtensionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StatementExtensionsQuery, StatementExtensionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StatementExtensionsQuery, StatementExtensionsQueryVariables>(
    StatementExtensionsDocument,
    options
  )
}
export type StatementExtensionsQueryHookResult = ReturnType<typeof useStatementExtensionsQuery>
export type StatementExtensionsLazyQueryHookResult = ReturnType<typeof useStatementExtensionsLazyQuery>
export type StatementExtensionsSuspenseQueryHookResult = ReturnType<typeof useStatementExtensionsSuspenseQuery>
export type StatementExtensionsQueryResult = Apollo.QueryResult<
  StatementExtensionsQuery,
  StatementExtensionsQueryVariables
>
export const OutstandingBalancesDocument = gql`
  query OutstandingBalances {
    outstandingBalances {
      statementExtensionOutstandingBalance
      cardSettledBalance
      statementOutstandingBalance
    }
  }
`

/**
 * __useOutstandingBalancesQuery__
 *
 * To run a query within a React component, call `useOutstandingBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutstandingBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutstandingBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutstandingBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<OutstandingBalancesQuery, OutstandingBalancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OutstandingBalancesQuery, OutstandingBalancesQueryVariables>(
    OutstandingBalancesDocument,
    options
  )
}
export function useOutstandingBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OutstandingBalancesQuery, OutstandingBalancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OutstandingBalancesQuery, OutstandingBalancesQueryVariables>(
    OutstandingBalancesDocument,
    options
  )
}
export function useOutstandingBalancesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OutstandingBalancesQuery, OutstandingBalancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OutstandingBalancesQuery, OutstandingBalancesQueryVariables>(
    OutstandingBalancesDocument,
    options
  )
}
export type OutstandingBalancesQueryHookResult = ReturnType<typeof useOutstandingBalancesQuery>
export type OutstandingBalancesLazyQueryHookResult = ReturnType<typeof useOutstandingBalancesLazyQuery>
export type OutstandingBalancesSuspenseQueryHookResult = ReturnType<typeof useOutstandingBalancesSuspenseQuery>
export type OutstandingBalancesQueryResult = Apollo.QueryResult<
  OutstandingBalancesQuery,
  OutstandingBalancesQueryVariables
>
export const StatementExtensionApprovalDocument = gql`
  query StatementExtensionApproval {
    statementExtensionApproval {
      id
      businessNameLegal
      businessId
      maxRepaymentAmount
      repaymentFrequency
      enable
      apr
      enabledAt
      cardDueDate
      repayments {
        repayment
        formatted
      }
      autoEnrollmentConfig {
        id
        enabled
        lastToggledAt
        repaymentInstallments
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useStatementExtensionApprovalQuery__
 *
 * To run a query within a React component, call `useStatementExtensionApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementExtensionApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementExtensionApprovalQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatementExtensionApprovalQuery(
  baseOptions?: Apollo.QueryHookOptions<StatementExtensionApprovalQuery, StatementExtensionApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StatementExtensionApprovalQuery, StatementExtensionApprovalQueryVariables>(
    StatementExtensionApprovalDocument,
    options
  )
}
export function useStatementExtensionApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatementExtensionApprovalQuery, StatementExtensionApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StatementExtensionApprovalQuery, StatementExtensionApprovalQueryVariables>(
    StatementExtensionApprovalDocument,
    options
  )
}
export function useStatementExtensionApprovalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StatementExtensionApprovalQuery,
    StatementExtensionApprovalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StatementExtensionApprovalQuery, StatementExtensionApprovalQueryVariables>(
    StatementExtensionApprovalDocument,
    options
  )
}
export type StatementExtensionApprovalQueryHookResult = ReturnType<typeof useStatementExtensionApprovalQuery>
export type StatementExtensionApprovalLazyQueryHookResult = ReturnType<typeof useStatementExtensionApprovalLazyQuery>
export type StatementExtensionApprovalSuspenseQueryHookResult = ReturnType<
  typeof useStatementExtensionApprovalSuspenseQuery
>
export type StatementExtensionApprovalQueryResult = Apollo.QueryResult<
  StatementExtensionApprovalQuery,
  StatementExtensionApprovalQueryVariables
>
export const CalculateRepaymentPlanDocument = gql`
  query CalculateRepaymentPlan(
    $apr: Float!
    $repaymentFrequency: String!
    $fundingAmount: Float!
    $repaymentInstallments: Int!
    $feeDiscountRate: Float
  ) {
    calculateRepaymentPlan(
      apr: $apr
      repaymentFrequency: $repaymentFrequency
      fundingAmount: $fundingAmount
      repaymentInstallments: $repaymentInstallments
      feeDiscountRate: $feeDiscountRate
    ) {
      ...RepaymentPlanFragment
    }
  }
  ${RepaymentPlanFragmentFragmentDoc}
`

/**
 * __useCalculateRepaymentPlanQuery__
 *
 * To run a query within a React component, call `useCalculateRepaymentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRepaymentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRepaymentPlanQuery({
 *   variables: {
 *      apr: // value for 'apr'
 *      repaymentFrequency: // value for 'repaymentFrequency'
 *      fundingAmount: // value for 'fundingAmount'
 *      repaymentInstallments: // value for 'repaymentInstallments'
 *      feeDiscountRate: // value for 'feeDiscountRate'
 *   },
 * });
 */
export function useCalculateRepaymentPlanQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateRepaymentPlanQuery, CalculateRepaymentPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CalculateRepaymentPlanQuery, CalculateRepaymentPlanQueryVariables>(
    CalculateRepaymentPlanDocument,
    options
  )
}
export function useCalculateRepaymentPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateRepaymentPlanQuery, CalculateRepaymentPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CalculateRepaymentPlanQuery, CalculateRepaymentPlanQueryVariables>(
    CalculateRepaymentPlanDocument,
    options
  )
}
export function useCalculateRepaymentPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CalculateRepaymentPlanQuery, CalculateRepaymentPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CalculateRepaymentPlanQuery, CalculateRepaymentPlanQueryVariables>(
    CalculateRepaymentPlanDocument,
    options
  )
}
export type CalculateRepaymentPlanQueryHookResult = ReturnType<typeof useCalculateRepaymentPlanQuery>
export type CalculateRepaymentPlanLazyQueryHookResult = ReturnType<typeof useCalculateRepaymentPlanLazyQuery>
export type CalculateRepaymentPlanSuspenseQueryHookResult = ReturnType<typeof useCalculateRepaymentPlanSuspenseQuery>
export type CalculateRepaymentPlanQueryResult = Apollo.QueryResult<
  CalculateRepaymentPlanQuery,
  CalculateRepaymentPlanQueryVariables
>
export const CalculateRepaymentPlansWithOriginationFeeDocument = gql`
  query CalculateRepaymentPlansWithOriginationFee(
    $fundingAmount: Float!
    $maxRepaymentMonths: Int!
    $repaymentFrequency: String!
  ) {
    calculateRepaymentPlansWithOriginationFee(
      fundingAmount: $fundingAmount
      maxRepaymentMonths: $maxRepaymentMonths
      repaymentFrequency: $repaymentFrequency
    ) {
      amountPerRemainingPayments
      complianceFee
      durationFormatted
      monthlyFeeRate
      originationFeeRate
      monthsDuration
      monthsDurationFormatted
      originationFee
      principalAmount
      receivedAmount
      repaymentInstallments
      serviceFee
      totalFee
      totalRepaymentAmount
      lastPaymentDate
    }
  }
`

/**
 * __useCalculateRepaymentPlansWithOriginationFeeQuery__
 *
 * To run a query within a React component, call `useCalculateRepaymentPlansWithOriginationFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRepaymentPlansWithOriginationFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRepaymentPlansWithOriginationFeeQuery({
 *   variables: {
 *      fundingAmount: // value for 'fundingAmount'
 *      maxRepaymentMonths: // value for 'maxRepaymentMonths'
 *      repaymentFrequency: // value for 'repaymentFrequency'
 *   },
 * });
 */
export function useCalculateRepaymentPlansWithOriginationFeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateRepaymentPlansWithOriginationFeeQuery,
    CalculateRepaymentPlansWithOriginationFeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CalculateRepaymentPlansWithOriginationFeeQuery,
    CalculateRepaymentPlansWithOriginationFeeQueryVariables
  >(CalculateRepaymentPlansWithOriginationFeeDocument, options)
}
export function useCalculateRepaymentPlansWithOriginationFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateRepaymentPlansWithOriginationFeeQuery,
    CalculateRepaymentPlansWithOriginationFeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CalculateRepaymentPlansWithOriginationFeeQuery,
    CalculateRepaymentPlansWithOriginationFeeQueryVariables
  >(CalculateRepaymentPlansWithOriginationFeeDocument, options)
}
export function useCalculateRepaymentPlansWithOriginationFeeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CalculateRepaymentPlansWithOriginationFeeQuery,
    CalculateRepaymentPlansWithOriginationFeeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CalculateRepaymentPlansWithOriginationFeeQuery,
    CalculateRepaymentPlansWithOriginationFeeQueryVariables
  >(CalculateRepaymentPlansWithOriginationFeeDocument, options)
}
export type CalculateRepaymentPlansWithOriginationFeeQueryHookResult = ReturnType<
  typeof useCalculateRepaymentPlansWithOriginationFeeQuery
>
export type CalculateRepaymentPlansWithOriginationFeeLazyQueryHookResult = ReturnType<
  typeof useCalculateRepaymentPlansWithOriginationFeeLazyQuery
>
export type CalculateRepaymentPlansWithOriginationFeeSuspenseQueryHookResult = ReturnType<
  typeof useCalculateRepaymentPlansWithOriginationFeeSuspenseQuery
>
export type CalculateRepaymentPlansWithOriginationFeeQueryResult = Apollo.QueryResult<
  CalculateRepaymentPlansWithOriginationFeeQuery,
  CalculateRepaymentPlansWithOriginationFeeQueryVariables
>
export const PrimaryAccountDocument = gql`
  query PrimaryAccount {
    primaryAccount {
      accountName
      accountNumber
    }
  }
`

/**
 * __usePrimaryAccountQuery__
 *
 * To run a query within a React component, call `usePrimaryAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrimaryAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<PrimaryAccountQuery, PrimaryAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrimaryAccountQuery, PrimaryAccountQueryVariables>(PrimaryAccountDocument, options)
}
export function usePrimaryAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrimaryAccountQuery, PrimaryAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrimaryAccountQuery, PrimaryAccountQueryVariables>(PrimaryAccountDocument, options)
}
export function usePrimaryAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PrimaryAccountQuery, PrimaryAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PrimaryAccountQuery, PrimaryAccountQueryVariables>(PrimaryAccountDocument, options)
}
export type PrimaryAccountQueryHookResult = ReturnType<typeof usePrimaryAccountQuery>
export type PrimaryAccountLazyQueryHookResult = ReturnType<typeof usePrimaryAccountLazyQuery>
export type PrimaryAccountSuspenseQueryHookResult = ReturnType<typeof usePrimaryAccountSuspenseQuery>
export type PrimaryAccountQueryResult = Apollo.QueryResult<PrimaryAccountQuery, PrimaryAccountQueryVariables>
export const TotalBalanceInCurrencyDocument = gql`
  query TotalBalanceInCurrency($currency: CurrencyCode!) {
    totalBalanceInCurrency(currency: $currency) {
      currentBalanceInRequestedCurrency
      availableBalanceInRequestedCurrency
    }
  }
`

/**
 * __useTotalBalanceInCurrencyQuery__
 *
 * To run a query within a React component, call `useTotalBalanceInCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalBalanceInCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalBalanceInCurrencyQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useTotalBalanceInCurrencyQuery(
  baseOptions: Apollo.QueryHookOptions<TotalBalanceInCurrencyQuery, TotalBalanceInCurrencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TotalBalanceInCurrencyQuery, TotalBalanceInCurrencyQueryVariables>(
    TotalBalanceInCurrencyDocument,
    options
  )
}
export function useTotalBalanceInCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalBalanceInCurrencyQuery, TotalBalanceInCurrencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TotalBalanceInCurrencyQuery, TotalBalanceInCurrencyQueryVariables>(
    TotalBalanceInCurrencyDocument,
    options
  )
}
export function useTotalBalanceInCurrencySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TotalBalanceInCurrencyQuery, TotalBalanceInCurrencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TotalBalanceInCurrencyQuery, TotalBalanceInCurrencyQueryVariables>(
    TotalBalanceInCurrencyDocument,
    options
  )
}
export type TotalBalanceInCurrencyQueryHookResult = ReturnType<typeof useTotalBalanceInCurrencyQuery>
export type TotalBalanceInCurrencyLazyQueryHookResult = ReturnType<typeof useTotalBalanceInCurrencyLazyQuery>
export type TotalBalanceInCurrencySuspenseQueryHookResult = ReturnType<typeof useTotalBalanceInCurrencySuspenseQuery>
export type TotalBalanceInCurrencyQueryResult = Apollo.QueryResult<
  TotalBalanceInCurrencyQuery,
  TotalBalanceInCurrencyQueryVariables
>
export const ReferralCodeDocument = gql`
  query ReferralCode {
    referralCode {
      code
    }
  }
`

/**
 * __useReferralCodeQuery__
 *
 * To run a query within a React component, call `useReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(ReferralCodeDocument, options)
}
export function useReferralCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(ReferralCodeDocument, options)
}
export function useReferralCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(ReferralCodeDocument, options)
}
export type ReferralCodeQueryHookResult = ReturnType<typeof useReferralCodeQuery>
export type ReferralCodeLazyQueryHookResult = ReturnType<typeof useReferralCodeLazyQuery>
export type ReferralCodeSuspenseQueryHookResult = ReturnType<typeof useReferralCodeSuspenseQuery>
export type ReferralCodeQueryResult = Apollo.QueryResult<ReferralCodeQuery, ReferralCodeQueryVariables>
export const ApplicationV2Document = gql`
  query ApplicationV2 {
    applicationV2 {
      ...ApplicationV2Fragment
    }
  }
  ${ApplicationV2FragmentFragmentDoc}
`

/**
 * __useApplicationV2Query__
 *
 * To run a query within a React component, call `useApplicationV2Query` and pass it any options that fit your needs.
 * When your component renders, `useApplicationV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationV2Query({
 *   variables: {
 *   },
 * });
 */
export function useApplicationV2Query(
  baseOptions?: Apollo.QueryHookOptions<ApplicationV2Query, ApplicationV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationV2Query, ApplicationV2QueryVariables>(ApplicationV2Document, options)
}
export function useApplicationV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationV2Query, ApplicationV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationV2Query, ApplicationV2QueryVariables>(ApplicationV2Document, options)
}
export function useApplicationV2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ApplicationV2Query, ApplicationV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ApplicationV2Query, ApplicationV2QueryVariables>(ApplicationV2Document, options)
}
export type ApplicationV2QueryHookResult = ReturnType<typeof useApplicationV2Query>
export type ApplicationV2LazyQueryHookResult = ReturnType<typeof useApplicationV2LazyQuery>
export type ApplicationV2SuspenseQueryHookResult = ReturnType<typeof useApplicationV2SuspenseQuery>
export type ApplicationV2QueryResult = Apollo.QueryResult<ApplicationV2Query, ApplicationV2QueryVariables>
export const ApplicationV2ByBusinessIdDocument = gql`
  query ApplicationV2ByBusinessId {
    applicationV2ByBusinessId {
      id
    }
  }
`

/**
 * __useApplicationV2ByBusinessIdQuery__
 *
 * To run a query within a React component, call `useApplicationV2ByBusinessIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationV2ByBusinessIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationV2ByBusinessIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationV2ByBusinessIdQuery(
  baseOptions?: Apollo.QueryHookOptions<ApplicationV2ByBusinessIdQuery, ApplicationV2ByBusinessIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationV2ByBusinessIdQuery, ApplicationV2ByBusinessIdQueryVariables>(
    ApplicationV2ByBusinessIdDocument,
    options
  )
}
export function useApplicationV2ByBusinessIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationV2ByBusinessIdQuery, ApplicationV2ByBusinessIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationV2ByBusinessIdQuery, ApplicationV2ByBusinessIdQueryVariables>(
    ApplicationV2ByBusinessIdDocument,
    options
  )
}
export function useApplicationV2ByBusinessIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ApplicationV2ByBusinessIdQuery, ApplicationV2ByBusinessIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ApplicationV2ByBusinessIdQuery, ApplicationV2ByBusinessIdQueryVariables>(
    ApplicationV2ByBusinessIdDocument,
    options
  )
}
export type ApplicationV2ByBusinessIdQueryHookResult = ReturnType<typeof useApplicationV2ByBusinessIdQuery>
export type ApplicationV2ByBusinessIdLazyQueryHookResult = ReturnType<typeof useApplicationV2ByBusinessIdLazyQuery>
export type ApplicationV2ByBusinessIdSuspenseQueryHookResult = ReturnType<
  typeof useApplicationV2ByBusinessIdSuspenseQuery
>
export type ApplicationV2ByBusinessIdQueryResult = Apollo.QueryResult<
  ApplicationV2ByBusinessIdQuery,
  ApplicationV2ByBusinessIdQueryVariables
>
export const CalculateRepaymentPlansDocument = gql`
  query CalculateRepaymentPlans(
    $apr: Float!
    $repaymentFrequency: String!
    $repaymentInstallments: [Int!]!
    $fundingAmount: Float!
    $feeDiscountRate: Float!
  ) {
    calculateRepaymentPlans(
      apr: $apr
      repaymentFrequency: $repaymentFrequency
      repaymentInstallments: $repaymentInstallments
      fundingAmount: $fundingAmount
      feeDiscountRate: $feeDiscountRate
    ) {
      ...RepaymentPlanFragment
    }
  }
  ${RepaymentPlanFragmentFragmentDoc}
`

/**
 * __useCalculateRepaymentPlansQuery__
 *
 * To run a query within a React component, call `useCalculateRepaymentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRepaymentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRepaymentPlansQuery({
 *   variables: {
 *      apr: // value for 'apr'
 *      repaymentFrequency: // value for 'repaymentFrequency'
 *      repaymentInstallments: // value for 'repaymentInstallments'
 *      fundingAmount: // value for 'fundingAmount'
 *      feeDiscountRate: // value for 'feeDiscountRate'
 *   },
 * });
 */
export function useCalculateRepaymentPlansQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateRepaymentPlansQuery, CalculateRepaymentPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CalculateRepaymentPlansQuery, CalculateRepaymentPlansQueryVariables>(
    CalculateRepaymentPlansDocument,
    options
  )
}
export function useCalculateRepaymentPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateRepaymentPlansQuery, CalculateRepaymentPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CalculateRepaymentPlansQuery, CalculateRepaymentPlansQueryVariables>(
    CalculateRepaymentPlansDocument,
    options
  )
}
export function useCalculateRepaymentPlansSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CalculateRepaymentPlansQuery, CalculateRepaymentPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CalculateRepaymentPlansQuery, CalculateRepaymentPlansQueryVariables>(
    CalculateRepaymentPlansDocument,
    options
  )
}
export type CalculateRepaymentPlansQueryHookResult = ReturnType<typeof useCalculateRepaymentPlansQuery>
export type CalculateRepaymentPlansLazyQueryHookResult = ReturnType<typeof useCalculateRepaymentPlansLazyQuery>
export type CalculateRepaymentPlansSuspenseQueryHookResult = ReturnType<typeof useCalculateRepaymentPlansSuspenseQuery>
export type CalculateRepaymentPlansQueryResult = Apollo.QueryResult<
  CalculateRepaymentPlansQuery,
  CalculateRepaymentPlansQueryVariables
>
export const ReferralCodeByCodeDocument = gql`
  query ReferralCodeByCode($code: String!) {
    referralCodeByCode(code: $code) {
      code
      user {
        id
        email
        firstName
        lastName
        userType
      }
    }
  }
`

/**
 * __useReferralCodeByCodeQuery__
 *
 * To run a query within a React component, call `useReferralCodeByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useReferralCodeByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<ReferralCodeByCodeQuery, ReferralCodeByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReferralCodeByCodeQuery, ReferralCodeByCodeQueryVariables>(ReferralCodeByCodeDocument, options)
}
export function useReferralCodeByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeByCodeQuery, ReferralCodeByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReferralCodeByCodeQuery, ReferralCodeByCodeQueryVariables>(
    ReferralCodeByCodeDocument,
    options
  )
}
export function useReferralCodeByCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralCodeByCodeQuery, ReferralCodeByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReferralCodeByCodeQuery, ReferralCodeByCodeQueryVariables>(
    ReferralCodeByCodeDocument,
    options
  )
}
export type ReferralCodeByCodeQueryHookResult = ReturnType<typeof useReferralCodeByCodeQuery>
export type ReferralCodeByCodeLazyQueryHookResult = ReturnType<typeof useReferralCodeByCodeLazyQuery>
export type ReferralCodeByCodeSuspenseQueryHookResult = ReturnType<typeof useReferralCodeByCodeSuspenseQuery>
export type ReferralCodeByCodeQueryResult = Apollo.QueryResult<
  ReferralCodeByCodeQuery,
  ReferralCodeByCodeQueryVariables
>
export const ReferralsDocument = gql`
  query Referrals($take: Int, $page: Int) {
    referrals(take: $take, page: $page) {
      referrals {
        id
        inviteeId
        status
        createdAt
        invitee {
          id
          email
          firstName
          lastName
          userType
        }
      }
      totalCount
    }
  }
`

/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useReferralsQuery(baseOptions?: Apollo.QueryHookOptions<ReferralsQuery, ReferralsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, options)
}
export function useReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralsQuery, ReferralsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, options)
}
export function useReferralsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralsQuery, ReferralsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, options)
}
export type ReferralsQueryHookResult = ReturnType<typeof useReferralsQuery>
export type ReferralsLazyQueryHookResult = ReturnType<typeof useReferralsLazyQuery>
export type ReferralsSuspenseQueryHookResult = ReturnType<typeof useReferralsSuspenseQuery>
export type ReferralsQueryResult = Apollo.QueryResult<ReferralsQuery, ReferralsQueryVariables>
export const ReferralRewardsEarnedDocument = gql`
  query ReferralRewardsEarned {
    referralRewardsEarned {
      amount
    }
  }
`

/**
 * __useReferralRewardsEarnedQuery__
 *
 * To run a query within a React component, call `useReferralRewardsEarnedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralRewardsEarnedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralRewardsEarnedQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralRewardsEarnedQuery(
  baseOptions?: Apollo.QueryHookOptions<ReferralRewardsEarnedQuery, ReferralRewardsEarnedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReferralRewardsEarnedQuery, ReferralRewardsEarnedQueryVariables>(
    ReferralRewardsEarnedDocument,
    options
  )
}
export function useReferralRewardsEarnedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralRewardsEarnedQuery, ReferralRewardsEarnedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReferralRewardsEarnedQuery, ReferralRewardsEarnedQueryVariables>(
    ReferralRewardsEarnedDocument,
    options
  )
}
export function useReferralRewardsEarnedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReferralRewardsEarnedQuery, ReferralRewardsEarnedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReferralRewardsEarnedQuery, ReferralRewardsEarnedQueryVariables>(
    ReferralRewardsEarnedDocument,
    options
  )
}
export type ReferralRewardsEarnedQueryHookResult = ReturnType<typeof useReferralRewardsEarnedQuery>
export type ReferralRewardsEarnedLazyQueryHookResult = ReturnType<typeof useReferralRewardsEarnedLazyQuery>
export type ReferralRewardsEarnedSuspenseQueryHookResult = ReturnType<typeof useReferralRewardsEarnedSuspenseQuery>
export type ReferralRewardsEarnedQueryResult = Apollo.QueryResult<
  ReferralRewardsEarnedQuery,
  ReferralRewardsEarnedQueryVariables
>
export const CalculateRepaymentDatesDocument = gql`
  query CalculateRepaymentDates($repaymentFrequency: String!, $repaymentInstallments: Int!, $startDate: DateTime!) {
    calculateRepaymentDates(
      repaymentFrequency: $repaymentFrequency
      repaymentInstallments: $repaymentInstallments
      startDate: $startDate
    ) {
      dates
      first
      last
    }
  }
`

/**
 * __useCalculateRepaymentDatesQuery__
 *
 * To run a query within a React component, call `useCalculateRepaymentDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRepaymentDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRepaymentDatesQuery({
 *   variables: {
 *      repaymentFrequency: // value for 'repaymentFrequency'
 *      repaymentInstallments: // value for 'repaymentInstallments'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useCalculateRepaymentDatesQuery(
  baseOptions: Apollo.QueryHookOptions<CalculateRepaymentDatesQuery, CalculateRepaymentDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CalculateRepaymentDatesQuery, CalculateRepaymentDatesQueryVariables>(
    CalculateRepaymentDatesDocument,
    options
  )
}
export function useCalculateRepaymentDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CalculateRepaymentDatesQuery, CalculateRepaymentDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CalculateRepaymentDatesQuery, CalculateRepaymentDatesQueryVariables>(
    CalculateRepaymentDatesDocument,
    options
  )
}
export function useCalculateRepaymentDatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CalculateRepaymentDatesQuery, CalculateRepaymentDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CalculateRepaymentDatesQuery, CalculateRepaymentDatesQueryVariables>(
    CalculateRepaymentDatesDocument,
    options
  )
}
export type CalculateRepaymentDatesQueryHookResult = ReturnType<typeof useCalculateRepaymentDatesQuery>
export type CalculateRepaymentDatesLazyQueryHookResult = ReturnType<typeof useCalculateRepaymentDatesLazyQuery>
export type CalculateRepaymentDatesSuspenseQueryHookResult = ReturnType<typeof useCalculateRepaymentDatesSuspenseQuery>
export type CalculateRepaymentDatesQueryResult = Apollo.QueryResult<
  CalculateRepaymentDatesQuery,
  CalculateRepaymentDatesQueryVariables
>
export const GetCapitalInfoDocument = gql`
  query GetCapitalInfo {
    capitalInfo {
      availableCreditLimit
      capitalCreditLimit
      underwritingStatus
      capitalRequest {
        id
        businessId
        createdAt
      }
      approval {
        ...CapitalApprovalFragment
      }
    }
  }
  ${CapitalApprovalFragmentFragmentDoc}
`

/**
 * __useGetCapitalInfoQuery__
 *
 * To run a query within a React component, call `useGetCapitalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapitalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapitalInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCapitalInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>(GetCapitalInfoDocument, options)
}
export function useGetCapitalInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>(GetCapitalInfoDocument, options)
}
export function useGetCapitalInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>(GetCapitalInfoDocument, options)
}
export type GetCapitalInfoQueryHookResult = ReturnType<typeof useGetCapitalInfoQuery>
export type GetCapitalInfoLazyQueryHookResult = ReturnType<typeof useGetCapitalInfoLazyQuery>
export type GetCapitalInfoSuspenseQueryHookResult = ReturnType<typeof useGetCapitalInfoSuspenseQuery>
export type GetCapitalInfoQueryResult = Apollo.QueryResult<GetCapitalInfoQuery, GetCapitalInfoQueryVariables>
export const ActiveIncidentNotificationsDocument = gql`
  query ActiveIncidentNotifications {
    activeIncidentNotifications {
      message
      severity
      pages
    }
  }
`

/**
 * __useActiveIncidentNotificationsQuery__
 *
 * To run a query within a React component, call `useActiveIncidentNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveIncidentNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveIncidentNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveIncidentNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveIncidentNotificationsQuery, ActiveIncidentNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActiveIncidentNotificationsQuery, ActiveIncidentNotificationsQueryVariables>(
    ActiveIncidentNotificationsDocument,
    options
  )
}
export function useActiveIncidentNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveIncidentNotificationsQuery, ActiveIncidentNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActiveIncidentNotificationsQuery, ActiveIncidentNotificationsQueryVariables>(
    ActiveIncidentNotificationsDocument,
    options
  )
}
export function useActiveIncidentNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ActiveIncidentNotificationsQuery,
    ActiveIncidentNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ActiveIncidentNotificationsQuery, ActiveIncidentNotificationsQueryVariables>(
    ActiveIncidentNotificationsDocument,
    options
  )
}
export type ActiveIncidentNotificationsQueryHookResult = ReturnType<typeof useActiveIncidentNotificationsQuery>
export type ActiveIncidentNotificationsLazyQueryHookResult = ReturnType<typeof useActiveIncidentNotificationsLazyQuery>
export type ActiveIncidentNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useActiveIncidentNotificationsSuspenseQuery
>
export type ActiveIncidentNotificationsQueryResult = Apollo.QueryResult<
  ActiveIncidentNotificationsQuery,
  ActiveIncidentNotificationsQueryVariables
>
export const UserHasAgreedReferralsDocument = gql`
  query UserHasAgreedReferrals {
    userHasAgreedReferrals
  }
`

/**
 * __useUserHasAgreedReferralsQuery__
 *
 * To run a query within a React component, call `useUserHasAgreedReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasAgreedReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasAgreedReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHasAgreedReferralsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserHasAgreedReferralsQuery, UserHasAgreedReferralsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserHasAgreedReferralsQuery, UserHasAgreedReferralsQueryVariables>(
    UserHasAgreedReferralsDocument,
    options
  )
}
export function useUserHasAgreedReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHasAgreedReferralsQuery, UserHasAgreedReferralsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserHasAgreedReferralsQuery, UserHasAgreedReferralsQueryVariables>(
    UserHasAgreedReferralsDocument,
    options
  )
}
export function useUserHasAgreedReferralsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserHasAgreedReferralsQuery, UserHasAgreedReferralsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserHasAgreedReferralsQuery, UserHasAgreedReferralsQueryVariables>(
    UserHasAgreedReferralsDocument,
    options
  )
}
export type UserHasAgreedReferralsQueryHookResult = ReturnType<typeof useUserHasAgreedReferralsQuery>
export type UserHasAgreedReferralsLazyQueryHookResult = ReturnType<typeof useUserHasAgreedReferralsLazyQuery>
export type UserHasAgreedReferralsSuspenseQueryHookResult = ReturnType<typeof useUserHasAgreedReferralsSuspenseQuery>
export type UserHasAgreedReferralsQueryResult = Apollo.QueryResult<
  UserHasAgreedReferralsQuery,
  UserHasAgreedReferralsQueryVariables
>
export const GetUserVerificationDataDocument = gql`
  query GetUserVerificationData {
    getUserVerificationData {
      id
      email
      verifiedAt
      phoneNumber
      phoneVerifiedAt
    }
  }
`

/**
 * __useGetUserVerificationDataQuery__
 *
 * To run a query within a React component, call `useGetUserVerificationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVerificationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVerificationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserVerificationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserVerificationDataQuery, GetUserVerificationDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserVerificationDataQuery, GetUserVerificationDataQueryVariables>(
    GetUserVerificationDataDocument,
    options
  )
}
export function useGetUserVerificationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserVerificationDataQuery, GetUserVerificationDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserVerificationDataQuery, GetUserVerificationDataQueryVariables>(
    GetUserVerificationDataDocument,
    options
  )
}
export function useGetUserVerificationDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserVerificationDataQuery, GetUserVerificationDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserVerificationDataQuery, GetUserVerificationDataQueryVariables>(
    GetUserVerificationDataDocument,
    options
  )
}
export type GetUserVerificationDataQueryHookResult = ReturnType<typeof useGetUserVerificationDataQuery>
export type GetUserVerificationDataLazyQueryHookResult = ReturnType<typeof useGetUserVerificationDataLazyQuery>
export type GetUserVerificationDataSuspenseQueryHookResult = ReturnType<typeof useGetUserVerificationDataSuspenseQuery>
export type GetUserVerificationDataQueryResult = Apollo.QueryResult<
  GetUserVerificationDataQuery,
  GetUserVerificationDataQueryVariables
>
export const IsTxDisputableDocument = gql`
  query IsTxDisputable($body: IsTxDisputableInput!) {
    isTxDisputable(body: $body) {
      isDisputable
    }
  }
`

/**
 * __useIsTxDisputableQuery__
 *
 * To run a query within a React component, call `useIsTxDisputableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTxDisputableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTxDisputableQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useIsTxDisputableQuery(
  baseOptions: Apollo.QueryHookOptions<IsTxDisputableQuery, IsTxDisputableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsTxDisputableQuery, IsTxDisputableQueryVariables>(IsTxDisputableDocument, options)
}
export function useIsTxDisputableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsTxDisputableQuery, IsTxDisputableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsTxDisputableQuery, IsTxDisputableQueryVariables>(IsTxDisputableDocument, options)
}
export function useIsTxDisputableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IsTxDisputableQuery, IsTxDisputableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IsTxDisputableQuery, IsTxDisputableQueryVariables>(IsTxDisputableDocument, options)
}
export type IsTxDisputableQueryHookResult = ReturnType<typeof useIsTxDisputableQuery>
export type IsTxDisputableLazyQueryHookResult = ReturnType<typeof useIsTxDisputableLazyQuery>
export type IsTxDisputableSuspenseQueryHookResult = ReturnType<typeof useIsTxDisputableSuspenseQuery>
export type IsTxDisputableQueryResult = Apollo.QueryResult<IsTxDisputableQuery, IsTxDisputableQueryVariables>
export const GetInstallmentsDocument = gql`
  query GetInstallments($fundingId: String!) {
    installments(fundingId: $fundingId) {
      ...InstallmentFragment
    }
  }
  ${InstallmentFragmentFragmentDoc}
`

/**
 * __useGetInstallmentsQuery__
 *
 * To run a query within a React component, call `useGetInstallmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallmentsQuery({
 *   variables: {
 *      fundingId: // value for 'fundingId'
 *   },
 * });
 */
export function useGetInstallmentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetInstallmentsQuery, GetInstallmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetInstallmentsQuery, GetInstallmentsQueryVariables>(GetInstallmentsDocument, options)
}
export function useGetInstallmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInstallmentsQuery, GetInstallmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetInstallmentsQuery, GetInstallmentsQueryVariables>(GetInstallmentsDocument, options)
}
export function useGetInstallmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetInstallmentsQuery, GetInstallmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetInstallmentsQuery, GetInstallmentsQueryVariables>(GetInstallmentsDocument, options)
}
export type GetInstallmentsQueryHookResult = ReturnType<typeof useGetInstallmentsQuery>
export type GetInstallmentsLazyQueryHookResult = ReturnType<typeof useGetInstallmentsLazyQuery>
export type GetInstallmentsSuspenseQueryHookResult = ReturnType<typeof useGetInstallmentsSuspenseQuery>
export type GetInstallmentsQueryResult = Apollo.QueryResult<GetInstallmentsQuery, GetInstallmentsQueryVariables>
export const GetRewardsHistoryDocument = gql`
  query GetRewardsHistory($order: OrderBy, $skip: Int, $take: Int) {
    rewardsHistory(order: $order, skip: $skip, take: $take) {
      pages
      rewards {
        rewardDate
        userFirstName
        userLastName
        amount
        amountAsNumber
        action
        entityType
        entityId
        polarity
      }
    }
  }
`

/**
 * __useGetRewardsHistoryQuery__
 *
 * To run a query within a React component, call `useGetRewardsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardsHistoryQuery({
 *   variables: {
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRewardsHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>(GetRewardsHistoryDocument, options)
}
export function useGetRewardsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>(
    GetRewardsHistoryDocument,
    options
  )
}
export function useGetRewardsHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>(
    GetRewardsHistoryDocument,
    options
  )
}
export type GetRewardsHistoryQueryHookResult = ReturnType<typeof useGetRewardsHistoryQuery>
export type GetRewardsHistoryLazyQueryHookResult = ReturnType<typeof useGetRewardsHistoryLazyQuery>
export type GetRewardsHistorySuspenseQueryHookResult = ReturnType<typeof useGetRewardsHistorySuspenseQuery>
export type GetRewardsHistoryQueryResult = Apollo.QueryResult<GetRewardsHistoryQuery, GetRewardsHistoryQueryVariables>
export const FundingsByBusinessIdPaginatedDocument = gql`
  query FundingsByBusinessIdPaginated($limit: Int!, $page: Int!, $financingChannel: String, $status: [String]) {
    fundingsByBusinessIdPaginated(limit: $limit, page: $page, financingChannel: $financingChannel, status: $status) {
      total
      pages
      data {
        id
        loanNumber
        businessId
        status
        repaymentFrequency
        principalAmount
        totalFee
        termDurationInMonths
        apr
        totalRepayment
        totalRefinanced
        outstandingBalance
        agreementSignedDate
        firstRepaymentDate
        financingChannel
        preDiscountTotalFee
        feeDiscountRate
        depositedOn
        reason
        nextPaymentDate
        discounts {
          ...DiscountFragment
        }
        lastFundingPayment {
          ...LastFundingPaymentFragment
        }
      }
    }
  }
  ${DiscountFragmentFragmentDoc}
  ${LastFundingPaymentFragmentFragmentDoc}
`

/**
 * __useFundingsByBusinessIdPaginatedQuery__
 *
 * To run a query within a React component, call `useFundingsByBusinessIdPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingsByBusinessIdPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingsByBusinessIdPaginatedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      financingChannel: // value for 'financingChannel'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFundingsByBusinessIdPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<FundingsByBusinessIdPaginatedQuery, FundingsByBusinessIdPaginatedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FundingsByBusinessIdPaginatedQuery, FundingsByBusinessIdPaginatedQueryVariables>(
    FundingsByBusinessIdPaginatedDocument,
    options
  )
}
export function useFundingsByBusinessIdPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FundingsByBusinessIdPaginatedQuery,
    FundingsByBusinessIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FundingsByBusinessIdPaginatedQuery, FundingsByBusinessIdPaginatedQueryVariables>(
    FundingsByBusinessIdPaginatedDocument,
    options
  )
}
export function useFundingsByBusinessIdPaginatedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FundingsByBusinessIdPaginatedQuery,
    FundingsByBusinessIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FundingsByBusinessIdPaginatedQuery, FundingsByBusinessIdPaginatedQueryVariables>(
    FundingsByBusinessIdPaginatedDocument,
    options
  )
}
export type FundingsByBusinessIdPaginatedQueryHookResult = ReturnType<typeof useFundingsByBusinessIdPaginatedQuery>
export type FundingsByBusinessIdPaginatedLazyQueryHookResult = ReturnType<
  typeof useFundingsByBusinessIdPaginatedLazyQuery
>
export type FundingsByBusinessIdPaginatedSuspenseQueryHookResult = ReturnType<
  typeof useFundingsByBusinessIdPaginatedSuspenseQuery
>
export type FundingsByBusinessIdPaginatedQueryResult = Apollo.QueryResult<
  FundingsByBusinessIdPaginatedQuery,
  FundingsByBusinessIdPaginatedQueryVariables
>
export const CardsTransactionsTotalNetDocument = gql`
  query CardsTransactionsTotalNet($businessId: String!, $dateFrom: CalendarDate!, $dateTo: CalendarDate!) {
    cardsTransactionsTotalNet(businessId: $businessId, dateFrom: $dateFrom, dateTo: $dateTo) {
      total
    }
  }
`

/**
 * __useCardsTransactionsTotalNetQuery__
 *
 * To run a query within a React component, call `useCardsTransactionsTotalNetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsTransactionsTotalNetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsTransactionsTotalNetQuery({
 *   variables: {
 *      businessId: // value for 'businessId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useCardsTransactionsTotalNetQuery(
  baseOptions: Apollo.QueryHookOptions<CardsTransactionsTotalNetQuery, CardsTransactionsTotalNetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CardsTransactionsTotalNetQuery, CardsTransactionsTotalNetQueryVariables>(
    CardsTransactionsTotalNetDocument,
    options
  )
}
export function useCardsTransactionsTotalNetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CardsTransactionsTotalNetQuery, CardsTransactionsTotalNetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CardsTransactionsTotalNetQuery, CardsTransactionsTotalNetQueryVariables>(
    CardsTransactionsTotalNetDocument,
    options
  )
}
export function useCardsTransactionsTotalNetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CardsTransactionsTotalNetQuery, CardsTransactionsTotalNetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CardsTransactionsTotalNetQuery, CardsTransactionsTotalNetQueryVariables>(
    CardsTransactionsTotalNetDocument,
    options
  )
}
export type CardsTransactionsTotalNetQueryHookResult = ReturnType<typeof useCardsTransactionsTotalNetQuery>
export type CardsTransactionsTotalNetLazyQueryHookResult = ReturnType<typeof useCardsTransactionsTotalNetLazyQuery>
export type CardsTransactionsTotalNetSuspenseQueryHookResult = ReturnType<
  typeof useCardsTransactionsTotalNetSuspenseQuery
>
export type CardsTransactionsTotalNetQueryResult = Apollo.QueryResult<
  CardsTransactionsTotalNetQuery,
  CardsTransactionsTotalNetQueryVariables
>
export const CapitalFundingsByBusinessIdPaginatedDocument = gql`
  query CapitalFundingsByBusinessIdPaginated($limit: Int!, $page: Int!, $status: [String]) {
    capitalFundingsByBusinessIdPaginated(limit: $limit, page: $page, status: $status) {
      pages
      data {
        id
        loanNumber
        businessId
        status
        principalAmount
        totalFee
        originationFee
        totalRepayment
        feeDiscountRate
        preDiscountTotalFee
        nextPaymentDate
        firstRepaymentDate
        repaymentFrequency
        apr
        discounts {
          ...DiscountFragment
        }
        lastFundingPayment {
          ...LastFundingPaymentFragment
        }
      }
    }
  }
  ${DiscountFragmentFragmentDoc}
  ${LastFundingPaymentFragmentFragmentDoc}
`

/**
 * __useCapitalFundingsByBusinessIdPaginatedQuery__
 *
 * To run a query within a React component, call `useCapitalFundingsByBusinessIdPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapitalFundingsByBusinessIdPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapitalFundingsByBusinessIdPaginatedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCapitalFundingsByBusinessIdPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    CapitalFundingsByBusinessIdPaginatedQuery,
    CapitalFundingsByBusinessIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CapitalFundingsByBusinessIdPaginatedQuery, CapitalFundingsByBusinessIdPaginatedQueryVariables>(
    CapitalFundingsByBusinessIdPaginatedDocument,
    options
  )
}
export function useCapitalFundingsByBusinessIdPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CapitalFundingsByBusinessIdPaginatedQuery,
    CapitalFundingsByBusinessIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CapitalFundingsByBusinessIdPaginatedQuery,
    CapitalFundingsByBusinessIdPaginatedQueryVariables
  >(CapitalFundingsByBusinessIdPaginatedDocument, options)
}
export function useCapitalFundingsByBusinessIdPaginatedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CapitalFundingsByBusinessIdPaginatedQuery,
    CapitalFundingsByBusinessIdPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CapitalFundingsByBusinessIdPaginatedQuery,
    CapitalFundingsByBusinessIdPaginatedQueryVariables
  >(CapitalFundingsByBusinessIdPaginatedDocument, options)
}
export type CapitalFundingsByBusinessIdPaginatedQueryHookResult = ReturnType<
  typeof useCapitalFundingsByBusinessIdPaginatedQuery
>
export type CapitalFundingsByBusinessIdPaginatedLazyQueryHookResult = ReturnType<
  typeof useCapitalFundingsByBusinessIdPaginatedLazyQuery
>
export type CapitalFundingsByBusinessIdPaginatedSuspenseQueryHookResult = ReturnType<
  typeof useCapitalFundingsByBusinessIdPaginatedSuspenseQuery
>
export type CapitalFundingsByBusinessIdPaginatedQueryResult = Apollo.QueryResult<
  CapitalFundingsByBusinessIdPaginatedQuery,
  CapitalFundingsByBusinessIdPaginatedQueryVariables
>
export const ExpensesByBusinessDocument = gql`
  query ExpensesByBusiness($filters: ExpensesFilters, $pagination: PaginationInput) {
    listExpensesByBusiness(filters: $filters, pagination: $pagination) {
      expenses {
        id
        businessId
        status
        statusSnapshots {
          id
          status
          description
          createdAt
        }
        transactionDate
        merchantName
        amount
        currency
        requestedAmount
        requestedCurrency
        cardId
        card {
          id
          userId
          businessId
          status
          lastFour
          nameOnCard
          type
        }
        memo
        receipt {
          id
          documentId
          document {
            id
            path
            displayName
            mimeType
          }
        }
        entityMerchantId
        entityCategoryId
        entityTaxCodeId
        entityClassId
      }
      totalExpenses
      countsByStatus {
        CREATED
        REQUEST_FOR_INFORMATION
        IN_REVIEW
        REVIEWED
        SYNCING
        SYNCED
        FAILED
      }
    }
  }
`

/**
 * __useExpensesByBusinessQuery__
 *
 * To run a query within a React component, call `useExpensesByBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesByBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesByBusinessQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExpensesByBusinessQuery(
  baseOptions?: Apollo.QueryHookOptions<ExpensesByBusinessQuery, ExpensesByBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ExpensesByBusinessQuery, ExpensesByBusinessQueryVariables>(ExpensesByBusinessDocument, options)
}
export function useExpensesByBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExpensesByBusinessQuery, ExpensesByBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ExpensesByBusinessQuery, ExpensesByBusinessQueryVariables>(
    ExpensesByBusinessDocument,
    options
  )
}
export function useExpensesByBusinessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExpensesByBusinessQuery, ExpensesByBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ExpensesByBusinessQuery, ExpensesByBusinessQueryVariables>(
    ExpensesByBusinessDocument,
    options
  )
}
export type ExpensesByBusinessQueryHookResult = ReturnType<typeof useExpensesByBusinessQuery>
export type ExpensesByBusinessLazyQueryHookResult = ReturnType<typeof useExpensesByBusinessLazyQuery>
export type ExpensesByBusinessSuspenseQueryHookResult = ReturnType<typeof useExpensesByBusinessSuspenseQuery>
export type ExpensesByBusinessQueryResult = Apollo.QueryResult<
  ExpensesByBusinessQuery,
  ExpensesByBusinessQueryVariables
>
export const ExpensesByUserDocument = gql`
  query ExpensesByUser($filters: ExpensesFilters, $pagination: PaginationInput) {
    listExpensesByUser(filters: $filters, pagination: $pagination) {
      expenses {
        id
        businessId
        status
        statusSnapshots {
          id
          status
          description
          createdAt
        }
        transactionDate
        merchantName
        amount
        currency
        cardId
        card {
          id
          userId
          businessId
          status
          lastFour
          nameOnCard
          type
        }
        memo
        receipt {
          id
          documentId
          document {
            id
            path
            displayName
            mimeType
          }
        }
        entityMerchantId
        entityCategoryId
        entityTaxCodeId
        entityClassId
      }
    }
  }
`

/**
 * __useExpensesByUserQuery__
 *
 * To run a query within a React component, call `useExpensesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesByUserQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExpensesByUserQuery(
  baseOptions?: Apollo.QueryHookOptions<ExpensesByUserQuery, ExpensesByUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ExpensesByUserQuery, ExpensesByUserQueryVariables>(ExpensesByUserDocument, options)
}
export function useExpensesByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExpensesByUserQuery, ExpensesByUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ExpensesByUserQuery, ExpensesByUserQueryVariables>(ExpensesByUserDocument, options)
}
export function useExpensesByUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExpensesByUserQuery, ExpensesByUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ExpensesByUserQuery, ExpensesByUserQueryVariables>(ExpensesByUserDocument, options)
}
export type ExpensesByUserQueryHookResult = ReturnType<typeof useExpensesByUserQuery>
export type ExpensesByUserLazyQueryHookResult = ReturnType<typeof useExpensesByUserLazyQuery>
export type ExpensesByUserSuspenseQueryHookResult = ReturnType<typeof useExpensesByUserSuspenseQuery>
export type ExpensesByUserQueryResult = Apollo.QueryResult<ExpensesByUserQuery, ExpensesByUserQueryVariables>
export const MfaSettingsDocument = gql`
  query MfaSettings {
    mfaSettings {
      id
      channel
      isEnabled
      isPrimary
    }
  }
`

/**
 * __useMfaSettingsQuery__
 *
 * To run a query within a React component, call `useMfaSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<MfaSettingsQuery, MfaSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MfaSettingsQuery, MfaSettingsQueryVariables>(MfaSettingsDocument, options)
}
export function useMfaSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MfaSettingsQuery, MfaSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MfaSettingsQuery, MfaSettingsQueryVariables>(MfaSettingsDocument, options)
}
export function useMfaSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MfaSettingsQuery, MfaSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MfaSettingsQuery, MfaSettingsQueryVariables>(MfaSettingsDocument, options)
}
export type MfaSettingsQueryHookResult = ReturnType<typeof useMfaSettingsQuery>
export type MfaSettingsLazyQueryHookResult = ReturnType<typeof useMfaSettingsLazyQuery>
export type MfaSettingsSuspenseQueryHookResult = ReturnType<typeof useMfaSettingsSuspenseQuery>
export type MfaSettingsQueryResult = Apollo.QueryResult<MfaSettingsQuery, MfaSettingsQueryVariables>
export const BankConnectionAccountsDocument = gql`
  query BankConnectionAccounts {
    bankConnectionAccounts {
      id
      name
      accountNumber
      branchNumber
      bankNumber
      verifiedAt
      isPrimary
      subtype
      currencyCode
      provider
      currentBalance
      availableBalance
      bank {
        name
        logo
      }
    }
  }
`

/**
 * __useBankConnectionAccountsQuery__
 *
 * To run a query within a React component, call `useBankConnectionAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankConnectionAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankConnectionAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBankConnectionAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<BankConnectionAccountsQuery, BankConnectionAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BankConnectionAccountsQuery, BankConnectionAccountsQueryVariables>(
    BankConnectionAccountsDocument,
    options
  )
}
export function useBankConnectionAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankConnectionAccountsQuery, BankConnectionAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BankConnectionAccountsQuery, BankConnectionAccountsQueryVariables>(
    BankConnectionAccountsDocument,
    options
  )
}
export function useBankConnectionAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BankConnectionAccountsQuery, BankConnectionAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BankConnectionAccountsQuery, BankConnectionAccountsQueryVariables>(
    BankConnectionAccountsDocument,
    options
  )
}
export type BankConnectionAccountsQueryHookResult = ReturnType<typeof useBankConnectionAccountsQuery>
export type BankConnectionAccountsLazyQueryHookResult = ReturnType<typeof useBankConnectionAccountsLazyQuery>
export type BankConnectionAccountsSuspenseQueryHookResult = ReturnType<typeof useBankConnectionAccountsSuspenseQuery>
export type BankConnectionAccountsQueryResult = Apollo.QueryResult<
  BankConnectionAccountsQuery,
  BankConnectionAccountsQueryVariables
>
export const ListAccountingCategoriesDocument = gql`
  query ListAccountingCategories {
    listAccountingCategories {
      id
      name
      status
      category
      nominalCode
      createdAt
      updatedAt
    }
  }
`

/**
 * __useListAccountingCategoriesQuery__
 *
 * To run a query within a React component, call `useListAccountingCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountingCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountingCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountingCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAccountingCategoriesQuery, ListAccountingCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAccountingCategoriesQuery, ListAccountingCategoriesQueryVariables>(
    ListAccountingCategoriesDocument,
    options
  )
}
export function useListAccountingCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAccountingCategoriesQuery, ListAccountingCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAccountingCategoriesQuery, ListAccountingCategoriesQueryVariables>(
    ListAccountingCategoriesDocument,
    options
  )
}
export function useListAccountingCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListAccountingCategoriesQuery, ListAccountingCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListAccountingCategoriesQuery, ListAccountingCategoriesQueryVariables>(
    ListAccountingCategoriesDocument,
    options
  )
}
export type ListAccountingCategoriesQueryHookResult = ReturnType<typeof useListAccountingCategoriesQuery>
export type ListAccountingCategoriesLazyQueryHookResult = ReturnType<typeof useListAccountingCategoriesLazyQuery>
export type ListAccountingCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useListAccountingCategoriesSuspenseQuery
>
export type ListAccountingCategoriesQueryResult = Apollo.QueryResult<
  ListAccountingCategoriesQuery,
  ListAccountingCategoriesQueryVariables
>
export const ListAccountingTaxRatesDocument = gql`
  query listAccountingTaxRates {
    listAccountingTaxRates {
      id
      name
      code
      effectiveRate
      totalTaxRate
      createdAt
      updatedAt
      status
    }
  }
`

/**
 * __useListAccountingTaxRatesQuery__
 *
 * To run a query within a React component, call `useListAccountingTaxRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountingTaxRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountingTaxRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountingTaxRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAccountingTaxRatesQuery, ListAccountingTaxRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAccountingTaxRatesQuery, ListAccountingTaxRatesQueryVariables>(
    ListAccountingTaxRatesDocument,
    options
  )
}
export function useListAccountingTaxRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAccountingTaxRatesQuery, ListAccountingTaxRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAccountingTaxRatesQuery, ListAccountingTaxRatesQueryVariables>(
    ListAccountingTaxRatesDocument,
    options
  )
}
export function useListAccountingTaxRatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListAccountingTaxRatesQuery, ListAccountingTaxRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListAccountingTaxRatesQuery, ListAccountingTaxRatesQueryVariables>(
    ListAccountingTaxRatesDocument,
    options
  )
}
export type ListAccountingTaxRatesQueryHookResult = ReturnType<typeof useListAccountingTaxRatesQuery>
export type ListAccountingTaxRatesLazyQueryHookResult = ReturnType<typeof useListAccountingTaxRatesLazyQuery>
export type ListAccountingTaxRatesSuspenseQueryHookResult = ReturnType<typeof useListAccountingTaxRatesSuspenseQuery>
export type ListAccountingTaxRatesQueryResult = Apollo.QueryResult<
  ListAccountingTaxRatesQuery,
  ListAccountingTaxRatesQueryVariables
>
export const ListAccountingVendorsDocument = gql`
  query ListAccountingVendors {
    listAccountingVendors {
      id
      status
      vendorName
      email
      phone
    }
  }
`

/**
 * __useListAccountingVendorsQuery__
 *
 * To run a query within a React component, call `useListAccountingVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountingVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountingVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountingVendorsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAccountingVendorsQuery, ListAccountingVendorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAccountingVendorsQuery, ListAccountingVendorsQueryVariables>(
    ListAccountingVendorsDocument,
    options
  )
}
export function useListAccountingVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAccountingVendorsQuery, ListAccountingVendorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAccountingVendorsQuery, ListAccountingVendorsQueryVariables>(
    ListAccountingVendorsDocument,
    options
  )
}
export function useListAccountingVendorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListAccountingVendorsQuery, ListAccountingVendorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListAccountingVendorsQuery, ListAccountingVendorsQueryVariables>(
    ListAccountingVendorsDocument,
    options
  )
}
export type ListAccountingVendorsQueryHookResult = ReturnType<typeof useListAccountingVendorsQuery>
export type ListAccountingVendorsLazyQueryHookResult = ReturnType<typeof useListAccountingVendorsLazyQuery>
export type ListAccountingVendorsSuspenseQueryHookResult = ReturnType<typeof useListAccountingVendorsSuspenseQuery>
export type ListAccountingVendorsQueryResult = Apollo.QueryResult<
  ListAccountingVendorsQuery,
  ListAccountingVendorsQueryVariables
>
export const GetExpenseReceiptFileDocument = gql`
  query GetExpenseReceiptFile($expenseId: String!) {
    getExpenseReceiptFile(expenseId: $expenseId) {
      file
      fileName
    }
  }
`

/**
 * __useGetExpenseReceiptFileQuery__
 *
 * To run a query within a React component, call `useGetExpenseReceiptFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseReceiptFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseReceiptFileQuery({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useGetExpenseReceiptFileQuery(
  baseOptions: Apollo.QueryHookOptions<GetExpenseReceiptFileQuery, GetExpenseReceiptFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetExpenseReceiptFileQuery, GetExpenseReceiptFileQueryVariables>(
    GetExpenseReceiptFileDocument,
    options
  )
}
export function useGetExpenseReceiptFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseReceiptFileQuery, GetExpenseReceiptFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetExpenseReceiptFileQuery, GetExpenseReceiptFileQueryVariables>(
    GetExpenseReceiptFileDocument,
    options
  )
}
export function useGetExpenseReceiptFileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetExpenseReceiptFileQuery, GetExpenseReceiptFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetExpenseReceiptFileQuery, GetExpenseReceiptFileQueryVariables>(
    GetExpenseReceiptFileDocument,
    options
  )
}
export type GetExpenseReceiptFileQueryHookResult = ReturnType<typeof useGetExpenseReceiptFileQuery>
export type GetExpenseReceiptFileLazyQueryHookResult = ReturnType<typeof useGetExpenseReceiptFileLazyQuery>
export type GetExpenseReceiptFileSuspenseQueryHookResult = ReturnType<typeof useGetExpenseReceiptFileSuspenseQuery>
export type GetExpenseReceiptFileQueryResult = Apollo.QueryResult<
  GetExpenseReceiptFileQuery,
  GetExpenseReceiptFileQueryVariables
>
export const FetchSyncingExpensesDocument = gql`
  query FetchSyncingExpenses {
    fetchSyncingExpenses {
      expenses {
        id
      }
      count
    }
  }
`

/**
 * __useFetchSyncingExpensesQuery__
 *
 * To run a query within a React component, call `useFetchSyncingExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSyncingExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSyncingExpensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSyncingExpensesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchSyncingExpensesQuery, FetchSyncingExpensesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSyncingExpensesQuery, FetchSyncingExpensesQueryVariables>(
    FetchSyncingExpensesDocument,
    options
  )
}
export function useFetchSyncingExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchSyncingExpensesQuery, FetchSyncingExpensesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSyncingExpensesQuery, FetchSyncingExpensesQueryVariables>(
    FetchSyncingExpensesDocument,
    options
  )
}
export function useFetchSyncingExpensesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FetchSyncingExpensesQuery, FetchSyncingExpensesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FetchSyncingExpensesQuery, FetchSyncingExpensesQueryVariables>(
    FetchSyncingExpensesDocument,
    options
  )
}
export type FetchSyncingExpensesQueryHookResult = ReturnType<typeof useFetchSyncingExpensesQuery>
export type FetchSyncingExpensesLazyQueryHookResult = ReturnType<typeof useFetchSyncingExpensesLazyQuery>
export type FetchSyncingExpensesSuspenseQueryHookResult = ReturnType<typeof useFetchSyncingExpensesSuspenseQuery>
export type FetchSyncingExpensesQueryResult = Apollo.QueryResult<
  FetchSyncingExpensesQuery,
  FetchSyncingExpensesQueryVariables
>
export const DelinquencyAmountDocument = gql`
  query DelinquencyAmount {
    delinquencyAmount {
      amount
    }
  }
`

/**
 * __useDelinquencyAmountQuery__
 *
 * To run a query within a React component, call `useDelinquencyAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelinquencyAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelinquencyAmountQuery({
 *   variables: {
 *   },
 * });
 */
export function useDelinquencyAmountQuery(
  baseOptions?: Apollo.QueryHookOptions<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>(DelinquencyAmountDocument, options)
}
export function useDelinquencyAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>(
    DelinquencyAmountDocument,
    options
  )
}
export function useDelinquencyAmountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>(
    DelinquencyAmountDocument,
    options
  )
}
export type DelinquencyAmountQueryHookResult = ReturnType<typeof useDelinquencyAmountQuery>
export type DelinquencyAmountLazyQueryHookResult = ReturnType<typeof useDelinquencyAmountLazyQuery>
export type DelinquencyAmountSuspenseQueryHookResult = ReturnType<typeof useDelinquencyAmountSuspenseQuery>
export type DelinquencyAmountQueryResult = Apollo.QueryResult<DelinquencyAmountQuery, DelinquencyAmountQueryVariables>
export const CapitalApprovalDocument = gql`
  query CapitalApproval {
    capitalApproval {
      ...CapitalApprovalFragment
    }
  }
  ${CapitalApprovalFragmentFragmentDoc}
`

/**
 * __useCapitalApprovalQuery__
 *
 * To run a query within a React component, call `useCapitalApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapitalApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapitalApprovalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCapitalApprovalQuery(
  baseOptions?: Apollo.QueryHookOptions<CapitalApprovalQuery, CapitalApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CapitalApprovalQuery, CapitalApprovalQueryVariables>(CapitalApprovalDocument, options)
}
export function useCapitalApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CapitalApprovalQuery, CapitalApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CapitalApprovalQuery, CapitalApprovalQueryVariables>(CapitalApprovalDocument, options)
}
export function useCapitalApprovalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CapitalApprovalQuery, CapitalApprovalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CapitalApprovalQuery, CapitalApprovalQueryVariables>(CapitalApprovalDocument, options)
}
export type CapitalApprovalQueryHookResult = ReturnType<typeof useCapitalApprovalQuery>
export type CapitalApprovalLazyQueryHookResult = ReturnType<typeof useCapitalApprovalLazyQuery>
export type CapitalApprovalSuspenseQueryHookResult = ReturnType<typeof useCapitalApprovalSuspenseQuery>
export type CapitalApprovalQueryResult = Apollo.QueryResult<CapitalApprovalQuery, CapitalApprovalQueryVariables>
export const CanApplyForNewUserPromotionDocument = gql`
  query CanApplyForNewUserPromotion {
    canApplyForNewUserPromotion {
      canApply
      cohort
    }
  }
`

/**
 * __useCanApplyForNewUserPromotionQuery__
 *
 * To run a query within a React component, call `useCanApplyForNewUserPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanApplyForNewUserPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanApplyForNewUserPromotionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanApplyForNewUserPromotionQuery(
  baseOptions?: Apollo.QueryHookOptions<CanApplyForNewUserPromotionQuery, CanApplyForNewUserPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CanApplyForNewUserPromotionQuery, CanApplyForNewUserPromotionQueryVariables>(
    CanApplyForNewUserPromotionDocument,
    options
  )
}
export function useCanApplyForNewUserPromotionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CanApplyForNewUserPromotionQuery, CanApplyForNewUserPromotionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CanApplyForNewUserPromotionQuery, CanApplyForNewUserPromotionQueryVariables>(
    CanApplyForNewUserPromotionDocument,
    options
  )
}
export function useCanApplyForNewUserPromotionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CanApplyForNewUserPromotionQuery,
    CanApplyForNewUserPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CanApplyForNewUserPromotionQuery, CanApplyForNewUserPromotionQueryVariables>(
    CanApplyForNewUserPromotionDocument,
    options
  )
}
export type CanApplyForNewUserPromotionQueryHookResult = ReturnType<typeof useCanApplyForNewUserPromotionQuery>
export type CanApplyForNewUserPromotionLazyQueryHookResult = ReturnType<typeof useCanApplyForNewUserPromotionLazyQuery>
export type CanApplyForNewUserPromotionSuspenseQueryHookResult = ReturnType<
  typeof useCanApplyForNewUserPromotionSuspenseQuery
>
export type CanApplyForNewUserPromotionQueryResult = Apollo.QueryResult<
  CanApplyForNewUserPromotionQuery,
  CanApplyForNewUserPromotionQueryVariables
>
export const ActivationChecklistDocument = gql`
  query ActivationChecklist {
    activationChecklist {
      id
      name
      description
      completed
      rewardsAfterCompletion
      stepNumber
      ctaText
      ctaUrl
    }
  }
`

/**
 * __useActivationChecklistQuery__
 *
 * To run a query within a React component, call `useActivationChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivationChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivationChecklistQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivationChecklistQuery(
  baseOptions?: Apollo.QueryHookOptions<ActivationChecklistQuery, ActivationChecklistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActivationChecklistQuery, ActivationChecklistQueryVariables>(
    ActivationChecklistDocument,
    options
  )
}
export function useActivationChecklistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivationChecklistQuery, ActivationChecklistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActivationChecklistQuery, ActivationChecklistQueryVariables>(
    ActivationChecklistDocument,
    options
  )
}
export function useActivationChecklistSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ActivationChecklistQuery, ActivationChecklistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ActivationChecklistQuery, ActivationChecklistQueryVariables>(
    ActivationChecklistDocument,
    options
  )
}
export type ActivationChecklistQueryHookResult = ReturnType<typeof useActivationChecklistQuery>
export type ActivationChecklistLazyQueryHookResult = ReturnType<typeof useActivationChecklistLazyQuery>
export type ActivationChecklistSuspenseQueryHookResult = ReturnType<typeof useActivationChecklistSuspenseQuery>
export type ActivationChecklistQueryResult = Apollo.QueryResult<
  ActivationChecklistQuery,
  ActivationChecklistQueryVariables
>
export const GetUserAddressDocument = gql`
  query GetUserAddress {
    getUserAddress {
      address1
      address2
      postalCode
      city
      country
      state
    }
  }
`

/**
 * __useGetUserAddressQuery__
 *
 * To run a query within a React component, call `useGetUserAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserAddressQuery, GetUserAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserAddressQuery, GetUserAddressQueryVariables>(GetUserAddressDocument, options)
}
export function useGetUserAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserAddressQuery, GetUserAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserAddressQuery, GetUserAddressQueryVariables>(GetUserAddressDocument, options)
}
export function useGetUserAddressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserAddressQuery, GetUserAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetUserAddressQuery, GetUserAddressQueryVariables>(GetUserAddressDocument, options)
}
export type GetUserAddressQueryHookResult = ReturnType<typeof useGetUserAddressQuery>
export type GetUserAddressLazyQueryHookResult = ReturnType<typeof useGetUserAddressLazyQuery>
export type GetUserAddressSuspenseQueryHookResult = ReturnType<typeof useGetUserAddressSuspenseQuery>
export type GetUserAddressQueryResult = Apollo.QueryResult<GetUserAddressQuery, GetUserAddressQueryVariables>
export const CurrentStatementDocument = gql`
  query CurrentStatement {
    currentStatement {
      id
      businessId
      statementDateTo
      statementDueDate
    }
  }
`

/**
 * __useCurrentStatementQuery__
 *
 * To run a query within a React component, call `useCurrentStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentStatementQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentStatementQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentStatementQuery, CurrentStatementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentStatementQuery, CurrentStatementQueryVariables>(CurrentStatementDocument, options)
}
export function useCurrentStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentStatementQuery, CurrentStatementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentStatementQuery, CurrentStatementQueryVariables>(CurrentStatementDocument, options)
}
export function useCurrentStatementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentStatementQuery, CurrentStatementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CurrentStatementQuery, CurrentStatementQueryVariables>(
    CurrentStatementDocument,
    options
  )
}
export type CurrentStatementQueryHookResult = ReturnType<typeof useCurrentStatementQuery>
export type CurrentStatementLazyQueryHookResult = ReturnType<typeof useCurrentStatementLazyQuery>
export type CurrentStatementSuspenseQueryHookResult = ReturnType<typeof useCurrentStatementSuspenseQuery>
export type CurrentStatementQueryResult = Apollo.QueryResult<CurrentStatementQuery, CurrentStatementQueryVariables>
export const EstimateShippingTimeDocument = gql`
  query EstimateShippingTime {
    estimateShippingTime {
      estimatedShippingDate
      isVip
    }
  }
`

/**
 * __useEstimateShippingTimeQuery__
 *
 * To run a query within a React component, call `useEstimateShippingTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateShippingTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateShippingTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstimateShippingTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<EstimateShippingTimeQuery, EstimateShippingTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EstimateShippingTimeQuery, EstimateShippingTimeQueryVariables>(
    EstimateShippingTimeDocument,
    options
  )
}
export function useEstimateShippingTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimateShippingTimeQuery, EstimateShippingTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EstimateShippingTimeQuery, EstimateShippingTimeQueryVariables>(
    EstimateShippingTimeDocument,
    options
  )
}
export function useEstimateShippingTimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EstimateShippingTimeQuery, EstimateShippingTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<EstimateShippingTimeQuery, EstimateShippingTimeQueryVariables>(
    EstimateShippingTimeDocument,
    options
  )
}
export type EstimateShippingTimeQueryHookResult = ReturnType<typeof useEstimateShippingTimeQuery>
export type EstimateShippingTimeLazyQueryHookResult = ReturnType<typeof useEstimateShippingTimeLazyQuery>
export type EstimateShippingTimeSuspenseQueryHookResult = ReturnType<typeof useEstimateShippingTimeSuspenseQuery>
export type EstimateShippingTimeQueryResult = Apollo.QueryResult<
  EstimateShippingTimeQuery,
  EstimateShippingTimeQueryVariables
>
export const ListAccountingClassesDocument = gql`
  query ListAccountingClasses {
    listAccountingClasses {
      id
      name
      status
    }
  }
`

/**
 * __useListAccountingClassesQuery__
 *
 * To run a query within a React component, call `useListAccountingClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountingClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountingClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountingClassesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAccountingClassesQuery, ListAccountingClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAccountingClassesQuery, ListAccountingClassesQueryVariables>(
    ListAccountingClassesDocument,
    options
  )
}
export function useListAccountingClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAccountingClassesQuery, ListAccountingClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAccountingClassesQuery, ListAccountingClassesQueryVariables>(
    ListAccountingClassesDocument,
    options
  )
}
export function useListAccountingClassesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListAccountingClassesQuery, ListAccountingClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListAccountingClassesQuery, ListAccountingClassesQueryVariables>(
    ListAccountingClassesDocument,
    options
  )
}
export type ListAccountingClassesQueryHookResult = ReturnType<typeof useListAccountingClassesQuery>
export type ListAccountingClassesLazyQueryHookResult = ReturnType<typeof useListAccountingClassesLazyQuery>
export type ListAccountingClassesSuspenseQueryHookResult = ReturnType<typeof useListAccountingClassesSuspenseQuery>
export type ListAccountingClassesQueryResult = Apollo.QueryResult<
  ListAccountingClassesQuery,
  ListAccountingClassesQueryVariables
>
export const UserHasPhysicalCardDocument = gql`
  query UserHasPhysicalCard {
    userHasPhysicalCard
  }
`

/**
 * __useUserHasPhysicalCardQuery__
 *
 * To run a query within a React component, call `useUserHasPhysicalCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasPhysicalCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasPhysicalCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHasPhysicalCardQuery(
  baseOptions?: Apollo.QueryHookOptions<UserHasPhysicalCardQuery, UserHasPhysicalCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserHasPhysicalCardQuery, UserHasPhysicalCardQueryVariables>(
    UserHasPhysicalCardDocument,
    options
  )
}
export function useUserHasPhysicalCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHasPhysicalCardQuery, UserHasPhysicalCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserHasPhysicalCardQuery, UserHasPhysicalCardQueryVariables>(
    UserHasPhysicalCardDocument,
    options
  )
}
export function useUserHasPhysicalCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserHasPhysicalCardQuery, UserHasPhysicalCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserHasPhysicalCardQuery, UserHasPhysicalCardQueryVariables>(
    UserHasPhysicalCardDocument,
    options
  )
}
export type UserHasPhysicalCardQueryHookResult = ReturnType<typeof useUserHasPhysicalCardQuery>
export type UserHasPhysicalCardLazyQueryHookResult = ReturnType<typeof useUserHasPhysicalCardLazyQuery>
export type UserHasPhysicalCardSuspenseQueryHookResult = ReturnType<typeof useUserHasPhysicalCardSuspenseQuery>
export type UserHasPhysicalCardQueryResult = Apollo.QueryResult<
  UserHasPhysicalCardQuery,
  UserHasPhysicalCardQueryVariables
>
export const CalculateWholeAmountToPayForEarlyPaymentDocument = gql`
  query CalculateWholeAmountToPayForEarlyPayment($fundingId: String!) {
    calculateWholeAmountToPayForEarlyPayment(fundingId: $fundingId) {
      principalOutstandingAmount
      proRatedFeeAmount
      totalAmount
    }
  }
`

/**
 * __useCalculateWholeAmountToPayForEarlyPaymentQuery__
 *
 * To run a query within a React component, call `useCalculateWholeAmountToPayForEarlyPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateWholeAmountToPayForEarlyPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateWholeAmountToPayForEarlyPaymentQuery({
 *   variables: {
 *      fundingId: // value for 'fundingId'
 *   },
 * });
 */
export function useCalculateWholeAmountToPayForEarlyPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateWholeAmountToPayForEarlyPaymentQuery,
    CalculateWholeAmountToPayForEarlyPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CalculateWholeAmountToPayForEarlyPaymentQuery,
    CalculateWholeAmountToPayForEarlyPaymentQueryVariables
  >(CalculateWholeAmountToPayForEarlyPaymentDocument, options)
}
export function useCalculateWholeAmountToPayForEarlyPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateWholeAmountToPayForEarlyPaymentQuery,
    CalculateWholeAmountToPayForEarlyPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CalculateWholeAmountToPayForEarlyPaymentQuery,
    CalculateWholeAmountToPayForEarlyPaymentQueryVariables
  >(CalculateWholeAmountToPayForEarlyPaymentDocument, options)
}
export function useCalculateWholeAmountToPayForEarlyPaymentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CalculateWholeAmountToPayForEarlyPaymentQuery,
    CalculateWholeAmountToPayForEarlyPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CalculateWholeAmountToPayForEarlyPaymentQuery,
    CalculateWholeAmountToPayForEarlyPaymentQueryVariables
  >(CalculateWholeAmountToPayForEarlyPaymentDocument, options)
}
export type CalculateWholeAmountToPayForEarlyPaymentQueryHookResult = ReturnType<
  typeof useCalculateWholeAmountToPayForEarlyPaymentQuery
>
export type CalculateWholeAmountToPayForEarlyPaymentLazyQueryHookResult = ReturnType<
  typeof useCalculateWholeAmountToPayForEarlyPaymentLazyQuery
>
export type CalculateWholeAmountToPayForEarlyPaymentSuspenseQueryHookResult = ReturnType<
  typeof useCalculateWholeAmountToPayForEarlyPaymentSuspenseQuery
>
export type CalculateWholeAmountToPayForEarlyPaymentQueryResult = Apollo.QueryResult<
  CalculateWholeAmountToPayForEarlyPaymentQuery,
  CalculateWholeAmountToPayForEarlyPaymentQueryVariables
>
export const TotalCardsCreditLimitDocument = gql`
  query TotalCardsCreditLimit {
    totalCardsCreditLimit
  }
`

/**
 * __useTotalCardsCreditLimitQuery__
 *
 * To run a query within a React component, call `useTotalCardsCreditLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalCardsCreditLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalCardsCreditLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalCardsCreditLimitQuery(
  baseOptions?: Apollo.QueryHookOptions<TotalCardsCreditLimitQuery, TotalCardsCreditLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TotalCardsCreditLimitQuery, TotalCardsCreditLimitQueryVariables>(
    TotalCardsCreditLimitDocument,
    options
  )
}
export function useTotalCardsCreditLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalCardsCreditLimitQuery, TotalCardsCreditLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TotalCardsCreditLimitQuery, TotalCardsCreditLimitQueryVariables>(
    TotalCardsCreditLimitDocument,
    options
  )
}
export function useTotalCardsCreditLimitSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TotalCardsCreditLimitQuery, TotalCardsCreditLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TotalCardsCreditLimitQuery, TotalCardsCreditLimitQueryVariables>(
    TotalCardsCreditLimitDocument,
    options
  )
}
export type TotalCardsCreditLimitQueryHookResult = ReturnType<typeof useTotalCardsCreditLimitQuery>
export type TotalCardsCreditLimitLazyQueryHookResult = ReturnType<typeof useTotalCardsCreditLimitLazyQuery>
export type TotalCardsCreditLimitSuspenseQueryHookResult = ReturnType<typeof useTotalCardsCreditLimitSuspenseQuery>
export type TotalCardsCreditLimitQueryResult = Apollo.QueryResult<
  TotalCardsCreditLimitQuery,
  TotalCardsCreditLimitQueryVariables
>
export const ListWalletsDocument = gql`
  query ListWallets {
    wallets {
      id
      currency
      balance {
        availableBalance {
          amount
          amountAsNumber
        }
      }
    }
  }
`

/**
 * __useListWalletsQuery__
 *
 * To run a query within a React component, call `useListWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListWalletsQuery, ListWalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListWalletsQuery, ListWalletsQueryVariables>(ListWalletsDocument, options)
}
export function useListWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListWalletsQuery, ListWalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListWalletsQuery, ListWalletsQueryVariables>(ListWalletsDocument, options)
}
export function useListWalletsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListWalletsQuery, ListWalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ListWalletsQuery, ListWalletsQueryVariables>(ListWalletsDocument, options)
}
export type ListWalletsQueryHookResult = ReturnType<typeof useListWalletsQuery>
export type ListWalletsLazyQueryHookResult = ReturnType<typeof useListWalletsLazyQuery>
export type ListWalletsSuspenseQueryHookResult = ReturnType<typeof useListWalletsSuspenseQuery>
export type ListWalletsQueryResult = Apollo.QueryResult<ListWalletsQuery, ListWalletsQueryVariables>
export const WalletsDocument = gql`
  query Wallets {
    wallets {
      id
      currency
      businessId
      balance {
        availableBalance {
          amount
          amountAsNumber
        }
        currentBalance {
          amount
          amountAsNumber
        }
      }
      fundingAccounts {
        id
        paymentType
        bankAccount {
          id
          agreedPad
          name
          address
          country
          accountNumber
          routingCode
          routingCodeType
        }
      }
    }
  }
`

/**
 * __useWalletsQuery__
 *
 * To run a query within a React component, call `useWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletsQuery(baseOptions?: Apollo.QueryHookOptions<WalletsQuery, WalletsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WalletsQuery, WalletsQueryVariables>(WalletsDocument, options)
}
export function useWalletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletsQuery, WalletsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WalletsQuery, WalletsQueryVariables>(WalletsDocument, options)
}
export function useWalletsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<WalletsQuery, WalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WalletsQuery, WalletsQueryVariables>(WalletsDocument, options)
}
export type WalletsQueryHookResult = ReturnType<typeof useWalletsQuery>
export type WalletsLazyQueryHookResult = ReturnType<typeof useWalletsLazyQuery>
export type WalletsSuspenseQueryHookResult = ReturnType<typeof useWalletsSuspenseQuery>
export type WalletsQueryResult = Apollo.QueryResult<WalletsQuery, WalletsQueryVariables>
export const BankingStatementsDocument = gql`
  query BankingStatements($input: GetBankingStatementsInput) {
    bankingStatements(input: $input) {
      id
      businessId
      currency
      year
      month
      createdAt
    }
  }
`

/**
 * __useBankingStatementsQuery__
 *
 * To run a query within a React component, call `useBankingStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankingStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankingStatementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBankingStatementsQuery(
  baseOptions?: Apollo.QueryHookOptions<BankingStatementsQuery, BankingStatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BankingStatementsQuery, BankingStatementsQueryVariables>(BankingStatementsDocument, options)
}
export function useBankingStatementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankingStatementsQuery, BankingStatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BankingStatementsQuery, BankingStatementsQueryVariables>(
    BankingStatementsDocument,
    options
  )
}
export function useBankingStatementsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BankingStatementsQuery, BankingStatementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BankingStatementsQuery, BankingStatementsQueryVariables>(
    BankingStatementsDocument,
    options
  )
}
export type BankingStatementsQueryHookResult = ReturnType<typeof useBankingStatementsQuery>
export type BankingStatementsLazyQueryHookResult = ReturnType<typeof useBankingStatementsLazyQuery>
export type BankingStatementsSuspenseQueryHookResult = ReturnType<typeof useBankingStatementsSuspenseQuery>
export type BankingStatementsQueryResult = Apollo.QueryResult<BankingStatementsQuery, BankingStatementsQueryVariables>
export const GetBankingStatementUrlDocument = gql`
  query GetBankingStatementUrl($input: GetBankingStatementUrlInput!) {
    getBankingStatementUrl(input: $input) {
      signedUrl
    }
  }
`

/**
 * __useGetBankingStatementUrlQuery__
 *
 * To run a query within a React component, call `useGetBankingStatementUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankingStatementUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankingStatementUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBankingStatementUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetBankingStatementUrlQuery, GetBankingStatementUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBankingStatementUrlQuery, GetBankingStatementUrlQueryVariables>(
    GetBankingStatementUrlDocument,
    options
  )
}
export function useGetBankingStatementUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBankingStatementUrlQuery, GetBankingStatementUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBankingStatementUrlQuery, GetBankingStatementUrlQueryVariables>(
    GetBankingStatementUrlDocument,
    options
  )
}
export function useGetBankingStatementUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBankingStatementUrlQuery, GetBankingStatementUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetBankingStatementUrlQuery, GetBankingStatementUrlQueryVariables>(
    GetBankingStatementUrlDocument,
    options
  )
}
export type GetBankingStatementUrlQueryHookResult = ReturnType<typeof useGetBankingStatementUrlQuery>
export type GetBankingStatementUrlLazyQueryHookResult = ReturnType<typeof useGetBankingStatementUrlLazyQuery>
export type GetBankingStatementUrlSuspenseQueryHookResult = ReturnType<typeof useGetBankingStatementUrlSuspenseQuery>
export type GetBankingStatementUrlQueryResult = Apollo.QueryResult<
  GetBankingStatementUrlQuery,
  GetBankingStatementUrlQueryVariables
>
export const PaymentMethodsDocument = gql`
  query PaymentMethods {
    paymentMethods {
      id
      currency
      externalId
      isPrimary
      lastFour
      name
      type
    }
  }
`

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options)
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options)
}
export function usePaymentMethodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options)
}
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>
export type PaymentMethodsSuspenseQueryHookResult = ReturnType<typeof usePaymentMethodsSuspenseQuery>
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>
export const StatementPaymentsDocument = gql`
  query StatementPayments($status: [StatementPaymentStatus!], $order: OrderBy, $skip: Int, $take: Int) {
    statementPayments(status: $status, order: $order, skip: $skip, take: $take) {
      payments {
        id
        amount
        status
        createdAt
        initatorRole
      }
      skip
      take
      count
      totalCount
      pages
    }
  }
`

/**
 * __useStatementPaymentsQuery__
 *
 * To run a query within a React component, call `useStatementPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementPaymentsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStatementPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<StatementPaymentsQuery, StatementPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StatementPaymentsQuery, StatementPaymentsQueryVariables>(StatementPaymentsDocument, options)
}
export function useStatementPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StatementPaymentsQuery, StatementPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StatementPaymentsQuery, StatementPaymentsQueryVariables>(
    StatementPaymentsDocument,
    options
  )
}
export function useStatementPaymentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StatementPaymentsQuery, StatementPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StatementPaymentsQuery, StatementPaymentsQueryVariables>(
    StatementPaymentsDocument,
    options
  )
}
export type StatementPaymentsQueryHookResult = ReturnType<typeof useStatementPaymentsQuery>
export type StatementPaymentsLazyQueryHookResult = ReturnType<typeof useStatementPaymentsLazyQuery>
export type StatementPaymentsSuspenseQueryHookResult = ReturnType<typeof useStatementPaymentsSuspenseQuery>
export type StatementPaymentsQueryResult = Apollo.QueryResult<StatementPaymentsQuery, StatementPaymentsQueryVariables>
export const BusinessDelinquencyDetailsDocument = gql`
  query BusinessDelinquencyDetails($product: BusinessDelinquencyProduct!) {
    businessDelinquencyDetails(product: $product) {
      isDelinquent
      lastDelinquentAmount
    }
  }
`

/**
 * __useBusinessDelinquencyDetailsQuery__
 *
 * To run a query within a React component, call `useBusinessDelinquencyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessDelinquencyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessDelinquencyDetailsQuery({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useBusinessDelinquencyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessDelinquencyDetailsQuery, BusinessDelinquencyDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BusinessDelinquencyDetailsQuery, BusinessDelinquencyDetailsQueryVariables>(
    BusinessDelinquencyDetailsDocument,
    options
  )
}
export function useBusinessDelinquencyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessDelinquencyDetailsQuery, BusinessDelinquencyDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BusinessDelinquencyDetailsQuery, BusinessDelinquencyDetailsQueryVariables>(
    BusinessDelinquencyDetailsDocument,
    options
  )
}
export function useBusinessDelinquencyDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BusinessDelinquencyDetailsQuery,
    BusinessDelinquencyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BusinessDelinquencyDetailsQuery, BusinessDelinquencyDetailsQueryVariables>(
    BusinessDelinquencyDetailsDocument,
    options
  )
}
export type BusinessDelinquencyDetailsQueryHookResult = ReturnType<typeof useBusinessDelinquencyDetailsQuery>
export type BusinessDelinquencyDetailsLazyQueryHookResult = ReturnType<typeof useBusinessDelinquencyDetailsLazyQuery>
export type BusinessDelinquencyDetailsSuspenseQueryHookResult = ReturnType<
  typeof useBusinessDelinquencyDetailsSuspenseQuery
>
export type BusinessDelinquencyDetailsQueryResult = Apollo.QueryResult<
  BusinessDelinquencyDetailsQuery,
  BusinessDelinquencyDetailsQueryVariables
>
