import { FilterValues } from '../utils'
import { ExpenseFilterTag } from './expense-filter-tag'
import { FilterTagsProps } from './utils'

export const useReceiptTags = ({ form, onFilter }: FilterTagsProps) => {
  const getReceiptTag = (params: { receipt?: FilterValues['receipt'] }) => {
    const clearReceipt = () => form.setValue('receipt', '')
    if (params.receipt === 'nonEmpty') {
      return <ExpenseFilterTag form={form} label="With Receipt" onDelete={clearReceipt} onFilter={onFilter} />
    }
    if (params.receipt === 'empty') {
      return <ExpenseFilterTag form={form} label="Without Receipt" onDelete={clearReceipt} onFilter={onFilter} />
    }
    return null
  }

  return { getReceiptTag }
}
