import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

export const Drawer = defineMultiStyleConfig({
  variants: {
    sidebar: definePartsStyle({
      dialog: {
        maxW: '263px',
        mt: '81px',
      },
      overlay: {
        mt: '80px',
        backdropFilter: 'blur(10px)',
        height: 'calc(100vh - 81px)',
        mixBlendMode: 'normal',
        bg: 'rgba(0, 0, 0, 0.05)',
      },
      container: {
        mt: '80px',
      },
    }),
  },
})
