import { isDefined } from 'utils'

import { useListAccountingVendorsQuery } from '@/gql'

import { FilterValues } from '../utils'
import { ExpenseFilterTag } from './expense-filter-tag'
import { FilterTagsProps } from './utils'

export const useVendorTags = ({ form, onFilter }: FilterTagsProps) => {
  const { data: vendors } = useListAccountingVendorsQuery()

  const getVendorTag = (vendorId: string, vendorIds: string[]) => {
    const vendor = vendors?.listAccountingVendors?.find(({ id }) => id === vendorId)
    if (!isDefined(vendor)) {
      return null
    }
    const deleteVendor = () => {
      form.setValue(
        'vendors',
        vendorIds.filter((id) => id !== vendorId)
      )
    }
    return <ExpenseFilterTag form={form} label={vendor.vendorName} onDelete={deleteVendor} onFilter={onFilter} />
  }

  const getVendorTags = (params: Pick<FilterValues, 'vendors' | 'vendorsGroup'>) => {
    const clearVendors = () => {
      form.setValue('vendorsGroup', '')
      form.setValue('vendors', undefined)
    }
    const key = params.vendorsGroup === 'categorized' ? 'with-vendor' : 'without-vendor'
    const label = params.vendorsGroup === 'categorized' ? 'With Vendor' : 'Without Vendor'
    if (params.vendorsGroup === 'categorized' || params.vendorsGroup === 'uncategorized') {
      return [<ExpenseFilterTag key={key} form={form} label={label} onDelete={clearVendors} onFilter={onFilter} />]
    }
    if (!Array.isArray(params.vendors)) {
      return []
    }
    const tags: JSX.Element[] = []
    for (const vendor of params.vendors) {
      const tag = getVendorTag(vendor, params.vendors)
      if (isDefined(tag)) {
        tags.push(tag)
      }
    }
    return tags
  }

  return { getVendorTags }
}
