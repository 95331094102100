import { useBoolean } from 'ui-lib'

import { FileResult } from '@/src/hooks/misc'
import { useErrorToast } from '@/src/hooks/useErrorToast'

import { useAddReceiptToExpense } from './use-add-receipt-to-expense'
import { useUpdateExpenses } from './use-update-expenses'

type UpdateCardholderExpenseData = {
  memo: string
  receiptDocument: FileResult & { id?: string }
}

type UpdateCardholderExpense = {
  expenseId: string
  data: UpdateCardholderExpenseData
  onError?: (error: Error) => void
}

export const useUpdateCardholderExpenseData = () => {
  const { onApolloError } = useErrorToast()
  const [isLoading, setIsLoading] = useBoolean()
  const { updateExpenses } = useUpdateExpenses()
  const { addReceiptToExpense } = useAddReceiptToExpense()

  const updateCardholderExpenseData = async ({ expenseId, data, onError }: UpdateCardholderExpense) => {
    setIsLoading.on()
    try {
      const { updatedExpenseIds, errors } = await updateExpenses({
        expensesIds: [expenseId],
        input: { memo: data.memo },
      })
      if (updatedExpenseIds.length === 0 || errors.length > 0) {
        throw new Error('Error updating the expense')
      }
      addReceiptToExpense(expenseId, data.receiptDocument, {
        onError,
      })
      return { updatedExpenseId: updatedExpenseIds[0] }
    } catch (error) {
      if (error instanceof Error) {
        onApolloError(error)
        onError?.(error)
      }
    }
    setIsLoading.off()
  }

  return { updateCardholderExpenseData, isLoading }
}
