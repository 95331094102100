import { chakra } from '@chakra-ui/react'
import React, { ComponentProps } from 'react'

const ArrowLeftIcon = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 1L1 8M1 8L8 15M1 8H17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  )
}

export default ArrowLeftIcon
