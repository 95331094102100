import { useApolloClient } from '@apollo/client'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { isDefined } from 'utils'

import { usePayOffFundingMutation } from '@/gql'
import { BANK_ACCOUNT_TYPE } from '@/src/components/molecules/SelectPaymentMethod'
import { useConfirmAction } from '@/src/contexts/misc'
import { BrowserStorage } from '@/src/utils/misc'
import { GenericFunding } from '@/types/capital'

import { Action, EarlyPaymentContextType, PaymentStep, State, StepParams } from './types'

export const PAYMENT_STORAGE_KEY = 'early-payment'
const browserStorage = new BrowserStorage('session')

const Context = createContext<EarlyPaymentContextType | null>(null)

interface EarlyPaymentProviderProps {
  children: React.ReactNode
  isOpen: boolean
  onComplete: () => void
  funding: GenericFunding
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setAmount':
      return { ...state, paymentAmount: action.payload }
    case 'setAccount':
      return { ...state, account: action.payload }
    case 'setCurrentStep':
      return { ...state, currentStep: action.payload }
    case 'setToDefault':
      return { ...action.payload }
    default:
      return state
  }
}

const confirmOptions = {
  title: 'Exit without saving?',
  description: 'You have unsaved changes. Are you sure you want to exit?',
  primaryAction: {
    label: 'Exit',
  },
}

const initializeState = (defaultValues: State): State => {
  const earlyPayment = browserStorage.get(PAYMENT_STORAGE_KEY)

  if (!isDefined(earlyPayment)) {
    return defaultValues
  }

  return JSON.parse(earlyPayment) as State
}

export const EarlyPaymentProvider = (props: EarlyPaymentProviderProps) => {
  const confirm = useConfirmAction()

  const defaultValues: State = {
    funding: props.funding,
    paymentAmount: null,
    creationStatus: 'success',
    account: null,
    currentStep: 'amount',
    isLoading: false,
  }

  const [state, dispatch] = useReducer(reducer, defaultValues, initializeState)

  const [createPayment, { data, loading: isLoading, error }] = usePayOffFundingMutation()
  const createdPayment = data?.payOffFunding

  const client = useApolloClient()

  const setCurrentStep = (step: PaymentStep) => {
    dispatch({ type: 'setCurrentStep', payload: step })
  }

  const goToNextStep = async (params?: StepParams) => {
    const nextStep: Record<PaymentStep, PaymentStep> = {
      amount: 'account',
      account: 'review',
      review: 'complete',
      complete: 'complete',
    } as const

    if (state.currentStep === 'amount' && isDefined(params) && 'paymentAmount' in params) {
      dispatch({
        type: 'setAmount',
        payload: params.paymentAmount,
      })
      setCurrentStep(nextStep[state.currentStep])
    }

    if (state.currentStep === 'account' && isDefined(params) && 'account' in params) {
      dispatch({
        type: 'setAccount',
        payload: params.account,
      })
      setCurrentStep(nextStep[state.currentStep])
    }

    if (state.currentStep === 'review') {
      try {
        await createPayment({
          variables: {
            body: {
              fundingId: state.funding.fundingId,
              accountType: state.account?.type === BANK_ACCOUNT_TYPE.PlaidAccount ? 'PLAID' : 'CC',
              linkedAccountId: state.account?.type === BANK_ACCOUNT_TYPE.PlaidAccount ? state.account?.id : undefined,
            },
          },
        })
      } catch {
        console.error('Failed to create payment')
      }
      setCurrentStep(nextStep[state.currentStep])
    }
    if (state.currentStep === 'complete') {
      handleExit()
    }
  }

  const handleExit = async () => {
    dispatch({ type: 'setToDefault', payload: defaultValues })
    props.onComplete()
    browserStorage.remove(PAYMENT_STORAGE_KEY)
    client.refetchQueries({
      include: ['GetCapitalInfo', 'CapitalFundingsByBusinessIdPaginated', 'GetInstallments', 'StatementExtensions'],
    })
  }

  const confirmExit = () => {
    confirm(confirmOptions).then((result) => {
      if (result) {
        handleExit()
      }
    })
  }

  const goToPreviousStep = (params?: StepParams) => {
    const previousStep: Record<PaymentStep, PaymentStep> = {
      amount: 'amount',
      account: 'amount',
      review: 'account',
      complete: 'review',
    } as const

    if (state.currentStep === 'amount') {
      confirm(confirmOptions).then((result) => Boolean(result) && props.onComplete())
    }

    if (state.currentStep === 'account' && isDefined(params) && 'account' in params) {
      dispatch({
        type: 'setAccount',
        payload: params.account,
      })
    }

    setCurrentStep(previousStep[state.currentStep])
  }

  useEffect(() => {
    props.isOpen && browserStorage.set(PAYMENT_STORAGE_KEY, JSON.stringify(state))
  }, [props.isOpen, state])

  useEffect(() => {
    return () => {
      props.isOpen && browserStorage.remove(PAYMENT_STORAGE_KEY)
    }
  }, [props.isOpen])

  useEffect(() => {
    if (state.currentStep == 'complete' && isDefined(browserStorage.get(PAYMENT_STORAGE_KEY))) {
      browserStorage.remove(PAYMENT_STORAGE_KEY)
    }
  }, [state.currentStep])

  return (
    <Context.Provider
      value={{
        ...state,
        isLoading,
        creationStatus: isDefined(error) ? 'error' : 'success',
        goToPreviousStep,
        goToNextStep,
        goToStep: setCurrentStep,
        createdPayment,
        confirmExit,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export const useEarlyPayment = () => {
  const context = useContext(Context)

  if (!isDefined(context)) {
    throw new Error('useEarlyPayment must be used within a useEarlyPaymentProvider')
  }

  return context
}
