import { createContext, useContext, useEffect, useReducer } from 'react'
import { useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { useCreateAutoEnrollmentConfigMutation } from '@/gql'
import { useConfirmAction } from '@/src/contexts/misc'
import { BrowserStorage } from '@/src/utils/misc'

import {
  StatementExtensionAction,
  StatementExtensionContextType,
  StatementExtensionState,
  StatementExtensionStep,
  StatementExtensionStepParams,
} from './types'

export const EXTENSIONS_STORAGE_KEY = 'statement-extension'
const sessionStorage = new BrowserStorage('session')

const Context = createContext<StatementExtensionContextType | null>(null)

interface StatementExtensionProviderProps {
  children: React.ReactNode
  onComplete?: () => void
}

const reducer = (state: StatementExtensionState, action: StatementExtensionAction): StatementExtensionState => {
  switch (action.type) {
    case 'setExtensionData':
      return { ...state, ...action.payload }

    case 'setCurrentStep':
      return { ...state, currentStep: action.payload }

    case 'setToDefault':
      return { ...action.payload }
    default:
      return state
  }
}

const confirmOptions = {
  title: 'Exit without saving?',
  description: 'You have unsaved changes. Are you sure you want to exit?',
  primaryAction: {
    label: 'Exit',
  },
}

const initializeState = (defaultValues: StatementExtensionState): StatementExtensionState => {
  const statementExtension = sessionStorage.get(EXTENSIONS_STORAGE_KEY)

  if (!isDefined(statementExtension)) {
    return defaultValues
  }

  return JSON.parse(statementExtension) as StatementExtensionState
}

export const StatementExtensionProvider = (props: StatementExtensionProviderProps) => {
  const confirm = useConfirmAction()
  const toast = useToast()

  const defaultValues: StatementExtensionState = {
    repaymentFrequency: 'Weekly',
    enrollmentEnabled: false,
    repaymentInstallments: 0,
    currentStep: 'plan',
    creationStatus: 'success',
    isLoading: false,
  }

  const [state, dispatch] = useReducer(reducer, defaultValues, initializeState)

  const [createEnrollment, { data, loading, error }] = useCreateAutoEnrollmentConfigMutation()
  const createdExtension = data?.createAutoEnrollmentConfig

  const setCurrentStep = (step: StatementExtensionStep) => {
    dispatch({ type: 'setCurrentStep', payload: step })
  }

  const goToNextStep = async (params?: StatementExtensionStepParams) => {
    const nextStep: Record<StatementExtensionStep, StatementExtensionStep> = {
      plan: 'review',
      review: 'complete',
      complete: 'complete',
    } as const

    if (
      state.currentStep === 'plan' &&
      isDefined(params) &&
      'repaymentInstallments' in params &&
      'enrollmentEnabled' in params
    ) {
      dispatch({
        type: 'setExtensionData',
        payload: params,
      })
      setCurrentStep(nextStep[state.currentStep])
    }

    if (state.currentStep === 'review') {
      createEnrollment({
        variables: {
          enabled: state.enrollmentEnabled,
          repaymentInstallments: state.repaymentInstallments,
        },
        onError(error) {
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        },

        onCompleted() {
          setCurrentStep(nextStep[state.currentStep])
        },
        refetchQueries: ['StatementExtensionApproval'],
        awaitRefetchQueries: true,
      })
      setCurrentStep(nextStep[state.currentStep])
    }
    if (state.currentStep === 'complete') {
      handleExit()
    }
  }

  const handleExit = async () => {
    props.onComplete?.()
    sessionStorage.remove(EXTENSIONS_STORAGE_KEY)
  }

  const confirmExit = () => {
    confirm(confirmOptions).then((result) => {
      if (result) {
        handleExit()
      }
    })
  }

  const goToPreviousStep = () => {
    const previousStep: Record<StatementExtensionStep, StatementExtensionStep> = {
      plan: 'plan',
      review: 'plan',
      complete: 'review',
    } as const

    if (state.currentStep === 'plan') {
      confirm(confirmOptions).then((result) => Boolean(result) && props.onComplete?.())
    }

    setCurrentStep(previousStep[state.currentStep])
  }

  useEffect(() => {
    sessionStorage.set(EXTENSIONS_STORAGE_KEY, JSON.stringify(state))
  }, [state])

  useEffect(() => {
    return () => {
      sessionStorage.remove(EXTENSIONS_STORAGE_KEY)
    }
  }, [])

  useEffect(() => {
    if (state.currentStep == 'complete' && isDefined(sessionStorage.get(EXTENSIONS_STORAGE_KEY))) {
      sessionStorage.remove(EXTENSIONS_STORAGE_KEY)
    }
  }, [state.currentStep])

  return (
    <Context.Provider
      value={{
        ...state,
        isLoading: loading,
        creationStatus: isDefined(error) ? 'error' : 'success',
        goToPreviousStep,
        goToNextStep,
        goToStep: setCurrentStep,
        createdExtension,
        confirmExit,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export const useStatementExtension = () => {
  const context = useContext(Context)

  if (!isDefined(context)) {
    throw new Error('useStatementExtension must be used within a useStatementExtensionProvider')
  }

  return context
}
