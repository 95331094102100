import { useSession } from 'next-auth/react'
import { useFormContext } from 'react-hook-form'
import { Box, Flex, Form, Icon, isDefined, Spinner, Tooltip, useBoolean, useToast, VisuallyHiddenInput } from 'ui-lib'
import { sanitizeFileName } from 'utils'

import { ExpenseStatus } from '@/gql'
import { useAddReceiptToExpense } from '@/src/hooks/accounting'
import { FileResult, useUploadFile } from '@/src/hooks/misc'

import { ColumnComponentProps } from '../expenses-table-form/types'
import { ReceiptActionsPopover } from './receipt-actions-popover'

export interface ReceiptButtonProps extends ColumnComponentProps {
  disabled?: boolean
}

export const ReceiptButton = ({ row, disabled = false }: ReceiptButtonProps) => {
  const expense = row.original

  const { formState, setValue } = useFormContext()
  const toast = useToast()
  const { data: session } = useSession()
  const activeUser = session?.user?.internal
  const hasDocument = isDefined(expense.receipt)

  const [isLoadingReceipt, setIsLoadingReceipt] = useBoolean(false)

  const { uploadFile } = useUploadFile({
    path: `business/${activeUser?.businessId}/expenseReceipts/${expense.id}/`,
  })

  const { addReceiptToExpense } = useAddReceiptToExpense()

  const handleAddReceiptToExpense = async (expenseId: string, receiptDocument: FileResult) => {
    const response = await addReceiptToExpense(expenseId, receiptDocument)
    if (isDefined(response)) {
      row.original.receipt = response
      setValue(`dataGridExpenses.${row.index}.receipt`, response)
      toast({
        title: 'Receipt added',
        description: 'The receipt has been successfully added to the expense',
        status: 'success',
      })
    }
  }

  const handleChangeReceipt = async (files: FileList) => {
    const sanitizedName = sanitizeFileName(files[0].name)
    const extension = files[0].name.split('.').pop()
    const newFile = new File([files[0]], `${sanitizedName}.${extension}`, { type: files[0].type })
    if (!isDefined(newFile) || !(newFile instanceof File)) {
      return
    }
    try {
      setIsLoadingReceipt.on()
      const uploadedFile = await uploadFile(newFile)
      if (isDefined(uploadedFile.error)) {
        throw uploadedFile.error
      }
      await handleAddReceiptToExpense(expense.id, uploadedFile)
    } catch (err) {
      console.error(err)
    }
    setIsLoadingReceipt.off()
  }

  const deleteReceiptFromTable = async () => {
    row.original.receipt = undefined
    setValue(`dataGridExpenses.${row.index}.receipt`, null)
  }

  const canDeleteReceipt = () => {
    const RESTRICTED_STATUSES = [ExpenseStatus.Synced, ExpenseStatus.Syncing, ExpenseStatus.ExternallySynced]
    return !RESTRICTED_STATUSES.includes(expense.status)
  }

  return (
    <Form.Field
      id={`dataGridExpenses.${row.index}.receipt`}
      errorMessage={(formState.errors.dataGridExpenses?.at(row.index)?.receipt as any)?.message}
    >
      <Flex position="relative" justifyContent="center">
        {isLoadingReceipt && <Spinner size="xs" />}
        {!hasDocument && !isLoadingReceipt && (
          <>
            <Icon icon="add-receipt" size="md" variant="original" />
            <Tooltip label="Add receipt" hasArrow={false} openDelay={200}>
              <Box as="label" position="absolute" w="full" h="full" top="0" left="0" cursor="pointer">
                <VisuallyHiddenInput
                  disabled={disabled}
                  type="file"
                  multiple={false}
                  accept=".pdf,.png,.jpg,.jpeg"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.files == null || event.target.files.length === 0) {
                      return
                    }
                    handleChangeReceipt(event.target.files)
                  }}
                />
              </Box>
            </Tooltip>
          </>
        )}
        {isDefined(expense.receipt) && (
          <ReceiptActionsPopover
            expenseId={expense.id}
            receipt={expense.receipt}
            allowDelete={canDeleteReceipt()}
            onDeleteReceipt={deleteReceiptFromTable}
          />
        )}
      </Flex>
    </Form.Field>
  )
}
