import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { FieldError, useForm, useWatch } from 'react-hook-form'
import { ActionButtons, Flex, Form, Skeleton, Text } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { BankConnectionAccountsQuery, useBankConnectionAccountsQuery } from '@/gql'
import SelectPaymentMethod, {
  Account,
  BANK_ACCOUNT_TYPE,
  PlaidAccount,
} from '@/src/components/molecules/SelectPaymentMethod'
import { SEGMENT_EVENTS, TEAM } from '@/src/constants/segmentEvents'
import { useEarlyPayment } from '@/src/contexts/capital'
import { useServerTrackEvent } from '@/src/hooks/misc'
import { useAccounts } from '@/src/hooks/useAccounts'
import { useUserHasAllFeatureFlags } from '@/src/hooks/useUserHasFeatureFlags'

import { accountStepSchema, FormValues } from './types'

const formatAccount = (account: Account) => {
  if (account.type === BANK_ACCOUNT_TYPE.CCAccount) {
    return {
      ...account,
      accountName: `${account.currencyCode} Account`,
      accountNumber: account.accountNumber.slice(-4),
    }
  }
  return account
}

const transformToAccount = (bankAccount: BankConnectionAccountsQuery['bankConnectionAccounts'][0]): PlaidAccount => {
  return {
    type: 'PlaidAccount',
    id: bankAccount?.id,
    accountName: bankAccount?.name,
    accountNumber: bankAccount?.accountNumber?.slice(-4) ?? 'Error',
    isPrimary: bankAccount?.isPrimary ?? false,
    bankLogo: bankAccount?.bank?.logo ?? '',
  }
}

export const AccountStep = () => {
  const trackEvent = useServerTrackEvent()
  const ccPaymentEnabled = useUserHasAllFeatureFlags('CC_STATEMENT_PAYMENT') ?? false
  const { paymentAmount, account, goToNextStep, goToPreviousStep, funding } = useEarlyPayment()
  const { data, loading } = useBankConnectionAccountsQuery()
  const { getAccountByCurrency } = useAccounts()
  const ccCadAccount = getAccountByCurrency('CAD')
  const bankConnectionAccounts = data?.bankConnectionAccounts ?? []

  const {
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: zodResolver(accountStepSchema),
    defaultValues: {
      amount: paymentAmount ?? 0,
      account: account ?? undefined,
    },
  })

  const selectedAccount: Account | undefined = useWatch({ control, name: 'account' })

  const onSubmit = (data: FormValues) => {
    trackEvent({
      type: SEGMENT_EVENTS.EARLY_PAYMENT_ACCOUNT_SELECTED,
      payload: { team: TEAM.CAPITAL, financingChannel: funding.financingChannel, plan_number: funding.loanNumber },
    })
    isDefined(data.account) && goToNextStep?.({ account: formatAccount(data.account) })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap="6" flexDir="column">
        <Text textStyle="subheading-md" color="text-secondary" textTransform="uppercase">
          SELECT PAYMENT ACCOUNT
        </Text>
        <Form.Field id="amount" label="amount">
          <Form.NumberInput
            label="amount"
            value={Money.fromNumber(paymentAmount ?? 0).toFormattedCurrencyString()}
            readOnly={true}
            disabled={true}
          />
        </Form.Field>
        {loading && !isDefined(bankConnectionAccounts) && <Skeleton w="full" height="100px" />}
        {isDefined(bankConnectionAccounts) && (
          <Flex gap="2" flexDir="column">
            <Text textStyle="subheading-md" color="text-secondary" textTransform="uppercase" mb="1">
              From
            </Text>
            <SelectPaymentMethod
              plaidAccounts={bankConnectionAccounts.map(transformToAccount)}
              onRowSelected={(account: Account | undefined) => {
                if (isDefined(account)) {
                  setValue('account', account)
                }
              }}
              selectedBankAccount={selectedAccount}
              ccAccounts={
                isDefined(ccCadAccount) && ccPaymentEnabled
                  ? // AccountType has Wallet.id as the id, override it with the CCAccountId for the CC account option
                    [{ ...ccCadAccount, id: ccCadAccount.ccAccountId }]
                  : []
              }
            />
            <Text textStyle="paragraph-sm" color="text-error">
              {(errors.account as FieldError | undefined)?.message}
            </Text>
          </Flex>
        )}
      </Flex>
      <ActionButtons mt="6">
        <ActionButtons.Secondary
          onClick={() => goToPreviousStep?.(isDefined(selectedAccount) ? { account: selectedAccount } : undefined)}
        >
          Back
        </ActionButtons.Secondary>
        <ActionButtons.Primary type="submit" disabled={isSubmitting || isDefined(errors.amount)}>
          Continue
        </ActionButtons.Primary>
      </ActionButtons>
    </Form>
  )
}
