import { Flex, Image, Text } from 'ui-lib'

import { CardType } from '@/gql'

import { ColumnComponentProps } from './types'

export const ExpenseNameOnCard = ({ row }: ColumnComponentProps) => {
  return (
    <Flex gap={3} alignItems="center">
      <Image
        src={
          row.original.cardType === CardType.Physical ? '/cards/physical-card-mini.png' : '/cards/virtual-card-mini.png'
        }
        width="8"
        h="auto"
        alt="card-logo"
      />
      <Flex flexDirection="column">
        <Text textStyle="paragraph-md" color="text-primary" whiteSpace="nowrap">
          {row.original.nameOnCard}
        </Text>
        <Text textStyle="paragraph-sm" color="text-soft">
          {row.original.lastFour}
        </Text>
      </Flex>
    </Flex>
  )
}
