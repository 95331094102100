export const ErrorTypes = {
  DUPLICATED: 'DUPLICATED',
  EXCEEDED_MAX_FILES: 'EXCEEDED_MAX_FILES',
  OVERSIZED: 'OVERSIZED',
  INVALID_TYPE: 'INVALID_TYPE',
} as const

export type ErrorTypes = keyof typeof ErrorTypes

export const FileType = {
  png: 'image/png',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
  any: '*/*',
} as const

export type FileType = keyof typeof FileType

export interface FileInputProps {
  accept: FileType[]
  id: string
  maxFiles?: number
  maxFileSize: number
  isDisabled?: boolean
  isInvalid?: boolean
  isLoading?: boolean
  isRequired?: boolean
  label?: string
  onError?: (type: ErrorTypes, message: string) => void
  onFilesChange?: (files: File[]) => void
  value?: File[]
  requiresNameSanitization?: boolean
}

export type DraggingStatus = 'dragging' | 'idle'
