import { mode } from '@chakra-ui/theme-tools'

export const Sidebar = {
  baseStyle: {
    container: (props: any) => ({
      bg: mode('white', 'primary.500')(props),
    }),
  },
  badge: (props: { badgeType: 'count' | 'new' }) => {
    const { badgeType } = props

    const notificationBadgeStyles = {
      bg: 'icon-error',
      color: 'white',
      width: '18px',
      height: '18px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      fontSize: '11px',
    }

    const newBadgeStyles = {
      bg: 'icon-info',
      color: 'white',
      textTransform: 'none',
      fontSize: 'xs',
      height: '20px',
      width: '42px',
      textAlign: 'center',
    }

    return badgeType === 'count' ? notificationBadgeStyles : newBadgeStyles
  },
  menuItem: (props: {
    isSidebarOpen: boolean
    isExpanded: boolean
    isSubmenu: boolean
    isActive: boolean
    badgeType: 'count' | 'new'
  }) => {
    const { isSidebarOpen, isExpanded, isSubmenu, isActive, badgeType } = props

    const layoutWidthMobile = isSubmenu ? '213px' : '247px'
    const layoutWidthDesktop = isSidebarOpen ? (isSubmenu ? '227px' : '247px') : '40px'

    const layoutStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: isSidebarOpen ? 'space-between' : 'center',
      width: {
        base: layoutWidthMobile,
        md: layoutWidthDesktop,
      },
      height: '40px',
      px: isSidebarOpen ? 2 : 0,
    }

    // Visual appearance styles
    const visualStyles = {
      bg: 'transparent',
      color: 'text-disabled',
      borderRadius: 'md',
      _hover: {
        bg: 'bg-disabled',
      },
    }

    // Styles applied when menu item is active
    const activeStyles = {
      color: 'text-primary',
      bg: 'active-item-gradient',
      border: '1px solid',
      borderColor: 'border-soft',
    }

    // Icon container styles
    const iconStyles = {
      width: 6,
      height: 6,
      color: Boolean(isActive) ? 'text-primary' : 'text-disabled',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }

    // Label container styles
    const labelContainerStyles = {
      display: 'flex',
      width: 'full',
    }

    // Label text styles
    const labelTextStyles = {
      color: Boolean(isActive) ? 'text-primary' : 'text-disabled',
      ml: 3,
      transition: 'color 0.2s',
      _groupHover: {
        color: 'text-secondary',
      },
    }

    // Badge container styles
    const badgeContainerStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'auto',
      alignItems: 'center',
    }

    // Add new collapsed badge styles
    const collapsedBadgeStyles = {
      position: 'absolute',
      top: '8px',
      right: '7px',
      width: '7px',
      height: '7px',
      borderRadius: 'full',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '10px',
      color: 'white',
      bg: badgeType === 'count' ? 'semantics.iconError' : 'semantics.iconInfo',
    }

    const chevronStyles = {
      ml: 2,
      transform: Boolean(isExpanded) ? 'rotate(180deg)' : undefined,
      justifySelf: 'flex-end',
    }

    return {
      layoutStyles,
      visualStyles,
      activeStyles,
      iconStyles,
      labelContainerStyles,
      badgeContainerStyles,
      collapsedBadgeStyles,
      chevronStyles,
      labelTextStyles,
    }
  },
  userMenu: (props: { isOpen: boolean }) => {
    const { isOpen } = props

    const avatarStyles = {
      w: '40px',
      h: '40px',
      color: 'white',
      bg: 'avatar-gradient',
    }

    const menuButtonFlexStyles = {
      border: '1px solid',
      borderColor: 'transparent',
      transition: 'all 0.2s',
      borderRadius: 'md',
      _groupHover: { bg: 'bg.disabled' },
      px: {
        base: 3,
        md: isOpen ? 4 : 3,
      },
      width: 'full',

      _groupActive: {
        bg: 'active-item-gradient',
        borderColor: 'border-soft',
        borderRadius: 'md',
      },

      '[data-active] &': {
        bg: 'active-item-gradient',
        border: '1px solid',
        borderColor: 'border-soft',
        borderRadius: 'md',
      },
    }

    const innerFlexStyles = {
      align: 'center',
      height: '66px',
      cursor: 'pointer',
      width: isOpen ? '100%' : 'auto',
      justify: isOpen ? 'flex-start' : 'center',
    }

    const menuListStyles = {
      p: 0,
      mb: 4,
      ml: 2,
      minW: '252px',
      borderRadius: 'md',
      boxShadow: 'md',
      overflow: 'hidden',
    }

    const menuItemStyles = {
      height: '48px',
      boxShadow: 'md',
      px: 4,
      transition: 'all 0.2s',
      color: 'text-disabled',
      _notFirst: {
        borderTop: '1px solid',
        borderColor: 'border-soft',
      },
      _hover: {
        bg: 'bg.disabled',
        color: 'text-secondary',
      },
      _focus: {
        bg: 'bg.disabled',
      },
    }

    const userInfoStyles = {
      p: 4,
      borderBottom: '1px solid',
      borderColor: 'border-soft',
      height: '78px',
    }

    return {
      avatarStyles,
      menuButtonFlexStyles,
      innerFlexStyles,
      menuListStyles,
      menuItemStyles,
      userInfoStyles,
    }
  },
  header: (props: { isOpen: boolean }) => {
    const { isOpen } = props

    const wrapperStyle = {
      alignItems: 'center',
      p: '2',
      borderBottomWidth: '1px',
    }

    const containerStyle = {
      width: '100%',
      height: '63px',
      borderRadius: 'md',
    }

    const flexContainerStyle = {
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      px: '4',
      position: 'relative',
      borderRadius: 'md',
      transition: 'all 0.2s',
      _hover: {
        bg: 'bg-disabled',
      },
    }

    const imageWrapperStyle = {
      position: 'relative',
    }

    const logoStyle = {
      height: '8',
      width: isOpen ? '110px' : '29px',
      opacity: !isOpen ? '_groupHover.opacity.0' : 1,
      transition: 'opacity 0.2s',
    }

    const toggleButtonStyle = {
      opacity: 0,
      _groupHover: { opacity: 1 },
      transition: 'opacity 0.2s',
    }

    const collapsedButtonStyle = {
      ...toggleButtonStyle,
      bg: 'bg.disabled',
      position: 'absolute',
      left: 0,
      top: 0,
    }

    const containerMobileStyle = {
      px: '4',
      alignItems: 'center',
      width: '100%',
      borderBottomWidth: '1px',
      borderColor: 'border-disabled',
    }

    return {
      wrapper: wrapperStyle,
      container: containerStyle,
      flexContainer: flexContainerStyle,
      imageWrapper: imageWrapperStyle,
      logo: logoStyle,
      toggleButton: toggleButtonStyle,
      collapsedButton: collapsedButtonStyle,
      containerMobile: containerMobileStyle,
    }
  },
  footer: {
    flexContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: '1px',
      borderColor: 'border-disabled',
      w: 'full',
      height: '90px',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      width: 'full',
      borderRadius: 'md',
    },
  },
}
