import { Text, TextProps } from 'ui-lib'
type GradientTextProps = TextProps & {
  gradient: string
}

const GradientText = ({ gradient, ...props }: GradientTextProps) => (
  <Text
    background={`gradients.${gradient}`}
    backgroundClip="text"
    width={props.width ?? 'max-content'}
    maxW="full"
    sx={{ WebkitTextFillColor: 'transparent' }}
    {...props}
  />
)

export default GradientText
