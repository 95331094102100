import { find } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Form, Icon } from 'ui-lib'
import { isDefined } from 'utils'

import { ExpenseStatus, useListAccountingTaxRatesQuery } from '@/gql'
import { useUpdateExpenses } from '@/src/hooks/accounting'

import { buildTaxRateOptions } from '../utils'
import { ColumnComponentProps } from './types'

export const ExpenseTaxRateSelect = ({ row }: ColumnComponentProps) => {
  const { control, formState, getValues } = useFormContext()

  const { data: taxRates } = useListAccountingTaxRatesQuery()

  const { updateExpenses } = useUpdateExpenses()
  const isDisabled = row.original.status === ExpenseStatus.Synced

  function findTaxRateOption(entityTaxCodeId: string | null | undefined) {
    if (!isDefined(entityTaxCodeId)) {
      return null
    }
    return find(buildTaxRateOptions(taxRates?.listAccountingTaxRates), { value: entityTaxCodeId })
  }

  return (
    <Box minW="180px">
      <Controller
        control={control}
        name={`dataGridExpenses.${row.index}.entityTaxCodeId`}
        render={({ field }) => (
          <Form.Field
            id={`dataGridExpenses.${row.index}.entityTaxCodeId`}
            errorMessage={formState.errors.dataGridExpenses?.at(row.index)?.entityTaxCodeId?.message}
          >
            <Form.Select
              value={findTaxRateOption(getValues(`dataGridExpenses.${row.index}.entityTaxCodeId`))}
              options={buildTaxRateOptions(taxRates?.listAccountingTaxRates)}
              onChange={(v) => {
                field.onChange(v?.value)
                updateExpenses({
                  expensesIds: [row.original.id],
                  input: { entityTaxCodeId: isDefined(v) ? v.value : null },
                })
              }}
              dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
              isDisabled={isDisabled}
            />
          </Form.Field>
        )}
      />
    </Box>
  )
}
