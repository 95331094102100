import React from 'react'
import { Box, Flex, useMediaQuery } from 'ui-lib'
import { isDefined } from 'utils'

import { FullStepType } from '@/src/constants/steps'

import { StepperDesktop } from './stepper-desktop'
import { StepperMobile } from './stepper-mobile'

interface Props<T> {
  currentStep: T
  steps: { [key: string]: FullStepType<T> }
  hideSteps?: boolean
  disableSteps?: boolean
  onStepChange: (step: T) => void
  children: React.ReactNode
}

export const SidebarStepperTemplate = <T,>({
  currentStep,
  steps,
  hideSteps = false,
  disableSteps = false,
  onStepChange,
  children,
}: Props<T>) => {
  const [isLessThan760] = useMediaQuery('(max-width: 768px)')

  const listOfSteps = Object.values(steps).filter((step) => !Boolean(step.skip))

  const activeStep = steps[currentStep as string]

  const activeIndex = listOfSteps.findIndex((step) => step.position === activeStep.position)

  const isPastStep = (step: FullStepType<T>) => step.position < activeStep.position

  const goToStep = (stepIndex: number) => {
    const step = listOfSteps[stepIndex]
    if (disableSteps || !isPastStep(step)) {
      return
    }
    onStepChange(step.key)
  }

  const goToPreviousStep = () => {
    if (disableSteps) {
      return
    }
    const previousStep = listOfSteps[activeIndex].previous
    if (isDefined(previousStep)) {
      onStepChange(previousStep)
    }
  }

  return (
    <Box width="full">
      <Box position="relative" width="full" minWidth="100hv">
        {!hideSteps && !isLessThan760 && (
          <StepperDesktop
            activeStep={activeStep}
            activeIndex={activeIndex}
            title={activeStep.label}
            totalSteps={listOfSteps.length}
            onClick={goToStep}
            steps={listOfSteps.filter((step) => !Boolean(step.hideStepInNav))}
            goToPreviousStep={goToPreviousStep}
            disableSteps={disableSteps}
          />
        )}
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          marginInline="auto"
          width="full"
          maxWidth="480px"
          paddingInline={{ base: '6', md: '0' }}
          pb="6"
        >
          {!hideSteps && isLessThan760 && (
            <StepperMobile
              activeStep={activeStep}
              activeIndex={activeIndex}
              title={activeStep.label}
              totalSteps={listOfSteps.length}
              onClick={goToStep}
              goToPreviousStep={goToPreviousStep}
              disableSteps={disableSteps}
            />
          )}
          {children}
        </Flex>
      </Box>
    </Box>
  )
}
