import { Tag } from 'ui-lib'

import { useGetCapitalInfoQuery } from '@/gql'
import { useInternalUserStatus } from '@/src/utils/user-session'

export const CreditLimitStatusTag = () => {
  const { data } = useGetCapitalInfoQuery()
  const { userBusinessIsActive } = useInternalUserStatus()
  const underwritingStatus = data?.capitalInfo?.underwritingStatus

  if (underwritingStatus !== 'PENDING' || !userBusinessIsActive) {
    return null
  }

  return (
    <Tag h="fit-content" variant="neutral">
      UNDER REVIEW
    </Tag>
  )
}
