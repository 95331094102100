import moment from 'moment'
import { Money } from 'utils'

import { SerializedStatementExtensionPaymentInfo, StatementExtensionPaymentInfo } from '@/types/capital'

export const statementExtensionPaymentInfoUnserializer = (
  serializedStatementExtensionPaymentInfo: SerializedStatementExtensionPaymentInfo
): StatementExtensionPaymentInfo => ({
  ...serializedStatementExtensionPaymentInfo,
  outstandingBalance: Money.fromFormattedCurrencyString(serializedStatementExtensionPaymentInfo.outstandingBalance),
  paymentDate: moment(serializedStatementExtensionPaymentInfo.paymentDate).toDate(),
})
