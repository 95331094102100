import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { ActionButtons, Flex, Form, Icon, Modal, Text } from 'ui-lib'
import { isDefined } from 'utils'
import { z } from 'zod'

import {
  AccountingExpense,
  ExpenseErrorType,
  ExpenseStatus,
  useListAccountingCategoriesQuery,
  useListAccountingClassesQuery,
  useListAccountingTaxRatesQuery,
  useListAccountingVendorsQuery,
} from '@/gql'
import { useUpdateExpenses } from '@/src/hooks/accounting'

import {
  buildCategoryOptions,
  buildClassOptions,
  buildStatusOptions,
  buildTaxRateOptions,
  buildVendorsOptions,
} from './utils'

const schema = z
  .object({
    form: z.void().optional(),
    status: z.nativeEnum(ExpenseStatus).optional(),
    entityCategoryId: z.string().optional(),
    entityTaxCodeId: z.string().optional(),
    entityMerchantId: z.string().optional(),
    entityClassId: z.string().optional(),
  })
  .refine(
    (values) => {
      const hasSelection = [
        values.status,
        values.entityCategoryId,
        values.entityTaxCodeId,
        values.entityMerchantId,
        values.entityClassId,
      ].some(isDefined)
      return hasSelection
    },
    { message: 'Please select at least one field to update.', path: ['form'] }
  )

export type BulkUpdateValues = z.infer<typeof schema>

interface Props {
  isOpen: boolean
  expenses: AccountingExpense[]
  statusesToTransition: ExpenseStatus[]
  onClose: () => void
  onUpdate: (updatedExpenseIds: string[], errors: ExpenseErrorType[], values: BulkUpdateValues) => void
}

export const BulkUpdateExpensesModal = ({ isOpen, expenses, statusesToTransition, onClose, onUpdate }: Props) => {
  const { updateExpenses } = useUpdateExpenses()

  const { data: categories } = useListAccountingCategoriesQuery()
  const { data: taxRates } = useListAccountingTaxRatesQuery()
  const { data: vendors } = useListAccountingVendorsQuery()
  const { data: classes } = useListAccountingClassesQuery()

  const { control, formState, handleSubmit, trigger, reset } = useForm<BulkUpdateValues>({
    resolver: zodResolver(schema),
  })

  const handleBulkUpdate = async (values: BulkUpdateValues) => {
    const { updatedExpenseIds, errors } = await updateExpenses({
      expensesIds: expenses.map((e) => e.id),
      input: values,
    })
    onUpdate(updatedExpenseIds, errors, values)
    close()
  }

  const close = () => {
    reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={close} isCentered>
      <Modal.Content as={Form} onSubmit={handleSubmit(handleBulkUpdate)}>
        {!formState.isSubmitting && <Modal.CloseButton />}
        <Modal.Header py="4">
          <Text textStyle="headline-4">Bulk actions ({expenses.length})</Text>
        </Modal.Header>
        <Modal.Body>
          <Flex flexDir="column" gap="4">
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Form.Field id="status" label="Status">
                  <Form.Select
                    options={buildStatusOptions(statusesToTransition)}
                    onChange={(v) => {
                      field.onChange(v?.value)
                      trigger('form')
                    }}
                    dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
                  />
                </Form.Field>
              )}
            />
            <Controller
              control={control}
              name="entityCategoryId"
              render={({ field }) => (
                <Form.Field id="entityCategoryId" label="Categories">
                  <Form.Select
                    options={buildCategoryOptions(categories?.listAccountingCategories)}
                    onChange={(v) => {
                      field.onChange(v?.value)
                      trigger('form')
                    }}
                    dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
                  />
                </Form.Field>
              )}
            />
            <Controller
              control={control}
              name="entityTaxCodeId"
              render={({ field }) => (
                <Form.Field id="entityTaxCodeId" label="Add tax code">
                  <Form.Select
                    options={buildTaxRateOptions(taxRates?.listAccountingTaxRates)}
                    onChange={(v) => {
                      field.onChange(v?.value)
                      trigger('form')
                    }}
                    dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
                  />
                </Form.Field>
              )}
            />
            <Controller
              control={control}
              name="entityMerchantId"
              render={({ field }) => (
                <Form.Field id="entityMerchantId" label="Vendors/Suppliers">
                  <Form.Select
                    options={buildVendorsOptions(vendors?.listAccountingVendors)}
                    onChange={(v) => {
                      field.onChange(v?.value)
                      trigger('form')
                    }}
                    dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
                  />
                </Form.Field>
              )}
            />
            <Controller
              control={control}
              name="entityClassId"
              render={({ field }) => (
                <Form.Field id="entityClassId" label="Class">
                  <Form.Select
                    options={buildClassOptions(classes?.listAccountingClasses)}
                    onChange={(v) => {
                      field.onChange(v?.value)
                      trigger('form')
                    }}
                    dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
                  />
                </Form.Field>
              )}
            />
            {isDefined(formState.errors.form) && (
              <Form.FormControl isInvalid>
                <Form.ErrorMessage mt="0">{formState.errors.form.message}</Form.ErrorMessage>
              </Form.FormControl>
            )}
          </Flex>
        </Modal.Body>
        <Modal.Footer p="6" borderTop="1px solid" borderColor="border-soft">
          <ActionButtons>
            <ActionButtons.Secondary onClick={onClose} isDisabled={formState.isSubmitting}>
              Cancel
            </ActionButtons.Secondary>
            <ActionButtons.Primary type="submit" isLoading={formState.isSubmitting}>
              Apply changes
            </ActionButtons.Primary>
          </ActionButtons>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
