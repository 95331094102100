import { sortBy } from 'lodash'
import React from 'react'
import {
  Box,
  Divider,
  Flex,
  Grid,
  Icon,
  IconTooltip,
  isDefined,
  ListItem,
  Tag,
  Text,
  TextProps,
  Tooltip,
  UnorderedList,
  useMediaQuery,
} from 'ui-lib'
import moment from 'utils/moment'

import { FundingInstallment } from '@/gql'
import { INSTALLMENT_STATE_NAMES, INSTALLMENT_STATUS_STYLES } from '@/src/constants/capital'
import { InstallmentStatus } from '@/types/capital/enums'

type FundingInstallmentsProps = { installments: FundingInstallment[] }

const THeader = ({ children, ...props }: TextProps) => (
  <Text textStyle="subheading-sm" textColor="text-soft" textTransform="uppercase" {...props}>
    {children}
  </Text>
)

const MobileAmountInfo = ({ installment, showLateFee }: { installment: FundingInstallment; showLateFee: boolean }) => (
  <IconTooltip
    variant={installment.status === InstallmentStatus.FAILED ? 'error' : 'primary'}
    label={
      <UnorderedList border="none">
        <ListItem>Principal Amount: {installment?.principalAmountDue}</ListItem>
        <ListItem>Fee amount: {installment?.interestAmountDue}</ListItem>
        {showLateFee && installment.lateFee !== '$0.00' && (
          <>
            <ListItem>Other Fees: {installment?.lateFee}</ListItem>
            <UnorderedList border="none" pl="0" pt="1">
              <ListItem textStyle="paragraph-sm">
                Late Fee of ${installment.lateFee} CAD has been added to the Fee Amount.
              </ListItem>
            </UnorderedList>
          </>
        )}
      </UnorderedList>
    }
    icon="info-fill"
  />
)

const LateFeeCol = ({ installment }: { installment: FundingInstallment }) => (
  <Flex gap="1">
    {installment.lateFee !== '$0.00' ? (
      <>
        <Text
          textStyle="paragraph-md"
          color={installment.status === InstallmentStatus.PAID ? 'text-primary' : 'text-error'}
        >
          {installment?.lateFee}
        </Text>
        <Tooltip label={`Late Fee of ${installment.lateFee} CAD has been added to the Fee Amount.`}>
          <Icon
            icon="circle-alert-fill"
            variant={installment.status === InstallmentStatus.PAID ? 'soft' : 'error'}
            size="sm"
          />
        </Tooltip>
      </>
    ) : (
      <Text textStyle="paragraph-md" textAlign="center" minW="8">
        -
      </Text>
    )}
  </Flex>
)

const Timeline = ({
  installment,
  isLastInstallment,
  isFirstInstallment,
}: {
  installment: FundingInstallment
  isLastInstallment: boolean
  isFirstInstallment: boolean
}) => (
  <Flex direction="column" alignItems="center">
    <Flex
      direction="column"
      alignItems="flex-start"
      width="2px"
      height="12px"
      bg={isFirstInstallment ? 'transparent' : 'bg-soft'}
    />
    <Flex
      direction="column"
      alignItems="flex-start"
      width="10px"
      height="10px"
      borderRadius="50%"
      my={2}
      bg={installment.status === InstallmentStatus.PAID ? 'black' : 'bg-soft'}
    />
    <Flex
      direction="column"
      alignItems="flex-start"
      width="2px"
      height="12px"
      bg={isLastInstallment ? 'transparent' : 'bg-soft'}
    />
  </Flex>
)

export const FundingInstallments = ({ installments = [] }: FundingInstallmentsProps) => {
  const [isMobile] = useMediaQuery('(max-width: 479px)')
  const showLateFee = installments.some(
    (installment) => isDefined(installment.lateFee) && installment.lateFee !== '$0.00'
  )
  const cols = isMobile ? 3 : showLateFee ? 6 : 5
  return (
    <Flex flex={1} direction="column" gap="24px" padding="24px">
      <Text textStyle="title-sm" textColor="text-primary">
        Payment Schedule
      </Text>
      <Flex direction="column" gap="12px">
        <Grid templateColumns={`repeat(${cols}, minmax(0, 1fr))`} width="100%">
          <THeader>Due Date</THeader>
          <THeader display={['none', 'inline']}>Principal Amount</THeader>
          <THeader display={['none', 'inline']}>Fee amount</THeader>
          {showLateFee && <THeader display={['none', 'inline']}>Other Fees</THeader>}
          <THeader>
            {isMobile ? (
              <>
                Amount <IconTooltip label="Total amount due" icon="info-fill" variant="secondary" />
              </>
            ) : (
              'Total amount due'
            )}
          </THeader>
          <THeader>Status</THeader>
        </Grid>
        <Divider />

        <Box>
          {sortBy(installments, 'installmentNumber').map((installment, i) => {
            const color = installment.status === InstallmentStatus.FAILED ? 'text-error' : 'text-primary'
            return (
              <Grid templateColumns={`repeat(${cols}, minmax(0, 1fr))`} key={i} alignItems="center">
                <Flex alignItems="center" gap="16px">
                  <Timeline
                    installment={installment}
                    isLastInstallment={i === installments.length - 1}
                    isFirstInstallment={i === 0}
                  />
                  <Text textStyle="paragraph-md" color={color}>
                    {moment(installment?.dateDue).utc().format('ll')}
                  </Text>
                </Flex>
                <Text textStyle="paragraph-md" display={['none', 'inline']} color={color}>
                  {installment?.principalAmountDue}
                </Text>
                <Text textStyle="paragraph-md" display={['none', 'inline']} color={color}>
                  {installment?.interestAmountDue}
                </Text>
                {showLateFee && !isMobile && <LateFeeCol installment={installment} />}
                <Flex gap="1">
                  <Text textStyle="paragraph-md" color={color}>
                    {installment?.amountDue}
                  </Text>
                  {isMobile && <MobileAmountInfo installment={installment} showLateFee={showLateFee} />}
                </Flex>

                <Box>
                  <Tag variant={INSTALLMENT_STATUS_STYLES[installment.status]}>
                    <Text textStyle="subheading-sm" textColor="none">
                      {INSTALLMENT_STATE_NAMES[installment.status] ?? installment.status}
                    </Text>
                  </Tag>
                </Box>
              </Grid>
            )
          })}
        </Box>
      </Flex>
    </Flex>
  )
}
