import { Tab, TabList, TabPanel, TabPanels, Tabs as ChakraTabs } from '@chakra-ui/react'
import React from 'react'

export const Tabs = (props: React.ComponentProps<typeof ChakraTabs>) => {
  return <ChakraTabs {...props} />
}

Tabs.Tab = Tab
Tabs.TabList = TabList
Tabs.TabPanel = TabPanel
Tabs.TabPanels = TabPanels
