import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import React, { createContext, ReactNode, useContext, useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useDebouncedValue } from 'rooks'
import {
  ActionButtons,
  Flex,
  Form,
  Grid,
  GridProps,
  Icon,
  InputLeftElement,
  isDefined,
  Link,
  Skeleton,
  Tag,
  Text,
  Tooltip,
} from 'ui-lib'
import { Money } from 'utils'
import { z, ZodRawShape } from 'zod'

import { CalculateRepaymentPlanWithOriginationFee, useCalculateRepaymentPlansWithOriginationFeeQuery } from '@/gql'
import { TextRow } from '@/src/components/misc'

import {
  CalculatorBaseProps,
  FinancingCalculatorContextType,
  FormResolver,
  FormValues,
  getCalculatePlanSchema,
  OnSubmit,
} from './types'

const calculatePlanSchema = getCalculatePlanSchema()

const FinancingCalculatorContext = createContext<FinancingCalculatorContextType<typeof calculatePlanSchema.shape>>({
  termLength: '0',
  mode: 'read',
  fundingAmount: 0,
  onCancel: () => undefined,
  isLoading: false,
  selectedRepaymentPlan: undefined,
  getRepaymentPlan: () => undefined,
  showSkeleton: () => undefined,
  maxFundingAmount: undefined,
})

const SKELETON_DELAY = 300

export const useFinancingPlanCalculatorContext = () => {
  return useContext(FinancingCalculatorContext)
}

const FinancingPlanCalculator = <T extends ZodRawShape>({
  onCancel,
  onSubmit,
  children,
  repaymentFrequency,
  maxRepaymentMonths,
  maxFundingAmount,
  initialFundingAmount,
  initialTermLength,
  mode,
  customSchema,
  ...stylingProps
}: CalculatorBaseProps<T>) => {
  const [skeleton, setSkeleton] = useState(false)
  const calculatePlanSchema = getCalculatePlanSchema(maxFundingAmount)
  const schema =
    isDefined(customSchema) && isDefined(customSchema.shape)
      ? customSchema.merge(calculatePlanSchema)
      : calculatePlanSchema

  const resolver = zodResolver(schema) as FormResolver<T>

  const form = useForm<z.infer<typeof schema>>({
    resolver,
    defaultValues: {
      termLength: initialTermLength?.toString(),
      fundingAmount: initialFundingAmount?.toString() ?? '0',
    },
  })

  const [fundingAmount] = useDebouncedValue(Number(form.watch('fundingAmount') ?? '0'), 400)
  const termLength = form.watch('termLength')

  const { data, loading } = useCalculateRepaymentPlansWithOriginationFeeQuery({
    variables: {
      repaymentFrequency,
      fundingAmount: fundingAmount ?? 0,
      maxRepaymentMonths,
    },
  })

  const getRepaymentPlan = (termLength: string): CalculateRepaymentPlanWithOriginationFee | undefined =>
    data?.calculateRepaymentPlansWithOriginationFee?.find(
      (plan) => plan?.monthsDuration === Number(termLength)
    ) as CalculateRepaymentPlanWithOriginationFee

  const selectedRepaymentPlan = getRepaymentPlan(termLength)

  const showSkeleton = () => {
    setSkeleton(true)
    setTimeout(() => {
      setSkeleton(false)
    }, SKELETON_DELAY)
  }

  return (
    <FinancingCalculatorContext.Provider
      value={{
        termLength,
        mode,
        fundingAmount,
        isLoading: loading || skeleton,
        getRepaymentPlan,
        selectedRepaymentPlan,
        onCancel,
        maxFundingAmount,
        showSkeleton,
      }}
    >
      <Form
        validateOnChange
        onSubmit={isDefined(onSubmit) ? form.handleSubmit(onSubmit as OnSubmit<T>) : undefined}
        {...stylingProps}
      >
        <FormProvider {...form}>
          <Flex flexDirection="column" gap={6}>
            {children}
          </Flex>
        </FormProvider>
      </Form>
    </FinancingCalculatorContext.Provider>
  )
}

const AmountInput = ({ helperText }: { helperText?: string | ReactNode }) => {
  const { formState, setValue, control } = useFormContext()
  const { maxFundingAmount } = useFinancingPlanCalculatorContext()

  return (
    <Controller
      name="fundingAmount"
      control={control}
      render={({ field }) => (
        <Form.Field
          label="Financing Amount"
          id="fundingAmount"
          helperText={helperText}
          errorMessage={formState.errors['fundingAmount']?.message}
        >
          <Form.NumberInput
            {...field}
            value={field.value}
            onChange={(e) => {
              const numericValue = Number(e.target.value)
              if (isDefined(maxFundingAmount) && numericValue > maxFundingAmount) {
                setValue('fundingAmount', maxFundingAmount.toString())
                return
              }
              !isNaN(numericValue) && field.onChange(e)
            }}
            leftElement={
              <InputLeftElement textStyle="title-sm" h="full">
                $
              </InputLeftElement>
            }
          />
        </Form.Field>
      )}
    />
  )
}

const TermLengthInput = <Option extends { label: string; value: string }>({
  availableRepaymentOptions,
  optionComponent,
}: {
  availableRepaymentOptions: Option[]
  optionComponent?: ((props: { option: Option }) => JSX.Element) | undefined
}) => {
  const form = useFormContext()
  const { getRepaymentPlan, showSkeleton } = useFinancingPlanCalculatorContext()

  const filteredOptions = availableRepaymentOptions.filter((option) => {
    const repaymentPlan = getRepaymentPlan(option.value)
    return isDefined(repaymentPlan)
  })

  return (
    <Controller
      name="termLength"
      control={form.control}
      render={({ field: { value, ...field } }) => (
        <Form.Field
          label="Select your Term length"
          id="termLength"
          errorMessage={form.formState.errors['termLength']?.message}
        >
          <Form.Select
            {...field}
            isClearable={false}
            isSearchable={false}
            onChange={(value) => {
              const option = value
              field.onChange(option?.value)
              form.setValue('termLengthFormatted', option?.label)
              showSkeleton()
            }}
            value={filteredOptions.find((v) => v.value === value)}
            options={filteredOptions}
            optionComponent={optionComponent}
          />
        </Form.Field>
      )}
    />
  )
}

const Summary = ({ children, ...stylingProps }: { children?: ReactNode } & GridProps) => {
  const { mode } = useFinancingPlanCalculatorContext()

  return (
    <Grid
      borderRadius="md"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="border-soft"
      overflow="hidden"
      p={4}
      gap={4}
      w="full"
      bgColor={mode === 'write' ? 'bg-neutral' : 'bg-primary'}
      {...stylingProps}
    >
      <Grid gap={2}>{children}</Grid>
    </Grid>
  )
}

const TotalAmountToBePaid = () => {
  const { isLoading, selectedRepaymentPlan } = useFinancingPlanCalculatorContext()

  return (
    <TextRow>
      <TextRow.Title>Total amount to be repaid</TextRow.Title>
      {isLoading === true ? (
        <Skeleton w="90px" height="24px" />
      ) : (
        <TextRow.Value textStyle="paragraph-lg">
          {Money.fromNumber(
            selectedRepaymentPlan?.totalRepaymentAmount.amountAsNumber ?? 0
          ).toFormattedCurrencyString()}
        </TextRow.Value>
      )}
    </TextRow>
  )
}

const FinancingAmount = () => {
  const { isLoading, fundingAmount } = useFinancingPlanCalculatorContext()

  return (
    <TextRow>
      <TextRow.Title>Financing Amount</TextRow.Title>
      {isLoading === true ? (
        <Skeleton w="110px" height="24px" />
      ) : (
        <TextRow.Value textStyle="paragraph-lg">
          {Money.fromNumber(fundingAmount ?? 0).toFormattedCurrencyString()}
        </TextRow.Value>
      )}
    </TextRow>
  )
}

const TotalFinancingFee = () => {
  const { isLoading, selectedRepaymentPlan } = useFinancingPlanCalculatorContext()
  return (
    <TextRow>
      <Flex gap={[0, 2]} flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
        <TextRow.Title>Total Financing fee</TextRow.Title>
        <Tag variant="blue" size="sm" rounded="full" fontWeight="semibold">
          {((selectedRepaymentPlan?.monthlyFeeRate ?? 0) * 100).toFixed(2)}% monthly
        </Tag>
      </Flex>
      {isLoading === true ? (
        <Skeleton w="70px" height="24px" />
      ) : (
        <TextRow.Value textStyle="paragraph-lg">
          {Money.fromNumber(selectedRepaymentPlan?.totalFee.amountAsNumber ?? 0).toFormattedCurrencyString()}
        </TextRow.Value>
      )}
    </TextRow>
  )
}

const AmountReceived = () => {
  const { isLoading, selectedRepaymentPlan } = useFinancingPlanCalculatorContext()
  return (
    <TextRow>
      <Flex gap={1}>
        <TextRow.Title>Amount you’ll receive</TextRow.Title>
        <Tooltip label="Total amount you will receive after deducting compliance and service fees.">
          <Icon size="sm" icon="info-fill" variant="soft" />
        </Tooltip>
      </Flex>
      {isLoading === true ? (
        <Skeleton w="110px" height="24px" />
      ) : (
        <TextRow.Value textStyle="paragraph-lg">
          {Money.fromNumber(selectedRepaymentPlan?.receivedAmount.amountAsNumber ?? 0).toFormattedCurrencyString()}
        </TextRow.Value>
      )}
    </TextRow>
  )
}

const DividedFees = () => {
  const { isLoading, selectedRepaymentPlan, fundingAmount } = useFinancingPlanCalculatorContext()
  const originationFeeRate = selectedRepaymentPlan?.originationFeeRate ?? 0
  const amount = fundingAmount ?? 0
  const beforeDiscountAmount = amount * (originationFeeRate + 0.05)

  return (
    <>
      <TextRow>
        <Flex gap={1} alignItems="center">
          <TextRow.Title>Compliance fee</TextRow.Title>
          <Tooltip label="This fee helps us comply with federal and provincial regulations to operate Keep.">
            <Icon size="sm" icon="info-fill" variant="soft" />
          </Tooltip>
        </Flex>
        {isLoading === true ? (
          <Skeleton w="110px" height="24px" />
        ) : (
          <Flex gap={[0, 1]} flexDirection={['column', 'row']}>
            <TextRow.Value textStyle="paragraph-lg" as="s" color="text-disabled">
              {Money.fromNumber(beforeDiscountAmount / 3).toFormattedCurrencyString()}
            </TextRow.Value>
            <TextRow.Value textStyle="paragraph-lg" color="text-success">
              {Money.fromNumber(selectedRepaymentPlan?.complianceFee.amountAsNumber ?? 0).toFormattedCurrencyString()}
            </TextRow.Value>
          </Flex>
        )}
      </TextRow>
      <TextRow>
        <Flex gap={1} alignItems="center">
          <TextRow.Title>Service fee</TextRow.Title>
          <Tooltip label="This fee helps us operate Keep to provide you with the best financial products in Canada.">
            <Icon size="sm" icon="info-fill" variant="soft" />
          </Tooltip>
        </Flex>
        {isLoading === true ? (
          <Skeleton w="110px" height="24px" />
        ) : (
          <Flex gap={[0, 1]} flexDirection={['column', 'row']}>
            <TextRow.Value textStyle="paragraph-lg" as="s" color="text-disabled">
              {Money.fromNumber(beforeDiscountAmount * (2 / 3)).toFormattedCurrencyString()}
            </TextRow.Value>
            <TextRow.Value textStyle="paragraph-lg" color="text-success">
              {Money.fromNumber(selectedRepaymentPlan?.serviceFee.amountAsNumber ?? 0).toFormattedCurrencyString()}
            </TextRow.Value>
          </Flex>
        )}
      </TextRow>
    </>
  )
}

const Agreements = () => {
  const { register, formState } = useFormContext()
  return (
    <Flex flexDirection="column" width="100%">
      <Text textStyle="title-md" mb="24px">
        Check the boxes to sign and confirm your plan
      </Text>
      <Form.Field
        id="confirmedFinancialAgreement"
        isInvalid={isDefined(formState.errors['confirmedFinancialAgreement']?.message)}
      >
        <Form.Checkbox {...register('confirmedFinancialAgreement')} mb="2">
          <Text textStyle="title-xs">
            I hereby confirm and agree to sign the{' '}
            <Link href="/agreements/capital-finance-agreement.pdf" target="_blank" color="text-info">
              Finance Agreement.
            </Link>
          </Text>
        </Form.Checkbox>
      </Form.Field>
      <Form.Field
        id="confirmedDebitAgreement"
        isInvalid={isDefined(formState.errors['confirmedDebitAgreement']?.message)}
      >
        <Form.Checkbox {...register('confirmedDebitAgreement')}>
          <Text textStyle="title-xs">
            I hereby confirm and agree to sign the{' '}
            <Link href="/agreements/pre-authorized-debit-agreement.pdf" target="_blank" color="text-info">
              Pre Authorized Debit Agreement.
            </Link>
          </Text>
        </Form.Checkbox>
      </Form.Field>
      {(isDefined(formState.errors['confirmedDebitAgreement']?.message) ||
        isDefined(formState.errors['confirmedFinancialAgreement']?.message)) && (
        <Text textStyle="paragraph-sm" textColor="text-error">
          You need to agree and sign the Pre Authorized Debit Agreement and Finance Agreement
        </Text>
      )}
    </Flex>
  )
}

const CalculatorActionButtons = ({ loading }: { loading?: boolean }) => {
  const { onCancel } = useFinancingPlanCalculatorContext()
  const { formState, getValues } = useFormContext()

  return (
    <ActionButtons w="full">
      <ActionButtons.Secondary onClick={() => onCancel(getValues() as FormValues)}>Back</ActionButtons.Secondary>
      <ActionButtons.Primary
        type="submit"
        isLoading={loading}
        isDisabled={!isEmpty(formState.errors)}
        id="applicant-identity-submit-button"
      >
        Next
      </ActionButtons.Primary>
    </ActionButtons>
  )
}

FinancingPlanCalculator.AmountInput = AmountInput
FinancingPlanCalculator.TermLengthInput = TermLengthInput
FinancingPlanCalculator.Summary = Summary
FinancingPlanCalculator.Agreements = Agreements
FinancingPlanCalculator.CalculatorActionButtons = CalculatorActionButtons
FinancingPlanCalculator.AmountReceived = AmountReceived
FinancingPlanCalculator.DividedFees = DividedFees
FinancingPlanCalculator.TotalFinancingFee = TotalFinancingFee
FinancingPlanCalculator.FinancingAmount = FinancingAmount
FinancingPlanCalculator.TotalAmountToBePaid = TotalAmountToBePaid

export { FinancingPlanCalculator }
