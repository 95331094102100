import React, { ReactNode } from 'react'
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  usePopoverContext,
} from 'ui-lib'

import SelectedArrow from '@/src/svgs/SelectedArrow'

type PopoverProps = Omit<React.ComponentProps<typeof Popover>, 'placement' | 'gutter' | 'strategy'>

export const _TablePopover = ({ children, ...props }: PopoverProps) => (
  <Popover
    strategy="fixed"
    placement="right-start"
    closeOnBlur={false}
    closeOnEsc
    gutter={32}
    variant="overlay"
    modifiers={[
      {
        name: 'computeStyles',
        options: {
          adaptive: false, // Prevents the popover from adapting its position on scroll/resize
          roundOffsets: ({ x, y }: { x: number; y: number }) => ({
            x,
            y: y - 11, // Shifts the popover up by 11 pixels
          }),
        },
      },
      {
        name: 'offset',
        options: {
          offset: ({ popper }: { popper: { height: number } }) => [
            popper.height <= 550 ? 400 : 0, // If popover height ≤ 550px, shifts it 400px horizontally
            32, // Always adds 32px vertical offset
          ],
        },
      },
      {
        name: 'eventListeners',
        options: { scroll: false }, // Disables scroll event listeners, making the popover stay in place when scrolling
      },
    ]}
    {...props}
  >
    {({ isOpen }) => (
      <Box
        backgroundColor={Boolean(isOpen) ? 'bg-neutral' : 'bg-primary'}
        position="relative"
        borderRadius="md"
        _hover={{
          backgroundColor: 'bg-neutral',
        }}
      >
        {children as ReactNode}
      </Box>
    )}
  </Popover>
)

const Arrow = () => {
  const { isOpen } = usePopoverContext()

  return Boolean(isOpen) ? (
    <SelectedArrow boxSize="12px" position="absolute" right="-20px" top="0" bottom="0" margin="auto 0" />
  ) : null
}

const Trigger = ({ children, ...props }: React.ComponentProps<typeof PopoverTrigger>) => (
  <PopoverTrigger {...props}>{children}</PopoverTrigger>
)

const Content = (props: React.ComponentProps<typeof PopoverContent>) => {
  return <PopoverContent width="auto" {...props} borderRadius="lg" borderColor="border-soft" boxShadow="xl" />
}

const Header = (props: React.ComponentProps<typeof PopoverHeader>) => {
  const { isOpen } = usePopoverContext()

  return isOpen ? <PopoverHeader py="16px" px="32px" {...props} borderColor="border-soft" /> : null
}

const Body = (props: React.ComponentProps<typeof PopoverBody>) => {
  const { isOpen } = usePopoverContext()

  return isOpen ? <PopoverBody maxH="calc(100vh - 224px)" overflow="auto" py="16px" px="32px" {...props} /> : null
}

const Footer = (props: React.ComponentProps<typeof PopoverFooter>) => {
  return <PopoverFooter py="16px" px="32px" {...props} borderColor="border-soft" />
}

export const TablePopover = Object.assign(_TablePopover, {
  Arrow,
  Trigger,
  Content,
  Header,
  Body,
  Footer,
})
