import { Alert, Skeleton, Text } from 'ui-lib'
import { isDefined } from 'utils'

import { BusinessDelinquencyProduct, useBusinessDelinquencyDetailsQuery } from '@/gql'

export const PendingPaymentAlert = ({ product }: { product: BusinessDelinquencyProduct }) => {
  const { data: delinquencyData, loading: queryDelinquencyDetailsLoading } = useBusinessDelinquencyDetailsQuery({
    variables: {
      product,
    },
  })
  const failedAmount = delinquencyData?.businessDelinquencyDetails?.lastDelinquentAmount
  const isDelinquent = delinquencyData?.businessDelinquencyDetails?.isDelinquent ?? false
  const shouldDisplayAlert = isDefined(failedAmount) && failedAmount > 0 && isDelinquent

  return queryDelinquencyDetailsLoading ? (
    <Skeleton h="30px" mb="6" />
  ) : shouldDisplayAlert ? (
    <Alert variant="negative" mb="6">
      <Text textStyle="paragraph-md" color="text-error">
        Your last payment failed, and as a result, your card has been blocked. Please contact{' '}
        <a href="mailto:collections@trykeep.com">
          <u>collections@trykeep.com</u>
        </a>{' '}
        at your earliest convenience.
      </Text>
    </Alert>
  ) : null
}
