import React from 'react'
import { Flex, Icon, Text, Tooltip } from 'ui-lib'
import { isDefined, Money } from 'utils'
import moment from 'utils/moment'

import { getPaymentDueText } from '@/src/components/capital/utils/repaymentFrequencyParsers'
import { TextRow } from '@/src/components/misc'
import { FundingDiscountType, PlainDiscount, PlainStatementExtensions, RepaymentFrequency } from '@/types/capital'

type StatementCardDetailsProps = {
  funding: PlainStatementExtensions[number]
  totalAmount: string
}

export const StatementCardDetails = ({ funding, totalAmount }: StatementCardDetailsProps) => {
  const isDiscountApplied = isDefined(funding.feeDiscountRate) && funding.feeDiscountRate > 0
  const discounts = funding.discounts?.reduce(
    (acc, crr) => ({
      ...acc,
      [crr.type]: crr,
    }),
    {} as { [key in FundingDiscountType]: PlainDiscount }
  )

  const preDiscountTotalFee = Money.fromFormattedCurrencyString(funding.preDiscountTotalFee)
  const discountAmount = Money.fromFormattedCurrencyString(discounts?.FLAT_DISCOUNT?.amount ?? '0')
  const feeLessFlatDiscount = preDiscountTotalFee.sub(discountAmount)

  return (
    <Flex flex={1} direction="column" gap="24px" padding="24px">
      <Text textStyle="title-sm" textColor="text-primary">
        Financing Details
      </Text>
      <Flex direction="column" gap="12px">
        <TextRow>
          <TextRow.Title>Financing amount</TextRow.Title>
          <TextRow.Value>{funding.principalAmount}</TextRow.Value>
        </TextRow>
        <TextRow>
          <TextRow.Title>Total fee</TextRow.Title>
          <TextRow.Value textStyle="paragraph-md">
            {isDefined(discounts?.FLAT_DISCOUNT) ? (
              <>
                <Text as="del" color="text-soft">
                  {preDiscountTotalFee.toFormattedCurrencyString()}
                </Text>{' '}
                <Text as="span" color="text-success">
                  {feeLessFlatDiscount.toFormattedCurrencyString()}
                </Text>
              </>
            ) : (
              preDiscountTotalFee.toFormattedCurrencyString()
            )}
          </TextRow.Value>
        </TextRow>
        {isDefined(discounts?.REWARDS_DISCOUNT) && (
          <TextRow>
            <TextRow.Title display="flex" alignItems="center" gap={1}>
              Rewards redemption discount
              <Tooltip label="Rewards discount is applied on the fee amount.">
                <Icon size="sm" icon="info-fill" />
              </Tooltip>
            </TextRow.Title>

            <TextRow.Value
              textStyle="paragraph-md"
              css={{
                background: 'linear-gradient(236deg, #DB57B6 0%, #6497C7 100%)',
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
              }}
            >
              - {discounts?.REWARDS_DISCOUNT.amount}
            </TextRow.Value>
          </TextRow>
        )}
        <TextRow>
          <TextRow.Title>Total due</TextRow.Title>
          <TextRow.Value textStyle="title-md">
            <Flex>
              <Icon display={isDiscountApplied ? 'block' : 'none'} icon="tag-fill" variant="success" />
              {totalAmount}
            </Flex>
            <Text textStyle="paragraph-sm" textColor="text-secondary" align="end">
              {getPaymentDueText(
                moment(funding.firstRepaymentDate).utc(),
                funding.repaymentFrequency as RepaymentFrequency
              )}
            </Text>
          </TextRow.Value>
        </TextRow>
      </Flex>
    </Flex>
  )
}
