export const colorTokens = {
  'bg-primary': {
    default: 'basic.white',
    _dark: 'basic.white',
  },
  'bg-disabled': {
    default: 'neutral.50',
    _dark: 'neutral.50',
  },
  'bg-soft': {
    default: 'neutral.100',
    _dark: 'neutral.100',
  },
  'bg-secondary': {
    default: 'neutral.800',
    _dark: 'neutral.800',
  },
  'bg-inverse': {
    default: 'neutral.900',
    _dark: 'neutral.900',
  },
  'bg-success': {
    default: 'positive.50',
    _dark: 'positive.50',
  },
  'bg-warning': {
    default: 'warning.50',
    _dark: 'warning.50',
  },
  'bg-error': {
    default: 'error.50',
    _dark: 'error.50',
  },
  'bg-info': {
    default: 'info.50',
    _dark: 'info.50',
  },
  'bg-neutral': {
    default: 'neutral.50',
    _dark: 'neutral.50',
  },

  'border-primary': {
    default: 'neutral.900',
    _dark: 'neutral.900',
  },
  'border-secondary': {
    default: 'neutral.300',
    _dark: 'neutral.300',
  },
  'border-soft': {
    default: 'neutral.200',
    _dark: 'neutral.200',
  },
  'border-disabled': {
    default: 'neutral.100',
    _dark: 'neutral.100',
  },
  'border-inverse': {
    default: 'basic.white',
    _dark: 'basic.white',
  },
  'border-success': {
    default: 'positive.200',
    _dark: 'positive.200',
  },
  'border-warning': {
    default: 'warning.500',
    _dark: 'warning.500',
  },
  'border-error': {
    default: 'error.300',
    _dark: 'error.300',
  },
  'border-info': {
    default: 'info.200',
    _dark: 'info.200',
  },
  'border-neutral': {
    default: 'neutral.300',
    _dark: 'neutral.300',
  },
  'border-gradient-1': {
    default: 'gradient.bluePink',
    _dark: 'gradient.bluePink',
  },
  'border-gradient-2': {
    default: 'gradient.hotRed',
    _dark: 'gradient.hotRed',
  },
  'border-input-focus': {
    default: 'info.500',
    _dark: 'info.500',
  },
  'outline-input-focus': {
    default: 'info.50',
    _dark: 'info.50',
  },

  'icon-inverse': {
    default: 'basic.white',
    _dark: 'basic.white',
  },
  'icon-disabled': {
    default: 'neutral.500',
    _dark: 'neutral.500',
  },
  'icon-soft': {
    default: 'neutral.600',
    _dark: 'neutral.600',
  },
  'icon-secondary': {
    default: 'neutral.700',
    _dark: 'neutral.700',
  },
  'icon-primary': {
    default: 'neutral.900',
    _dark: 'neutral.900',
  },
  'icon-success': {
    default: 'positive.400',
    _dark: 'positive.400',
  },
  'icon-warning': {
    default: 'warning.500',
    _dark: 'warning.500',
  },
  'icon-error': {
    default: 'error.500',
    _dark: 'error.500',
  },
  'icon-info': {
    default: 'info.500',
    _dark: 'info.500',
  },
  'icon-neutral': {
    default: 'neutral.500',
    _dark: 'neutral.500',
  },
  'icon-gradient-1': {
    default: 'gradient.yellowPink',
    _dark: 'gradient.yellowPink',
  },
  'icon-gradient-2': {
    default: 'gradient.blueGreen',
    _dark: 'gradient.blueGreen',
  },

  'text-inverse': {
    default: 'basic.white',
    _dark: 'basic.white',
  },
  'text-disabled': {
    default: 'neutral.500',
    _dark: 'neutral.500',
  },
  'text-soft': {
    default: 'neutral.600',
    _dark: 'neutral.600',
  },
  'text-secondary': {
    default: 'neutral.700',
    _dark: 'neutral.700',
  },
  'text-primary': {
    default: 'neutral.900',
    _dark: 'neutral.900',
  },
  'text-success': {
    default: 'positive.700',
    _dark: 'positive.700',
  },
  'text-warning': {
    default: 'warning.800',
    _dark: 'warning.800',
  },
  'text-error': {
    default: 'error.600',
    _dark: 'error.600',
  },
  'text-info': {
    default: 'info.700',
    _dark: 'info.700',
  },
  'text-neutral': {
    default: 'neutral.600',
    _dark: 'neutral.600',
  },
  'avatar-gradient': {
    default: 'gradient.bluePink',
    _dark: 'gradient.bluePink',
  },
  'active-item-gradient': {
    default: 'gradient.inverseGrayWhite',
    _dark: 'gradient.inverseGrayWhite',
  },
}
