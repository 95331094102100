import { Box, Th } from '@chakra-ui/react'
import { flexRender, Header } from '@tanstack/react-table'
import React from 'react'

import { getPinnedColumnStyle } from './helpers'
import { Styles } from './styles-context'

export const DataGridTh = <T,>({
  header,
  position,
  styles,
}: {
  header: Header<T, unknown>
  position: 'footer' | 'header'
  styles: Styles
}) => {
  const showResizer = header.column.getCanResize()
  const isPinned = header.column.getIsPinned()

  const thStyles = {
    width: `calc(var(--header-${header.id}-size) * 1px)`,
  }

  const getPosition = () => {
    if (isPinned === false) {
      return showResizer ? 'relative' : 'inherit'
    }
    return undefined // pinned columns are handled by the getPinnedColumnStyle function
  }

  return (
    <Th
      key={header.id}
      style={{
        ...thStyles,
        position: getPosition(),
      }}
      sx={{ ...getPinnedColumnStyle(header.column, styles) }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
        }}
      >
        {header.isPlaceholder ? null : flexRender(header.column.columnDef[position], header.getContext())}
      </Box>
      {showResizer && (
        <Box
          onDoubleClick={() => header.column.resetSize()}
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          sx={styles.resizer}
        />
      )}
    </Th>
  )
}
