import { Button, Flex, Tag, useMediaQuery } from 'ui-lib'
import { isDefined } from 'utils'

import { useCategoryTags } from './use-category-tags'
import { useDateTags } from './use-date-tags'
import { useReceiptTags } from './use-receipt-tags'
import { useTaxCodeTags } from './use-tax-code-tags'
import { useVendorTags } from './use-vendor-tags'
import { FilterTagsProps } from './utils'

interface Props extends FilterTagsProps {
  onMoreFiltersClicked: () => void
  onResetFilters: () => void
}

export const ExpenseFilterTags = (props: Props) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)')
  const { getCategoryTags } = useCategoryTags(props)
  const { getVendorTags } = useVendorTags(props)
  const { getTaxCodeTags } = useTaxCodeTags(props)
  const { getReceiptTag } = useReceiptTags(props)
  const { getDateTag } = useDateTags(props)

  const getTags = () => {
    const values = props.form.getValues()
    const tags: JSX.Element[] = []
    const dateTag = getDateTag({ dateRange: values.dateRange })
    if (isDefined(dateTag)) {
      tags.push(dateTag)
    }
    const categoryFilter = {
      categories: values.categories,
      categoriesGroup: values.categoriesGroup,
    }
    const categoryTags = getCategoryTags(categoryFilter)
    tags.push(...categoryTags)
    const vendorFilter = {
      vendors: values.vendors,
      vendorsGroup: values.vendorsGroup,
    }
    const vendorTags = getVendorTags(vendorFilter)
    tags.push(...vendorTags)
    const taxCodeFilter = {
      taxCodes: values.taxCodes,
      taxCodesGroup: values.taxCodesGroup,
    }
    const taxCodeTags = getTaxCodeTags(taxCodeFilter)
    tags.push(...taxCodeTags)
    const receiptTag = getReceiptTag({ receipt: values.receipt })
    if (isDefined(receiptTag)) {
      tags.push(receiptTag)
    }
    return tags
  }

  const tags = getTags()
  const numberOfDisplayedTags = isLargerThan1500 ? 3 : 2
  const displayableTags = tags.slice(0, numberOfDisplayedTags)
  const hiddenTags = tags.slice(numberOfDisplayedTags)

  return (
    <Flex gap="2" wrap="wrap">
      {displayableTags}
      {hiddenTags.length > 0 && (
        <Tag variant="info" px="3" cursor="pointer" onClick={props.onMoreFiltersClicked}>
          +{hiddenTags.length}
        </Tag>
      )}
      {displayableTags.length > 0 && (
        <Button
          variant="ghost"
          textColor="text-info"
          onClick={() => {
            props.onResetFilters()
          }}
        >
          Reset filters
        </Button>
      )}
    </Flex>
  )
}
