import { useSearchParams } from 'next/navigation'
import { MutableRefObject } from 'react'
import { isDefined, Money } from 'utils'

import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useSegmentPageVisited } from '@/src/hooks/useSegmentPageVisited'
import { ClosedFundingStatus, PlainStatementExtensions } from '@/types/capital'

import { FundingCard, StatementCardDetails } from './funding-card'
import { transformPlainStatementExtensionToGeneric } from './utils'

const getTotalAmount = (principalAmount: string, totalFee: string) => {
  const principalAmountFormatted = Money.fromFormattedCurrencyString(principalAmount)
  return Money.fromFormattedCurrencyString(totalFee).add(principalAmountFormatted).toFormattedCurrencyString()
}

export const StatementFundingCard = ({
  extension,
  targetRef,
}: {
  extension: PlainStatementExtensions[number]
  targetRef?: MutableRefObject<{
    [key: string]: HTMLDivElement
  }>
}) => {
  const trackPageVisited = useSegmentPageVisited()
  const searchParams = useSearchParams()
  const selectedPlanId = searchParams.get('planId') ?? undefined
  const handleTackEvent = () => {
    trackPageVisited({
      type: SEGMENT_PAGE_VISITED.STATEMENT_EXTENSIONS_DETAILS_VIEWED,
      payload: {
        team: 'Capital',
        page: `${
          (Object.values(ClosedFundingStatus) as string[]).includes(extension?.status) ? 'Closed' : 'Active'
        } Statement Extensions`,
        extension,
      },
    })
  }

  return (
    <FundingCard
      ref={(ref) => {
        if (isDefined(ref) && isDefined(targetRef)) {
          targetRef.current[extension?.loanNumber] = ref
        }
      }}
      key={extension.id}
      funding={transformPlainStatementExtensionToGeneric(extension)}
      defaultOpen={selectedPlanId === extension.loanNumber}
      onOpen={() => {
        handleTackEvent()
      }}
    >
      <StatementCardDetails
        funding={extension}
        totalAmount={getTotalAmount(extension.principalAmount, extension.totalFee)}
      />
    </FundingCard>
  )
}
