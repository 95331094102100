import { CheckIcon, ChevronRightIcon, Flex, Progress, Text } from 'ui-lib'

import { CommonStepperProp } from './types'

export const StepperMobile = ({
  title,
  activeIndex,
  totalSteps,
  goToPreviousStep,
  containerProps,
}: CommonStepperProp) => {
  const isLastStep = activeIndex === totalSteps - 1
  const max = totalSteps
  const progressPercent = ((activeIndex + 1) / max) * 100

  return (
    <Flex width="full" flexDirection="column" mb="7" {...containerProps}>
      <Flex justifyContent="space-between" alignItems="center" mb="3">
        <Flex
          as="button"
          boxSize="18px"
          backgroundColor={isLastStep ? 'icon-disabled' : 'icon-primary'}
          borderRadius="full"
          cursor="pointer"
          onClick={goToPreviousStep}
        >
          <ChevronRightIcon width="full" height="auto" color="icon-inverse" transform="rotate(180deg)" />
        </Flex>
        <Text textStyle="title-md" textColor="text-primary">
          {title}
        </Text>
        {!isLastStep ? (
          <Text textStyle="subheading-lg" textColor="icon-secondary">{`${activeIndex + 1}/${totalSteps}`}</Text>
        ) : (
          <Flex p="2px" backgroundColor="bg-inverse" borderRadius="full">
            <CheckIcon boxSize="14px" color="icon-inverse" />
          </Flex>
        )}
      </Flex>
      <Progress variant="primary" value={progressPercent} size="xs" borderRadius="4" />
    </Flex>
  )
}
