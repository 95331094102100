import { useCapitalFundingsByBusinessIdPaginatedQuery } from '@/gql'

export const useHasCapitalFundings = () => {
  const { data, loading: isLoading } = useCapitalFundingsByBusinessIdPaginatedQuery({
    variables: {
      limit: 1,
      page: 1,
    },
    fetchPolicy: 'network-only',
  })

  const hasFundings = data?.capitalFundingsByBusinessIdPaginated?.data.length

  return {
    hasFundings: Boolean(hasFundings),
    isLoading,
  }
}
