import { CurrencyCode, FeatureFlag } from 'db-schema'

import { Currency, SupportedCurrenciesType } from './types'

// To add a new Currency check the Currency type definition, it has all the reference links

const USD: Currency = {
  code: CurrencyCode.USD,
  friendlyName: 'United States Dollar',
  canConvertTo: [
    'AUD',
    'ILS',
    'RUB',
    'BHD',
    'JPY',
    'SAR',
    'BGN',
    'KES',
    'SGD',
    'CAD',
    'KWD',
    'ZAR',
    'CNH',
    'MYR',
    'SEK',
    'HRK',
    'MXN',
    'CHF',
    'CZK',
    'NZD',
    'THB',
    'DKK',
    'NOK',
    'TRY',
    'EUR',
    'OMR',
    'UGX',
    'HKD',
    'PHP',
    'GBP',
    'HUF',
    'PLN',
    'AED',
    'INR',
    'QAR',
    'IDR',
    'RON',
  ],
  canFundBalance: true,
  canPayIndividual: true,
  canPayBusiness: true,
  paymentTypes: ['priority', 'regular'],
  enabled: true,
  priorityPaymentCutoff: {
    type: 'end_of_window',
    value: '11:30',
  },
  localPaymentCutoff: {
    type: 'end_of_window',
    value: '15:45',
  },
  conversionRank: 37,
  conversionCutoffTimeUTC: { type: 'end_of_window', value: '15:50' },
}

const CAD: Currency = {
  code: CurrencyCode.CAD,
  friendlyName: 'Canadian dollar',
  canConvertTo: [
    'AUD',
    'JPY',
    'SAR',
    'BGN',
    'KES',
    'SGD',
    'BHD',
    'KWD',
    'ZAR',
    'CNH',
    'MXN',
    'SEK',
    'CZK',
    'MYR',
    'CHF',
    'DKK',
    'NZD',
    'THB',
    'EUR',
    'NOK',
    'TRY',
    'HKD',
    'PHP',
    'UGX',
    'HUF',
    'PLN',
    'AED',
    'INR',
    'QAR',
    'GBP',
    'IDR',
    'RON',
    'USD',
    'ILS',
    'RUB',
  ],
  canFundBalance: true,
  canPayIndividual: true,
  canPayBusiness: true,
  paymentTypes: ['priority', 'regular'],
  enabled: true,
  priorityPaymentCutoff: {
    type: 'end_of_window',
    value: '11:45',
  },
  localPaymentCutoff: {
    type: 'end_of_window',
    value: '11:00',
  },
  conversionRank: 38,
  conversionCutoffTimeUTC: { type: 'end_of_window', value: '16:20' },
}

const EUR: Currency = {
  code: CurrencyCode.EUR,
  friendlyName: 'Euro',
  canConvertTo: [
    'AUD',
    'JPY',
    'SAR',
    'BHD',
    'KES',
    'SGD',
    'BGN',
    'KWD',
    'ZAR',
    'CAD',
    'MYR',
    'SEK',
    'CNH',
    'MXN',
    'CHF',
    'HRK',
    'NZD',
    'THB',
    'CZK',
    'NOK',
    'TRY',
    'DKK',
    'OMR',
    'UGX',
    'HKD',
    'PHP',
    'GBP',
    'HUF',
    'PLN',
    'AED',
    'INR',
    'QAR',
    'USD',
    'IDR',
    'RON',
    'ILS',
    'RUB',
  ],
  canFundBalance: true,
  canPayIndividual: true,
  canPayBusiness: true,
  paymentTypes: ['priority', 'regular'],
  enabled: true,
  priorityPaymentCutoff: {
    type: 'end_of_window',
    value: '09:30',
  },
  localPaymentCutoff: {
    type: 'end_of_window',
    value: '09:00',
  },
  conversionRank: 35,
  conversionCutoffTimeUTC: {
    type: 'end_of_window',
    value: '13:50',
    exceptionsWhenSelling: [
      {
        buyingCurrency: CurrencyCode.USD,
        cutoffTime: {
          type: 'end_of_window',
          /**
           * 14:00 for BV clients
           * 15:30 for Ltd clients
           */
          value: '14:00',
        },
      },
    ],
  },
}

const GBP: Currency = {
  code: CurrencyCode.GBP,
  friendlyName: 'British Pound',
  canConvertTo: [
    'AUD',
    'ILS',
    'RUB',
    'BHD',
    'JPY',
    'SAR',
    'BGN',
    'KES',
    'SGD',
    'CAD',
    'KWD',
    'ZAR',
    'CNH',
    'MYR',
    'SEK',
    'HRK',
    'MXN',
    'CHF',
    'CZK',
    'NZD',
    'THB',
    'DKK',
    'NOK',
    'TRY',
    'EUR',
    'OMR',
    'UGX',
    'HKD',
    'PHP',
    'AED',
    'HUF',
    'PLN',
    'USD',
    'INR',
    'QAR',
    'IDR',
    'RON',
  ],
  canFundBalance: true,
  canPayIndividual: true,
  canPayBusiness: true,
  paymentTypes: ['priority', 'regular'],
  enabled: true,
  priorityPaymentCutoff: {
    type: 'end_of_window',
    /**
     * 16:15 for BV clients
     * 17:00 for Ltd. clients
     */
    value: '16:15',
  },
  localPaymentCutoff: {
    type: 'weekday_instant',
    value: null,
  },
  conversionRank: 36,
  conversionCutoffTimeUTC: {
    type: 'end_of_window',
    value: '14:20',
    exceptionsWhenSelling: [
      {
        buyingCurrency: CurrencyCode.USD,
        cutoffTime: {
          type: 'end_of_window',
          value: '15:30',
        },
      },
    ],
  },
}

const PHP: Currency = {
  code: CurrencyCode.PHP,
  friendlyName: 'Philippine Peso',
  canConvertTo: ['AUD', 'EUR', 'USD', 'CAD', 'GBP'],
  canFundBalance: false,
  canPayIndividual: true,
  canPayBusiness: true,
  paymentTypes: ['regular'],
  enabled: true,
  priorityPaymentCutoff: null,
  localPaymentCutoff: {
    type: 'end_of_window',
    value: '11:30',
  },
  conversionRank: 22,
  conversionCutoffTimeUTC: { type: 'end_of_window', value: '16:30' },
  featureFlags: [FeatureFlag.CURRENCY_CLOUD_PHP_CURRENCY],
}

const MXN: Currency = {
  code: CurrencyCode.MXN,
  friendlyName: 'Mexican Peso',
  canConvertTo: [
    'AUD',
    'JPY',
    'ZAR',
    'CAD',
    'NOK',
    'SEK',
    'CNH',
    'NZD',
    'CHF',
    'CZK',
    'PLN',
    'THB',
    'DKK',
    'QAR',
    'TRY',
    'EUR',
    'RON',
    'GBP',
    'HUF',
    'RUB',
    'USD',
    'HKD',
    'SAR',
    'ILS',
    'SGD',
  ],
  canFundBalance: true,
  canPayIndividual: true,
  canPayBusiness: true,
  // Regular payments are unavailable at the time, see https://support.currencycloud.com/hc/en-gb/articles/360018417079-Currencies#:~:text=Ugandan%20Shilling%20(UGX)-,Mexican%20Peso,-Regular%20MXN%20payments
  paymentTypes: ['priority'],
  enabled: true,
  priorityPaymentCutoff: {
    type: 'end_of_window',
    value: '04:00',
  },
  localPaymentCutoff: {
    type: 'end_of_window',
    value: '03:30',
  },
  conversionRank: 24,
  conversionCutoffTimeUTC: {
    type: 'end_of_window',
    value: '08:00',
  },
}

export const ALLOWED_CURRENCIES = [
  CurrencyCode.USD,
  CurrencyCode.CAD,
  CurrencyCode.EUR,
  CurrencyCode.GBP,
  CurrencyCode.PHP,
  CurrencyCode.MXN,
] as const

export const RESTRICTED_CURRENCIES: CurrencyCode[] = [
  CurrencyCode.PHP,
  CurrencyCode.INR,
  CurrencyCode.IDR,
  CurrencyCode.MYR,
]

export const ALLOWED_NON_RESTRICTED_CURRENCIES = ALLOWED_CURRENCIES.filter(
  (currency) => !RESTRICTED_CURRENCIES.includes(currency)
)

export const isAllowedCurrency = (currency: string): currency is SupportedCurrenciesType => currency in currencies

export const currencyNames: Record<SupportedCurrenciesType, string> = {
  CAD: 'Canadian Dollar',
  USD: 'United States Dollar',
  EUR: 'Euro',
  GBP: 'British Pound',
  MXN: 'Mexican Peso',
  PHP: 'Philippine Peso',
}

// TODO: Replace Record<SupportedCurrencies, Currency> with Record<CurrencyCode, Currency> once we support all currencies
export const currencies: Record<SupportedCurrenciesType, Currency> = {
  USD,
  CAD,
  EUR,
  GBP,
  PHP,
  MXN,
}

export default currencies
