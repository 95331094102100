import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Flex, Form, Icon, Input, InputGroup, InputLeftElement, Spinner, Text } from 'ui-lib'

import { useListAccountingVendorsQuery } from '@/gql'

export const VendorFilter = () => {
  const { data: vendors, loading } = useListAccountingVendorsQuery()
  const { setValue, register } = useFormContext()
  const vendorIds = useWatch({ name: 'vendors' }) ?? []
  const vendorGroup = useWatch({ name: 'vendorsGroup' }) ?? ''
  const [search, setSearch] = useState('')

  const filteredVendors = vendors?.listAccountingVendors?.filter(({ vendorName }) =>
    vendorName?.toLowerCase().includes(search.trim().toLowerCase())
  )

  return (
    <Flex gap="7" flexDir="column">
      <Flex px="4">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon icon="search" size="sm" />
          </InputLeftElement>
          <Input
            placeholder="Search for a vendor/supplier"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </Flex>
      <Form.RadioGroup id="type" defaultValue={vendorGroup}>
        <Flex flexDir="column" px="4" gap={2}>
          <Form.Radio
            {...register('vendorsGroup')}
            onChange={(e) => {
              register('vendorsGroup').onChange(e)
              setValue('vendors', undefined)
            }}
            text="All"
            value=""
          />
          <Form.Radio
            {...register('vendorsGroup')}
            onChange={(e) => {
              register('vendorsGroup').onChange(e)
              setValue(
                'vendors',
                vendors?.listAccountingVendors?.map((category) => category.id)
              )
            }}
            text="With a vendor or supplier"
            value="categorized"
          />
          <Form.Radio
            {...register('vendorsGroup')}
            onChange={(e) => {
              register('vendorsGroup').onChange(e)
              setValue('vendors', null)
            }}
            text="Without a vendor or supplier"
            value="uncategorized"
          />
        </Flex>
      </Form.RadioGroup>
      <Flex flexDir="column" gap="3">
        <Text textStyle="subheading-sm" textTransform="uppercase" px="4">
          Vendors/Suppliers
        </Text>
        <Divider />
        <Flex flexDir="column" gap={3} px="4" maxH="300px" overflowY="auto">
          {loading ? <Spinner /> : !Boolean(filteredVendors?.length) && <Text>No vendors found</Text>}
          {filteredVendors?.map((vendor) => (
            <Form.Checkbox
              key={vendor.id}
              isChecked={vendorIds.includes(vendor.id)}
              disabled={vendorGroup === 'uncategorized'}
              onChange={(e) => {
                setValue(
                  'vendors',
                  e.target.checked ? [...vendorIds, vendor.id] : vendorIds.filter((id: string) => id !== vendor.id)
                )
              }}
            >
              <Text textStyle="title-xs" maxW="220px">
                {vendor.vendorName}
              </Text>
            </Form.Checkbox>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
