import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  Flex,
  Form,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from 'ui-lib'
import { isDefined } from 'utils'

import { useQueryParamState } from '@/src/hooks/misc'

import { ExpenseFilterTags } from './tags/expense-filter-tags'
import {
  Filter,
  FILTER_ITEMS,
  FilterValues,
  formatFilterValues,
  FormattedFilterValues,
  getDefaultValues,
  schema,
} from './utils'

interface FilterExpensesTableProps {
  onChange: (values: FormattedFilterValues) => void
}

export const FilterExpensesTable = ({ onChange }: FilterExpensesTableProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [filter, setFilter] = useState<Filter>(Filter.Date)
  const [selectedFilters, setSelectedFilters] = useQueryParamState<FilterValues>({
    key: 'filters',
    parse: (value) => (isDefined(value) ? JSON.parse(value) : undefined),
  })
  const form = useForm<FilterValues>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(selectedFilters),
  })

  const handleChangeFilters = (values: FilterValues) => {
    setSelectedFilters(values)
    onChange(formatFilterValues(values))
  }

  const clearFilters = () => {
    form.reset({})
    handleChangeFilters({})
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button variant="outline" leftIcon={<Icon size="sm" icon="filter" />} onClick={onToggle}>
          Filter
        </Button>
      </PopoverTrigger>
      <ExpenseFilterTags
        form={form}
        onFilter={handleChangeFilters}
        onResetFilters={clearFilters}
        onMoreFiltersClicked={onToggle}
      />
      <PopoverContent w="full" minW="600px">
        <PopoverCloseButton />
        <PopoverBody p="0">
          <Flex>
            <Flex
              h="auto"
              bgColor="bg-disabled"
              py="3"
              borderRight="1px solid"
              borderColor="border-disabled"
              pb="64px"
              pos="relative"
              minW="230px"
              minH="450px"
            >
              <Flex px="2" flexDir="column" gap="1.5" w="full">
                {(Object.keys(FILTER_ITEMS) as Filter[]).map((key) => (
                  <Button
                    variant={key === filter ? 'outline' : 'ghost'}
                    bg={key === filter ? 'bg-soft' : undefined}
                    leftIcon={<Icon icon={FILTER_ITEMS[key].icon} variant={key === filter ? 'primary' : 'secondary'} />}
                    rightIcon={key === filter ? <Icon icon="chevron-right" size="sm" /> : undefined}
                    key={key}
                    onClick={() => setFilter(key)}
                    minW="173px"
                  >
                    <Text
                      color={key === filter ? undefined : 'text-soft'}
                      w="100%"
                      textAlign="left"
                      textStyle="title-sm"
                    >
                      {key}
                    </Text>
                  </Button>
                ))}
              </Flex>
              <Flex
                pos="absolute"
                bottom="0"
                py="4"
                px="4"
                w="100%"
                borderTop="1px solid"
                borderColor="border-disabled"
                gap="4"
              >
                <Button size="sm" onClick={clearFilters} variant="ghost">
                  Clear all
                </Button>
                <Button size="sm" onClick={() => handleChangeFilters(form.getValues())}>
                  Apply filters
                </Button>
              </Flex>
            </Flex>
            <Flex px="3" pb="5" pt="7" w="full">
              <Form onSubmit={form.handleSubmit(handleChangeFilters)} w="full">
                <FormProvider {...form}>{isDefined(filter) && FILTER_ITEMS[filter].component}</FormProvider>
              </Form>
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
