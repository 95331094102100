import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { Action } from 'redux'

import accountsSlice from './slices/accounts.slice'
import animationSlice from './slices/animation.slice'
import bankTransferSlice from './slices/bankTransfer.slice'
import currencyConversionSlice from './slices/currencyConversion.slice'
import currencyConversionV2Slice from './slices/currencyConversionV2.slice'
import featureFlagsSlice from './slices/featureFlags.slice'
import kycFormSlice from './slices/kycForm.slice'
import navigationSlice from './slices/navigation.slice'
import onboardingSlice from './slices/onboarding.slice'
import paginationSlice from './slices/pagination.slice'
import settingsSlice from './slices/settings.slice'
import sidebarSlice from './slices/sidebar.slice'
import withdrawSlice from './slices/withdraw.slice'

export const makeStore = () =>
  configureStore({
    reducer: {
      [navigationSlice.name]: navigationSlice.reducer,
      [onboardingSlice.name]: onboardingSlice.reducer,
      [kycFormSlice.name]: kycFormSlice.reducer,
      [animationSlice.name]: animationSlice.reducer,
      [paginationSlice.name]: paginationSlice.reducer,
      [currencyConversionSlice.name]: currencyConversionSlice.reducer,
      [currencyConversionV2Slice.name]: currencyConversionV2Slice.reducer,
      [bankTransferSlice.name]: bankTransferSlice.reducer,
      [withdrawSlice.name]: withdrawSlice.reducer,
      [accountsSlice.name]: accountsSlice.reducer,
      [featureFlagsSlice.name]: featureFlagsSlice.reducer,
      [sidebarSlice.name]: sidebarSlice.reducer,
      [settingsSlice.name]: settingsSlice.reducer,
    },
    devTools: true,
  })
export default makeStore()
export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>
export type AppDispatch = AppStore['dispatch']

export const wrapper = createWrapper<AppStore>(makeStore)
