import { Button, Flex, FlexProps } from 'ui-lib'

import CheckIcon from '@/src/svgs/CheckIcon'

type ActionButtonsProps = FlexProps & {
  orientation?: 'horizontal' | 'vertical'
}

// TODO: promote it to a ui lib component
// TODO fix the props spread position to be effective when we need to override the default styles
export const ActionButtons = ({ orientation = 'horizontal', ...props }: ActionButtonsProps) => {
  return (
    <Flex
      {...props}
      width="full"
      gap="4"
      flexDirection={{
        base: 'column',
        lg: orientation === 'horizontal' ? 'row' : 'column-reverse',
      }}
    >
      {props.children}
    </Flex>
  )
}

type ButtonProps = Pick<
  React.ComponentProps<typeof Button>,
  'isLoading' | 'isDisabled' | 'type' | 'onClick' | 'children'
> & {
  isSuccess?: boolean
}

const Primary = ({ isSuccess = false, ...props }: ButtonProps) => {
  return (
    <Button
      variant="primary"
      size="md"
      onClick={props.onClick}
      isLoading={props.isLoading}
      isDisabled={props.isLoading === true || props.isDisabled}
      flex={{ base: 'none', lg: '1' }}
      flexShrink="0"
      type={props.type}
      rightIcon={isSuccess ? <CheckIcon marginLeft=".5rem" animated /> : undefined}
      order={{ base: '0', lg: '1' }}
      px={6}
      py={3}
      {...props}
    >
      {props.children}
    </Button>
  )
}

const Secondary = (props: Omit<ButtonProps, 'isSuccess'>) => {
  return (
    <Button
      variant={{ base: 'tertiary', lg: 'outline' }}
      size="md"
      onClick={props.onClick}
      isDisabled={props.isLoading === true || props.isDisabled}
      flex={{ base: 'none', lg: '1' }}
      flexShrink="0"
      order={{ base: '1', lg: '0' }}
      px={6}
      py={3}
      {...props}
    >
      {props.children}
    </Button>
  )
}

ActionButtons.Primary = Primary
ActionButtons.Secondary = Secondary
