import { isDefined } from 'utils'

import { useListAccountingCategoriesQuery } from '@/gql'

import { FilterValues } from '../utils'
import { ExpenseFilterTag } from './expense-filter-tag'
import { FilterTagsProps } from './utils'

export const useCategoryTags = ({ form, onFilter }: FilterTagsProps) => {
  const { data: categories } = useListAccountingCategoriesQuery()

  const getCategoryTag = (categoryId: string, categoryIds: string[]) => {
    const category = categories?.listAccountingCategories?.find(({ id }) => id === categoryId)
    if (!isDefined(category)) {
      return null
    }
    const deleteCategory = () => {
      form.setValue(
        'categories',
        categoryIds.filter((id) => id !== categoryId)
      )
    }
    return <ExpenseFilterTag form={form} label={category.name} onDelete={deleteCategory} onFilter={onFilter} />
  }

  const getCategoryTags = (params: Pick<FilterValues, 'categories' | 'categoriesGroup'>) => {
    const clearCategories = () => {
      form.setValue('categoriesGroup', '')
      form.setValue('categories', undefined)
    }
    const key = params.categoriesGroup === 'categorized' ? 'categorized' : 'uncategorized'
    const label = params.categoriesGroup === 'categorized' ? 'Categorized' : 'Uncategorized'
    if (params.categoriesGroup === 'categorized' || params.categoriesGroup === 'uncategorized') {
      return [<ExpenseFilterTag key={key} form={form} label={label} onDelete={clearCategories} onFilter={onFilter} />]
    }
    if (!Array.isArray(params.categories)) {
      return []
    }
    const tags: JSX.Element[] = []
    for (const category of params.categories) {
      const tag = getCategoryTag(category, params.categories)
      if (isDefined(tag)) {
        tags.push(tag)
      }
    }
    return tags
  }

  return { getCategoryTags }
}
