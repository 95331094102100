import { find } from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Form, Icon } from 'ui-lib'
import { isDefined } from 'utils'

import { ExpenseStatus, useListAccountingVendorsQuery } from '@/gql'
import { useUpdateExpenses } from '@/src/hooks/accounting'

import { buildVendorsOptions } from '../utils'
import { ColumnComponentProps } from './types'

export const ExpenseVendorSelect = ({ row }: ColumnComponentProps) => {
  const { control, formState, getValues } = useFormContext()

  const { data: vendors } = useListAccountingVendorsQuery()

  const { updateExpenses } = useUpdateExpenses()
  const isDisabled = row.original.status === ExpenseStatus.Synced

  const findVendorOption = (entityMerchantId: string | null | undefined) => {
    if (!isDefined(entityMerchantId)) {
      return null
    }
    return find(buildVendorsOptions(vendors?.listAccountingVendors), { value: entityMerchantId })
  }

  return (
    <Box minW="180px">
      <Controller
        control={control}
        name={`dataGridExpenses.${row.index}.entityMerchantId`}
        render={({ field }) => (
          <Form.Field
            id={`dataGridExpenses.${row.index}.entityMerchantId`}
            errorMessage={formState.errors.dataGridExpenses?.at(row.index)?.entityMerchantId?.message}
            isDisabled={isDisabled}
          >
            <Form.Select
              value={findVendorOption(getValues(`dataGridExpenses.${row.index}.entityMerchantId`))}
              options={buildVendorsOptions(vendors?.listAccountingVendors?.filter(isDefined))}
              onChange={(v) => {
                field.onChange(v?.value)
                updateExpenses({
                  expensesIds: [row.original.id],
                  input: { entityMerchantId: isDefined(v) ? v.value : null },
                })
              }}
              dropdownIndicatorComponent={() => <Icon icon="triangle-down" size="sm" />}
              isDisabled={isDisabled}
            />
          </Form.Field>
        )}
      />
    </Box>
  )
}
