import { useFormContext, useWatch } from 'react-hook-form'
import { Flex, Form } from 'ui-lib'

export const ReceiptFilter = () => {
  const { register } = useFormContext()
  const receipt = useWatch({ name: 'receipt' })
  return (
    <Flex gap="7" flexDir="column">
      <Form.RadioGroup id="type" defaultValue={receipt}>
        <Flex flexDir="column" px="4" gap={2}>
          <Form.Radio {...register('receipt')} text="All" value="" />
          <Form.Radio {...register('receipt')} text="With a receipt" value="nonEmpty" />
          <Form.Radio {...register('receipt')} text="Without a receipt" value="empty" />
        </Flex>
      </Form.RadioGroup>
    </Flex>
  )
}
