import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, Flex } from 'ui-lib'

import { ExpenseStatus } from '@/gql'

import { ColumnComponentProps } from './types'

export const ExpenseSelectedCheckBox = ({ row }: ColumnComponentProps) => {
  const { control } = useFormContext()
  const isDisabled = row.original.status === ExpenseStatus.Synced

  return (
    <Controller
      name={`dataGridExpenses.${row.index}.selected`}
      control={control}
      render={({ field }) => (
        <Flex alignItems="center" w="4">
          <Checkbox
            colorScheme="blue"
            isChecked={field.value}
            onChange={(value) => {
              field.onChange(value)
              row.toggleSelected(value.target.checked)
            }}
            isDisabled={isDisabled}
          />
        </Flex>
      )}
    />
  )
}
