import { CreateBeneficiaryInputData } from 'services/src/currencyCloud/beneficiary/beneficiaryFieldsForm'
import { ConstToUnion } from 'utils/types'

import { PaymentPriority } from '@/src/graphql/generated/globalTypes'
import BlackLightningIcon from '@/src/svgs/lightningIconBlack.svg'
import HotRedLightningIcon from '@/src/svgs/lightningIconHotRed.svg'
import BlackPaperPlaneIcon from '@/src/svgs/paperPlaneIconBlack.svg'
import HotRedPaperPlaneIcon from '@/src/svgs/paperPlaneIconHotRed.svg'

// CC TODO: Using the FormattedCreateBeneficiaryForm as the type work for showing the RecipientDetails if the beneficiary was just created with the form
// If you choose a created one from the list, the data it has is different
export type Recipient = CreateBeneficiaryInputData
export type PaymentSpeedValue = 'priority' | 'standard'
export type PaymentMethodValue = 'ach' | 'swift' | 'wire'
export type RecipientTypeValue = 'business' | 'individual'
export type RecipientRelationValue = 'employee' | 'contractor' | 'vendor' | 'landlord' | 'subsidiary' | 'other'

export type RewardActionValue = 'earn' | 'apply'

export type FormValues = typeof DEFAULT_FORM_VALUES

export type RecipientType = {
  label: string
  value: RecipientTypeValue
  fields: {
    label: string
    id: string
    placeholder: string
  }[]
}

export interface PaymentMethod {
  label: string
  value: PaymentMethodValue
  fields: {
    label: string
    id: string
    placeholder: string
  }[]
}

export interface PaymentType {
  title: string
  value: PaymentSpeedValue
  points: number
  eta: string
  tooltipEta: string
  iconWidth: string
  iconMarginLeft: string
  defaultIcon: string
  activeIcon: string
  paymentCost: string
}

export type RecipientRelationOption = {
  label: string
  value: RecipientRelationValue
}

export type RewardAction = {
  label: string
  value: RewardActionValue
}

export const BankTransferStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
} as const
export type BankTransferStatus = ConstToUnion<typeof BankTransferStatus>

export const PAYMENT_TYPES: { [key: string]: PaymentType } = {
  priority: {
    title: 'Priority',
    value: 'priority',
    points: 1500,
    eta: 'Same day or next day',
    tooltipEta: 'Should arrive same day or next day',
    iconWidth: '32px',
    iconMarginLeft: '32px',
    defaultIcon: BlackLightningIcon.src,
    activeIcon: HotRedLightningIcon.src,
    paymentCost: '$15 CAD',
  },
  standard: {
    title: 'Standard',
    value: 'standard',
    points: 100,
    eta: 'Up to 7 business days',
    tooltipEta: 'Should arrive in 7 business days',
    iconWidth: '40px',
    iconMarginLeft: '28px',
    defaultIcon: BlackPaperPlaneIcon.src,
    activeIcon: HotRedPaperPlaneIcon.src,
    paymentCost: '$1 CAD',
  },
}

export const REWARD_ACTIONS: { [key: string]: RewardAction } = {
  earn: {
    label: 'Earn rewards',
    value: 'earn',
  },
  apply: {
    label: 'Redeem rewards',
    value: 'apply',
  },
}

export const RECIPIENT_TYPES: { [key: string]: RecipientType } = {
  business: {
    label: 'Business',
    value: 'business',
    fields: [
      {
        label: 'Name of the business / organization',
        id: 'businessName',
        placeholder: 'Enter a business name',
      },
    ],
  },
  individual: {
    label: 'Individual',
    value: 'individual',
    fields: [
      {
        label: 'First name of the account holder',
        id: 'accountHolderFirstName',
        placeholder: 'First name',
      },
      {
        label: 'Last name of the account holder',
        id: 'accountHolderLastName',
        placeholder: 'Last name',
      },
    ],
  },
}

export const RECIPIENT_TYPE_OPTIONS = Object.values(RECIPIENT_TYPES)

export const ADDRESS_FIELDS = [
  {
    id: 'addressLine1',
    placeholder: 'Address line 1',
  },
  {
    id: 'addressLine2',
    placeholder: 'Address line 2',
  },
  {
    id: 'city',
    placeholder: 'City',
  },
  {
    id: 'state',
    placeholder: 'State/Province',
  },
  {
    id: 'postalCode',
    placeholder: 'Postal Code',
  },
]

export const RECIPIENT_RELATIONS: Record<RecipientRelationValue, RecipientRelationOption> = {
  employee: { label: 'Employee', value: 'employee' },
  contractor: { label: 'Contractor', value: 'contractor' },
  vendor: { label: 'Vendor', value: 'vendor' },
  landlord: { label: 'Landlord', value: 'landlord' },
  subsidiary: { label: 'Transfering to subsidiary', value: 'subsidiary' },
  other: { label: 'Other', value: 'other' },
}

export const RECIPIENT_RELATION_OPTIONS = Object.values(RECIPIENT_RELATIONS)

export const DEFAULT_FORM_VALUES = {
  currentStep: 0,
  recipientType: 'business',
  businessName: '',
  accountHolderFirstName: '',
  accountHolderLastName: '',
  email: '',
  paymentCurrency: null,
  bankAccountCountry: null,
  recipientCountry: null,
  paymentMethod: 'ach',
  achRoutingNumber: '',
  swiftCode: '',
  fedwireRoutingNumber: '',
  accountNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
}

export const PAYMENT_PRIORITY_MAP: Record<PaymentSpeedValue, PaymentPriority> = {
  priority: PaymentPriority.PRIORITY,
  standard: PaymentPriority.STANDARD,
}
