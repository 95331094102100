import {
  chakra,
  Flex,
  FlexProps,
  Radio,
  RadioGroup,
  Text,
  TextProps,
  useMultiStyleConfig,
  useRadio,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { isDefined } from 'utils'

type RadioCardProps = Omit<React.ComponentProps<typeof Radio>, 'variant'> & {
  title: string
  description?: string
  titleProps?: TextProps
  descriptionProps?: TextProps
}

export const RadioCard = React.forwardRef<HTMLInputElement, RadioCardProps>(
  ({ title, description, titleProps, descriptionProps, ...props }, ref) => {
    const { getInputProps, getLabelProps, getRadioProps } = useRadio(props)
    const styles = useMultiStyleConfig('Radio', {
      variant: 'card',
    })

    return (
      <Flex as="label" sx={styles.container} {...getLabelProps()}>
        <Radio {...props} {...getRadioProps()} />
        <VStack alignItems="start" spacing={0} width="full">
          <Text textStyle="title-sm" {...titleProps}>
            {title}
          </Text>
          {isDefined(description) && (
            <Text textStyle="paragraph-sm" color="text-secondary" {...descriptionProps}>
              {description}
            </Text>
          )}
        </VStack>
        <chakra.input {...getInputProps()} ref={ref} hidden />
      </Flex>
    )
  }
)

type RadioCardGroupProps = React.ComponentProps<typeof RadioGroup> & {
  children: React.ReactElement<HTMLInputElement>[] | React.ReactElement<HTMLInputElement>
  flexDir?: FlexProps['flexDir']
}

export const RadioCardGroup = ({ children, flexDir = 'column', ...props }: RadioCardGroupProps) => {
  const { getRadioProps, getRootProps } = useRadioGroup(props)

  return (
    <Flex {...getRootProps} gap="4" width="full" flexDir={flexDir}>
      {React.Children.map(children, (child) => {
        const radio = getRadioProps({ value: child?.props?.value })
        return React.cloneElement(child as React.ReactElement, { ...radio })
      })}
    </Flex>
  )
}
