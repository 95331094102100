import { useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { useAddReceiptToExpenseMutation } from '@/gql'
import { FileResult } from '@/src/hooks/misc'

export interface AddReceiptToExpenseOptions {
  onError?: (error: Error) => void
}

export const useAddReceiptToExpense = () => {
  const toast = useToast()

  const [addReceiptToExpenseMutation] = useAddReceiptToExpenseMutation()

  const addReceiptToExpense = async (
    expenseId: string,
    receiptDocument: FileResult & { id?: string },
    options?: AddReceiptToExpenseOptions
  ) => {
    try {
      if (!isDefined(receiptDocument.type) || !isDefined(receiptDocument.path)) {
        throw new Error('Invalid receipt document')
      }
      const result = await addReceiptToExpenseMutation({
        variables: {
          body: {
            expenseId,
            receiptDocument: {
              id: receiptDocument.id,
              displayName: receiptDocument.name,
              mimeType: receiptDocument.type,
              path: receiptDocument.path,
            },
          },
        },
        refetchQueries: ['LegacyTransactions', 'ExpensesByBusiness'],
      })
      const response = result.data?.addReceiptToExpense
      if (!isDefined(response)) {
        throw new Error('Something went wrong while adding the receipt to the expense')
      }
      return response
    } catch (error) {
      if (error instanceof Error) {
        options?.onError?.(error)
        toast({
          title: `An error occurred while adding the receipt to the expense (${receiptDocument.name})`,
          description: error.message ?? 'Unknown error',
          status: 'error',
          position: 'top',
        })
      }
    }
  }

  return { addReceiptToExpense }
}
