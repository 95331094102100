import { SystemStyleObject } from '@chakra-ui/react'
import { Column } from '@tanstack/react-table'
import { isDefined } from 'utils'

import { Styles } from './styles-context'

/**
 * This function returns the style object for the pinned column.
 * @param column {Column<T>} The column to get the style for.
 * @param styles {Styles} The styles object from the Chakra UI theme.
 * @returns {SystemStyleObject} The style object for the pinned column.
 */
export const getPinnedColumnStyle = <T>(column: Column<T>, styles: Styles): SystemStyleObject => {
  const isPinned = column.getIsPinned()

  if (isPinned === false) {
    return {}
  }

  const offset = () => {
    if (isPinned === 'left') {
      return column.getStart('left')
    }
    if (isPinned === 'right') {
      return column.getAfter('right')
    }
  }

  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn(isPinned)
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsLastColumn(isPinned)

  const sxObject: SystemStyleObject = {
    ...styles['pinnedColumn'],
    ...(isLastLeftPinnedColumn ? styles['leftPinnedColumn'] : {}),
    ...(isFirstRightPinnedColumn ? styles['rightPinnedColumn'] : {}),
    [isPinned]: isDefined(offset()) ? `${offset()}px` : undefined,
  }

  return sxObject
}
