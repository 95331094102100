import { useRef, useState } from 'react'
import {
  Box,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBoolean,
  useOutsideClick,
  useToast,
} from 'ui-lib'
import { isDefined } from 'utils'

import { AccountingExpenseReceipt, useRemoveReceiptFromExpenseMutation } from '@/gql'
import { ReceiptViewer } from '@/src/components/accounting/receipts/receipt-viewer/receipt-viewer'
import { useDownloadReceipt } from '@/src/hooks/accounting'
import SelectedArrow from '@/src/svgs/SelectedArrow'
import { getSignedUrl } from '@/src/utils/misc'

interface ReceiptActionsPopoverProps {
  expenseId: string
  receipt: AccountingExpenseReceipt
  allowDelete?: boolean
  onDeleteReceipt: () => void
}

export const ReceiptActionsPopover = ({
  expenseId,
  receipt,
  allowDelete,
  onDeleteReceipt,
}: ReceiptActionsPopoverProps) => {
  const toast = useToast()
  const [isOpen, { on: open, off: close }] = useBoolean(false)
  const [isFetchingSignedUrl, setIsFetchingSignedUrl] = useBoolean(false)
  const [signedUrl, setSignedUrl] = useState<string | undefined>(undefined)
  const containerRef = useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: containerRef,
    handler: () => close(),
  })

  const [removeReceiptFromExpense, { loading: isDeletingReceipt }] = useRemoveReceiptFromExpenseMutation()
  const { downloadReceipt } = useDownloadReceipt()

  const fetchSignedUrl = async () => {
    if (isDefined(signedUrl)) {
      return signedUrl
    }
    const filePath = receipt.document?.path
    if (!isDefined(filePath)) {
      return
    }
    setIsFetchingSignedUrl.on()
    try {
      const signedUrl = await getSignedUrl(filePath)
      setSignedUrl(signedUrl)
    } catch (error) {
      console.error('Error fetching signed URL:', error)
    }
    setIsFetchingSignedUrl.off()
  }

  const handleOpen = () => {
    fetchSignedUrl()
    open()
  }

  const deleteReceipt = async () => {
    try {
      await removeReceiptFromExpense({ variables: { body: { expenseId } } })
      onDeleteReceipt()
      toast({
        title: 'Receipt deleted',
        description: 'The receipt has been successfully deleted',
        status: 'success',
      })
      close()
    } catch (error) {
      console.error(error)
      toast({
        title: 'Error deleting receipt',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
      })
    }
  }

  const Arrow = () => {
    if (!isOpen) {
      return null
    }
    return <SelectedArrow boxSize="12px" position="absolute" right="-20px" top="0" bottom="0" margin="auto 0" />
  }

  return (
    <Box ref={containerRef}>
      <Popover
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={close}
        size="lg"
        placement="right"
        strategy="fixed"
        gutter={32}
      >
        <Arrow />
        <PopoverTrigger>
          <IconButton
            variant="ghost"
            icon={<Icon icon="approved-receipt" size="md" variant="original" />}
            aria-label="Download receipt"
          />
        </PopoverTrigger>
        <PopoverContent
          w={{
            md: '384px',
            lg: '460px',
            '2xl': '30vw',
          }}
          borderRadius="lg"
          border="1px solid"
          borderColor="border-soft"
          overflow="hidden"
        >
          <PopoverBody p={0}>
            <ReceiptViewer
              isLoadingSignedUrl={isFetchingSignedUrl}
              signedUrl={signedUrl ?? ''}
              receipt={receipt}
              isDeleting={isDeletingReceipt}
              allowDelete={allowDelete}
              onDownloadReceipt={() => downloadReceipt(receipt)}
              onDeleteReceipt={deleteReceipt}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
