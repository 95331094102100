import { useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Divider, Flex, Form, Icon, Input, InputGroup, InputLeftElement, Spinner, Text } from 'ui-lib'

import { useListAccountingCategoriesQuery } from '@/gql'

export const CategoryFilter = () => {
  const { data: categories, loading } = useListAccountingCategoriesQuery()
  const { setValue, register } = useFormContext()
  const categoryIds = useWatch({ name: 'categories' }) ?? []
  const categoriesGroup = useWatch({ name: 'categoriesGroup' }) ?? ''
  const [search, setSearch] = useState('')

  const filteredCategories = categories?.listAccountingCategories?.filter(({ name }) =>
    name?.toLowerCase().includes(search.trim().toLowerCase())
  )

  return (
    <Flex gap="7" flexDir="column">
      <Flex px="4">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon icon="search" size="sm" />
          </InputLeftElement>
          <Input placeholder="Search for category" value={search} onChange={(e) => setSearch(e.target.value)} />
        </InputGroup>
      </Flex>
      <Form.RadioGroup id="type" defaultValue={categoriesGroup}>
        <Flex flexDir="column" px="4" gap={2}>
          <Form.Radio
            {...register('categoriesGroup')}
            onChange={(e) => {
              register('categoriesGroup').onChange(e)
              setValue('categories', undefined)
            }}
            text="All"
            value=""
          />
          <Form.Radio
            {...register('categoriesGroup')}
            onChange={(e) => {
              register('categoriesGroup').onChange(e)
              setValue(
                'categories',
                categories?.listAccountingCategories?.map((category) => category.id)
              )
            }}
            text="Categorized"
            value="categorized"
          />
          <Form.Radio
            {...register('categoriesGroup')}
            onChange={(e) => {
              register('categoriesGroup').onChange(e)
              setValue('categories', null)
            }}
            text="Uncategorized"
            value="uncategorized"
          />
        </Flex>
      </Form.RadioGroup>
      <Flex flexDir="column" gap="3">
        <Text textStyle="subheading-sm" px="4" textTransform="uppercase">
          Categories
        </Text>
        <Divider />
        <Flex flexDir="column" px="4" gap="3" maxH="300px" overflowY="auto">
          {loading ? <Spinner /> : !Boolean(filteredCategories?.length) && <Text>No categories found</Text>}
          {filteredCategories?.map((category) => (
            <Form.Checkbox
              key={category.id}
              isChecked={categoryIds.includes(category.id)}
              disabled={categoriesGroup === 'uncategorized'}
              onChange={(e) => {
                setValue(
                  'categories',
                  e.target.checked
                    ? [...categoryIds, category.id]
                    : categoryIds.filter((id: string) => id !== category.id)
                )
              }}
            >
              <Text textStyle="title-xs" maxW="220px">
                {category.name}
              </Text>
            </Form.Checkbox>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
