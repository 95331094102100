import { Money } from 'utils'

import { FundingStatus, GenericFunding, PartialCapitalFunding, PlainStatementExtensions } from '@/types/capital'
import { FinancingChannel } from '@/types/capital/enums'

export const transformPaginatedFundingToGeneric = (funding: PartialCapitalFunding): GenericFunding => {
  return {
    fundingId: funding.id,
    loanNumber: funding.loanNumber,
    status: funding.status as FundingStatus,
    totalRepayment: funding.totalRepayment.amountAsNumber,
    principalAmount: funding.principalAmount.amountAsNumber,
    nextPaymentDate: funding.nextPaymentDate,
    totalFee: funding.totalFee.amountAsNumber,
    financingChannel: FinancingChannel.CAPITAL,
    lastFundingPayment: {
      id: funding.lastFundingPayment?.id ?? '',
      amount: funding.lastFundingPayment?.amount?.amountAsNumber ?? 0,
      status: funding.lastFundingPayment?.status,
      type: funding.lastFundingPayment?.type,
      date: funding.lastFundingPayment?.updatedAt,
    },
  }
}

export const transformPlainStatementExtensionToGeneric = (
  funding: PlainStatementExtensions[number]
): GenericFunding => {
  return {
    fundingId: funding.id,
    loanNumber: funding.loanNumber,
    status: funding.status as FundingStatus,
    totalRepayment: Money.fromFormattedCurrencyString(funding.totalRepayment).toNumber(),
    principalAmount: Money.fromFormattedCurrencyString(funding.principalAmount).toNumber(),
    nextPaymentDate: funding.nextPaymentDate,
    totalFee: Money.fromFormattedCurrencyString(funding.totalFee).toNumber(),
    financingChannel: FinancingChannel.STATEMENT_EXTENSION,
    lastFundingPayment: {
      id: funding.lastFundingPayment?.id ?? '',
      amount: funding.lastFundingPayment?.amount?.amountAsNumber ?? 0,
      status: funding.lastFundingPayment?.status,
      type: funding.lastFundingPayment?.type,
      date: funding.lastFundingPayment?.updatedAt,
    },
  }
}
