import { useState } from 'react'
import { isDefined } from 'utils'

import { AccountingIntegrationStatus, AccountingIntegrationType, useBusinessAccountingIntegrationQuery } from '@/gql'

export const useActiveBusinessAccountingIntegration = () => {
  const [isConnected, setIsConnected] = useState(false)
  const { loading: isLoading } = useBusinessAccountingIntegrationQuery({
    variables: { integrationType: AccountingIntegrationType.RutterQbo },
    onCompleted: (data) => {
      const businessAccountingIntegration = data?.businessAccountingIntegration
      if (!isDefined(businessAccountingIntegration)) {
        return
      }
      setIsConnected(businessAccountingIntegration.status !== AccountingIntegrationStatus.Pending)
    },
  })
  return { isConnected, isLoading }
}
