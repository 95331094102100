import { isDefined } from 'utils'

import { PresignedPost } from '@/gql'

interface Options {
  path?: string
}

export interface FileResult {
  url: string | null
  size: number | null
  type: string | null
  error: Error | null
  path: string | null
  name: string
}

export interface UploadResult {
  url: string
  size: number
  type: string
  path: string
  name: string
}

export const useUploadFile = ({ path }: Options = {}) => {
  const getPresignedUrl = async (file: File) => {
    const { name, type } = file
    const [fileName] = name.split('.')
    const extension = type.split('/')[1]

    try {
      const headers = {
        'Content-Type': 'application/json',
      }

      const body = {
        fileName,
        contentType: type,
        extension,
        path,
      }

      const response = await fetch('/api/assets/signed-url', {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      })

      const result = await response.json()

      return result.data as {
        presignedUrl: string
      }
    } catch (error) {
      console.log('Failed to generate presigned url', error)
      return null
    }
  }

  const uploadFile = async (file: File): Promise<FileResult> => {
    const response = await getPresignedUrl(file)

    if (!isDefined(response)) {
      throw new Error('Failed to get presigned URL')
    }

    try {
      const res = await fetch(response.presignedUrl, {
        method: 'PUT',
        body: file,
      })

      // Remove the first slash of the pathname
      const url = new URL(res.url)
      const extractedPath = url.pathname.slice(1)

      return {
        url: res.url,
        type: file.type,
        size: file.size,
        error: null,
        path: extractedPath,
        name: file.name,
      }
    } catch (error) {
      return {
        url: null,
        type: file.type,
        size: file.size,
        error: error as Error,
        path: null,
        name: file.name,
      }
    }
  }

  const uploadFiles = async (files: File[]) => {
    const results = await Promise.all(files.map(uploadFile))

    return results
  }

  const uploadFileWithPresignedPost = async ({
    file,
    presignedPost,
  }: {
    file: File
    presignedPost: PresignedPost
  }): Promise<UploadResult> => {
    if (!isDefined(file) || !isDefined(presignedPost) || !isDefined(presignedPost.url)) {
      throw new Error('File or URL is missing')
    }

    if (presignedPost.url === '') {
      throw new Error('Presigned URL is empty')
    }

    // Create a FormData object
    const parsedFields: Record<string, string> = JSON.parse(presignedPost.fields)

    const formData = new FormData()

    // Append the fields from the presigned URL
    Object.entries(parsedFields).forEach(([key, value]) => {
      formData.append(key, value)
    })

    // Append the file
    formData.append('file', file)

    const res = await fetch(presignedPost.url, {
      method: 'POST',
      body: formData,
    })

    if (!res.ok) {
      throw new Error(`Failed to upload file: ${res.statusText}`)
    }

    return {
      url: '',
      type: file.type,
      size: file.size,
      path: parsedFields.key,
      name: file.name,
    }
  }

  return {
    // This two should be removed once all FE uploads are changed to use uploadFileWithPresignedPost
    uploadFile,
    uploadFiles,
    uploadFileWithPresignedPost,
  }
}
