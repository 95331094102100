import { Children, isValidElement, ReactElement, ReactNode } from 'react'
import { Button, ButtonProps, Flex, Icon, Text, Tooltip } from 'ui-lib'
import { isDefined } from 'utils'

interface ActionButtonProps extends ButtonProps {
  tooltip?: string
}

const ActionButton = (props: ActionButtonProps) => {
  if (isDefined(props.tooltip)) {
    return (
      <Tooltip label={props.tooltip}>
        <Button variant="secondaryOutline" size="sm" {...props} />
      </Tooltip>
    )
  }
  return <Button variant="secondaryOutline" size="sm" {...props} />
}

const Content = ({ children }: { children: ReactNode }) => {
  return <>{children}</>
}

const icon = {
  error: 'circle-cross-fill',
  info: 'info-fill',
  disabled: null,
}

export const StatusBar = ({
  open,
  variant,
  children,
}: {
  open: boolean
  variant: 'info' | 'error' | 'disabled'
  children: ReactNode
}) => {
  const allChildren = Children.toArray(children)

  const button = allChildren.find(
    (child): child is ReactElement => isValidElement(child) && child.type === ActionButton
  )

  const content = allChildren.find((child): child is ReactElement => isValidElement(child) && child.type === Content)
  return (
    <Flex
      bgColor={`bg-${variant}`}
      w="full"
      px="4"
      py="2"
      borderBottomRadius={!open ? 'md' : 'none'}
      justifyContent="space-between"
      direction={{
        base: 'column',
        md: 'row',
      }}
      gap="2"
    >
      <Flex gap="2" justifyContent="center" alignItems="center">
        {isDefined(icon[variant]) && <Icon icon={icon[variant]} variant={variant} size="sm" pb="0.5" />}
        <Text textStyle="title-xs" textColor={`text-${variant}`}>
          {content}
        </Text>
      </Flex>
      {button}
    </Flex>
  )
}

StatusBar.Content = Content
StatusBar.Button = ActionButton
