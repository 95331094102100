// import moment from 'moment'
// import { useSession } from 'next-auth/react'
import { Icon, Tag } from 'ui-lib'

export const AccountingMenuTagNew = () => {
  // TODO: Add back in once we decide to not show the tag on the sidebar for users who have onboarded in the last 30 days
  // const { data: session } = useSession()
  // const isRecentOnboarding = moment().diff(session?.user.internal?.onboardedAt, 'days') <= 30

  // if (!isRecentOnboarding) {
  //   return null
  // }

  return (
    <Tag variant="blue" size="sm" rounded="full" fontWeight="semibold">
      New <Icon icon="sparkles" w="3" h="3" ml="1" variant="inverse" />
    </Tag>
  )
}
