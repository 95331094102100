import React, { useEffect } from 'react'
import {
  CheckIcon,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  Image,
  Step as ChakraStep,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useSteps,
} from 'ui-lib'

import { StepType } from '@/src/constants/steps'
import CloseIcon from '@/src/svgs/closeIconBlack.svg'
import KeepLogo from '@/src/svgs/logo.svg'

export interface HeaderStepperProps extends FlexProps {
  steps: StepType[]
  activeStep: number
  onStepClick: (step: number, route?: string) => void
  onClose?: () => void
  allowExit?: boolean
  hideLogo?: boolean
}

const HeaderStepper = ({
  steps,
  activeStep,
  onStepClick,
  onClose,
  allowExit = true,
  hideLogo = false,
  ...props
}: HeaderStepperProps) => {
  const { isActiveStep, isCompleteStep, setActiveStep } = useSteps({
    index: activeStep,
    count: steps.length,
  })

  const handleStepClick = (step: number, route?: string) => {
    if (isCompleteStep(step)) {
      onStepClick(step, route)
    }
  }

  useEffect(() => {
    setActiveStep(activeStep)
  }, [activeStep, setActiveStep])

  return (
    <Flex align="center" justify="space-between" {...props}>
      {!hideLogo && <Image src={KeepLogo.src} w="36px" h="24px" mr="54px" alt="Keep Technologies logo" />}
      <Stepper size="sm" index={activeStep} width="100%">
        {steps.map(({ label, route }, step) => {
          const isActive = isActiveStep(step)
          const isCompleted = isCompleteStep(step)

          return (
            <ChakraStep key={step} onClick={() => handleStepClick(step, route)}>
              <Flex
                alignItems="center"
                cursor={'pointer'}
                borderRadius="12px"
                border="1px solid"
                borderColor={isActive ? 'border-primary' : isCompleted ? 'border-info' : 'border-disabled'}
                bg={isActive ? 'bg-inverse' : isCompleted ? 'bg-info' : 'bg-disabled'}
                color={isActive ? 'text-inverse' : isCompleted ? 'text-info' : 'text-disabled'}
                px="10px"
                minH="30px"
                whiteSpace="nowrap"
              >
                <StepStatus
                  complete={<CheckIcon width="12px" height="12px" mr="10px" />}
                  incomplete={
                    <Text textStyle="paragraph-md" color="inherit" mr="10px">
                      {step + 1}
                    </Text>
                  }
                  active={
                    <Text textStyle="paragraph-md" color="inherit" mr="10px">
                      {step + 1}
                    </Text>
                  }
                />

                <StepTitle>{label}</StepTitle>
              </Flex>

              <StepSeparator />
            </ChakraStep>
          )
        })}
      </Stepper>

      {allowExit === true && <CloseButton onClick={onClose} />}
    </Flex>
  )
}

export default HeaderStepper

const CloseButton = ({ onClick }: Partial<IconButtonProps>) => {
  return (
    <IconButton
      onClick={onClick}
      aria-label="Close"
      height="32px"
      minW="32px"
      width="32px"
      ml="48px"
      bg="white"
      _hover={{ bg: 'grey.100' }}
      _active={{ bg: 'grey.200' }}
      _focus={{
        outlineStyle: 'solid',
        outlineColor: 'blue.200',
        outlineWidth: '4px',
      }}
      tabIndex={0}
      icon={<Image src={CloseIcon.src} w="16px" h="16px" alt="Close icon" />}
    />
  )
}
