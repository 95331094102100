import { useRouter } from 'next/navigation'
import { ArrowLeftIcon, Box, Button, Flex, isDefined, Text } from 'ui-lib'

import CircularGradiengBackground from '@/src/svgs/CircularGradientBackground.svg'

interface Props {
  isBackButtonDisabled?: boolean
  hideGradient?: boolean
  onBackClick?: () => void
  children: React.ReactNode
}

export const BackHeaderTemplate = ({
  isBackButtonDisabled = false,
  hideGradient = false,
  onBackClick,
  children,
}: Props) => {
  const router = useRouter()

  const handleBackClicked = () => {
    if (isBackButtonDisabled) {
      return
    }
    if (isDefined(onBackClick)) {
      return onBackClick()
    }
    router.back()
  }

  return (
    <Flex position="relative" flexDirection="column" alignItems="flex-start" boxSizing="border-box" width="full">
      <Box
        position="absolute"
        top="-180px"
        width={{ base: 'full', sm: '460px' }}
        height="360px"
        pointerEvents="none"
        css={{
          left: '50%',
          transform: 'translateX(-50%)',
          background: `url(${!hideGradient ? CircularGradiengBackground.src : ''})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
      <Box
        paddingBlock="2"
        paddingInline={{ base: '6', md: '8' }}
        borderBottom="1px"
        borderColor="border-disabled"
        width="full"
        mb={{ base: '4', md: '10' }}
      >
        <Button
          variant={isBackButtonDisabled ? 'ghost-disabled' : 'ghost'}
          alignItems="center"
          color={!isBackButtonDisabled ? 'text-primary' : 'icon-disabled'}
          maxWidth="fit-content"
          paddingBlock="2"
          paddingInline={{ base: '0', md: '4' }}
          onClick={handleBackClicked}
          disabled={isBackButtonDisabled}
          leftIcon={<ArrowLeftIcon width="4" height="4" />}
        >
          <Text textStyle="title-sm" textColor={!isBackButtonDisabled ? 'text-primary' : 'text-disabled'}>
            Back
          </Text>
        </Button>
      </Box>
      {children}
    </Flex>
  )
}
