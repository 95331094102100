import { useFormContext } from 'react-hook-form'
import { Icon, IconButton, Tooltip, useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { AccountingExpense, ExpenseStatus } from '@/gql'
import { useUpdateExpenses } from '@/src/hooks/accounting'

import { ColumnComponentProps } from './types'

interface Props extends ColumnComponentProps {
  onUpdate: (expense: AccountingExpense) => void
}

export const TransitionExpenseButton = ({ row, onUpdate }: Props) => {
  const { trigger } = useFormContext()

  const expense = row.original
  const toast = useToast()
  const { updateExpenses } = useUpdateExpenses()
  if (!isDefined(expense)) {
    return null
  }

  const isInReview = [ExpenseStatus.InReview, ExpenseStatus.RequestForInformation, ExpenseStatus.Created].includes(
    expense.status
  )
  const isReadyToSync = [ExpenseStatus.Reviewed, ExpenseStatus.Failed].includes(expense.status)

  const transitionTo = async (toStatus: ExpenseStatus) => {
    const canUpdate = await trigger(`dataGridExpenses.${row.index}`)
    if (canUpdate) {
      const { errors } = await updateExpenses({
        expensesIds: [expense.id],
        input: { status: toStatus },
      })
      if (errors.length === 0) {
        toast({
          title: 'Update successful',
          description: 'The expenses have been successfully moved',
          status: 'success',
          position: 'top',
        })
        onUpdate(expense)
      }
    } else {
      toast({
        title: 'Error moving expense',
        description: 'The expense has missing data',
        status: 'error',
        position: 'top',
      })
    }
  }

  const transitionToReadyToSync = () => transitionTo(ExpenseStatus.Reviewed)

  const transitionToNeedsReview = () => transitionTo(ExpenseStatus.InReview)

  return (
    <>
      {isReadyToSync && (
        <Tooltip label="Mark as needs review" openDelay={200} hasArrow={false} placement="bottom-end">
          <IconButton
            display="flex"
            w="full"
            variant="unstyled"
            icon={<Icon icon="arrow-back" size="md" variant="secondary" />}
            onClick={transitionToNeedsReview}
            aria-label="Mark as needs review"
          />
        </Tooltip>
      )}
      {isInReview && (
        <Tooltip label="Mark as ready" openDelay={200} hasArrow={false} placement="bottom-end">
          <IconButton
            display="flex"
            w="full"
            variant="unstyled"
            icon={<Icon icon="circle-check" size="lg" variant="secondary" />}
            onClick={transitionToReadyToSync}
            aria-label="Mark as ready"
          />
        </Tooltip>
      )}
    </>
  )
}
