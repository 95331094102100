import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Form, Icon, InputRightElement, useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { ExpenseStatus } from '@/gql'
import { useUpdateExpenses } from '@/src/hooks/accounting'

import { DataGripExpense } from '../types'
import { ColumnComponentProps } from './types'

export const MemoInput = ({ row }: ColumnComponentProps) => {
  const toast = useToast()
  const expense = row.original
  const { formState, register } = useFormContext()

  const isInvalid = isDefined(formState.errors.dataGridExpenses?.at(row.index)?.memo)
  const inputProps = register(`dataGridExpenses.${row.index}.memo` as const)
  const isDisabled = row.original.status === ExpenseStatus.Synced

  const { updateExpenses } = useUpdateExpenses()

  const handleMemoUpdate = async (expense: DataGripExpense, newMemo: string) => {
    const { errors } = await updateExpenses({
      expensesIds: [expense.id],
      input: { memo: newMemo },
    })
    if (errors.length === 0) {
      toast({
        title: 'Update successful',
        description: 'The memo has been successfully updated',
        status: 'success',
        position: 'top',
      })
    } else {
      toast({
        title: 'Error updating memo',
        description: 'An error occurred while updating the memo',
        status: 'error',
        position: 'top',
      })
    }
  }

  return (
    <Box minW="180px">
      <Form.Input
        size="md"
        isInvalid={Boolean(isInvalid)}
        rightElement={
          <InputRightElement>
            <Icon icon="pencil" size="sm" />
          </InputRightElement>
        }
        isDisabled={isDisabled}
        {...inputProps}
        onBlur={(e) => handleMemoUpdate(expense, e.target.value)}
      />
    </Box>
  )
}
