import { useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { AccountingExpenseReceipt } from '@/gql'
import { downloadFileFromUrl, getSignedUrl } from '@/src/utils/misc'

export const useDownloadReceipt = () => {
  const toast = useToast()

  const downloadReceipt = async (receipt: AccountingExpenseReceipt) => {
    try {
      const receiptPath = receipt?.document?.path
      if (!isDefined(receiptPath)) {
        throw new Error('Receipt path is undefined')
      }
      const signedUrl = await getSignedUrl(receiptPath)
      const fileName = receipt?.document?.displayName ?? 'receipt'
      downloadFileFromUrl({ url: signedUrl, fileName })
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Something went wrong. Please try again later.'
      toast({
        title: 'Error downloading receipt',
        description: errorMessage,
        status: 'error',
      })
    }
  }

  return { downloadReceipt }
}
