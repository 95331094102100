import { ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { useToast } from 'ui-lib'

import isDefined from '@/src/utils/dataShaping/isDefined'

type ShowErrorToastProps = {
  title?: string
  description?: string
  duration?: number
}

export type ApolloErrorDisplayPreference = 'network' | 'graphql' | 'generic'

export type ApolloErrorDisplayPreferences = {
  preference: ApolloErrorDisplayPreference
}

type ShowErrorToastPropsWithDisplayPreference = ShowErrorToastProps & {
  preference?: ApolloErrorDisplayPreference
}

export const shouldCaptureException = (error: ApolloError) => {
  const { graphQLErrors } = error
  if (graphQLErrors.length === 1 && graphQLErrors[0].extensions?.code === 'BAD_USER_INPUT') {
    return false
  }
  return true
}

export const captureException = (
  error: unknown
): {
  type: 'captureException' | 'captureMessage' | 'captureEvent'
  id: string
} => {
  if (error instanceof Error) {
    const id = Sentry.captureException(error)
    return {
      type: 'captureException',
      id,
    }
  } else if (typeof error === 'string') {
    const id = Sentry.captureMessage(error)
    return {
      type: 'captureMessage',
      id,
    }
  } else {
    const id = Sentry.captureEvent({
      message: 'Unknown error occurred',
      extra: {
        context: error,
      },
    })
    return {
      type: 'captureEvent',
      id,
    }
  }
}

function getServerErrorMessage(error: ApolloError) {
  const networkError = error.networkError
  let networkErrorContent: string | null = null

  if (isDefined(networkError)) {
    if ('result' in networkError) {
      networkErrorContent = (networkError?.result as { message: string }).message
    } else if ('bodyText' in networkError) {
      networkErrorContent = networkError.bodyText
    } else {
      networkErrorContent = networkError.message
    }
  }
  return networkErrorContent
}

function getGraphqlError(error: ApolloError) {
  const graphQLErrors = error.graphQLErrors
  return (
    // @ts-ignore
    graphQLErrors[0]?.extensions?.exception?.request?.[0]?.message ?? graphQLErrors[0]?.message
  )
}

function getErrorTitle(error: ApolloError, displayPreferences?: ApolloErrorDisplayPreferences): string {
  const preference = displayPreferences?.preference ?? 'network'
  const networkErrorContent = getServerErrorMessage(error)
  const graphqlError = getGraphqlError(error)
  const genericError = 'An unexpected error has occurred'

  let errorMessages: (string | null)[] = []

  switch (preference) {
    case 'network':
      errorMessages = [networkErrorContent, graphqlError, genericError]
      break
    case 'graphql':
      errorMessages = [graphqlError, networkErrorContent, genericError]
      break
    case 'generic':
      errorMessages = [genericError, networkErrorContent, graphqlError]
  }

  // return the first defined option
  return errorMessages.find((ee: string | null | undefined) => isDefined(ee)) ?? genericError
}

export const onApolloError = (
  error: unknown,
  showErrorToast: (props: ShowErrorToastProps | ShowErrorToastPropsWithDisplayPreference) => void,
  displayPreferences?: ShowErrorToastPropsWithDisplayPreference
) => {
  let title
  let alertSentry = true

  if (error instanceof ApolloError) {
    alertSentry = shouldCaptureException(error)
    const preference = displayPreferences?.preference
    title = getErrorTitle(error, isDefined(preference) ? { preference } : undefined)
  } else if (error instanceof Error) {
    title = error.message
  }

  showErrorToast({ title, ...displayPreferences })
  if (alertSentry) {
    const eventId = captureException(error)
    console.log('Sentry logged eventId', eventId)
  }
}

export const useErrorToast = () => {
  const toast = useToast()
  const showErrorToast = (props: ShowErrorToastProps) => {
    const {
      title = 'An unexpected error has occurred',
      description = 'Something went wrong. Please try again.',
      duration = 5000,
    } = props
    toast({
      title,
      description,
      position: 'top',
      status: 'error',
      duration,
      isClosable: true,
    })
  }

  return {
    showErrorToast,
    onApolloError: (error: unknown, preferences?: ShowErrorToastPropsWithDisplayPreference) =>
      onApolloError(error, showErrorToast, preferences),
  }
}
