import React from 'react'
import { ActionButtons, Flex, Icon, Skeleton, Text, Tooltip, useToast } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { useCalculateWholeAmountToPayForEarlyPaymentQuery } from '@/gql'
import { SEGMENT_EVENTS, TEAM } from '@/src/constants/segmentEvents'
import { useEarlyPayment } from '@/src/contexts/capital'
import { useServerTrackEvent } from '@/src/hooks/misc'

export const AmountStep = () => {
  const trackEvent = useServerTrackEvent()
  const toast = useToast()
  const { goToNextStep, funding } = useEarlyPayment()
  const { totalFee: fee, fundingId } = funding
  const { data, loading } = useCalculateWholeAmountToPayForEarlyPaymentQuery({
    variables: {
      fundingId,
    },
    onError: () => {
      toast({
        title: 'Calculation Error',
        description: 'An error occurred while calculating the amount to pay',
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const { totalAmount, principalOutstandingAmount, proRatedFeeAmount } =
    data?.calculateWholeAmountToPayForEarlyPayment ?? {}

  const feeDiscount = isDefined(fee) ? fee - (proRatedFeeAmount?.amountAsNumber ?? 0) : 0

  const onContinue = () => {
    trackEvent({
      type: SEGMENT_EVENTS.EARLY_PAYMENT_AMOUNT_SELECTED,
      payload: { team: TEAM.CAPITAL, financingChannel: funding.financingChannel, plan_number: funding.loanNumber },
    })
    goToNextStep?.({ paymentAmount: totalAmount?.amountAsNumber ?? 0 })
  }

  return (
    <>
      <Flex gap="2" mb="4" flexDir="column">
        <Text textStyle="subheading-md" color="text-secondary" textTransform="uppercase">
          Total amount to be paid back
        </Text>
        {loading ? <Skeleton w="full" height="30px" /> : <Text textStyle="headline-3">{totalAmount?.amount}</Text>}
      </Flex>
      <Flex gap="2" flexDir="column">
        <Text textStyle="subheading-md" color="text-secondary" textTransform="uppercase" mb="1">
          How is it applied
        </Text>
        {loading ? (
          <Skeleton w="full" height="150px" />
        ) : (
          <>
            <Flex borderRadius="sm" bgColor="bg-disabled" p="4" gap="1" flexDir="column">
              <Flex justifyContent="space-between">
                <Text textStyle="paragraph-md" color="text-secondary">
                  Outstanding principal
                </Text>
                <Text textStyle="title-xs">{principalOutstandingAmount?.amount}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Flex gap="1">
                  <Text textStyle="paragraph-md" color="text-secondary">
                    Pro-rated fee
                  </Text>
                  <Tooltip label="You're paying only the fee for the upcoming installment, saving on fees for future installments.">
                    <Icon icon="info-fill" size="sm" />
                  </Tooltip>
                </Flex>
                <Text textStyle="title-xs">{proRatedFeeAmount?.amount}</Text>
              </Flex>
            </Flex>
            <Flex borderRadius="sm" bgColor="bg-disabled" p="4" justifyContent="space-between">
              <Flex gap="1">
                <Flex borderRadius="full" p="1" bg="text-disabled" h="fit-content">
                  <Icon icon="percentage" variant="inverse" size="xs" />
                </Flex>
                <Flex flexDir="column">
                  <Text textStyle="title-xs">Outstanding Principal (after payment)</Text>
                  <Text color="text-secondary" textStyle="paragraph-sm">
                    You’re saving {Money.fromNumber(feeDiscount).toFormattedCurrencyString()} in fee
                  </Text>
                </Flex>
              </Flex>
              <Text textStyle="title-xs">$0.00</Text>
            </Flex>
          </>
        )}
      </Flex>
      <ActionButtons mt="6">
        <ActionButtons.Primary isLoading={loading} onClick={onContinue}>
          Continue
        </ActionButtons.Primary>
      </ActionButtons>
    </>
  )
}
