import { isDefined } from 'utils'
import { z } from 'zod'

import {
  AccountingExpense,
  AccountingExpenseCategory,
  AccountingExpenseClass,
  AccountingExpenseTaxRate,
  AccountingExpenseVendor,
  CardType,
  ExpenseStatus,
} from '@/gql'

import { DataGripExpense } from './types'

export const buildVendorsOptions = (vendors?: AccountingExpenseVendor[] | null) => {
  if (!isDefined(vendors)) {
    return []
  }
  return vendors.map((vendor) => ({
    label: vendor.vendorName ?? vendor.email,
    value: vendor.id,
  }))
}

export const buildCategoryOptions = (categories?: AccountingExpenseCategory[] | null) => {
  if (!isDefined(categories)) {
    return []
  }
  return categories.map((category) => ({
    label: category.name,
    value: category.id,
  }))
}

export const buildClassOptions = (classes?: AccountingExpenseClass[] | null) => {
  if (!isDefined(classes)) {
    return []
  }
  return classes.map((c) => ({
    label: c.name,
    value: c.id,
  }))
}

const formatTaxRateToOption = (taxRate: AccountingExpenseTaxRate) => {
  const taxCode = isDefined(taxRate) && isDefined(taxRate.code) ? `${taxRate.code} - ` : ''
  return {
    label: `${taxCode}${taxRate?.name}`,
    value: taxRate?.id,
  }
}

export const buildTaxRateOptions = (taxRates?: AccountingExpenseTaxRate[] | null) => {
  if (!isDefined(taxRates)) {
    return []
  }
  return taxRates.map(formatTaxRateToOption)
}

export const buildStatusOptions = (statuses: ExpenseStatus[]) => {
  const mapper: Partial<Record<ExpenseStatus, string>> = {
    REQUEST_FOR_INFORMATION: 'Waiting for cardholder',
    REVIEWED: 'Ready to sync',
    IN_REVIEW: 'Needs review',
  }
  return statuses.map((status) => {
    const label = mapper[status] ?? status
    return { label, value: status }
  })
}

const expenseSchema = z.object({
  status: z.nativeEnum(ExpenseStatus),
  selected: z.boolean(),
  isReadyToSync: z.boolean(),
  entityCategoryId: z.string().optional(),
  entityTaxCodeId: z.string().optional(),
  entityMerchantId: z.string().optional(),
  memo: z.string().optional(),
  receipt: z
    .object({
      id: z.string(),
      documentId: z.string(),
    })
    .optional(),
  entityClassId: z.string().optional(),
})
export const expensesTableSchema = z.object({
  dataGridExpenses: z.array(expenseSchema),
})
export type ExpenseTableData = z.infer<typeof expensesTableSchema>

export const mapExpensesToDataGridData = (expenses: AccountingExpense[]): DataGripExpense[] => {
  return expenses.map((expense) => {
    const failedMessage = getFailedMessage(expense)
    return {
      id: expense.id,
      businessId: expense.businessId,
      transactionDate: expense.transactionDate ?? '',
      merchantName: expense.merchantName ?? '',
      status: expense.status,
      statusSnapshots: expense.statusSnapshots ?? [],
      amount: expense.amount ?? '',
      currency: expense.currency ?? '',
      requestedAmount: expense.requestedAmount ?? undefined,
      requestedCurrency: expense.requestedCurrency ?? undefined,
      cardType: expense.card?.type ?? CardType.Virtual,
      lastFour: expense.card?.lastFour ?? '',
      nameOnCard: expense.card?.nameOnCard ?? '',
      selected: false,
      isReadyToSync: false,
      entityCategoryId: expense.entityCategoryId ?? '',
      entityTaxCodeId: expense.entityTaxCodeId ?? '',
      entityMerchantId: expense.entityMerchantId ?? '',
      memo: expense.memo ?? '',
      receipt: expense.receipt ?? undefined,
      error: failedMessage,
      entityClassId: expense.entityClassId ?? '',
    }
  })
}

const getFailedMessage = (expense: AccountingExpense): string | undefined => {
  if (expense.status !== ExpenseStatus.Failed || !isDefined(expense.statusSnapshots)) {
    return
  }
  if (expense.statusSnapshots.length > 0 && expense.statusSnapshots[0].status === ExpenseStatus.Failed) {
    return expense.statusSnapshots[0].description ?? ''
  }
}
