type StorageType = 'local' | 'session'

export class BrowserStorage {
  storageType: StorageType
  constructor(storageType: StorageType = 'local') {
    this.storageType = storageType
  }

  get(key: string) {
    const storage = this.storageType === 'local' ? localStorage : sessionStorage
    return storage.getItem(key)
  }

  set(key: string, value: string) {
    const storage = this.storageType === 'local' ? localStorage : sessionStorage
    storage.setItem(key, value)
  }

  remove(key: string) {
    const storage = this.storageType === 'local' ? localStorage : sessionStorage
    storage.removeItem(key)
  }

  static clear(storageType: StorageType = 'local') {
    const storage = storageType === 'local' ? localStorage : sessionStorage
    storage.clear()
  }
}
