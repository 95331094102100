import { isDefined } from 'utils'
import { z } from 'zod'

import { AccountingExpense, ExpenseStatus } from '@/gql'

export const TEN_MB_IN_BYTES = 10 * 1024 * 1024

export const schema = z
  .object({
    memo: z.string().trim().min(1, { message: 'Memo is required' }),
    receipt: z
      .object({
        path: z.string(),
        type: z.string(),
        name: z.string(),
      })
      .nullable(),
  })
  .refine((data) => isDefined(data.receipt), { message: 'Receipt is required', path: ['receipt'] })

export type ReceiptFormData = z.infer<typeof schema>

export const getReceiptValues = (receipt: AccountingExpense['receipt']): ReceiptFormData['receipt'] | undefined => {
  const receiptDocument = receipt?.document
  if (!isDefined(receiptDocument)) {
    return
  }
  return {
    name: receiptDocument.displayName,
    path: receiptDocument.path,
    type: receiptDocument.mimeType,
  }
}

export const getIsDisabledReceiptForm = (expense: AccountingExpense | undefined | null): boolean => {
  const allowedStatusesToEditExpense: Array<ExpenseStatus | undefined> = [
    ExpenseStatus.Created,
    ExpenseStatus.RequestForInformation,
    ExpenseStatus.InReview,
    ExpenseStatus.Draft,
  ]
  return !allowedStatusesToEditExpense.includes(expense?.status)
}
