import { useRouter } from 'next/router'

import { StatementExtensionProvider, StatementExtensionStep, useStatementExtension } from '@/src/contexts/capital'

import { TopStepperTemplate } from '../stepper-template'

type StatementExtensionTemplateProps = React.ComponentProps<typeof StatementExtensionProvider>

interface TemplateProps {
  children: React.ReactNode
}

const STEPS: Record<
  string,
  {
    key: StatementExtensionStep
    label: string
    position: number
  }
> = {
  plan: {
    key: 'plan',
    label: 'Financing plan',
    position: 0,
  },
  review: {
    key: 'review',
    label: 'Review',
    position: 1,
  },
  complete: {
    key: 'complete',
    label: 'Complete',
    position: 2,
  },
}

const Template = (props: TemplateProps) => {
  const router = useRouter()
  const { currentStep, goToStep } = useStatementExtension()

  return (
    <TopStepperTemplate
      currentStep={currentStep}
      steps={currentStep === 'complete' ? {} : STEPS}
      hiddenSteps={['complete']}
      onExit={() => router.push('/statement-extension')}
      onStepChange={goToStep}
      showConfirmationOnExit={currentStep !== 'complete'}
    >
      {props.children}
    </TopStepperTemplate>
  )
}

export const StatementExtensionTemplate = ({
  children,
  onComplete,
}: Pick<StatementExtensionTemplateProps, 'children' | 'onComplete'>) => (
  <StatementExtensionProvider onComplete={onComplete}>
    <Template>{children}</Template>
  </StatementExtensionProvider>
)
