import { ExpenseStatus } from '@/gql'

export const isWaitingForCardholder = (statuses: ExpenseStatus[]) =>
  statuses.includes(ExpenseStatus.Created) || statuses.includes(ExpenseStatus.RequestForInformation)

export const isNeedsReview = (statuses: ExpenseStatus[]) => statuses.includes(ExpenseStatus.InReview)

export const isReadyToSync = (statuses: ExpenseStatus[]) =>
  statuses.includes(ExpenseStatus.Reviewed) || statuses.includes(ExpenseStatus.Failed)

export const getAllowedTransitions = (statuses: ExpenseStatus[]) => {
  let allowedTransitions: ExpenseStatus[] = []
  if (isWaitingForCardholder(statuses)) {
    allowedTransitions = [ExpenseStatus.InReview, ExpenseStatus.Reviewed]
  } else if (isNeedsReview(statuses)) {
    allowedTransitions = [ExpenseStatus.RequestForInformation, ExpenseStatus.Reviewed]
  } else if (isReadyToSync(statuses)) {
    allowedTransitions = [ExpenseStatus.InReview, ExpenseStatus.RequestForInformation]
  }
  return allowedTransitions
}

export const getDefaultStatuses = (tabIndex: number | undefined) => {
  switch (tabIndex) {
    case 0:
      return IN_REVIEW_STATUSES
    case 1:
      return READY_TO_SYNC_STATUSES
    case 2:
      return WAITING_FOR_CARDHOLDER_STATUSES
    case 3:
      return SYNCED_STATUSES
    default:
      return IN_REVIEW_STATUSES
  }
}

export const IN_REVIEW_STATUSES = [ExpenseStatus.InReview]
export const READY_TO_SYNC_STATUSES = [ExpenseStatus.Reviewed, ExpenseStatus.Failed]
export const WAITING_FOR_CARDHOLDER_STATUSES = [ExpenseStatus.Created, ExpenseStatus.RequestForInformation]
export const SYNCED_STATUSES = [ExpenseStatus.Synced]
