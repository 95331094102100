import { Controller, useFormContext } from 'react-hook-form'
import { Form } from 'ui-lib'

export const DateFilter = () => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name="dateRange"
      render={({ field }) => (
        <>
          <Form.Field label="Select a date range" id="primary" isDisabled={true}>
            <Form.DateRangeInput {...field.value} onChange={field.onChange} />
          </Form.Field>
        </>
      )}
    />
  )
}
