import { textStyles } from '../text-styles'

export const Modal = {
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    dialog: {
      padding: '62px',
    },
    header: {
      width: '100%',
      textAlign: 'center',
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  sizes: {
    md: {
      dialog: {
        maxWidth: '480px',
      },
    },
    lg: {
      dialog: {
        maxWidth: '560px',
      },
    },
  },
  variants: {
    default: {
      header: {
        paddingY: 4,
        paddingLeft: 6,
        paddingRight: 20,
        borderBottom: '1px solid var(--chakra-colors-_primary-100)',
        textAlign: 'left',
        fontSize: '1.75rem',
        fontWeight: 600,
        lineHeight: '2rem',
        letterSpacing: '-0.02em',
      },
      dialog: {
        width: '30rem',
        borderRadius: 'lg',
        pt: 12,
        px: 8,
        pb: 8,
        gap: 6,
        display: 'grid',
        textAlign: 'center',
        justifyItems: 'center',
      },
      closeButton: {
        fontSize: '1rem',
        top: 4,
        right: 4,
      },
      body: {
        padding: 6,
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: '1.625rem',
        letterSpacing: '-0.02em',
      },
      footer: {
        paddingTop: 0,
        paddingX: 6,
        paddingBottom: 6,
      },
    },
    new: {
      dialog: {
        padding: 0,
        borderRadius: 'lg',
      },
      header: {
        px: '6',
        py: '6',
        textColor: 'text-primary',
        borderBottom: '1px solid',
        borderColor: 'border-soft',
        textAlign: 'left',
        ...textStyles['headline-4'],
      },
      body: {
        px: 6,
        py: 6,
      },
      footer: {
        pt: 0,
        px: 6,
        pb: 6,
      },
      closeButton: {
        position: 'absolute',
        minWidth: 'auto',
        right: 6,
        top: 6,
      },
    },
  },
}
