import { Money } from 'utils'

import { CapitalApprovalVersion, useCapitalApprovalQuery } from '@/gql'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useSegmentPageVisited } from '@/src/hooks/useSegmentPageVisited'
import { PartialCapitalFunding } from '@/types/capital'

import { FundingCard, FundingCardDetails, OriginationFeeFundingCardDetails } from '.'
import { mapLoanToTrackedLoan, transformPaginatedFundingToGeneric } from './utils'

export const CapitalFundingCard = ({ funding }: { funding: PartialCapitalFunding }) => {
  const trackPageVisited = useSegmentPageVisited()
  const { data: dataApproval } = useCapitalApprovalQuery()

  const isCapitalPricingVersionV2 = dataApproval?.capitalApproval?.pricingVersion === CapitalApprovalVersion.V2

  const sendLoanAnalytics = (loan: PartialCapitalFunding) => {
    trackPageVisited({
      type: SEGMENT_PAGE_VISITED.LOAN_DETAILS_VIEWED,
      payload: {
        team: 'Capital',
        page: 'Active Loan Screen',
        loan: mapLoanToTrackedLoan(loan),
      },
    })
  }

  const genericFunding = transformPaginatedFundingToGeneric(funding)
  const totalAmount = Money.fromNumber(
    funding.totalFee.amountAsNumber + funding.principalAmount.amountAsNumber
  ).toFormattedCurrencyString()

  return (
    <FundingCard
      key={funding.id}
      funding={genericFunding}
      onOpen={() => {
        isCapitalPricingVersionV2 && sendLoanAnalytics(funding)
      }}
    >
      {isCapitalPricingVersionV2 ? (
        <OriginationFeeFundingCardDetails funding={funding as PartialCapitalFunding} totalAmount={totalAmount} />
      ) : (
        <FundingCardDetails funding={funding} totalAmount={totalAmount} />
      )}
    </FundingCard>
  )
}
