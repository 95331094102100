import Head from 'next/head'
import { useSession } from 'next-auth/react'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { isDefined, useBoolean } from 'ui-lib'

import { useGenerateSupportChatTokenMutation } from '@/gql'
import { useEffectOnce } from '@/src/hooks/misc'
import { BrowserStorage } from '@/src/utils/misc'

interface ISupportChatContext {
  isOpen: boolean
  isLoading: boolean
  open: () => Promise<void>
  close: () => void
  unreadMessagesCount: number
  haveUnreadMessages: boolean
}

export const SupportChatContext = createContext<ISupportChatContext>({} as any)

declare global {
  interface Window {
    HubSpotConversations?: {
      on: (eventName: string, payload: any) => void
      widget: {
        open: () => void
        close: () => void
        load: (params?: { widgetOpen: boolean }) => void
      }
    }
    hsConversationsSettings: {
      loadImmediately?: boolean
      inlineEmbedSelector?: string
      enableWidgetCookieBanner?: boolean | 'ON_WIDGET_LOAD' | 'ON_EXIT_INTENT'
      disableAttachment?: boolean
      disableInitialInputFocus?: boolean
      avoidInlineStyles?: boolean
      identificationToken?: string
      identificationEmail?: string
    }
    hsConversationsOnReady?: (() => void)[]
  }
}

const storage = new BrowserStorage('local')

export const SupportChatProvider = (props: { children: React.ReactNode }) => {
  const [isLoading, setLoading] = useBoolean(true)
  const [isOpen, setOpen] = useBoolean(false)
  const [unreadMessagesCount, setUnreadMessageCount] = useState(0)
  const [generateToken, { data: tokenData }] = useGenerateSupportChatTokenMutation()
  const token = tokenData?.generateSupportChatToken

  const { data } = useSession()
  const id = data?.user?.internal?.id
  const email = data?.user?.internal?.email

  useEffect(() => {
    // pull unread count from local storage if available
    const count = storage.get(`${id}_hs_unread_count`)
    if (isDefined(count)) {
      setUnreadMessageCount(+count)
    }
  }, [id])

  useEffectOnce(() => {
    function onConversationsAPIReady() {
      if (isDefined(window.HubSpotConversations)) {
        window.HubSpotConversations.on('widgetClosed', () => {
          setOpen.off()
        })
        window.HubSpotConversations.on('unreadConversationCountChanged', (payload: { unreadCount: number }) => {
          // save it to local storage so we can display unread indicators between page refreshes
          storage.set(`${id}_hs_unread_count`, payload.unreadCount.toString())
          setUnreadMessageCount(payload.unreadCount)
        })
        setLoading.off()
      }
    }

    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    if (isDefined(window.HubSpotConversations)) {
      onConversationsAPIReady()
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady]
    }
  })

  const open = useCallback(async () => {
    if (isDefined(window.HubSpotConversations) && isDefined(email)) {
      if (!isDefined(token)) {
        const response = await generateToken()
        window.hsConversationsSettings.identificationToken = response.data!.generateSupportChatToken!
        window.hsConversationsSettings.identificationEmail = email
        window.HubSpotConversations.widget.load({
          widgetOpen: true,
        })
      }

      setTimeout(() => {
        setOpen.on()
      }, 250)

      window.HubSpotConversations.widget.open()
    }
  }, [setOpen, token, email, generateToken])

  const close = useCallback(() => {
    if (isDefined(window.HubSpotConversations)) {
      window.HubSpotConversations.widget.close()
    }
  }, [])

  return (
    <>
      <Head>
        {/* controls wether we show the chat or not (mainly to hide the floating button)*/}
        <style type="text/css">
          {`
         div#hubspot-messages-iframe-container {
            ${!isOpen ? 'display: none !important;' : ''}
          }
        `}
        </style>
      </Head>
      <SupportChatContext.Provider
        value={{ isLoading, isOpen, open, close, unreadMessagesCount, haveUnreadMessages: unreadMessagesCount > 0 }}
      >
        {props.children}
      </SupportChatContext.Provider>
    </>
  )
}

export const useSupportChat = () => {
  return useContext(SupportChatContext)
}
