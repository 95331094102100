import { Box, CheckIcon, Step, StepIndicator, Stepper, StepStatus, StepTitle, Text } from 'ui-lib'

import { CommonStepperProp } from './types'

export interface StepperDesktopProps extends CommonStepperProp {
  steps: { label: string }[]
}

export const StepperDesktop = ({ activeIndex, steps, onClick, disableSteps = false }: StepperDesktopProps) => {
  return (
    <Stepper variant="new" orientation="vertical" index={activeIndex} position="absolute" left="10" top="0">
      {steps.map((step, index) => (
        <Step key={step.label} onClick={() => onClick?.(index)}>
          <StepIndicator>
            <StepStatus
              active={
                disableSteps ? (
                  <CheckIcon textColor="icon-inverse" width="3" height="auto" />
                ) : (
                  <Text textColor="text-inverse">{index + 1}</Text>
                )
              }
              complete={<CheckIcon textColor="icon-inverse" width="3" height="auto" />}
              incomplete={<Text textColor="text-inverse">{index + 1}</Text>}
            />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>{step.label}</StepTitle>
          </Box>
        </Step>
      ))}
    </Stepper>
  )
}
