import { debounce } from 'lodash'
import { useState } from 'react'
import { Form, Icon, InputLeftElement } from 'ui-lib'
import { isDefined } from 'utils'

import { useQueryParamState } from '@/src/hooks/misc'

import { FilterValues } from '../table-filter'

interface Props {
  onChange: (s: string) => void
}

export const ExpensesGlobalFilter = ({ onChange }: Props) => {
  const [selectedFilters] = useQueryParamState<FilterValues>({
    key: 'filters',
    parse: (value) => (isDefined(value) ? JSON.parse(value) : undefined),
  })
  const [searchCriteria, setSearchCriteria] = useState<string>(selectedFilters?.search ?? '')
  function setValue(s: string) {
    setSearchCriteria(s)
    onChange(s)
  }

  return (
    <Form.Input
      placeholder="Search expenses"
      size="md"
      w="248px"
      defaultValue={searchCriteria}
      leftElement={
        <InputLeftElement>
          <Icon icon="search" size="sm" color="icon-secondary" />
        </InputLeftElement>
      }
      onChange={(e) => debounce(() => setValue(e.target.value), 1500)()}
    />
  )
}
