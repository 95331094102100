import { isDefined } from 'utils'

import { useListAccountingTaxRatesQuery } from '@/gql'

import { FilterValues } from '../utils'
import { ExpenseFilterTag } from './expense-filter-tag'
import { FilterTagsProps } from './utils'

export const useTaxCodeTags = ({ form, onFilter }: FilterTagsProps) => {
  const { data: taxCodes } = useListAccountingTaxRatesQuery()

  const getTaxCodeTag = (taxCodeId: string, taxCodeIds: string[]) => {
    const taxCode = taxCodes?.listAccountingTaxRates?.find(({ id }) => id === taxCodeId)
    if (!isDefined(taxCode)) {
      return null
    }
    const deleteTaxCode = () => {
      form.setValue(
        'taxCodes',
        taxCodeIds.filter((id) => id !== taxCodeId)
      )
    }
    return <ExpenseFilterTag form={form} label={taxCode.name} onDelete={deleteTaxCode} onFilter={onFilter} />
  }

  const getTaxCodeTags = (params: Pick<FilterValues, 'taxCodes' | 'taxCodesGroup'>) => {
    const clearTaxCodes = () => {
      form.setValue('taxCodesGroup', '')
      form.setValue('taxCodes', undefined)
    }
    const key = params.taxCodesGroup === 'categorized' ? 'with-tax-code' : 'without-tax-code'
    const label = params.taxCodesGroup === 'categorized' ? 'With Tax Code' : 'Without Tax Code'
    if (params.taxCodesGroup === 'categorized' || params.taxCodesGroup === 'uncategorized') {
      return [<ExpenseFilterTag key={key} form={form} label={label} onDelete={clearTaxCodes} onFilter={onFilter} />]
    }
    if (!Array.isArray(params.taxCodes)) {
      return []
    }
    const tags: JSX.Element[] = []
    for (const taxCode of params.taxCodes) {
      const tag = getTaxCodeTag(taxCode, params.taxCodes)
      if (isDefined(tag)) {
        tags.push(tag)
      }
    }
    return tags
  }

  return { getTaxCodeTags }
}
