import { IconButton, Tooltip as ChakraTooltip, TooltipProps, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { isDefined } from 'utils'

import { Icon } from '../icon'
import { IconName, IconVariant } from '../icon/types'

type IconTooltipProps = Partial<TooltipProps> & {
  label: string | React.ReactNode
  icon: IconName
  iconSize?: string
  iconVariant?: IconVariant
}

function useCloseOutside<T extends HTMLElement>(onClose: () => void) {
  const ref = useRef<T>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (isDefined(ref.current) && !ref.current.contains(event.target as Node)) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return { ref }
}

export const IconTooltip = ({ icon, label, iconSize = 'sm', variant, ...props }: IconTooltipProps) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
  const { ref } = useCloseOutside(onClose)

  return (
    <ChakraTooltip label={label} {...props} isOpen={isOpen} ref={ref}>
      <IconButton
        icon={<Icon icon={icon} size={iconSize} variant={variant} />}
        aria-label="Helper text"
        variant="unstyled"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={onToggle}
        minW="none"
        minHeight="auto"
        height="auto"
        cursor="help"
        id={props.id}
      />
    </ChakraTooltip>
  )
}
