import Link from 'next/link'
import { Button, Flex, Text } from 'ui-lib'

import GradientText from '@/src/components/atoms/GradientText'

export function StartAccountingBanner() {
  return (
    <Flex
      p="5"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      border="1px solid"
      borderColor="border-soft"
      style={{
        background:
          'linear-gradient(91.57deg, rgba(237, 239, 241, 0.18) -1.44%, rgba(237, 239, 241, 0.18) 30.5%, #FFFFFF 57.56%, rgba(237, 239, 241, 0.35) 98.06%)',
      }}
    >
      <Flex flexDir="column">
        <GradientText gradient="blueGreen" textStyle="subheading-sm" textTransform="uppercase">
          have a better control of your expenses
        </GradientText>
        <Text maxW="630px" textStyle="title-md" mt="2">
          Your new Accounting page gives you a dedicated space to prep and sync your team’s card transactions each
          month.
        </Text>
      </Flex>

      <Button as={Link} href="/settings/accounting">
        Start now
      </Button>
    </Flex>
  )
}
