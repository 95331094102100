import { zodResolver } from '@hookform/resolvers/zod'
import { Row } from '@tanstack/react-table'
import { pick } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Checkbox,
  DataGrid,
  DataGridColumn,
  Flex,
  Text,
  useBoolean,
  useDataGrid,
  useDisclosure,
} from 'ui-lib'
import { isDefined } from 'utils'
import moment from 'utils/moment'

import { AccountingExpense, ExpenseErrorType, ExpenseStatus } from '@/gql'
import { SimplePagination } from '@/src/components/misc/simple-pagination'

import { FilterExpensesTable, FormattedFilterValues } from '../table-filter'
import { BulkUpdateExpensesModal, BulkUpdateValues } from './bulk-update-expenses-modal'
import { ExpensesGlobalFilter } from './expenses-global-filter'
import {
  ExpenseCategorySelect,
  ExpenseClassSelect,
  ExpenseMerchantName,
  ExpenseNameOnCard,
  ExpenseSelectedCheckBox,
  ExpenseTaxRateSelect,
  ExpenseVendorSelect,
  MemoInput,
  ReceiptButton,
  TransitionExpenseButton,
} from './expenses-table-form'
import { getAllowedTransitions } from './helpers'
import { DataGripExpense } from './types'
import { PAGE_SIZE, RefetchParams, RefetchType, useDataGridExpenses } from './use-data-grid-expenses'
import { expensesTableSchema, ExpenseTableData, mapExpensesToDataGridData } from './utils'

interface ExpensesTableProps {
  canSyncExpenses: boolean
}

export const ExpensesTable = ({ canSyncExpenses }: ExpensesTableProps) => {
  const {
    isSyncing,
    handleSyncExpenses,
    expenses: gridExpenses,
    refetchExpensesData,
    fetchExpensesInterval,
    totalExpenses,
    currentStatuses,
  } = useDataGridExpenses()

  const allowedTransitionStatus = getAllowedTransitions(currentStatuses)
  const [isLoadingMore, setIsLoadingMore] = useBoolean(false)

  const LAST_PAGE = Math.ceil(totalExpenses / PAGE_SIZE)
  const dataGridExpenses = useMemo(() => mapExpensesToDataGridData(gridExpenses), [gridExpenses])

  const form = useForm<ExpenseTableData>({
    defaultValues: {
      dataGridExpenses,
    },
    resolver: zodResolver(expensesTableSchema),
  })
  const { setValue, reset } = form

  const [currentPage, setCurrentPage] = useState(1)

  const deleteExpensesFromTable = async (expenseIds: string[]) => {
    const rows = table.getRowModel().rows.filter((r) => !expenseIds.includes(r.original.id))
    const dataGridExpenses = rows.map((r) => r.original)
    setData(dataGridExpenses)
    reset({ dataGridExpenses })
  }

  const setExpenseErrors = (errors: ExpenseErrorType[]) => {
    if (errors.length === 0) {
      return
    }
    table.getRowModel().rows.forEach((row) => {
      const error = errors.find((e) => e.expenseId === row.original.id)
      if (isDefined(error)) {
        row.original.error = error.message
      }
    })
  }

  const handleRefetch = async (params?: RefetchParams, type?: RefetchType, keepExistingFilters?: boolean) => {
    await refetchExpensesData(params, type, keepExistingFilters)
  }

  const handleExpenseTransitioned = (expense: AccountingExpense) => {
    deleteExpensesFromTable([expense.id])
    handleRefetch()
  }

  const handleFormValuesUpdated = async (expenseIds: string[], values: BulkUpdateValues) => {
    const selectableValues = pick(values, ['entityCategoryId', 'entityTaxCodeId', 'entityMerchantId', 'entityClassId'])
    if (Object.values(selectableValues).every((value) => !isDefined(value))) {
      return
    }

    table.getRowModel().rows.forEach((row) => {
      if (!expenseIds.includes(row.original.id)) {
        return
      }
      const selectableKeys = Object.keys(selectableValues)
      for (const key of selectableKeys) {
        const field = key as 'entityCategoryId' | 'entityTaxCodeId' | 'entityMerchantId' | 'entityClassId'
        if (isDefined(selectableValues[field])) {
          row.original[field] = selectableValues[field]
          setValue(`dataGridExpenses.${row.index}.${field}`, selectableValues[field])
        }
      }
    })
  }

  const handleExpensesUpdatedInBulk = (expenseIds: string[], errors: ExpenseErrorType[], values: BulkUpdateValues) => {
    table.toggleAllRowsSelected(false)
    handleFormValuesUpdated(expenseIds, values)
    deleteExpensesFromTable(isDefined(values.status) ? expenseIds : [])
    setExpenseErrors(errors)
    handleRefetch()
  }

  const columns = useMemo(() => {
    return [
      {
        id: 'selected',
        size: 64,
        header: ({ table }) => (
          <Flex minW="15px">
            <Checkbox
              isDisabled={gridExpenses.some((e: AccountingExpense) => e.status === ExpenseStatus.Synced)}
              checked={table.getIsAllRowsSelected()}
              colorScheme="blue"
              onChange={(value) => {
                table.getFilteredRowModel().rows.forEach((row) => {
                  table.getRow(`${row.index}`).toggleSelected(value.target.checked)
                  setValue(`dataGridExpenses.${row.index}.selected`, value.target.checked)
                })
              }}
            />
          </Flex>
        ),
        cell: ({ row }) => <ExpenseSelectedCheckBox row={row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'transactionDate',
        header: 'Date',
        size: 98,
        cell: (cell) => (
          <Text textStyle="subheading-md" color="text-primary" textTransform="uppercase" whiteSpace="nowrap">
            {moment(cell.getValue()).format('MMM DD')}
          </Text>
        ),
      },
      {
        id: 'merchantName',
        header: 'Description',
        size: 200,
        cell: (cell) => <ExpenseMerchantName row={cell.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'amount',
        header: 'Amount',
        size: 130,
        cell: (cell) => (
          <Box>
            <Text textStyle="paragraph-md" color="text-primary" whiteSpace="nowrap">
              {`${cell.getValue()} ${cell.row.original.currency}`}
            </Text>
            {isDefined(cell.row.original.requestedCurrency) &&
              cell.row.original.requestedCurrency !== cell.row.original.currency && (
                <Text textStyle="paragraph-sm" color="text-soft">
                  {`${cell.row.original.requestedAmount} ${cell.row.original.requestedCurrency}`}
                </Text>
              )}
          </Box>
        ),
      },
      {
        id: 'nameOnCard',
        header: 'Method',
        size: 155,
        cell: (c) => <ExpenseNameOnCard row={c.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'receipt',
        header: 'Receipt',
        size: 96,

        cell: (c) => (
          <ReceiptButton disabled={c.row.original.status === 'SYNCED'} row={c.row as unknown as Row<DataGripExpense>} />
        ),
      },
      {
        id: 'entityCategoryId',
        header: 'Category',
        enableResizing: true,
        size: 228,
        cell: (c) => <ExpenseCategorySelect row={c.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'entityTaxCodeId',
        header: 'Tax Code',
        enableResizing: true,
        size: 228,
        cell: (c) => <ExpenseTaxRateSelect row={c.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'entityMerchantId',
        header: 'Quickbooks Vendor',
        enableResizing: true,
        size: 228,
        cell: (c) => <ExpenseVendorSelect row={c.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'entityClassId',
        header: 'Class',
        enableResizing: true,
        size: 228,
        cell: (c) => <ExpenseClassSelect row={c.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'memo',
        header: 'Memo',
        size: 228,
        cell: (c) => <MemoInput row={c.row as unknown as Row<DataGripExpense>} />,
      },
      {
        id: 'isReadyToSync',
        header: 'Actions',
        size: 100,
        cell: (c) => (
          <TransitionExpenseButton
            row={c.row as unknown as Row<DataGripExpense>}
            onUpdate={handleExpenseTransitioned}
          />
        ),
      },
    ] as DataGridColumn<DataGripExpense>[]
    // We only want to re-run this when there is an error in the form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGridExpenses, isLoadingMore])

  const { table, setData, setColumns, selectedRows } = useDataGrid<DataGripExpense>({
    columns,
    data: dataGridExpenses,
    pinnedColumns: {
      left: ['selected', 'transactionDate', 'merchantName'],
      right: ['isReadyToSync'],
    },
    visibleColumns: {
      isReadyToSync: allowedTransitionStatus.length !== 0,
    },
  })

  useEffect(() => {
    setColumns(columns)
  }, [columns, setColumns])

  useEffect(() => {
    setData(dataGridExpenses)
    reset({ dataGridExpenses: mapExpensesToDataGridData(gridExpenses) }, { keepDefaultValues: false })
  }, [dataGridExpenses, gridExpenses, reset, setData])

  const selectedExpenses = Object.keys(selectedRows).map((index) => gridExpenses[Number(index)])
  const selectedExpensesCount = selectedExpenses.length > 0 ? `(${selectedExpenses.length})` : ''

  const {
    isOpen: isBulkUpdateModalOpen,
    onOpen: onBulkUpdateModalOpen,
    onClose: onBulkUpdateModalClose,
  } = useDisclosure()

  const handleSearch = (s: string) => {
    handleRefetch({ selectedFilters: { search: s } }, 'filters', true)
  }

  const handleFilterChange = (params: FormattedFilterValues) => {
    handleRefetch({ selectedFilters: params }, 'filters', true)
  }

  const handleSyncTableExpenses = async (expenses: AccountingExpense[]) => {
    if (expenses.length === 0) {
      return
    }

    await handleSyncExpenses(expenses)
    table.toggleAllRowsSelected(false)
    deleteExpensesFromTable(expenses.map((e) => e.id))
  }

  const handlePageChange = async (page: number) => {
    setIsLoadingMore.on()
    try {
      const startIndex = (page - 1) * PAGE_SIZE
      const endIndex = startIndex + PAGE_SIZE
      await fetchExpensesInterval(startIndex, endIndex)
      table.toggleAllRowsSelected(false)
      setCurrentPage(page)
    } finally {
      setIsLoadingMore.off()
    }
  }

  const showPagination = LAST_PAGE > 1

  return (
    <Flex direction="column" pb="8" gap="2">
      <Flex justifyContent="flex-end" mb="4" gap="2" p="2">
        <Flex w="full" gap={2}>
          <Box>
            <ExpensesGlobalFilter onChange={handleSearch} />
          </Box>
          <FilterExpensesTable onChange={handleFilterChange} />
        </Flex>

        <Button variant="outline" isDisabled={selectedExpenses.length <= 0} onClick={onBulkUpdateModalOpen}>
          Bulk actions {selectedExpensesCount}
        </Button>
        {canSyncExpenses && (
          <Button
            isDisabled={selectedExpenses.length <= 0 || isSyncing}
            onClick={() => handleSyncTableExpenses(selectedExpenses)}
            isLoading={isSyncing}
          >
            Sync {selectedExpensesCount}
          </Button>
        )}
      </Flex>
      <Box minH="calc(100% - 120px)" pb="4" overflowX="auto">
        <FormProvider {...form}>
          <DataGrid<DataGripExpense> table={table} enableColumnResizing />
        </FormProvider>
      </Box>

      {showPagination && (
        <Flex justifyContent="center" my="4">
          <SimplePagination
            currentPage={currentPage}
            totalPages={LAST_PAGE}
            onPageChange={handlePageChange}
            isDisabled={isLoadingMore}
          />
        </Flex>
      )}

      {selectedExpenses.length > 0 && (
        <BulkUpdateExpensesModal
          isOpen={isBulkUpdateModalOpen}
          expenses={selectedExpenses}
          statusesToTransition={allowedTransitionStatus}
          onClose={onBulkUpdateModalClose}
          onUpdate={handleExpensesUpdatedInBulk}
        />
      )}
    </Flex>
  )
}
