import {
  ColumnPinningState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  RowSelectionState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import { useState } from 'react'
import { isDefined } from 'utils'

import { DataGridColumn, useDataGridProps } from './types'

/**
 * This hook provides a way to manage the state of the data grid component, including the data, columns, selected rows, and pinned columns.
 * It uses the `useReactTable` hook from `@tanstack/react-table` to manage the state of the table.
 * @param props {useDataGridProps<T>} The props for the data grid component.
 * @returns
 */
export const useDataGrid = <T,>(props: useDataGridProps<T>) => {
  const [data, setData] = useState<T[]>(props.data)
  const [columns, setColumns] = useState<DataGridColumn<T>[]>(props.columns)

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>(props.selectedRows ?? {})
  const [pinnedColumns, setPinnedColumns] = useState<ColumnPinningState>(props.pinnedColumns ?? { left: [], right: [] })
  const [visibleColumns, setVisibleColumns] = useState<VisibilityState>(props.visibleColumns ?? {})

  const columnHelper = createColumnHelper<T>()

  const columnsDef = columns.map((col) => {
    return columnHelper.accessor((row) => row[col.id], {
      enableResizing: false,
      ...col,
    })
  })

  const table = useReactTable<T>({
    data,
    columns: columnsDef,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setSelectedRows,
    state: {
      rowSelection: selectedRows,
      columnPinning: pinnedColumns,
      globalFilter: props.globalFilter,
      columnVisibility: visibleColumns,
    },
    globalFilterFn: props.filterFn ?? 'auto',
    onGlobalFilterChange: props.setGlobalFilter,
    enableGlobalFilter: isDefined(props.globalFilter),
  })

  return {
    pinnedColumns,
    selectedRows,
    setPinnedColumns,
    setSelectedRows,
    setData,
    setColumns,
    table,
    data,
    visibleColumns,
    setVisibleColumns,
  }
}
