import { ReactNode } from 'react'
import { Divider, Flex, Skeleton, Text } from 'ui-lib'

import { SimplePagination } from '../misc'

type PaginationProps =
  | {
      children: ReactNode
      isLoading?: boolean
      pages: number | undefined
      page: number | undefined
      setPage: (page: number) => void
      showPagination?: true
    }
  | {
      pages?: never
      isLoading?: boolean
      page?: never
      setPage?: never
      children: ReactNode
      showPagination: false
    }

export const FundingsList = ({
  children,
  pages,
  page,
  setPage,
  showPagination = true,
  isLoading = false,
}: PaginationProps) => {
  if (isLoading) {
    return <Skeleton height="300px" />
  }

  return (
    <>
      <Flex direction="row" width="100%" display={['none', 'flex']}>
        <Flex width="50%" direction="row">
          <Text textStyle="subheading-sm" textColor="text-soft">
            PLAN ID
          </Text>
        </Flex>
        <Flex width="50%" direction="row">
          <Text textStyle="subheading-sm" textColor="text-soft">
            PAID TO DATE
          </Text>
        </Flex>
      </Flex>
      <Divider display={['none', 'flex']} />
      {children}
      {showPagination && (
        <SimplePagination
          currentPage={page ?? 1}
          totalPages={pages ?? 1}
          onPageChange={setPage as (page: number) => void}
        />
      )}
    </>
  )
}
