// TODO: replace color references with the equivalent ones when switching to the new theme

import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['th', 'td', 'tr', 'selected'])

export const Table = helpers.defineMultiStyleConfig({
  baseStyle: {
    th: {
      textTransform: 'initial',
      letterSpacing: '-0.02em',
      color: 'grey.300',
      lineHeight: '24px',
      fontWeight: 600,
    },
    td: {
      textTransform: 'initial',
      letterSpacing: '-0.02em',
      fontWeight: 500,
      size: '18px',
      lineHeight: '24px',
    },
    pinnedColumn: {
      zIndex: 1,
      position: 'sticky',
      color: 'text-secondary',
      backgroundColor: 'bg-primary',
    },
    leftPinnedColumn: {
      boxShadow: '-4px 0 1px -4px inset',
      _after: {
        content: '""',
        position: 'absolute',
        top: 0,
        right: '-20px',
        height: '100%',
        width: '20px',
        background: 'linear-gradient(90deg, rgba(249, 249, 249, 0.7) 0%, rgba(255, 255, 255, 0) 100%)',
      },
    },
    rightPinnedColumn: {
      boxShadow: '4px 0 1px -4px inset',
      _after: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '-20px',
        height: '100%',
        width: '20px',
        background: 'linear-gradient(270deg, rgba(249, 249, 249, 0.7) 0%, rgba(255, 255, 255, 0) 100%)',
      },
    },
    selected: {
      backgroundColor: 'bg-info',
    },
    resizer: {
      width: '10px',
      height: '80%',
      borderRight: '1px solid',
      borderColor: 'blackAlpha.500',
      cursor: 'col-resize',
      position: 'absolute',
      top: '20%',
      right: 0,
      zIndex: 0,
    },
  },
  variants: {
    new: {
      th: {
        textTransform: 'uppercase',
        borderBottom: '1px solid',
        textStyle: 'subheading-sm',
        textColor: 'text-soft',
        borderColor: 'border-disabled',
      },
      tr: {
        backgroundColor: 'bg-primary',
      },
    },
    interactive: {
      th: {
        textTransform: 'uppercase',
        borderBottom: '1px solid',
        textStyle: 'subheading-sm',
        textColor: 'text-soft',
        borderColor: 'border-disabled',
        paddingBottom: '4',
      },
      tr: {
        backgroundColor: 'bg-primary',
      },
      selected: {
        backgroundColor: 'bg-disabled',
        position: 'relative',
        _first: {
          borderLeftRadius: '12px',
        },
        '&:nth-last-child(2)': {
          borderRightRadius: '12px',
        },
        _last: {
          backgroundColor: 'bg-primary',
        },
      },
    },
  },
})
