import { CurrencyCode } from 'services/utils/countries'
import { z } from 'zod'

import { BANK_ACCOUNT_TYPE } from '@/src/components/molecules/SelectPaymentMethod'

export const PlaidAccountSchema = z.object({
  type: z.literal(BANK_ACCOUNT_TYPE.PlaidAccount),
  id: z.string(),
  accountName: z.string(),
  accountNumber: z.string(),
  isPrimary: z.boolean(),
  bankLogo: z.string(),
})

export const CCAccountSchema = z.object({
  type: z.literal(BANK_ACCOUNT_TYPE.CCAccount),
  id: z.string(),
  availableBalance: z.number(),
  currencyCode: z.custom<CurrencyCode>(),
  accountNumber: z.string(),
})

export const accountStepSchema = z.object({
  amount: z.number().positive(),
  account: z.discriminatedUnion('type', [PlaidAccountSchema, CCAccountSchema], {
    message: 'You need to select an account to pay the funding',
  }),
})

export type FormValues = z.infer<typeof accountStepSchema>
