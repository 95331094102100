import AddReceipt from './add-receipt.icon.svg'
import AlertReceipt from './alert-receipt.icon.svg'
import AlignToBottom from './align-to-bottom.icon.svg'
import ApprovedReceipt from './approved-receipt.icon.svg'
import ArrowBack from './arrow-back.icon.svg'
import ArrowClockwise from './arrow-clockwise.icon.svg'
import ArrowDown from './arrow-down.icon.svg'
import ArrowLeft from './arrow-left.icon.svg'
import ArrowRightLeft from './arrow-right-left.icon.svg'
import ArrowSend from './arrow-send.icon.svg'
import ArrowUp from './arrow-up.icon.svg'
import Bank from './bank.icon.svg'
import BankOutline from './bank-outline.icon.svg'
import Bell from './bell.icon.svg'
import BlockedCard from './blocked-card.icon.svg'
import Book from './book.icon.svg'
import Briefcase from './briefcase.icon.svg'
import Calendar from './calendar.icon.svg'
import Cards from './cards.icon.svg'
import Check from './check.icon.svg'
import ChevronDown from './chevron-down.icon.svg'
import ChevronLeft from './chevron-left.icon.svg'
import ChevronRight from './chevron-right.icon.svg'
import ChevronVertical from './chevron-vertical.icon.svg'
import CircleAlert from './circle-alert.icon.svg'
import CircleAlertFill from './circle-alert-fill.icon.svg'
import CircleCheck from './circle-check.icon.svg'
import CircleCheckFill from './circle-check-fill.icon.svg'
import CircleCross from './circle-cross.icon.svg'
import CircleCrossFill from './circle-cross-fill.icon.svg'
import CircleGiftFill from './circle-gift-fill.icon.svg'
import CirclePlusOutline from './circle-plus-outline.icon.svg'
import CircleTriangleRightFill from './circle-triangle-right-fill.icon.svg'
import ClockCountdown from './clock-countdown.icon.svg'
import ClosedCard from './closed-card.icon.svg'
import Cross from './cross.icon.svg'
import Door from './door.icon.svg'
import Download from './download.icon.svg'
import Envelope from './envelope.icon.svg'
import EyeOpen from './eye-open.icon.svg'
import EyeSlashed from './eye-slashed.icon.svg'
import FileIcon from './file-icon.icon.svg'
import Filter from './filter.icon.svg'
import Fingerprint from './fingerprint.icon.svg'
import FlinksLogo from './flinks-logo.icon.svg'
import HamburgerMenu from './hamburger-menu.icon.svg'
import InfoFill from './info-fill.icon.svg'
import LeadingCopy from './leading-copy.icon.svg'
import LightbulbIdea from './lightbulb-idea.icon.svg'
import LinkOut from './link-out.icon.svg'
import Lock from './lock.icon.svg'
import LockOn from './lock-on.icon.svg'
import LockOnFill from './lock-on-fill.icon.svg'
import Minus from './minus.icon.svg'
import More from './more.icon.svg'
import PanelRight from './panel-right.icon.svg'
import Paper from './paper.icon.svg'
import PaperAirplane from './paper-airplane.icon.svg'
import Papers from './papers.icon.svg'
import Password from './password.icon.svg'
import Pencil from './pencil.icon.svg'
import Percentage from './percentage.icon.svg'
import Person from './person.icon.svg'
import Phone from './phone.icon.svg'
import Plant from './plant.icon.svg'
import Plus from './plus.icon.svg'
import PlusThin from './plus-thin.icon.svg'
import Question from './question.icon.svg'
import QuestionFill from './question-fill.icon.svg'
import Receipt from './receipt.icon.svg'
import Repeat from './repeat.icon.svg'
import Search from './search.icon.svg'
import ShieldCheck from './shield-check.icon.svg'
import SidebarArrowDown from './sidebar-arrow-down.icon.svg'
import Snowflake from './snowflake.icon.svg'
import Sparkles from './sparkles.icon.svg'
import Subtract from './subtract.icon.svg'
import Tag from './tag.icon.svg'
import TagFill from './tag-fill.icon.svg'
import TextAlignLeft from './text-align-left.icon.svg'
import Thermometer from './thermometer.icon.svg'
import Trash from './trash.icon.svg'
import TriangleAlertFill from './triangle-alert-fill.icon.svg'
import TriangleDown from './triangle-down.icon.svg'
import TriangleRight from './triangle-right.icon.svg'
import User from './user.icon.svg'
import UserMenuArrow from './user-menu-arrow.icon.svg'
import UserPlus from './user-plus.icon.svg'
import VerticalDots from './vertical-dots.icon.svg'
import ZoomIn from './zoom-in.icon.svg'
import ZoomOut from './zoom-out.icon.svg'

export const icons = {
  'add-receipt': AddReceipt,
  'alert-receipt': AlertReceipt,
  'align-to-bottom': AlignToBottom,
  'approved-receipt': ApprovedReceipt,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-back': ArrowBack,
  'arrow-clockwise': ArrowClockwise,
  'arrow-left': ArrowLeft,
  'arrow-right-left': ArrowRightLeft,
  'arrow-send': ArrowSend,
  'bank-outline': BankOutline,
  bank: Bank,
  bell: Bell,
  'blocked-card': BlockedCard,
  book: Book,
  briefcase: Briefcase,
  calendar: Calendar,
  cards: Cards,
  check: Check,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-vertical': ChevronVertical,
  'circle-alert-fill': CircleAlertFill,
  'circle-alert': CircleAlert,
  'circle-check-fill': CircleCheckFill,
  'circle-check': CircleCheck,
  'circle-cross-fill': CircleCrossFill,
  'circle-cross': CircleCross,
  'circle-gift-fill': CircleGiftFill,
  'circle-plus-outline': CirclePlusOutline,
  'circle-triangle-right-fill': CircleTriangleRightFill,
  'clock-countdown': ClockCountdown,
  'closed-card': ClosedCard,
  cross: Cross,
  door: Door,
  download: Download,
  envelope: Envelope,
  'eye-open': EyeOpen,
  'eye-slashed': EyeSlashed,
  'file-icon': FileIcon,
  filter: Filter,
  fingerprint: Fingerprint,
  'flinks-logo': FlinksLogo,
  'info-fill': InfoFill,
  'leading-copy': LeadingCopy,
  'lightbulb-idea': LightbulbIdea,
  'link-out': LinkOut,
  'lock-on-fill': LockOnFill,
  'lock-on': LockOn,
  lock: Lock,
  minus: Minus,
  more: More,
  'paper-airplane': PaperAirplane,
  paper: Paper,
  papers: Papers,
  password: Password,
  pencil: Pencil,
  percentage: Percentage,
  person: Person,
  phone: Phone,
  plant: Plant,
  'plus-thin': PlusThin,
  plus: Plus,
  'question-fill': QuestionFill,
  question: Question,
  receipt: Receipt,
  repeat: Repeat,
  search: Search,
  'shield-check': ShieldCheck,
  snowflake: Snowflake,
  sparkles: Sparkles,
  subtract: Subtract,
  'tag-fill': TagFill,
  tag: Tag,
  'text-align-left': TextAlignLeft,
  thermometer: Thermometer,
  trash: Trash,
  'triangle-alert-fill': TriangleAlertFill,
  'triangle-down': TriangleDown,
  'triangle-right': TriangleRight,
  user: User,
  'user-plus': UserPlus,
  'vertical-dots': VerticalDots,
  'zoom-in': ZoomIn,
  'zoom-out': ZoomOut,
  'hamburger-menu': HamburgerMenu,
  'panel-right': PanelRight,
  'user-menu-arrow': UserMenuArrow,
  'sidebar-arrow-down': SidebarArrowDown,
}
