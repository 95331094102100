type DownloadFileFromUrlParams = {
  url: string
  fileName: string
}

/**
 * A utility function to download a file from a given URL and save it with a specified file name
 * from the web browser.
 * @param url - The URL of the file to download.
 * @param fileName - The name of the file to save.
 */
export const downloadFileFromUrl = ({ url, fileName }: DownloadFileFromUrlParams) => {
  const temporalLink = document.createElement('a')
  temporalLink.href = url
  temporalLink.target = '_blank'
  temporalLink.rel = 'noopener noreferrer'
  temporalLink.download = fileName
  document.body.appendChild(temporalLink)
  temporalLink.click()
  document.body.removeChild(temporalLink)
}
