import { isEmpty, isNull } from 'lodash'
import { ReactNode } from 'react'
import { isDefined } from 'utils'
import { z } from 'zod'

import { CategoryFilter } from './category-filter'
import { DateFilter } from './date-filter'
import { ReceiptFilter } from './receipt-filter'
import { TaxCodeFilter } from './tax-code-filter'
import { VendorFilter } from './vendor-filter'

export enum Filter {
  Date = 'Date',
  Categories = 'Categories',
  TaxCodes = 'Tax Codes',
  Vendors = 'Vendors',
  Receipt = 'Receipt',
}

export const schema = z.object({
  dateRange: z
    .object({
      startDate: z.date().optional(),
      endDate: z.date().optional(),
    })
    .optional(),
  categories: z.union([z.array(z.string()), z.null(), z.undefined()]).optional(),
  categoriesGroup: z.string().optional(),
  taxCodes: z.union([z.array(z.string()), z.null(), z.undefined()]).optional(),
  taxCodesGroup: z.string().optional(),
  vendors: z.union([z.array(z.string()), z.null(), z.undefined()]).optional(),
  vendorsGroup: z.string().optional(),
  receipt: z.string().optional(),
  search: z.string().optional(),
})

export const FILTER_ITEMS: {
  [key in Filter]: { icon: string; component: ReactNode }
} = {
  Date: {
    icon: 'calendar',
    component: <DateFilter />,
  },
  Categories: {
    icon: 'tag',
    component: <CategoryFilter />,
  },
  'Tax Codes': {
    icon: 'text-align-left',
    component: <TaxCodeFilter />,
  },
  Vendors: {
    icon: 'briefcase',
    component: <VendorFilter />,
  },
  Receipt: {
    icon: 'receipt',
    component: <ReceiptFilter />,
  },
}

export type FilterValues = z.infer<typeof schema>

export type DateRange = {
  startDate: string
  endDate: string
}

export type FormattedFilterValues = {
  dateRange?: DateRange
  categories?: string[] | null | undefined
  taxCodes?: string[] | null | undefined
  vendors?: string[] | null | undefined
  receipt?: boolean | undefined
  search?: string | undefined
}
export type FormattedFilterKey = keyof FormattedFilterValues

export const formatFilterValues = (values: FilterValues | undefined): FormattedFilterValues => {
  if (!isDefined(values) || isEmpty(values)) {
    return {}
  }

  const startDateInput = values.dateRange?.startDate
  const endDateInput = values.dateRange?.endDate
  const startDate = isDefined(startDateInput) ? new Date(startDateInput).toISOString().slice(0, 10) : undefined
  const endDate = isDefined(endDateInput) ? new Date(endDateInput).toISOString().slice(0, 10) : undefined

  const { categoriesGroup, taxCodesGroup, vendorsGroup, search, ...rest } = values
  const dateRange = isDefined(startDate) && isDefined(endDate) ? { startDate, endDate } : undefined
  const categories = isNull(values.categories) || !isEmpty(values.categories) ? values.categories : undefined
  const receipt = values.receipt === 'empty' ? false : values.receipt === 'nonEmpty' ? true : undefined

  return {
    ...rest,
    dateRange,
    categories,
    receipt,
  }
}

export const getDefaultValues = (values: FilterValues | undefined) => {
  if (!isDefined(values) || isEmpty(values)) {
    return {}
  }

  const startDate = values?.dateRange?.startDate
  const endDate = values?.dateRange?.endDate
  const dateRange =
    isDefined(startDate) && isDefined(endDate)
      ? {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        }
      : undefined
  return {
    ...values,
    dateRange,
  }
}
