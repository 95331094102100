import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useBoolean, useToast } from 'ui-lib'
import { isDefined } from 'utils'

import {
  AccountingExpense,
  ExpensesCountByStatus,
  ExpenseStatus,
  useExpensesByBusinessQuery,
  useFetchSyncingExpensesQuery,
  useSyncExpensesMutation,
} from '@/gql'
import { useQueryParamState } from '@/src/hooks/misc'

import { FormattedFilterValues } from '../table-filter'
import { getDefaultStatuses, isReadyToSync } from './helpers'

export type RefetchParams = {
  currentStatuses?: ExpenseStatus[]
  selectedFilters?: FormattedFilterValues
}

export type RefetchType = 'status' | 'filters' | 'default'

interface UseDataGridExpensesReturn {
  currentTab: number
  expenses: AccountingExpense[]
  totalExpenses: number
  isLoading: boolean
  fetchExpensesInterval: (startIndex: number, endIndex: number) => Promise<void>
  refetchExpensesData: (params?: RefetchParams, type?: RefetchType, keepExistingFilters?: boolean) => Promise<void>
  expensesCountByStatus: ExpensesCountByStatus | undefined
  currentStatuses: ExpenseStatus[]
  selectedFilters: FormattedFilterValues
  setCurrentFilters: (filters: FormattedFilterValues) => void
  setCurrentStatuses: (statuses: ExpenseStatus[]) => void
  isSyncing: boolean
  handleSyncExpenses: (expenses: AccountingExpense[]) => Promise<void>
}

export const PAGE_SIZE = 10

interface ExpensesGridContextType extends UseDataGridExpensesReturn {}

const ExpensesGridContext = createContext<ExpensesGridContextType | undefined>(undefined)

interface ExpensesGridProviderProps {
  children: ReactNode
  pageSize?: number
  currentTab: number
}

// TOOD move it to src/contexts/accounting
export function ExpensesGridProvider({ children, pageSize = PAGE_SIZE, currentTab }: ExpensesGridProviderProps) {
  const [selectedFilters, setCurrentFilters] = useQueryParamState<FormattedFilterValues>({
    key: 'filters',
    parse: (value) => (isDefined(value) ? JSON.parse(value) : {}),
  })
  const [currentStatuses, setCurrentStatuses] = useState<ExpenseStatus[]>(getDefaultStatuses(currentTab))
  const [isSyncing, setIsSyncing] = useBoolean(false)
  const [syncExpenses] = useSyncExpensesMutation()
  const [expensesCountByStatus, setExpensesCountByStatus] = useState<ExpensesCountByStatus | undefined>()

  const toast = useToast()
  const {
    data: expensesData,
    loading: isLoading,
    refetch: refetchExpenses,
  } = useExpensesByBusinessQuery({
    variables: {
      filters: { statuses: getDefaultStatuses(currentTab), ...selectedFilters },
      pagination: {
        take: pageSize,
      },
    },
  })

  useEffect(() => {
    const newCountsByStatus = expensesData?.listExpensesByBusiness?.countsByStatus
    if (Boolean(newCountsByStatus) && JSON.stringify(newCountsByStatus) !== JSON.stringify(expensesCountByStatus)) {
      setExpensesCountByStatus(newCountsByStatus as ExpensesCountByStatus)
    }
  }, [expensesData?.listExpensesByBusiness?.countsByStatus, expensesCountByStatus])

  const refetchExpensesData = async (
    params?: RefetchParams,
    type: RefetchType = 'default',
    keepExistingFilters = false
  ) => {
    try {
      await refetchExpenses({
        filters: {
          statuses: params?.currentStatuses ?? currentStatuses,
          ...(keepExistingFilters
            ? { ...selectedFilters, ...params?.selectedFilters }
            : params?.selectedFilters ?? selectedFilters),
        },
        pagination: { take: pageSize },
      })

      if (type === 'status') {
        setCurrentStatuses(
          isDefined(params) && isDefined(params?.currentStatuses)
            ? params.currentStatuses
            : getDefaultStatuses(currentTab)
        )
      } else if (type === 'filters') {
        setCurrentFilters(isDefined(params) && isDefined(params?.selectedFilters) ? params.selectedFilters : {})
      }
    } catch (error) {
      toast({
        title: 'Error getting expenses',
        description: 'An error occurred while getting expenses',
        status: 'error',
      })
    }
  }

  const fetchExpensesInterval = async (startIndex: number, endIndex: number) => {
    try {
      if (!isDefined(expensesData?.listExpensesByBusiness)) {
        return
      }

      const skip = startIndex
      const take = endIndex - startIndex

      await refetchExpenses({
        filters: {
          statuses: currentStatuses,
          ...selectedFilters,
        },
        pagination: {
          skip,
          take,
        },
      })
    } catch (error) {
      toast({
        title: 'Error getting expenses',
        description: 'An error occurred while getting expenses',
        status: 'error',
      })
    }
  }

  const { previousData: previousSyncingData } = useFetchSyncingExpensesQuery({
    pollInterval: 5000,
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
    skip: !isReadyToSync(currentStatuses),
    onCompleted(data) {
      const currentCount = data.fetchSyncingExpenses?.count ?? 0
      const previousCount = previousSyncingData?.fetchSyncingExpenses?.count ?? 0

      if (previousCount > currentCount) {
        refetchExpensesData({}, 'default', true)
      }

      if (currentCount !== 0) {
        setIsSyncing.on()
      } else {
        setIsSyncing.off()
      }
    },
  })

  const handleSyncExpenses = async (expenses: AccountingExpense[]) => {
    const expensesIds = expenses.map((expense) => expense.id)

    try {
      await syncExpenses({ variables: { expensesIds }, refetchQueries: ['ExpensesByBusiness'] })
      setIsSyncing.on()

      toast({
        title: 'Syncing expenses!',
        description: 'We are syncing your expenses. This may take awhile.',
        status: 'info',
        duration: 5_000,
        position: 'top-right',
      })

      setIsSyncing.off()
      // Needed in case the server responds quicker than the client refetches the data
      setTimeout(() => {
        refetchExpensesData({}, 'default', true)
      }, 1000)
    } catch (error) {
      toast({
        title: 'Something went wrong while syncing the expenses',
        status: 'error',
      })
    }
  }

  useEffect(() => {
    setCurrentStatuses(getDefaultStatuses(currentTab))
  }, [currentTab])

  const value: ExpensesGridContextType = {
    currentTab,
    expenses: expensesData?.listExpensesByBusiness?.expenses ?? [],
    totalExpenses: expensesData?.listExpensesByBusiness?.totalExpenses ?? 0,
    expensesCountByStatus,
    isLoading,
    fetchExpensesInterval,
    refetchExpensesData,
    currentStatuses,
    selectedFilters: isDefined(selectedFilters) ? selectedFilters : {},
    setCurrentFilters,
    setCurrentStatuses,
    isSyncing,
    handleSyncExpenses,
  }

  return <ExpensesGridContext.Provider value={value}>{children}</ExpensesGridContext.Provider>
}

export const useDataGridExpenses = (): UseDataGridExpensesReturn => {
  const context = useContext(ExpensesGridContext)
  if (context === undefined) {
    throw new Error('useDataGridExpenses must be used within a ExpensesGridProvider')
  }
  return context
}
