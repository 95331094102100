import React from 'react'
import { ActionButtons, Flex, Icon, Text } from 'ui-lib'

import { SEGMENT_EVENTS, TEAM } from '@/src/constants/segmentEvents'
import { useEarlyPayment } from '@/src/contexts/capital'
import { useServerTrackEvent } from '@/src/hooks/misc'

const content = {
  error: {
    icon: 'cross',
    title: 'Payment Failed',
    message: 'We were unable to process your payment. Please try again later.',
  },
  success: {
    icon: 'check',
    title: 'Payment Successful',
    message: 'You’ll receive an email when the payment is successfully applied.',
  },
}

export const CompleteStep = () => {
  const { goToNextStep, creationStatus, funding } = useEarlyPayment()
  const { icon, title, message } = content[creationStatus]
  const trackEvent = useServerTrackEvent()

  const onContinue = () => {
    if (creationStatus === 'success') {
      trackEvent({
        type: SEGMENT_EVENTS.EARLY_PAYMENT_COMPLETED,
        payload: { team: TEAM.CAPITAL, financingChannel: funding.financingChannel, plan_number: funding.loanNumber },
      })
    }
    goToNextStep()
  }
  return (
    <>
      <Flex flex="1" maxW="388px" mx="auto" flexDir="column" alignItems="center" justifyContent="center" my="6">
        <Icon icon={icon} p="4" bg="bg-inverse" borderRadius="full" variant="inverse" width="max-content" />
        <Text textStyle="headline-4" display="block" mt="8" mb="2" textAlign="center">
          {title}
        </Text>
        <Text textStyle="paragraph-lg" color="text-secondary" textAlign="center">
          {message}
        </Text>
      </Flex>
      <ActionButtons mt="6">
        <ActionButtons.Primary onClick={onContinue}>Okay</ActionButtons.Primary>
      </ActionButtons>
    </>
  )
}
