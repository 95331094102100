import { Flex, Text } from 'ui-lib'

import { CapitalApprovalVersion, CapitalInfo, useCapitalApprovalQuery } from '@/gql'
import { DelinquencyBanner } from '@/src/components/misc/delinquency-banner'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useEffectOnce, useInternalUser, useServerTrackEvent } from '@/src/hooks/misc'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'
import { useInternalUserStatus } from '@/src/utils/user-session'

import { CreditLimitStatusTag } from './credit-limit-status-tag'
import { GetStartedBanner } from './get-started-banner'
import { HowItWorksSection } from './how-it-works'
import { UnderwritingBanner } from './underwriting-banner'

interface CapitalEmptyStateProps {
  capitalInfo: CapitalInfo
}
export const CapitalEmptyState = ({ capitalInfo }: CapitalEmptyStateProps) => {
  const { userBusinessIsActive } = useInternalUserStatus()

  const { data } = useCapitalApprovalQuery()
  const isCapitalPricingVersionV2 = data?.capitalApproval?.pricingVersion === CapitalApprovalVersion.V2

  const preApprovedCapital = capitalInfo?.capitalCreditLimit ?? '0'
  const trackServerEvent = useServerTrackEvent()
  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt

  const getStarted = () =>
    trackServerEvent({
      type: SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED,
      payload: {
        team: 'Capital',
        page: 'Capital Landing Screen',
        request_type: 'new',
        version: isCapitalPricingVersionV2 ? 'v2' : 'v1',
        capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
      },
    })

  useEffectOnce(() => {
    trackServerEvent({
      type: SEGMENT_PAGE_VISITED.CAPITAL_HOME_PAGE_VISITED,
      payload: {
        team: 'Capital',
        page: 'Capital home page',
        total_credit_limit: preApprovedCapital,
        version: isCapitalPricingVersionV2 ? 'v2' : 'v1',
      },
    })
  })

  return (
    <>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={8}
        mt={4}
        display={{ base: 'none', md: 'flex' }}
      >
        <Text textStyle="headline-3">Capital</Text>
      </Flex>

      <DelinquencyBanner />

      {userBusinessIsActive ? (
        <>
          <UnderwritingBanner />
          <Flex flexDir="column" gap={3} align="flex-start" mt={6}>
            <Text textStyle="title-md" color="text-secondary">
              Total Credit Limit
            </Text>
            <Flex gap="3" alignItems="center">
              <Text textStyle="headline-3">{preApprovedCapital}</Text>
              <CreditLimitStatusTag />
            </Flex>
          </Flex>
        </>
      ) : (
        <GetStartedBanner
          preApprovedCapital={preApprovedCapital}
          getStartedLink={isCapitalPricingVersionV2 ? '/capital/v2/get-capital' : '/capital/apply'}
          onGetStarted={getStarted}
        />
      )}

      <HowItWorksSection />
    </>
  )
}
