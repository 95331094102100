import { useCallback, useRef, useState } from 'react'
import { TransformComponent, TransformWrapper, useControls } from 'react-zoom-pan-pinch'
import { Box, chakra, Flex, Icon, IconButton, Image, Skeleton, Text } from 'ui-lib'
import { isDefined } from 'utils'

import { AccountingExpenseReceipt } from '@/gql'

interface ReceiptViewerProps {
  isLoadingSignedUrl: boolean
  receipt: AccountingExpenseReceipt
  signedUrl: string
  isDeleting?: boolean
  maxHeight?: string
  allowDelete?: boolean
  onDownloadReceipt: () => void
  onDeleteReceipt: () => void
}

const MAX_HEIGHT = '600px'

export const ReceiptViewer = ({
  isLoadingSignedUrl,
  signedUrl,
  receipt,
  isDeleting = false,
  maxHeight = MAX_HEIGHT,
  allowDelete = true,
  onDownloadReceipt,
  onDeleteReceipt,
}: ReceiptViewerProps) => {
  const viewerRef = useRef<HTMLDivElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [rotation, setRotation] = useState(0)
  const [isImageLoading, setIsImageLoading] = useState(true)

  const rotateClockwise = useCallback(() => {
    setRotation((prevRotation) => (prevRotation + 90) % 360)
  }, [setRotation])

  const imageStyle = useCallback(() => {
    if (isImageLoading) {
      return { height: '0px', width: '100%' }
    }

    const transformComponentWidth = imageContainerRef?.current?.offsetWidth
    const viewerWidth = viewerRef?.current?.offsetWidth
    if (rotation % 180 !== 0) {
      return {
        transform: `rotate(${rotation}deg)`,
        width: '100%',
        height:
          isDefined(transformComponentWidth) && isDefined(viewerWidth)
            ? Math.max(transformComponentWidth, viewerWidth)
            : '100%',
        maxHeight: '100%',
      }
    }
    return {
      transform: `rotate(${rotation}deg)`,
      width: '100%',
      height: 'auto',
      maxHeight,
    }
  }, [rotation, maxHeight, isImageLoading])

  const getReceiptBody = useCallback(() => {
    const isLoading = isLoadingSignedUrl || isImageLoading

    if (receipt?.document?.mimeType === 'application/pdf') {
      return (
        <>
          <chakra.object
            data={`${signedUrl}#toolbar=1`}
            type="application/pdf"
            aria-label="PDF Document"
            objectFit="cover"
            width="full"
            height={isLoading ? '0' : 'auto'}
            minHeight={isLoading ? '0' : '450px'}
            style={{ transform: `rotate(${rotation}deg)` }}
            onLoad={() => setIsImageLoading(false)}
          >
            <Text>Your browser does not support PDFs.</Text>
          </chakra.object>
          {isLoading && <Skeleton w="full" height="450px" />}
        </>
      )
    }

    return (
      <Box ref={imageContainerRef} w="full">
        <Image
          src={signedUrl}
          objectFit="contain"
          alt="Receipt"
          style={imageStyle()}
          onLoad={() => setIsImageLoading(false)}
        />
        {isLoading && <Skeleton w="full" height="450px" borderRadius="none" />}
      </Box>
    )
  }, [isLoadingSignedUrl, isImageLoading, receipt?.document?.mimeType, rotation, signedUrl, imageStyle])

  const Controls = ({ mimeType }: { mimeType?: string }) => {
    const { zoomIn, zoomOut } = useControls()
    const isPdf = mimeType === 'application/pdf'
    return (
      <Flex gap="2">
        {!isPdf && (
          <>
            <IconButton
              variant="ghost"
              size="xs"
              icon={<Icon icon="zoom-in" variant="secondary" width="18px" height="18px" />}
              onClick={() => zoomIn()}
              aria-label="Zoom in"
            />
            <IconButton
              variant="ghost"
              size="xs"
              icon={<Icon icon="zoom-out" variant="secondary" width="18px" height="18px" />}
              onClick={() => zoomOut()}
              aria-label="Zoom out"
            />
            <IconButton
              variant="ghost"
              size="xs"
              icon={<Icon icon="arrow-clockwise" variant="secondary" width="18px" height="18px" />}
              onClick={rotateClockwise}
              aria-label="Rotate receipt"
            />
            <IconButton
              variant="ghost"
              size="xs"
              icon={<Icon icon="download" variant="secondary" width="18px" height="18px" />}
              onClick={onDownloadReceipt}
              aria-label="Download receipt"
            />
          </>
        )}
        {allowDelete && (
          <IconButton
            variant="ghost"
            size="xs"
            icon={<Icon icon="trash" variant="secondary" width="18px" height="18px" />}
            onClick={onDeleteReceipt}
            aria-label="Delete receipt"
            isLoading={isDeleting}
          />
        )}
      </Flex>
    )
  }

  return (
    <Box ref={viewerRef} boxShadow="md" overflow="hidden" borderBottomRadius="lg">
      <TransformWrapper>
        <Flex
          px={3}
          py={2.5}
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="border-soft"
          bgColor="bg-disabled"
          borderTopRadius="lg"
        >
          <Text
            textStyle="title-sm"
            textColor="text-secondary"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {receipt?.document?.displayName}
          </Text>
          <Controls mimeType={receipt?.document?.mimeType} />
        </Flex>
        <TransformComponent
          wrapperStyle={{ width: '100%' }}
          contentStyle={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight,
          }}
        >
          {getReceiptBody()}
        </TransformComponent>
      </TransformWrapper>
    </Box>
  )
}
