import { useState } from 'react'
import { Text, useToast } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { useBusinessQuery, useCancelFundingPaymentMutation } from '@/gql'
import { EarlyPaymentTemplate } from '@/src/components/templates/capital'
import { SEGMENT_EVENTS, TEAM } from '@/src/constants/segmentEvents'
import { PAYMENT_STORAGE_KEY } from '@/src/contexts/capital'
import { useConfirmAction } from '@/src/contexts/misc'
import { useServerTrackEvent } from '@/src/hooks/misc'
import { date } from '@/src/utils/date'
import { BrowserStorage } from '@/src/utils/misc'
import { FundingStatus, GenericFunding } from '@/types/capital'

import { StatusBar } from '.'

interface FundingCardActionProps {
  funding: GenericFunding
  open: boolean
}

const sessionStorage = new BrowserStorage('session')

const isOpenModal = (fundingId: string) => {
  const earlyPayment = sessionStorage.get(PAYMENT_STORAGE_KEY)
  if (isDefined(earlyPayment)) {
    const state = JSON.parse(earlyPayment)

    return state.funding.fundingId === fundingId && state.currentStep !== 'complete'
  }
  return false
}

const confirmCancelationOptions = {
  title: 'Cancel payment',
  description: 'Are you sure you want to cancel this payment?',
  primaryAction: {
    label: 'Yes',
  },
  secondaryAction: {
    label: 'No',
  },
}

const CAPITAL_TOOLTIP = 'Pay in full is not available as a payment is currently being processed for this funding.'
const STATEMENT_TOOLTIP = 'Pay in full is not available as a payment is currently being processed for this extension.'

const getTooltipByChannel = (financingChannel: string) => {
  if (financingChannel === 'CAPITAL') {
    return CAPITAL_TOOLTIP
  }
  if (financingChannel === 'STATEMENT_EXTENSION') {
    return STATEMENT_TOOLTIP
  }
  return undefined
}

export const FundingCardAction = ({ funding, open }: FundingCardActionProps) => {
  const confirm = useConfirmAction()
  const trackEvent = useServerTrackEvent()
  const [cancelPayment, { loading }] = useCancelFundingPaymentMutation({
    variables: { body: { fundingPaymentId: funding.lastFundingPayment?.id ?? '' } },
    onError: () =>
      toast({
        title: 'Payment canceled',
        description: 'Your payment has been canceled successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      }),
  })
  const toast = useToast()
  const { data: delinquentData } = useBusinessQuery()
  const [openPaymentModal, setOpenPaymentModal] = useState(isOpenModal(funding.fundingId))

  const isDelinquent = delinquentData?.business?.isDelinquent ?? false
  const isLastFundingPaymentInProcess =
    funding.lastFundingPayment?.type === 'PAYMENT' && funding.lastFundingPayment?.status === 'CREATED'
  const disablePayment: boolean = isDelinquent || isLastFundingPaymentInProcess
  const { lastFundingPayment, status, nextPaymentDate } = funding

  if (status !== FundingStatus.ActiveFundingStatus.DEPOSITED) {
    return null
  }

  const nextPaymentOn = isDefined(nextPaymentDate) ? date(nextPaymentDate).utc().format('MMM DD, YYYY') : null
  const lastPaymentStatus = lastFundingPayment?.status
  const lastPaymentType = lastFundingPayment?.type
  const hasPaymentStatus = isDefined(lastFundingPayment) && isDefined(lastPaymentStatus)
  const isWithinTwoDays =
    isDefined(lastFundingPayment?.date) && date().isBefore(date(lastFundingPayment?.date).add(2, 'day'))
  const paymentTemplateProps = {
    isOpen: openPaymentModal,
    onClose: () => setOpenPaymentModal(false),
    funding,
  }

  const startPayment = () => {
    const eventType =
      lastPaymentStatus === 'FAILED'
        ? SEGMENT_EVENTS.RETRY_EARLY_PAYMENT_INITIATED
        : SEGMENT_EVENTS.EARLY_PAYMENT_INITIATED

    trackEvent({
      type: eventType,
      payload: { team: TEAM.CAPITAL, financingChannel: funding.financingChannel, plan_number: funding.loanNumber },
    })

    setOpenPaymentModal(true)
    sessionStorage.remove(PAYMENT_STORAGE_KEY)
  }

  switch (true) {
    case hasPaymentStatus && lastPaymentStatus === 'FAILED' && lastPaymentType === 'EARLY_PAYMENT' && isWithinTwoDays:
      const failedAmount = Money.fromNumber(lastFundingPayment?.amount ?? 0).toFormattedCurrencyString()
      return (
        <EarlyPaymentTemplate {...paymentTemplateProps}>
          <StatusBar open={open} variant="error">
            <StatusBar.Content>
              Your recent payment of {failedAmount} Failed. You can retry the payment using retry button.
            </StatusBar.Content>
            <StatusBar.Button
              onClick={startPayment}
              isDisabled={disablePayment}
              tooltip={
                disablePayment
                  ? 'Early payment isn’t currently available for you, please write to payments@trykeep.com for assistance.'
                  : undefined
              }
            >
              Retry
            </StatusBar.Button>
          </StatusBar>
        </EarlyPaymentTemplate>
      )

    case hasPaymentStatus && lastPaymentStatus === 'CREATED' && lastPaymentType === 'EARLY_PAYMENT':
      const inProgressAmount = Money.fromNumber(lastFundingPayment?.amount ?? 0).toFormattedCurrencyString()
      const handleCancelPayment = async () => {
        const result = await confirm(confirmCancelationOptions)
        if (result) {
          await cancelPayment({
            refetchQueries: ['GetCapitalInfo', 'CapitalFundingsByBusinessIdPaginated', 'GetInstallments'],
            awaitRefetchQueries: true,
          })
        }
      }

      return (
        <StatusBar open={open} variant={loading ? 'disabled' : 'info'}>
          <StatusBar.Content>
            {loading
              ? 'Canceling payment...'
              : `Your payment of ${inProgressAmount} is processing. It will take 2-3 business days to settle.`}
          </StatusBar.Content>
          {!loading && (
            <StatusBar.Button
              alignSelf="end"
              onClick={handleCancelPayment}
              isDisabled={disablePayment}
              tooltip={
                isDelinquent
                  ? 'Early payment isn’t currently available for you, please write to payments@trykeep.com for assistance.'
                  : undefined
              }
            >
              Cancel payment
            </StatusBar.Button>
          )}
        </StatusBar>
      )

    case isDefined(nextPaymentOn):
      return (
        <EarlyPaymentTemplate {...paymentTemplateProps}>
          <StatusBar open={open} variant="disabled">
            <StatusBar.Content>
              <Text textStyle="paragraph-md" textColor="text-soft">
                Next payment on {nextPaymentOn}
              </Text>
            </StatusBar.Content>
            <StatusBar.Button
              onClick={startPayment}
              variant="primary"
              isDisabled={disablePayment}
              tooltip={
                isDelinquent
                  ? 'Early payment isn’t currently available for you, please write to payments@trykeep.com for assistance.'
                  : isLastFundingPaymentInProcess
                  ? getTooltipByChannel(funding.financingChannel)
                  : undefined
              }
            >
              Pay in full
            </StatusBar.Button>
          </StatusBar>
        </EarlyPaymentTemplate>
      )

    default:
      return null
  }
}
