import { CurrencyCode } from 'db-schema'
import { useCallback, useMemo } from 'react'
import { ALLOWED_NON_RESTRICTED_CURRENCIES, isAllowedCurrency } from 'services/src/currency/currencies'
import { KeepFeatureFlags } from 'services/src/featureFlag/featureFlags'
import { Currencies } from 'services/utils/countries'

import { AccountCurrencyOption } from '@/src/components/molecules/SelectAccountCurrency'
import { useAppDispatch, useAppSelector } from '@/src/redux/hooks'
import { AccountType, setSelectedCurrency } from '@/src/redux/slices/accounts.slice'
import isDefined from '@/src/utils/dataShaping/isDefined'

import { useUserHasAllFeatureFlags } from './useUserHasFeatureFlags'

const walletCurrencies = Currencies.filter(
  (country) => isDefined(country.currencyCode) && isAllowedCurrency(country.currencyCode)
)

export const useAccounts = () => {
  const dispatch = useAppDispatch()
  const { accounts, selectedCurrency, isAccountsLoading } = useAppSelector((state) => state.accounts)

  const isAccountsFlagEnabled = useUserHasAllFeatureFlags(KeepFeatureFlags.CURRENCY_CLOUD_ACCOUNT) ?? false

  const selectedAccount = accounts.find(({ currency }) => currency === selectedCurrency)

  const fundingAccounts = selectedAccount?.fundingAccounts ?? []

  const setSelectedAccount = useCallback(
    (currencyCode: CurrencyCode) => {
      dispatch(setSelectedCurrency(currencyCode))
    },
    [dispatch]
  )

  const existingCurrencies: CurrencyCode[] = accounts.map(({ currency }) => currency)

  const availableCurrencies =
    accounts.length > 0
      ? walletCurrencies.filter(
          ({ currencyCode }) => isDefined(currencyCode) && !existingCurrencies.includes(currencyCode)
        )
      : walletCurrencies

  const hasAllCurrencies = ALLOWED_NON_RESTRICTED_CURRENCIES.every((v) => existingCurrencies.includes(v))

  const accountOptions: {
    title: string
    options: AccountCurrencyOption[]
  }[] = useMemo(() => {
    const existingAccountOptions = {
      title: 'Your accounts',
      options: accounts.map((account) => {
        return {
          currencyCode: account.currency,
          accountBalance: account.balance.availableBalance.amountAsNumber,
        }
      }),
    }

    const availableAccountOptions = {
      title: 'Create an account',
      options: availableCurrencies.flatMap(({ currencyCode }) => (isDefined(currencyCode) ? { currencyCode } : [])),
    }

    const options: {
      title: string
      options: AccountCurrencyOption[]
    }[] = []

    if (existingAccountOptions.options.length > 0) {
      options.push(existingAccountOptions)
    }

    if (availableAccountOptions.options.length > 0) {
      options.push(availableAccountOptions)
    }

    return options
  }, [accounts, availableCurrencies])

  const getAccountByCurrency = (currency: CurrencyCode): AccountType | undefined =>
    accounts.find((account) => account.currency === currency)

  return {
    accountsLength: accounts.length,
    hasAccounts: !(accounts.length === 0),
    selectedAccount,
    accounts,
    accountOptions,
    fundingAccounts,
    availableCurrencies,
    existingCurrencies,
    hasAllCurrencies,
    isAccountsLoading,
    isAccountsFlagEnabled,
    setSelectedAccount,
    getAccountByCurrency,
  }
}
