import { Tbody } from '@chakra-ui/react'
import { Virtualizer } from '@tanstack/react-virtual'
import React from 'react'
import { isDefined } from 'utils'

import { DataGridTr } from './data-grid-tr'
import type { Styles } from './styles-context'
import type { DataGridProps } from './types'

/**
 * Default table body component
 */
export const TableBody = <T,>({
  table,
  styles,
  enableRowClick = false,
  virtualizer,
}: DataGridProps<T> & {
  styles: Styles
  enableRowClick?: boolean
  virtualizer?: Virtualizer<HTMLDivElement, Element> | null
}) => {
  const rows = table.getRowModel().rows

  if (isDefined(virtualizer)) {
    const virtualRows = virtualizer.getVirtualItems()
    const totalSize = virtualizer.getTotalSize()
    const paddingTop = virtualRows.length > 0 ? virtualRows[0]?.start ?? 0 : 0
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows[virtualRows.length - 1]?.end ?? 0) : 0

    return (
      <Tbody>
        {paddingTop > 0 && (
          <tr>
            <td style={{ height: `${paddingTop}px` }} />
          </tr>
        )}
        {virtualRows.map((virtualRow) => {
          const row = rows[virtualRow.index]
          return <DataGridTr<T> row={row} styles={styles} key={row.id} enableRowClick={enableRowClick} />
        })}
        {paddingBottom > 0 && (
          <tr>
            <td style={{ height: `${paddingBottom}px` }} />
          </tr>
        )}
      </Tbody>
    )
  }

  return (
    <Tbody>
      {rows.map((row) => (
        <DataGridTr<T> row={row} styles={styles} key={row.id} enableRowClick={enableRowClick} />
      ))}
    </Tbody>
  )
}

/**
 * Memoized table body component
 */
export const MemoizedTableBody = React.memo(TableBody, (prev, next) => {
  const hasDataChanged = prev.table.options.data !== next.table.options.data
  const hasGlobalFilterChanged = prev.table.options.state.globalFilter !== next.table.options.state.globalFilter
  const hasVirtualizerChanged = prev.virtualizer !== next.virtualizer

  // Return true if nothing has changed (to prevent re-render)
  return !hasDataChanged && !hasGlobalFilterChanged && !hasVirtualizerChanged
}) as typeof TableBody
