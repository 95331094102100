import { Avatar, Box, Flex, Icon, Text, Tooltip } from 'ui-lib'
import { isDefined } from 'utils'

import { ColumnComponentProps } from './types'

export const ExpenseMerchantName = ({ row }: ColumnComponentProps) => {
  return (
    <Flex alignItems="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
      {isDefined(row.original.error) && (
        <Box mr={4}>
          <Tooltip label={row.original.error} placement="top">
            <Icon icon="triangle-alert-fill" variant="error" ml="2" h={4} w={4} />
          </Tooltip>
        </Box>
      )}
      <Avatar textColor="text-primary" name={row.original.merchantName} background="gradients.avatar" size="sm" />
      <Text textStyle="paragraph-md" color="text-primary" ml="3" flexGrow={1}>
        {row.original.merchantName}
      </Text>
    </Flex>
  )
}
