import { Box, Icon, Tag, Text } from 'ui-lib'

import { FilterTagsProps } from './utils'

interface ExpenseFilterTagProps extends FilterTagsProps {
  label?: string | null
  onDelete: () => void
}

export const ExpenseFilterTag = ({ form, label, onDelete, onFilter }: ExpenseFilterTagProps) => {
  const deleteTag = () => {
    onDelete()
    const newValues = form.getValues()
    onFilter(newValues)
  }

  return (
    <Tag variant="info">
      <Text textColor="inherit">{label}</Text>
      <Box ml="1.5" cursor="pointer" onClick={deleteTag}>
        <Icon icon="cross" size="sm" variant="inherit" />
      </Box>
    </Tag>
  )
}
