import { Button, Card, Heading, Text, VStack } from 'ui-lib'
import { isDefined } from 'utils'

interface ErrorMessageProps {
  error?: Error
  resetError: () => void
}

export const ErrorMessage = ({ error, resetError }: ErrorMessageProps) => {
  return (
    <Card p={8} maxW="md" mx="auto" mt={8} boxShadow="lg">
      <VStack spacing={6} align="center">
        <Heading size="lg" textAlign="center" color="text-primary">
          Oops! Something went wrong
        </Heading>
        <Text textAlign="center" color="text-secondary">
          {isDefined(error) && isDefined(error?.message)
            ? error.message
            : "We're having trouble loading this page. Please try again."}
        </Text>
        <Button
          onClick={resetError}
          colorScheme="blue"
          size="lg"
          width="full"
          _hover={{ transform: 'translateY(-1px)' }}
          transition="all 0.2s"
        >
          Try Again
        </Button>
      </VStack>
    </Card>
  )
}
