import { Table, Tfoot, Thead, Tr, useMultiStyleConfig } from '@chakra-ui/react'
import { useVirtualizer } from '@tanstack/react-virtual'
import React, { useMemo, useRef } from 'react'
import { isDefined } from 'utils'

import { MemoizedTableBody, TableBody } from './data-grid-table-body'
import { DataGridTh } from './data-grid-th'
import { StylesProvider } from './styles-context'
import { DataGridProps } from './types'

/**
 * The DataGrid component is a table component that displays data in a grid format. It uses the `@tanstack/react-table` under the hood.
 * Wraps the `Table` component from Chakra UI and provides a way to manage the state of the data grid component.
 * @param props {DataGridProps<T>} The props for the data grid component.
 * @returns
 */
export const DataGrid = <T,>({
  table,
  variant = 'new',
  enableColumnResizing = false,
  useMemoizedBody = false,
  enableVirtualization = false,
  ...props
}: DataGridProps<T>) => {
  const styles = useMultiStyleConfig('Table', { variant })
  const initialSizes = useRef<Record<string, number>>()
  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = enableVirtualization
    ? useVirtualizer({
        count: table.getRowModel().rows.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => props.rowHeight ?? 40,
        overscan: 10,
      })
    : null

  /**
   * Memoize column size variables at the table level to avoid recalculating them on every render.
   * Using css variables to set column widths allows for dynamic resizing of columns without re-rendering the entire table.
   */
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders()
    const colSizes: { [key: string]: number } = {}

    /**
     * Store the initial sizes of the headers and columns to compare against when resizing.
     */
    if (initialSizes.current == null) {
      initialSizes.current = headers.reduce((acc: Record<string, number>, header) => {
        acc[header.id] = header.getSize()
        acc[header.column.id] = header.column.getSize()
        return acc
      }, {})
    }

    /**
     * Set the css variables for the header and column sizes based on the current size of the header or column.
     */
    headers.forEach((header) => {
      const initialHeaderSize = initialSizes.current![header.id]
      const initialColumnSize = initialSizes.current![header.column.id]

      colSizes[`--header-${header.id}-size`] =
        header.getSize() > initialHeaderSize ? header.getSize() : initialHeaderSize
      colSizes[`--col-${header.column.id}-size`] =
        header.column.getSize() > initialColumnSize ? header.column.getSize() : initialColumnSize
    })
    return colSizes
  }, [table.getFlatHeaders(), table.getState().columnSizingInfo, table.getState().columnSizing])

  const BodyComponent = useMemoizedBody ? MemoizedTableBody : TableBody
  const hasFooter =
    table
      .getFooterGroups()
      .map((group) => group.headers.map((header) => header.column.columnDef.footer))
      .flat()
      .filter(Boolean).length > 0

  const tableContent = (
    <Table
      variant={variant}
      style={{
        ...columnSizeVars,
        tableLayout: enableColumnResizing ? 'fixed' : 'auto',
      }}
    >
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <DataGridTh<T> header={header} styles={styles} position="header" key={header.id} />
            ))}
          </Tr>
        ))}
      </Thead>
      {isDefined(table) && (
        <BodyComponent
          styles={styles}
          table={table}
          enableRowClick={variant === 'interactive'}
          virtualizer={rowVirtualizer}
        />
      )}
      {hasFooter && (
        <Tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <DataGridTh<T> header={header} styles={styles} position="footer" key={header.id} />
              ))}
            </Tr>
          ))}
        </Tfoot>
      )}
    </Table>
  )

  return (
    <StylesProvider value={styles}>
      {enableVirtualization ? (
        <div
          ref={parentRef}
          style={{
            height: props.containerHeight,
            overflow: 'auto',
          }}
        >
          {tableContent}
        </div>
      ) : (
        tableContent
      )}
    </StylesProvider>
  )
}
