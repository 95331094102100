import { Money } from 'utils'
import { ConstToUnion } from 'utils/types'

import { CapitalFundingsByBusinessIdPaginatedQuery, PaginatedCapitalFunding, StatementExtensionsQuery } from '@/gql'

import {
  ActiveFundingStatus,
  ClosedFundingStatus,
  DeletedFundingStatus,
  FinancingChannel,
  FundingDiscountType,
  InstallmentStatus,
  RepaymentFrequency,
  SubmittedFundingStatus,
} from './enums'

export type ValuesOf<T> = T[keyof T]

export interface Approval {
  id?: string
  statementExtensionEnabled: boolean
  apr: number
}

export type InstallmentStatus = ConstToUnion<typeof InstallmentStatus>

export type FinancingChannel = ConstToUnion<typeof FinancingChannel>

export type RepaymentFrequency = ConstToUnion<typeof RepaymentFrequency>

export type FundingDiscountType = ValuesOf<typeof FundingDiscountType>

export const FundingStatus = {
  SubmittedFundingStatus,
  ActiveFundingStatus,
  ClosedFundingStatus,
  DeletedFundingStatus,
}

export type FundingStatus =
  | ValuesOf<typeof SubmittedFundingStatus>
  | ValuesOf<typeof ActiveFundingStatus>
  | ValuesOf<typeof ClosedFundingStatus>
  | ValuesOf<typeof DeletedFundingStatus>

export interface PlainDiscount {
  id: string
  percent: number
  amount: string
  type: string
}

export interface PlainInstallment {
  loanId: string
  status: string
  installmentNumber: number
  amountDue?: string
  businessId?: string
  principalAmountDue: string
  interestAmountDue?: string
  dateDue: Date | string
}

export interface StatementExtensionPaymentInfo {
  fundingNumber: string
  paymentDate: Date
  outstandingBalance: Money
  needsPayment: boolean
}

export interface SerializedStatementExtensionPaymentInfo {
  fundingNumber: string
  paymentDate: string
  outstandingBalance: string
  needsPayment: boolean
}

export interface PlainFunding {
  id: string
  loanNumber: string
  status: string
  outstandingBalance: string
  businessId: string
  repaymentFrequency: string
  principalAmount: string
  totalFee: string
  termDurationInMonths: number
  apr: number
  totalRepayment: string
  agreementSignedDate?: string
  firstRepaymentDate?: string
  financingChannel?: string
  discounts?: PlainDiscount[]
  feeDiscountRate?: number
  preDiscountTotalFee: string
  nextPaymentDate?: string | undefined
  lastFundingPayment?: PaginatedCapitalFunding['lastFundingPayment']
}

export type PlainStatementExtensions = Exclude<StatementExtensionsQuery['statementExtensions'], null | undefined>

export type PartialCapitalFunding = NonNullable<
  CapitalFundingsByBusinessIdPaginatedQuery['capitalFundingsByBusinessIdPaginated']
>['data'][0]

export interface GenericFunding {
  fundingId: string
  loanNumber: string
  status: FundingStatus
  totalRepayment: number
  principalAmount: number
  nextPaymentDate: string | undefined | null
  lastFundingPayment:
    | {
        id: string
        amount: number
        status: string | undefined | null
        date: string | undefined | null
        type: string | undefined | null
      }
    | undefined
    | null
  totalFee: null | undefined | number
  financingChannel: FinancingChannel
}

export * from './enums'
