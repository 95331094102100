import { isDefined } from 'utils'
import moment from 'utils/moment'

import { FilterValues } from '../utils'
import { ExpenseFilterTag } from './expense-filter-tag'
import { FilterTagsProps } from './utils'

export const useDateTags = ({ form, onFilter }: FilterTagsProps) => {
  const getDateTag = (params: { dateRange?: FilterValues['dateRange'] }) => {
    const startDate = params.dateRange?.startDate
    const endDate = params.dateRange?.endDate
    if (!isDefined(startDate) || !isDefined(endDate)) {
      return null
    }
    const label = moment(startDate).format('MMM D') + ' - ' + moment(endDate).format('MMM D')
    return (
      <ExpenseFilterTag
        form={form}
        label={label}
        onDelete={() => form.setValue('dateRange', undefined)}
        onFilter={onFilter}
      />
    )
  }

  return { getDateTag }
}
