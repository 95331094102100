import React from 'react'
import { CurrencyCode, getCountryCodeByCurrencyCode } from 'services/utils/countries'
import { ActionButtons, Flag, Flex, Icon, Image, Text, useToast } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { useCalculateWholeAmountToPayForEarlyPaymentQuery } from '@/gql'
import { CCAccount, PlaidAccount } from '@/src/components/molecules/SelectPaymentMethod'
import { SEGMENT_EVENTS, TEAM } from '@/src/constants/segmentEvents'
import { useEarlyPayment } from '@/src/contexts/capital'
import { useServerTrackEvent } from '@/src/hooks/misc'

const accountType = {
  CCAccount: 'Keep Account',
  PlaidAccount: 'Linked Account',
} as const

const icon = (currency: CurrencyCode, bankLogo: string) => ({
  CCAccount: (
    <Flag
      code={getCountryCodeByCurrencyCode(currency)}
      size="m"
      sx={{ borderRadius: '99px !important', width: '20px', height: '20px' }}
    />
  ),
  PlaidAccount: <Image src={bankLogo} alt="bank logo" h="5" w="5" borderRadius="full" />,
})

export const ReviewStep = () => {
  const trackEvent = useServerTrackEvent()
  const toast = useToast()
  const { goToNextStep, goToPreviousStep, funding, account, isLoading, paymentAmount } = useEarlyPayment()
  const { totalFee: fee, fundingId } = funding
  const { data } = useCalculateWholeAmountToPayForEarlyPaymentQuery({
    variables: {
      fundingId,
    },
    onError: () => {
      toast({
        title: 'Calculation Error',
        description: 'An error occurred while calculating the amount to pay',
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const { proRatedFeeAmount } = data?.calculateWholeAmountToPayForEarlyPayment ?? {}
  const feeDiscount = isDefined(fee) ? fee - (proRatedFeeAmount?.amountAsNumber ?? 0) : 0

  const onConfirm = () => {
    trackEvent({
      type: SEGMENT_EVENTS.EARLY_PAYMENT_CONFIRMED,
      payload: { team: TEAM.CAPITAL, financingChannel: funding.financingChannel, plan_number: funding.loanNumber },
    })
    goToNextStep?.()
  }

  return (
    <>
      <Flex gap="2" mb="6" flexDir="column">
        <Text textStyle="subheading-md" color="text-secondary" textTransform="uppercase">
          Amount you’ll pay
        </Text>
        <Text textStyle="headline-3">{Money.fromNumber(paymentAmount ?? 0).toFormattedCurrencyString()}</Text>
      </Flex>
      <Flex gap="2" flexDir="column" mb="4">
        <Flex borderRadius="sm" bgColor="bg-disabled" p="4" justifyContent="space-between">
          <Flex gap="1">
            <Flex borderRadius="full" p="1" bg="text-disabled" h="fit-content">
              <Icon icon="percentage" variant="inverse" size="xs" />
            </Flex>
            <Flex flexDir="column">
              <Text textStyle="title-xs">Remaining Outstanding Principal</Text>
              <Text color="text-secondary" textStyle="paragraph-sm">
                {`You’re saving ${Money.fromNumber(feeDiscount).toFormattedCurrencyString()} in fee`}
              </Text>
            </Flex>
          </Flex>
          <Text textStyle="title-xs">$0.00</Text>
        </Flex>
      </Flex>
      <Flex gap="2" flexDir="column">
        <Text textStyle="subheading-md" color="text-secondary" textTransform="uppercase">
          Debited from account
        </Text>
        <Flex borderRadius="sm" bgColor="bg-disabled" p="4" gap="1" flexDir="column">
          <Flex justifyContent="space-between">
            <Text textStyle="paragraph-md" color="text-secondary">
              Account Name
            </Text>
            <Flex gap={2}>
              {
                icon((account as CCAccount).currencyCode, (account as PlaidAccount).bankLogo)[
                  account?.type as keyof typeof accountType
                ]
              }
              <Text textStyle="paragraph-md">{account?.['accountName' as keyof typeof account]}</Text>{' '}
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Text textStyle="paragraph-md" color="text-secondary">
              Account Type
            </Text>
            <Text textStyle="paragraph-md">{accountType[account?.type as keyof typeof accountType]}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text textStyle="paragraph-md" color="text-secondary">
              Account Number
            </Text>
            <Text textStyle="paragraph-md">***{account?.accountNumber}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text textStyle="paragraph-md" color="text-secondary">
              Speed
            </Text>
            <Text textStyle="paragraph-md">
              {account?.type === 'PlaidAccount' ? '2 or 3 business days' : 'Immediately'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <ActionButtons mt="6">
        <ActionButtons.Secondary onClick={() => goToPreviousStep()} isDisabled={isLoading}>
          Back
        </ActionButtons.Secondary>
        <ActionButtons.Primary onClick={onConfirm} isLoading={isLoading}>
          Confirm
        </ActionButtons.Primary>
      </ActionButtons>
    </>
  )
}
