import { Td } from '@chakra-ui/react'
import { Cell, flexRender } from '@tanstack/react-table'
import React from 'react'

import { getPinnedColumnStyle } from './helpers'
import { Styles } from './styles-context'

export const DataGridTd = <T,>({ cell, styles }: { cell: Cell<T, unknown>; styles: Styles }) => {
  const selectedStyle = cell.row.getIsSelected() ? { ...styles.selected } : {}
  const tdStyle = {
    width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
  }

  return (
    <Td
      key={cell.id}
      sx={{
        ...getPinnedColumnStyle(cell.column, styles),
        ...selectedStyle,
      }}
      style={tdStyle}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())} {cell.row.getIsSelected()}
    </Td>
  )
}
