import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { CurrencyCode, getCountryCodeByCurrencyCode, getCurrencyNameByCurrencyCode } from 'services/utils/countries'
import { Avatar, chakra, Flag, Flex, FlexProps, Grid, GridItem, Radio, Text } from 'ui-lib'
import { isDefined } from 'utils'
import { ConstToUnion } from 'utils/types'

import { AccountType } from '@/src/redux/slices/accounts.slice'
import KeepSquareIcon from '@/src/svgs/logos/KeepSquareIcon'
import { formatCurrency } from '@/src/utils/formatCurrency'

export const BANK_ACCOUNT_TYPE = {
  CCAccount: 'CCAccount',
  PlaidAccount: 'PlaidAccount',
} as const

export type PlaidAccount = {
  type: typeof BANK_ACCOUNT_TYPE.PlaidAccount
  id: string
  accountName: string
  accountNumber: string
  isPrimary: boolean
  bankLogo: string
}

export type CCAccount = {
  type: typeof BANK_ACCOUNT_TYPE.CCAccount
  id: string
  availableBalance: number
  currencyCode: CurrencyCode
  accountNumber: string
}

export type Account = PlaidAccount | CCAccount

export type CCAccountOption = CCAccount & {
  friendlyCurrencyName: string
  flagComponent: ReactNode
}

export type BankAccountType = ConstToUnion<typeof BANK_ACCOUNT_TYPE>

type SelectPaymentMethodProps = {
  plaidAccounts: PlaidAccount[]
  ccAccounts: AccountType[]
  selectedBankAccount: Account | undefined
  onRowSelected?: (value: Account | undefined) => void
}

const SelectPaymentMethod = ({
  plaidAccounts = [],
  ccAccounts = [],
  selectedBankAccount,
  onRowSelected,
}: SelectPaymentMethodProps) => {
  const paymentOptions = useMemo(() => {
    const accounts: (CCAccountOption | PlaidAccount)[] = []

    accounts.push(
      ...ccAccounts.map((account) => ({
        id: account.id,
        type: BANK_ACCOUNT_TYPE.CCAccount,
        currencyCode: account.currency,
        friendlyCurrencyName: getCurrencyNameByCurrencyCode(account.currency),
        availableBalance: account.balance.availableBalance.amountAsNumber,
        flagComponent: <Flag code={getCountryCodeByCurrencyCode(account.currency)} />,
        accountNumber: account.fundingAccounts.find((i) => i.paymentType === 'REGULAR')?.accountNumber ?? '',
      }))
    )
    accounts.push(...plaidAccounts)

    return accounts
  }, [ccAccounts, plaidAccounts])
  const [selectedRow, setSelectedRow] = useState<number>()
  const handleRadioButtonClicked = (dataIndex: number, data: Account) => {
    setSelectedRow(dataIndex)
    onRowSelected?.(data)
  }

  useEffect(() => {
    setSelectedRow(paymentOptions.findIndex((account) => account.id === selectedBankAccount?.id))
  }, [paymentOptions, selectedBankAccount?.id])

  return (
    <Grid templateColumns={`repeat(${1}, 1fr)`} gap={4} width="100%">
      {paymentOptions.map((item, dataIndex) => (
        <GridItem
          key={dataIndex}
          colSpan={1}
          cursor="pointer"
          onClick={() => handleRadioButtonClicked(dataIndex, item)}
        >
          <Grid
            templateColumns={`24px repeat(${1}, 1fr)`}
            gap={4}
            width="100%"
            px="24px"
            py="12px"
            borderRadius="12px"
            border={`${dataIndex === selectedRow ? '2px solid black' : '1px solid #F4F4F6'}`}
          >
            <GridItem colSpan={1} display="flex" alignItems={'center'}>
              <Radio colorScheme="primary" borderColor="_primary.900" isChecked={dataIndex === selectedRow}></Radio>
            </GridItem>
            {getAccountItemByType(item)}
          </Grid>
        </GridItem>
      ))}
    </Grid>
  )
}

export type AccountItemProps = {
  id: string
  accountName: string
  accountNumber?: string
  balance?: number
  balanceCurrency?: CurrencyCode
  isPrimary?: boolean
  logo?: string
  svgLogo?: typeof chakra.svg
}

const AccountItem = ({
  accountName,
  accountNumber,
  isPrimary,
  balance,
  balanceCurrency,
  logo,
  svgLogo,
  ...flexProps
}: AccountItemProps & FlexProps) => {
  return (
    <Flex width="100%" borderRadius="6px" align="center" cursor="pointer" position="relative" {...flexProps}>
      {isDefined(svgLogo) && React.createElement(svgLogo, { height: '32px', width: '32px' })}
      {!isDefined(svgLogo) && isDefined(logo) && <Avatar src={logo} height="28px" width="28px" mr="2" />}

      <Flex flexGrow={1} justifyContent="flex-start" flexDirection="column" pl="3">
        <Text textStyle="text.md.medium" color="_primary.900">
          {accountName}
        </Text>
        {isDefined(accountNumber) && (
          <Text textStyle="text.xs.regular" color="_primary.500">
            ** {accountNumber}
          </Text>
        )}
      </Flex>
      {(!isDefined(isPrimary) || isPrimary === false) && isDefined(balance) && isDefined(balanceCurrency) && (
        <Text textStyle="text.md.medium" color="primary.200" pr="4">
          {formatCurrency(balance, balanceCurrency)}
        </Text>
      )}
      {isPrimary === true && (
        <Text textStyle="text.md.semibold" color="primary.200" px="4">
          Primary
        </Text>
      )}
    </Flex>
  )
}

const getAccountItemByType = (account: Account) => {
  if (account.type === BANK_ACCOUNT_TYPE.CCAccount) {
    return (
      <AccountItem
        accountName={`${account.currencyCode} Account`}
        accountNumber={`${account.accountNumber.slice(-4)}`}
        svgLogo={KeepSquareIcon}
        balance={account.availableBalance}
        balanceCurrency={account.currencyCode}
        id={account.id}
      />
    )
  }
  if (account.type === BANK_ACCOUNT_TYPE.PlaidAccount) {
    return (
      <AccountItem
        accountName={account.accountName}
        accountNumber={account.accountNumber}
        logo={account.bankLogo}
        isPrimary={account.isPrimary}
        id={account.id}
      />
    )
  }
}

export default SelectPaymentMethod
