import { chakra, useMultiStyleConfig } from '@chakra-ui/react'
import React, { CSSProperties } from 'react'
import { CountryCode } from 'services/utils/countries'

export interface Props {
  code: CountryCode
  size?: 's' | 'm' | 'l' | 'full' // s = { w: 16px, h: 12px}, m = { w: 20px, h: 15px}, l = { w: 32px, h: 24px}, full = { w: 100%, h: 100%}
  gradient?: '' | 'top-down' | 'real-circular' | 'real-linear'
  hasBorder?: boolean
  hasDropShadow?: boolean
  hasBorderRadius?: boolean
  sx?: CSSProperties
}

const Flag: React.FC<Props> = ({
  code = 'CAN',
  size = 'l',
  gradient = 'top-down',
  hasBorder = true,
  hasDropShadow = false,
  hasBorderRadius = true,
  sx,
}: Props) => {
  const styles = useMultiStyleConfig('Flag', { size })
  const urlSize = size === 'full' ? 'l' : size

  return (
    <chakra.div
      sx={{ ...styles.container, ...sx }}
      data-gradient={gradient}
      data-has-border={hasBorder}
      data-has-drop-shadow={hasDropShadow}
      data-has-border-radius={hasBorderRadius}
    >
      <chakra.span sx={styles.span}>
        <chakra.img sx={styles.img} src={`https://app.trykeep.com/flags/${urlSize}/${code}.svg`} />
      </chakra.span>
    </chakra.div>
  )
}

export default Flag
