import { OnboardingStep, SpendLimitPeriod } from 'db-schema'
import { CanadianProvinces } from 'services/utils/states'
import { isDefined, isValidAddress, isValidUrl, Money, passwordMinChar, passwordRegex } from 'utils'
import { getYearsAgo, today } from 'utils/time'
import { AnyObjectSchema, array, boolean, InferType, mixed, number, object, ref, SchemaOf, string } from 'yup'

import { EMPLOYEE_INVITATION_STEP, EmployeeInvitationStep } from '@/src/constants/employeeInvitations'
import { OwnershipNatureType } from '@/src/constants/ownership'
import {
  AboutBusinessData,
  AdditionalInformationData,
  BusinessAddressData,
  DirectorData,
  DirectorPersonalInformationData,
  GenericAddressData,
  OwnerData,
  OwnerPersonalInformationData,
  PersonalAddressData,
  PersonalInformationData,
  RelationshipToTheBusinessData,
} from '@/types/form-info'

import { isValidPhoneForRegion } from './formatPhone'

export const NOT_JUST_EMPTY_SPACE_REGEX = /[^\s]/
export const PHONE_REGEX = /(\+?\d{1,2})\(?\d{3}\)?\d{3}[-]?\d{4}$/
export const CAD_PHONE_REGEX = /^\+1\d{10}$/
const BASE64_REGEX =
  /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/

export const phoneInfoValidator = object({
  value: string(),
  countryCode: string(),
})

export type PhoneInfo = InferType<typeof phoneInfoValidator>

export const isValidPhoneInfo = (field?: PhoneInfo) => isValidPhoneForRegion(field?.value, field?.countryCode)

export const phoneInfoTest = {
  message: 'Phone number is not valid',
  name: 'phone',
  test: isValidPhoneInfo,
}

export const phoneInfoCheck = phoneInfoValidator.test(phoneInfoTest).required('Phone number is required')

export const validateOnboardingStep = string()
  .required('onboarding step is required')
  .test('is-step', 'This is not an onboarding step', (step) =>
    step !== undefined ? Object.keys(OnboardingStep).findIndex((key) => step === key) !== -1 : false
  )

const basePersonalInformationValidator = {
  firstName: string().required('First Name is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'First Name is required'),
  lastName: string().required('Last Name is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Last Name is required'),
  dateOfBirth: string()
    .required('Date of Birth is required')
    .nullable()
    .test({
      name: 'dateOfBirth',
      exclusive: true,
      message: 'You must be at least 18 years old to apply.',
      test: (value) => value == null || new Date(value) <= getYearsAgo(18),
    }),
  phoneNumber: string().matches(PHONE_REGEX, 'Phone number is not valid').required('Phone number is required'),
  socialNetworks: array(),
}

export const personalInformationValidator: SchemaOf<PersonalInformationData> = object(basePersonalInformationValidator)

export const ownerPersonalInformationValidator: SchemaOf<OwnerPersonalInformationData> = object({
  ...basePersonalInformationValidator,
  emailAddress: string()
    .required('Email address is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Email address is required'),
})

export const directorPersonalInformationValidator: SchemaOf<DirectorPersonalInformationData> = object({
  firstName: string().required('First Name is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'First Name is required'),
  lastName: string().required('Last Name is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Last Name is required'),
  emailAddress: string()
    .required('Email address is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Email address is required'),
})

export const additionalInformationValidator: SchemaOf<AdditionalInformationData> = object({
  politicalPerson: string().required('This field is required'),
  sin: string(),
})

export const addressValidator: SchemaOf<GenericAddressData> = object({
  addressLine1: string()
    .required('Address is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Address is required')
    .test({
      name: 'validAddress',
      message: 'PO box addresses are not valid',
      test: (value) => isDefined(value) && isValidAddress(value),
    }),
  addressLine2: string().test({
    name: 'validAddress',
    message: 'PO box addresses are not valid',
    test: (value) => isDefined(value) && isValidAddress(value),
  }),
  city: string().required('City is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'City is required'),
  country: string().required('Country is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Country is required'),
  postalCode: string()
    .required('Postal code is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Postal code is required')
    .when('country', {
      is: 'CAN',
      then: string().matches(/^[a-zA-Z]\d[a-zA-Z] \d[a-zA-Z]\d$/, 'Invalid postal code (e.g. A2C 1B2)'),
    }),
  state: string()
    .required('State/Province is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'State is required')
    .when('country', {
      is: 'CAN',
      then: string().oneOf(
        CanadianProvinces.map((province) => province.code),
        'Invalid province'
      ),
    }),
})

export const personalAddressValidator: SchemaOf<PersonalAddressData> = addressValidator.concat(
  object({
    useAsBusinessAddress: boolean().required(),
  })
)

export const relationshipWithBusinessValidator: SchemaOf<RelationshipToTheBusinessData> = object({
  position: string().required('Position is required'),
  customPosition: string().when('position', {
    is: 'OTHER',
    then: string().required('Position is required'),
  }),
  authorizedToSign: string().required('This field is required'),
  authorizedSigner: mixed().when('authorizedToSign', {
    is: 'no',
    then: object({
      email: string().email('Must be a valid email').required('Email is required'),
      firstName: string().required('First name is required'),
      lastName: string().required('Last name is required'),
    }).required(),
  }),
})

export const aboutBusinessValidator: SchemaOf<AboutBusinessData> = object({
  businessCategory: string().required('Business Category is required'),
  businessDBA: string(),
  businessDescription: string()
    .required('A brief description is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'A brief description is required'),
  businessNameLegal: string()
    .required('Business legal name is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Business legal name is required'),
  businessPhone: string().matches(PHONE_REGEX, 'Phone number is not valid').required('A business phone is required'),
  businessType: string().required('Business type is required'),
  businessWebsite: string()
    .default(null)
    .when('businessHasWebsite', {
      is: 'yes',
      then: (schema) =>
        schema
          .required('Business website is required')
          .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Business website is required')
          .test({
            name: 'websiteUrl',
            exclusive: true,
            message: 'Invalid URL (e.g. https://example.com)',
            test: (value) => isValidUrl(value),
          }),
      otherwise: (schema) => schema.nullable(true),
    }),
  incorporationNumber: string()
    .required('Business number is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Business number is required'),
  dateOfIncorporation: string()
    .required('Date of Incorporation is required')
    .test({
      name: 'dateOfIncorporation',
      exclusive: true,
      message: 'Date of incorporation must be in the past',
      test: (value) => value == null || new Date(value) <= today(),
    }),
  minNumberOfEmployees: number().required(),
  maxNumberOfEmployees: number().nullable(),
  isMoneyBusiness: string().required('Money services business field is required'),
  businessHasWebsite: string().required(),
  supportingDocuments: array().when('businessHasWebsite', {
    is: 'no',
    then: (schema) =>
      schema
        .of(
          object({
            name: string().required(),
            type: string().required(),
            size: number().required(),
            path: string().required(),
            url: string().required(),
          })
        )
        .required('Supporting documents are required')
        .length(3, '3 documents are required'),
    otherwise: (schema) => schema.nullable(true),
  }),
})

export const businessAddressValidator: SchemaOf<BusinessAddressData> = object({
  businessAddressLine1: string()
    .required('Address is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Address is required')
    .test({
      name: 'validAddress',
      message: 'PO box addresses are not valid',
      test: (value) => isDefined(value) && isValidAddress(value),
    }),
  businessAddressLine2: string().test({
    name: 'validAddress',
    message: 'PO box addresses are not valid',
    test: (value) => isDefined(value) && isValidAddress(value),
  }),
  businessPostalCode: string()
    .required('Postal code is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Postal code is required')
    .when('businessCountry', {
      is: 'CAN',
      then: string().matches(/^[a-zA-Z]\d[a-zA-Z] \d[a-zA-Z]\d$/, 'Invalid postal code (e.g. A2C 1B2)'),
    }),
  businessProvince: string()
    .required('State/Province is required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'State is required')
    .when('businessCountry', {
      is: 'CAN',
      then: string().oneOf(
        CanadianProvinces.map((province) => province.code),
        'Invalid province'
      ),
    }),
  businessCity: string().required('City is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'City is required'),
  businessCountry: string().required('Country is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Country is required'),
  usePersonalAddressAsBusinessAddress: boolean().required(),
})

export const businessOwnersValidator: SchemaOf<OwnerData> = object({
  ownerId: string().required(),
  details: ownerPersonalInformationValidator,
  additionalInformation: additionalInformationValidator,
  homeAddress: addressValidator,
  addAsDirector: boolean().required(),
  ownershipNature: string()
    .required('Ownership Nature is Required')
    .matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Ownership Nature is Required'),
  ownershipNatureDescription: string().when('ownershipNature', {
    is: OwnershipNatureType.INDIRECT,
    then: string().required('Ownership nature description is required when ownership nature is indirect.'),
  }),
})

export const businessDirectorsValidator: SchemaOf<DirectorData> = object({
  directorId: string().required(),
  details: directorPersonalInformationValidator,
  isOwnerToo: boolean(),
})

export const identityVerificationValidator = object({
  idFront: string().matches(BASE64_REGEX),
  idBack: string().matches(BASE64_REGEX),
  identitySelfie: string().matches(BASE64_REGEX),
})

export const OnboardingStepsValidators: Record<OnboardingStep, AnyObjectSchema> = {
  [OnboardingStep.INTRODUCTION_0]: object({}),
  [OnboardingStep.DISCLOSURE_00]: object({}),
  [OnboardingStep.PERSONAL_INFORMATION_1]: personalInformationValidator,
  [OnboardingStep.ADDITIONAL_INFORMATION_2]: additionalInformationValidator,
  [OnboardingStep.PERSONAL_ADDRESS_3]: personalAddressValidator,
  [OnboardingStep.RELATIONSHIP_TO_THE_BUSINESS_40]: relationshipWithBusinessValidator,
  [OnboardingStep.ABOUT_BUSINESS_4]: aboutBusinessValidator,
  [OnboardingStep.BUSINESS_ADDRESS_5]: businessAddressValidator,
  [OnboardingStep.BUSINESS_OWNERS_6]: businessOwnersValidator,
  [OnboardingStep.BUSINESS_DIRECTORS_7]: businessDirectorsValidator,
  [OnboardingStep.INCORPORATION_DOCUMENTS_8]: object({}),
  [OnboardingStep.PLAID_9]: object({}),
  [OnboardingStep.IDENTITY_VERIFICATION_10]: identityVerificationValidator,
  [OnboardingStep.REVIEW_11]: object({}),
  [OnboardingStep.BUSINESS_DETAILS]: object({}),
  [OnboardingStep.INCORPORATION_DETAILS]: object({}),
  [OnboardingStep.BUSINESS_ADDRESS]: object({}),
  [OnboardingStep.LIST_OF_OWNERS]: object({}),
  [OnboardingStep.APPLICANT_IDENTITY]: object({}),
  [OnboardingStep.IDENTITY_VERIFICATION]: object({}),
  [OnboardingStep.FINANCIAL_DETAILS]: object({}),
  [OnboardingStep.REVIEW]: object({}),
  [OnboardingStep.CARD_SETUP]: object({}),
  [OnboardingStep.PRODUCTS_SELECTION]: object({}),
  [OnboardingStep.CARD_SETUP_INTRO]: object({}),
}

//Remove this once Login and signup V2 are released AND refer page is updated to v2
export const signupFormValidator = object({
  email: string().email('Must be a valid email').required('Email is required'),
  password: string()
    .required('Password is required')
    .min(passwordMinChar, `Password must contain at least ${passwordMinChar} characters`)
    .matches(passwordRegex, 'Password must contain at least 1 number'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], "Passwords don't match!")
    .required('Password confirmation is required'),
  acceptedTermsOfUse: boolean().required('You must accept the terms of use').oneOf([true]),
  acceptedPrivacyPolicy: boolean().required('You must accept the privacy policy').oneOf([true]),
  acceptedPlatformAgreement: boolean().required('You must accept the platform agreement').oneOf([true]),
})

export const forgotPasswordFormValidator = object({
  email: string().email('Must be a valid email').required('Email is required'),
})

export const newPasswordFormValidator = object({
  password: string()
    .required('Password is required')
    .min(passwordMinChar, `Password must contain at least ${passwordMinChar} characters`)
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], "Passwords don't match!")
    .required('Password confirmation is required'),
})

export const cardSpendLimitValidator = object({
  maxLimit: number(),
  spendLimitPeriod: string(),
  spendLimit: number().lessThan(ref('maxLimit')),
})

export const createAccountValidator = object({
  currency: string().required('Account currency is required'),
})

export const createCardValidator = object({
  useExistingCardholder: boolean().required(),
  nickname: string().required('Nickname is required').max(30, 'Nickname must be less than 30 characters'),
  existingCardholderUserId: string().when('useExistingCardholder', {
    is: true,
    then: string().required('existingCardholderUserId is required when useExistingCardholder is true'),
  }),
  newCardholderFirstName: string().when('useExistingCardholder', {
    is: false,
    then: string().required('First name is required').min(1, `First name is required`),
  }),
  newCardholderLastName: string().when('useExistingCardholder', {
    is: false,
    then: string().required('Last name is required').min(1, `Last name is required`),
  }),
  newCardholderEmail: string().when('useExistingCardholder', {
    is: false,
    then: string().email('Must be a valid email').required('Email is required'),
  }),
  maxSpendLimitAmount: number(),
  spendLimitAmount: number()
    .typeError('Spend limit is required')
    .required('Spend limit is required')
    .max(
      ref('maxSpendLimitAmount'),
      ({ max }) => `Spend limit must be less or equal than ${Money.fromNumber(max).toFormattedCurrencyString()}`
    ),
  spendLimitPeriod: string().oneOf([SpendLimitPeriod.DAILY, SpendLimitPeriod.WEEKLY, SpendLimitPeriod.MONTHLY]),
})

export const inviteUserValidator = object({
  newUserFirstname: string().required('First name is required').min(1, 'First name is required'),
  newUserLastname: string().required('Last name is required').min(1, 'Last name is required'),
  newUserEmail: string().required('Email is required').email('Must be a valid email'),
})

export const personalDetailsValidator = object({
  firstName: string().required('First Name is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'First Name is required'),
  lastName: string().required('Last Name is required').matches(NOT_JUST_EMPTY_SPACE_REGEX, 'Last Name is required'),
  phoneNumber: string().matches(PHONE_REGEX, 'Phone number is not valid').required('Phone number is required'),
  dateOfBirth: string()
    .required('Date of birth is required')
    .test({
      name: 'dateOfBirth',
      exclusive: true,
      message: 'You must be at least 18 years old to apply.',
      test: (value) => value == null || new Date(value) <= getYearsAgo(18),
    }),
  workingPosition: string().required('Position in the is required').min(1, 'Position in the is required'),
})

export const idValidationValidator = object({
  selfiePicture: array()
    .of(
      object({
        name: string().required(),
        type: string().required(),
        size: number().required(),
        path: string().required(),
        url: string().required(),
      })
    )
    .required('Selfie Picture is required')
    .length(1, '1 document is required'),
  licenseFront: array()
    .of(
      object({
        name: string().required(),
        type: string().required(),
        size: number().required(),
        path: string().required(),
        url: string().required(),
      })
    )
    .required(`Driver's licence front is required`)
    .length(1, '1 document is required'),
  licenseBack: array()
    .of(
      object({
        name: string().required(),
        type: string().required(),
        size: number().required(),
        path: string().required(),
        url: string().required(),
      })
    )
    .required(`Driver's licence back is required`)
    .length(1, '1 document is required'),
})

export const KycValidators: Record<EmployeeInvitationStep, AnyObjectSchema> = {
  [EMPLOYEE_INVITATION_STEP.SET_PASSWORD]: newPasswordFormValidator,
  [EMPLOYEE_INVITATION_STEP.PERSONAL_DETAILS]: personalDetailsValidator,
  [EMPLOYEE_INVITATION_STEP.HOME_ADDRESS]: addressValidator,
  [EMPLOYEE_INVITATION_STEP.ID_VALIDATION]: idValidationValidator,
}
