import { Tr } from '@chakra-ui/react'
import { Row } from '@tanstack/react-table'
import React from 'react'

import { DataGridTd } from './data-grid-td'
import { Styles } from './styles-context'

export const DataGridTr = <T,>({
  row,
  styles,
  enableRowClick = false,
}: {
  row: Row<T>
  styles: Styles
  enableRowClick?: boolean
}) => {
  return (
    <Tr
      key={row.id}
      aria-selected={row.getIsSelected()}
      sx={{
        ...(row.getIsSelected() ? { ...styles.selected } : {}),
      }}
      onClick={enableRowClick ? () => row.toggleSelected() : undefined}
      {...(enableRowClick ? { cursor: 'pointer' } : {})}
    >
      {row.getVisibleCells().map((cell) => (
        <DataGridTd<T> key={cell.id} cell={cell} styles={styles} />
      ))}
    </Tr>
  )
}
